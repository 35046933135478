<ng-container [ngSwitch]="type">
  <fa-icon
    *ngSwitchCase="'fontAwesome'"
    [icon]="getIcon(icon)"
    [class]="'icon icon-' + color"
    [ngClass]="{
      'icon-common-bg': color === 'common'
    }"
  ></fa-icon>

  <mat-icon
    *ngSwitchCase="'material'"
    [class]="'icon icon-' + color"
    [ngClass]="{
      'icon-common-bg': color === 'common'
    }"
  >
    {{ icon }}
  </mat-icon>

  <span
    *ngSwitchCase="'mo'"
    [class]="'icon ' + icon + ' icon-' + color"
    [ngClass]="{
      'icon-common-bg': color === 'common'
    }"
    [ngStyle]="{ 'font-size': size }"
  ></span>

  <img
    *ngSwitchCase="'image'"
    [class]="'icon-image icon-' + color"
    [ngClass]="{
      'icon-common-bg': color === 'common'
    }"
    [src]="'/assets/img/icon/' + icon"
  />
</ng-container>
