import { Component, Input, OnInit } from '@angular/core';
import { UIService } from '@app/services/ui.service';
import { UtilService } from '@app/services/util.service';

@Component({
  selector: 'form-input-clipboard',
  templateUrl: './input-clipboard.component.html',
  styleUrls: ['./input-clipboard.component.scss']
})
export class InputClipboardComponent {

  @Input() inputInfo;
  @Input() showPassword: boolean = false;
  @Input() isPasswordControls: boolean = false;
  public isApiKeyDisabled: boolean = true;
  public eyeToggleIcon: boolean;

  constructor(private _utilService: UtilService, private _uiService: UIService) { }

  onTooglePassword() {
    this.showPassword = !this.showPassword;
  }

  onCopyToClipboard(inputInfo: string) {
    this._utilService.copyClipboard(inputInfo);
    this._uiService.showSnackbar('ID copied', 2000, 'mat-simple-snackbar-success');
  }
  
}
