<div
  class="carousel-mobile"
  [ngClass]="{ absolute: isAbsolute }"
  (swipeleft)="next()"
  (swiperight)="prev()"
>
  <ng-container *ngTemplateOutlet="itemTemplate; context: ctx"></ng-container>
  <ul class="dotstyle dotstyle-scaleup">
    <li
      *ngFor="let item of slides; let i = index"
      [ngClass]="{ selected: currentSlide === i }"
    >
      <a [tabindex]="i" (click)="goToSlide(i)"> - </a>
    </li>
  </ul>
  <button
    mat-button
    *ngIf="extraButton"
    [class]="'btn-extra ' + extraButton.btnClass"
    [routerLink]="extraButton.url"
    [innerHTML]="extraButton.text"
  ></button>
</div>
