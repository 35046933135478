<div class="message-container">
  <ng-container [ngSwitch]="type">
    <section *ngSwitchCase="'success'" class="title success">
      <layout-icon icon="faCheckCircle" color="success"></layout-icon>
      <h1>Done</h1>
    </section>
    <section *ngSwitchCase="'fail'" class="title fail">
      <layout-icon icon="faTimesCircle" color="failure"></layout-icon>
      <h1>Fail</h1>
    </section>
    <section *ngSwitchCase="'warning'" class="title warning">
      <layout-icon icon="faExclamationTriangle" color="warning"></layout-icon>
      <h1>Warning</h1>
    </section>
  </ng-container>

  <h5>{{ title }}</h5>
  <p class="text-detail" [innerHTML]="description"></p>
</div>
