export const INSIGHT_COUNTRIES_SUCCESS = {
  data: [
    {
      country: 'Colombia',
      code: 'col',
    },
    {
      country: 'Estados Unidos',
      code: 'usa',
    },
  ],
};
