<div class="tabs-header">
  <div
    class="tab-header"
    *ngFor="let tab of config"
    (click)="selectTab(tab)"
    [ngClass]="{ active: tab.title === selectedTab.title }"
  >
    {{ tab.title }}
  </div>
</div>
<div class="tab-content">
  <ng-container *ngComponentOutlet="selectedTab.component"></ng-container>
</div>
