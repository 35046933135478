import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { of, throwError } from 'rxjs';
import { map, switchMap, tap, catchError } from 'rxjs/operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { AuthAPI } from '@app/services/auth/auth.api';
import { AuthService } from '@app/services/auth/auth.service';

import * as authActions from './auth.actions';
import * as userActions from '../user/user.actions';
import { Token } from '@app/core/models/app.model';
import { APP_CONSTANTS as CONST } from '@app/app.constants';
import { ErrorService } from '@app/services/error.service';
import { GoogleAnalyticsService } from '@app/services/google-analytics.service';
import { GA } from '@app/core/constants/google-analytics.constant';

@Injectable()
export class AuthEffects {
  constructor(
    private _actions$: Actions,
    private _authAPI: AuthAPI,
    private _authService: AuthService,
    private _errorService: ErrorService,
    private _router: Router,
    private _gaService: GoogleAnalyticsService
  ) {}

  loginSubmit$ = createEffect(() =>
    this._actions$.pipe(
      ofType(authActions.loginSubmit),
      switchMap((payload) =>
        this._authAPI.login(payload.loginData).pipe(
          map((response: Token) => {
            response.days_missing_free_trial =
              response.days_missing_free_trial &&
              response.days_missing_free_trial > 0
                ? response.days_missing_free_trial
                : 0;
            this._authService.saveToken(response);
            return userActions.consultUserLogin();
          }),
          catchError((_) => {
            this._errorService.showGlobalError(
              'The username or password are invalid'
            );

            const error = { message: 'The username or password are invalid' };
            return of(authActions.failureLogin({ error }));
          })
        )
      )
    )
  );

  logout$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(authActions.logout, userActions.logout),
        tap(() => {
          this._authService.removeToken();
          this._router.navigateByUrl(CONST.ROUTES.LOGIN);
        })
      ),
    { dispatch: false }
  );

  forgotPasswordSubmit$ = createEffect(() =>
    this._actions$.pipe(
      ofType(authActions.forgotPasswordSubmit),
      switchMap((payload) =>
        this._authAPI.generateLink(payload.data).pipe(
          map(() => authActions.successForgotPassword()),
          catchError((_) => {
            this._errorService.showGlobalError('The user does not exists');

            const error = { message: 'Usuario o contraseña inválido' };
            return of(authActions.failureForgotPassword({ error }));
          })
        )
      )
    )
  );
  createPasswordSubmit$ = createEffect(() =>
    this._actions$.pipe(
      ofType(authActions.createPasswordSubmit),
      switchMap((payload) =>
        this._authAPI.changePassword(payload.data).pipe(
          map(() => {
            this._gaService.sendEvent(GA.SINGUP.REQUEST_SUCCESS);
            this._authService.removeTokenPassword();
            return authActions.successCreatePassword();
          }),
          catchError((response) => {
            const errorMessage =
              response.error.detail ?? 'Invalid password format';
            this._gaService.sendEvent(GA.SINGUP.REQUEST_ERROR);
            this._errorService.showGlobalError(errorMessage);

            const error = { message: response.error.detail };
            return of(authActions.failureForgotPassword({ error }));
          })
        )
      )
    )
  );
}
