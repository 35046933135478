import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MoInputComponent } from './components/mo-input/mo-input.component';
import { MoErrorComponent } from './components/mo-error/mo-error.component';
import { FormUtilService } from './services/form-util.service';
import { RenderPageInputComponents } from './pages/inputs/render-page-input.component';
import { MoFormsRoutingModule } from './mo-forms.routing.module';
import { MoCheckbox } from './components/mo-checkbox/mo-checkbox.component';
import { MoToggleComponent } from './components/mo-toggle/mo-toggle.component';
import { MoSelectComponent } from './components/mo-select/mo-select.component';
import { MoDropDown } from './components/mo-dropdown/mo-drop-down.component';
import { MoCopyComponent } from './components/mo-copy-clipboard/mo-copy.component';
import { MoButtonFormComponent } from './components/mo-button-form/mo-button-form.component';
import { MoLoadingComponent } from './components/mo-loading/mo-loading.component';
import { CleanRegex } from './directives/clean-regex.directive';
import { MoChips } from './components/mo-chips/mo-chips.component';
import { RouterFormsComponent } from './pages/router/router-forms.component';
import { MoTextAreaComponent } from './components/mo-text-area/mo-text-area.component';
import { MoCopyLabel } from './components/mo-copy-label/mo-copy-label.component';
import { MoPlaces } from './components/mo-places/mo-places.component';
import { AgmCoreModule } from '@agm/core';
import { environment } from '@environment';
import { MoUploadComponent } from './components/mo-upload/mo-upload.component';
import { MoCaptchaComponent } from './components/mo-captcha/mo-captcha.component';

const COMPONENTS = [
  MoInputComponent,
  MoErrorComponent,
  MoCheckbox,
  MoToggleComponent,
  MoSelectComponent,
  MoDropDown,
  MoCopyComponent,
  MoButtonFormComponent,
  MoLoadingComponent,
  MoChips,
  MoTextAreaComponent,
  MoCopyLabel,
  MoPlaces,
  MoUploadComponent,
  MoCaptchaComponent,
];

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MoFormsRoutingModule,
    AgmCoreModule.forRoot({
      apiKey: environment.API_Key_Google_maps,
      libraries: ['places'],
    }),
  ],
  declarations: [
    ...COMPONENTS,
    RenderPageInputComponents,
    CleanRegex,
    RouterFormsComponent,
  ],
  exports: [...COMPONENTS, CleanRegex, FormsModule, ReactiveFormsModule],
  providers: [FormUtilService],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class MoFormsModule {}
