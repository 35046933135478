import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GA } from '@app/core/constants/google-analytics.constant';
import { Token } from '@app/core/models/app.model';
import {
  LOCATION_TYPES,
  RequestAddress,
  RequestCoordinates,
  RequestForm,
  RequestSuccess,
} from '@app/core/models/request.model';
import { AppFacade } from '@app/facades/app.facade';
import { InsightFacade } from '@app/facades/insight.facade';
import {} from '@app/layout/components/bottombar-modal/bottombar-interface';
import {
  COMPONENTS,
  MODAL_SIZE,
  MODULES,
} from '@app/layout/components/modal/modal-content.type';
import {
  BUTON_TYPE,
  BUTTON_SIZE,
  BUTTON_TEMPLATE,
} from '@app/mo-forms/enums/button.type';
import { INPUT_SIZE } from '@app/mo-forms/enums/input.type';
import { ISelectOption } from '@app/mo-forms/interfaces/select-option.interface';
import { INSIGHT_CONSTANTS } from '@app/pages/insight/insight.constants';
import { AuthService } from '@app/services/auth/auth.service';
import { GoogleAnalyticsService } from '@app/services/google-analytics.service';
import { Observable } from 'rxjs';
import { filter, first } from 'rxjs/operators';

@Component({
  selector: 'form-new-request',
  templateUrl: './form-new-request.component.html',
})
export class FormNewRequestComponent {
  public newRequestForm!: FormGroup;
  public locationTypes;
  public RADIUS_COORDINATES: number;
  public INPUT_SIZE = INPUT_SIZE;
  public BUTON_TEMPLATES = BUTTON_TEMPLATE;
  public BUTTON_SIZE = BUTTON_SIZE;
  public BUTON_TYPE = BUTON_TYPE;
  public configurationValues: Array<ISelectOption<string>>;
  public closeIntoInfo: boolean;

  public isResponded$: Observable<boolean>;
  public isLoading$: Observable<boolean>;
  public isLoadingConfigs$: Observable<boolean>;
  public error$: Observable<string>;
  public success$: Observable<RequestSuccess>;

  public requestRemaining: number;
  public daysLeft: number;
  public tokenData: Token;

  constructor(
    private _appFacade: AppFacade,
    private _insightFacade: InsightFacade,
    private _formBuilder: FormBuilder,
    private _gaService: GoogleAnalyticsService,
    private _authService: AuthService
  ) {
    this.RADIUS_COORDINATES = INSIGHT_CONSTANTS.RADIUS_MAP;
    this._mapLocationTypes();
    this._mapListConfigurations();
    this._setupForm();
    this._setDaysToLeft();
    this._setRequestRemaining();
    this._getToken();
  }

  public ngOnInit() {
    this._insightFacade.initRequestForms();
    this._gaService.sendEvent(GA.DOWNLOAD.PAGE_VIEW);
    this.isLoadingConfigs$ = this._insightFacade.isLoadingConfigsRequests$;
    this.success$ = this._insightFacade.requestSuccess$;
    this.error$ = this._insightFacade.errorRequestForm$;
    this.isLoading$ = this._insightFacade.isLoadingRequestForm$;
    this.isResponded$ = this._insightFacade.isRespondedRequestForm$;

    this.isResponded$
      .pipe(filter((isResponded) => !!isResponded))
      .pipe(first())
      .subscribe((value) => console.log(value));
  }

  public ngOnDestroy() {
    this._resetForms();
  }

  // Public methods
  public onSubmitForm() {
    if (this.newRequestForm.invalid) return false;
    if (
      !this.tokenData?.is_premium &&
      (this.daysLeft <= 0 || this.requestRemaining <= 0)
    ) {
      this._showUpgradeModal();
    } else {
      const type = this.newRequestForm.get('type').value;
      switch (type) {
        case LOCATION_TYPES.COORDINATES:
          this._createByCoordinates();
          break;
        case LOCATION_TYPES.ADDRESS:
          this._createByAddress();
          break;
        default:
          break;
      }
    }
  }

  public onClose() {
    this._resetForms();
  }

  // Private methods

  private _resetForms() {
    this.closeIntoInfo = true;
    this._insightFacade.leaveRequestForms();
  }

  private _mapLocationTypes() {
    this.locationTypes = INSIGHT_CONSTANTS.REQUEST_TYPE.map(
      ({ value, text }) => {
        return { value: value, key: text };
      }
    );
  }

  private _mapListConfigurations() {
    this._insightFacade.configurations$.subscribe((configurations) => {
      this.configurationValues = configurations.map((config) => {
        return { value: config.config_id, key: config.name };
      });
    });
  }

  private _setupForm() {
    this.newRequestForm = this._formBuilder.group({
      configId: ['', Validators.required],
      type: LOCATION_TYPES.COORDINATES,
      latitude: [''],
      longitude: [''],
      radius: [{ value: this.RADIUS_COORDINATES + 'km', disabled: true }],
      address: [''],
    });
    const typeField = this.newRequestForm.get('type');
    typeField.valueChanges.subscribe(() => this._requiredFieldsByType());
    typeField.setValue(LOCATION_TYPES.COORDINATES);
  }

  private _requiredFieldsByType() {
    const type = this.newRequestForm.get('type').value;
    switch (type) {
      case LOCATION_TYPES.COORDINATES:
        this._setupFieldsByCoordinates();
        break;
      case LOCATION_TYPES.ADDRESS:
        this.setupFieldsByAddress();
        break;
      default:
        break;
    }
  }

  private _setupFieldsByCoordinates() {
    this._putRequired('latitude');
    this._putRequired('longitude');
    this._putOptional('address');
  }

  private setupFieldsByAddress() {
    this._putOptional('latitude');
    this._putOptional('longitude');
    this._putRequired('address');
  }

  private _putRequired(fieldName: string) {
    this.newRequestForm.get(fieldName).setValidators([Validators.required]);
    this.newRequestForm.get(fieldName).updateValueAndValidity();
  }

  private _putOptional(fieldName: string) {
    this.newRequestForm.get(fieldName).clearValidators();
    this.newRequestForm.get(fieldName).updateValueAndValidity();
  }

  private _createByCoordinates() {
    const values = this.newRequestForm.value;
    const request: RequestCoordinates = {
      type: values.type,
      radius: this.RADIUS_COORDINATES,
      config_id: values.configId,
      longitude: values.longitude,
      latitude: values.latitude,
    };
    this._sendDataForm(request);
  }

  private _createByAddress() {
    const values = this.newRequestForm.value;
    const request: RequestAddress = {
      type: values.type,
      config_id: values.configId,
      radius: this.RADIUS_COORDINATES,
      address: values.address,
    };

    this._sendDataForm(request);
  }

  private _sendDataForm(request: RequestForm) {
    this._gaService.sendEvent(GA.DOWNLOAD.REQUEST);
    this._insightFacade.createRequest(request);
  }

  private _setDaysToLeft() {
    this._insightFacade.daysLeft$.subscribe((daysLeft) => {
      this.daysLeft = daysLeft;
    });
  }

  private _setRequestRemaining() {
    this._insightFacade.requestRemaining$.subscribe((requestRemaining) => {
      this.requestRemaining = requestRemaining;
    });
  }

  private _getToken() {
    this._authService.getToken().subscribe((token) => (this.tokenData = token));
  }

  private _showUpgradeModal() {
    this._appFacade.openModal({
      content: {
        component: COMPONENTS.UPGRADE_MY_PLAN,
        module: MODULES.GEO,
      },
      show: true,
      size: MODAL_SIZE.MD,
      showExitIcon: true,
      withoutPadding: true,
    });
  }
}
