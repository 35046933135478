import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { OnlyDevelopAccess } from '@app/core/guard/only-develop-access.guard';
import { RenderPageInputComponents } from './pages/inputs/render-page-input.component';
import { RouterFormsComponent } from './pages/router/router-forms.component';

const routes: Routes = [
  {
    path: 'ui',
    component: RouterFormsComponent,
    children: [
      {
        path: 'components',
        canActivate: [OnlyDevelopAccess],
        component: RenderPageInputComponents,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class MoFormsRoutingModule {}
