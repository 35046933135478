<div class="input-content">
  <div *ngIf="inputInfo != undefined">
    <input
      [value]="inputInfo"
      [disabled]="true"
      placeholder="Loading..."
    >
    <img class="clipboard"
      src="/assets/img/icon/clipboard.svg"
      (click)="onCopyToClipboard(inputInfo)">
    <div *ngIf="isPasswordControls">
      <img *ngIf="!showPassword" class="show-password"
      src="/assets/img/icon/eye-outline.png" (click)="onTooglePassword()">
      <img *ngIf="showPassword" class="show-password-off"
        src="/assets/img/icon/eye-off-outline.svg" (click)="onTooglePassword()">
    </div>
  </div>
</div>
