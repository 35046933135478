import { Error } from '@app/core/models/error';
import {
  CreatePasswordDataBody,
  ForgotPasswordDataBody,
  LoginData,
} from '@core/models/auth.model';
import { createAction, props } from '@ngrx/store';

export const loginSubmit = createAction('[Auth Page] LoginSubmit', props<{ loginData: LoginData }>());
export const failureLogin = createAction(
  '[Auth API] FailureLogin',
  props<{ error: Error }>()
);
export const logout = createAction('[Base template] logout');

export const forgotPasswordSubmit = createAction(
  '[Auth Page] ForgotPasswordSubmit',
  props<{ data: ForgotPasswordDataBody }>()
);
export const successForgotPassword = createAction(
  '[Auth API] SuccessForgotPassword'
);
export const failureForgotPassword = createAction(
  '[Auth API] FailureForgotPassword',
  props<{ error: Error }>()
);
export const createPasswordSubmit = createAction(
  '[Auth Page] CreatePasswordSubmit',
  props<{ data: CreatePasswordDataBody }>()
);
export const successCreatePassword = createAction(
  '[Auth API] SuccessCreatePassword'
);
export const failureCreatePassword = createAction(
  '[Auth API] FailureCreatePassword',
  props<{ error: Error }>()
);
export const successPasswordToken = createAction(
  '[Auth API] SuccessValidateToken'
);
export const failurePasswordToken = createAction(
  '[Auth API] FailureValidateToken',
  props<{ error: Error }>()
);
export const resetAuthPages = createAction('[Auth Pages] Reset create or forgot password');
