export const OVERVIEW_HEADER_SUCCESS = {
  data: {
    submited_docs: {
      total: 69,
      percent_previous_month: 69,
    },
    accepted: 69,
    not_accepted: 69,
    failed: 69,
    successful: 69,
  },
  response_code: 200,
  errors: [],
};
