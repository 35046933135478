import { Component, OnInit } from '@angular/core';
import { AppFacade } from '@app/facades/app.facade';
import { BUTTON_SIZE, BUTTON_TEMPLATE } from '@app/mo-forms/enums/button.type';

@Component({
  selector: 'app-done',
  templateUrl: './done.modal.html',
  styleUrls: ['./done.modal.scss'],
})
export class DoneModal implements OnInit {
  public BUTTON_TEMPLATES = BUTTON_TEMPLATE;
  public BUTTON_SIZE = BUTTON_SIZE;

  constructor(private _appFacade: AppFacade) {}

  ngOnInit(): void {}

  public onCloseModal(): void {
    this._appFacade.closeModal();
  }
}
