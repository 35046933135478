import { MODES_MAP, STYLES_MAP } from '@app/core/models/map.model';
import {
  LOCATION_TYPES,
  REQUEST_STATES,
  TYPE_FILE,
} from '@app/core/models/request.model';

export const INSIGHT_CONSTANTS = {
  RADIUS_MAP: 0.5,
  TIME_INTERVAL_REQUEST_STATUS: 20000,
  ATTEMPS_REQUEST_STATUS: 3,
  REQUEST_TYPE: [
    { value: LOCATION_TYPES.COORDINATES, text: 'Coordinates' },
    { value: LOCATION_TYPES.ADDRESS, text: 'Address' },
  ],
  REQUEST_TYPE_DOWNLOAD: [
    { value: TYPE_FILE.EXCEL, text: 'Excel' },
    { value: TYPE_FILE.CSV, text: 'CSV' },
    { value: TYPE_FILE.JSON, text: 'JSON' },
  ],
  REQUEST_STATES: {
    STATUS_FAIL: {
      className: 'fail',
      key: REQUEST_STATES.FAIL,
      name: 'Failed',
    },
    STATUS_SUCCESS: {
      className: 'success',
      key: REQUEST_STATES.SUCCESS,
      name: 'Completed',
    },
    STATUS_PENDING: {
      className: 'pending',
      key: REQUEST_STATES.PENDING,
      name: 'Pending',
    },
  },
  MAP_STYLES: [
    { value: STYLES_MAP.DARK, text: 'Dark' },
    { value: STYLES_MAP.LIGHT, text: 'Light' },
  ],
  MAP_MODES: [
    { value: MODES_MAP.REQUEST, text: 'Download results' },
    { value: MODES_MAP.HEATMAP, text: 'Heatmap' },
  ],
};
