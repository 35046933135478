export enum BUTTON_SIZE {
  SM = 'size-sm',
  MD = 'size-md',
  LG = 'size-lg',
}
export enum BUTTON_TEMPLATE {
  DEFAULT = 'templeate-default',
  OUTLINE_DEFAULT = 'outline-default',
  OUTLINE_PRIMARY = 'outline-primary',
  OUTLINE_INFO = 'outline-info',
  PRIMARY = 'templeate-primary',
  INDIGO = 'template-indigo',
  OUTLINE_INDIGO = 'outline-indigo',
  ALTERNATIVE = 'template-alternative',
  ALTERNATIVE_ACTIVE = 'template-alternative active',
}

export enum BUTON_TYPE {
  BUTTON = 'button',
  SUBMIT = 'submit',
  RESET = 'reset',
}
