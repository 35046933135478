import { Component, Input } from '@angular/core';
import { SidebarModalRef } from '@app/layout/components/sidebar-modal/sidebar-modal.ref';
import {
  ISidebarModal,
  ISidebarModalCallback,
} from '../models/sidebar-modal.model';

@Component({
  template: '',
})
export class SidebarModal implements ISidebarModal {
  @Input() title: string;
  @Input() message: string;
  @Input() callbacks: ISidebarModalCallback;

  constructor(public sidebarModalRef: SidebarModalRef) {}
  public onCancelClick(): void {
    this.sidebarModalRef.close();
    if (this.callbacks) {
      this._execFunction(this.callbacks.close);
    }
  }

  public onOkClick(): void {
    if (this.callbacks) {
      this._execFunction(this.callbacks.ok);
    }
  }

  public onCloseClick(): void {
    this.sidebarModalRef.close();
    if (this.callbacks) {
      this._execFunction(this.callbacks.cancel);
    }
  }

  private _execFunction(fn?: Function) {
    if (fn && fn instanceof Function) {
      fn();
    }
  }
}
