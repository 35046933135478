export const TEMPLATES_VERSION_FIELDS_SUCCESS = {
  data: [
    {
      id: 1,
      name: 'v1',
      description: [
        {
          name: 'bank_name',
          description: 'a bank name',
          metadata: {
            example_value: 'This is only a mock structure',
          },
        },
        {
          name: 'customer_name',
          description: 'a customer name',
          metadata: {
            example_value: 'This is only a mock structure',
          },
        },
      ],
      entity_name: 'Banco Azteca',
      preview_url:
        'https://temp-fetch-alpha-public-previews.s3.amazonaws.com/preview-demo.pdf',
    },
  ],
  response_code: 200,
  errors: null,
};
