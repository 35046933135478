<div class="box-upgrade-my-plan">
  <div class="bg-figure">
    <img src="/assets/img/layout/frame-upgrade.png" />
  </div>
  <div class="upgrade-info">
    <div class="upgrade-text-info m-b-20">Your free trial has expired!</div>
    <h2>Upgrade to a paid account, to have full access</h2>
    <p class="m-t-15">Upgrade your plan and to get:</p>
    <ul>
      <li>Unlimited requests.</li>
      <li>Ability to upload multiple addresses in one request.</li>
      <li>24/7 customer support.</li>
    </ul>
    <div class="m-t-35">
      <mo-button-form
        text="Upgrade my plan"
        (click)="upgradeMyPlan()"
        [isLoading]="isloadingForms$ | async"
        [template]="BUTTON_TEMPLATES.PRIMARY"
        [size]="BUTTON_SIZE.MD"
      ></mo-button-form>
    </div>
  </div>
</div>
