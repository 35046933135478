import { Component, OnInit, OnDestroy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { AppFacade } from '@facades/app.facade';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  private _routeSubs: Subscription;

  constructor(private _router: Router, private _appFacade: AppFacade){}
  ngOnInit() {
    this._routeSubs = this._router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        this._appFacade.hideSplash();
        window.scrollTo(0, 0);
      });
  }

  ngOnDestroy() {
    this._routeSubs.unsubscribe();
  }
}
