<form
  class="configuration-form"
  [formGroup]="form"
  [appFormValidate]="form"
  (ngSubmit)="onContinue()"
>
  <h5 class="select-options-title">Basic information</h5>
  <form-select
    label="Country"
    formControlName="country"
    class="country"
    [isDefaultFirst]="true"
    [data]="countries"
    keyText="country"
    keyValue="code"
  >
  </form-select>
  <form-input-regex [regex]="regexCode" formControlName="name" label="Name">
  </form-input-regex>
  <form-input-text formControlName="description" label="Description">
  </form-input-text>

  <div class="select-options">
    <h5 class="select-options-title">Select KPI groups</h5>
    <div class="select-groups" formArrayName="kpi_groups">
      <ng-container *ngIf="kpi_groups?.controls; else empty">
        <div
          class="item"
          *ngFor="let group of kpi_groups.controls; let i = index"
        >
          <label
            [ngClass]="{ 'item-label--selected': kpi_groups.value[i] }"
            class="item-label"
          >
            {{ groups[i].group_name }}
          </label>
          <form-toggle-button [formControlName]="i"></form-toggle-button>
        </div>
      </ng-container>
      <ng-template #empty>
        The country selected does not have KPIs groups
      </ng-template>
    </div>
  </div>
  <div class="button-content">
    <button class="btn-primary-bg" type="submit">Create</button>
  </div>
</form>
