import { animate, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'layout-user-card',
  templateUrl: './user-card.component.html',
  styleUrls: ['./user-card.component.scss'],
  animations: [
    trigger('inOutAnimation', [
      transition(':enter', [
        style({ height: 0, opacity: 0 }),
        animate('300ms ease-in-out', style({ height: 'auto', opacity: 1 })),
      ]),
      transition(':leave', [
        style({ height: 'auto', opacity: 1 }),
        animate('300ms ease-in-out', style({ height: 0, opacity: 0 })),
      ]),
    ]),
  ],
})
export class UserCardComponent implements OnInit {
  @Input() active: boolean;
  @Input() label: string;

  constructor() {}

  ngOnInit(): void {}
}
