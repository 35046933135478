import { ViewContainerRef } from '@angular/core';
import { ISidebarExtraData } from '@app/core/models/sidebar-modal.model';

export class SidebarModalRef {
  public container: ViewContainerRef;
  private _close: Function;
  private _data: ISidebarExtraData;

  getData() {
    return this._data;
  }
  
  setData(data: ISidebarExtraData) {
    this._data = data;
  }

  setContainer(container: ViewContainerRef) {
    this.container = container;
  }

  setClose(close: Function) {
    this._close = close;
  }

  close() {
    if (this._close && this._close instanceof Function) {
      this._close();
    }
  }
}
