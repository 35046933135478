export const CONFIGURATIONS_SUCCESS = {
  data: [
    {
      config_id: '4f2fb31b-48aa-4ae3-86ba-b4fae93e378e',
      name: 'Segunda configuración',
      description:
        'Esta configuración se crea después de hacer la migración jeje',
      group_count: 3,
      kpi_count: 1521,
      creation_date: '2021-04-15 16:08:43',
      last_used_date: '2021-04-15 16:08:43',
      status: false,
      groups: [
        { group_key: 'Peop', group_name: 'People' },
        { group_key: 'Comm', group_name: 'Commerce' },
        { group_key: 'Gove', group_name: 'Government/Public services' },
      ],
      requests: [
        {
          id: '76e41190-4750-4754-97af-e1ea43315ed8',
          creation_date: '2021-05-19T20:03:51.968967+00:00',
          updated_at: '2021-05-19T20:03:51.969015+00:00',
          active: true,
          body: {
            radius: 0.5,
            latitude: 29.7604,
            config_id: 'ab835b67-8418-407e-8ea3-ccec5dee0786',
            longitude: -95.3698,
          },
          status__name: 'Pending',
        },
      ],
      premium_kpis: true,
    },
    {
      config_id: '2fcb56d6-b86d-4ff0-87db-cece8d8e42ee',
      name: 'Configuration test Julieth',
      description:
        'Julieth está probando la edición de las configuraciones con People ',
      group_count: 3,
      kpi_count: 815,
      creation_date: '2021-04-08 22:33:23',
      last_used_date: '2021-04-08 22:33:23',
      status: true,
      groups: [
        { group_key: 'Peop', group_name: 'People' },
        { group_key: 'Gove', group_name: 'Government/Public services' },
        { group_key: 'Envi', group_name: 'Environment' },
      ],
      requests: [
        {
          id: '76e41190-4750-4754-97af-e1ea43315ed8',
          creation_date: '2021-05-19T20:03:51.968967+00:00',
          updated_at: '2021-05-19T20:03:51.969015+00:00',
          body: {
            radius: 0.5,
            latitude: 29.7604,
            config_id: 'ab835b67-8418-407e-8ea3-ccec5dee0786',
            longitude: -95.3698,
          },
          status__name: 'Pending',
        },
      ],
      premium_kpis: false,
    },
  ],
  response_code: null,
  errors: [],
  total: 2,
  page: 0,
  size: 10,
};
