import { Injectable, ValueSansProvider } from '@angular/core';
import { PAGES_TYPE } from '@app/core/enums/pages.type';
import { Filters, OrderFilter } from '@app/core/models/table.model';
import { InsightFacade } from '@app/facades/insight.facade';
import { PAGE_SIZE_DEFAULT } from '@app/mo-table/constants/page-size.constant';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FiltersService<T extends Filters> {
  private _filters: T;
  private _currentPage: BehaviorSubject<number>;
  public currentPage$: Observable<number>;

  constructor(private _insightFacade: InsightFacade) {
    this._currentPage = new BehaviorSubject<number>(1);
    this.currentPage$ = this._currentPage.asObservable();
  }

  set setFilters(filters) {
    let newFilters = {};
    Object.keys(filters).forEach((key) => {
      if (!filters[key]) {
        return;
      }
      newFilters = { ...newFilters, [key]: filters[key] };
    });
    newFilters = {
      ...newFilters,
      page: 0,
    };
    if (this._filters?.size) {
      newFilters = {
        ...newFilters,
        size: this._filters?.size,
      };
    }
    if (this._filters?.order_by) {
      newFilters = {
        ...newFilters,
        order_by: this._filters?.order_by,
      };
    }
    this._filters = { ...(newFilters as T) };
    this._currentPage.next(1);
  }

  set setFilterPage(page: number) {
    this._filters = {
      ...this._filters,
      page,
    };
    this._currentPage.next(page + 1);
  }

  set setFilterSort(orderFilter: OrderFilter) {
    this._filters = {
      ...this._filters,
      sort: orderFilter?.sort,
      order_by: orderFilter?.order_by,
    };
    if (!orderFilter?.sort) delete this._filters.sort;
    if (!orderFilter?.order_by) delete this._filters.order_by;
  }

  set setFilterSize(size: number) {
    this._filters = {
      ...this._filters,
      size,
      page: 0,
    };
    this._currentPage.next(1);
  }

  get getFilters(): T {
    return this._filters;
  }

  public resetFilters(page: PAGES_TYPE): void {
    this._insightFacade.country$.subscribe((country) => {
      const reset = {
        size: PAGE_SIZE_DEFAULT.toString(),
        country: country,
      };
      this._filters = {} as T;
      switch (page) {
        case PAGES_TYPE.CONFIGURATION:
          this._insightFacade.changeFiltersConfigurations(reset);
          break;
        case PAGES_TYPE.REQUEST:
          this._insightFacade.changeFiltersRequests(reset);
          break;
        case PAGES_TYPE.STANDARD_DATA:
          this._insightFacade.changeFiltersLibrary(reset);
          break;
        case PAGES_TYPE.PREMIUM_INDEXES:
          this._insightFacade.changeFiltersPremium(reset);
          break;
      }
    });
  }
}
