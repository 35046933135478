import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { COLOR_TEMPLATE, INPUT_SIZE } from '@app/mo-forms/enums/input.type';
import { ISelectOption } from '@app/mo-forms/interfaces/select-option.interface';
import { FormUtilService } from '@app/mo-forms/services/form-util.service';

@Component({
  selector: 'mo-select',
  templateUrl: './mo-select.component.html',
  styleUrls: ['./mo-select.component.scss'],
})
export class MoSelectComponent<T> {
  @Input() form!: FormGroup;
  @Input() controlName!: string;
  @Input() template: COLOR_TEMPLATE;
  @Input() options: Array<ISelectOption<string>> = [];
  @Input() defaultOptionName: string;
  @Input() size: INPUT_SIZE;
  @Input() label: string;
  @Input() controlWithoutSpaces: boolean;
  @Output() changeValue: EventEmitter<void>;

  public TEMPLATES = COLOR_TEMPLATE;

  constructor(public formUtilService: FormUtilService) {
    this.changeValue = new EventEmitter<void>();
  }

  onChange() {
    this.changeValue.emit();
  }
}
