import { ComponentType } from '@angular/cdk/portal';
import { ISidebarModal, ISidebarModalParams } from './sidebar-modal.model';

export enum ShortcutsName {
  NEW_REQUEST,
  NEW_CONFIGURATION,
}
export type ShortcutName = ShortcutsName;
export interface Shortcut {
  title: string;
  name: ShortcutName;
  typeIcon?: 'image' | 'material' | 'mo';
  icon: string;
  iconSize?: string;
  component?: ComponentType<ISidebarModal>;
  onSelect?(): void;
  data?: ISidebarModalParams;
}

export interface Menu {
  productLogo: string;
  productLogoCollapsed: string;
  product: string;
  items: MenuItem[];
}

export interface MenuItem {
  type: string;
  label?: string;
  icon?: string;
  shortLabel?: string;
  path?: string;
  item?: SubMenu;
}

interface SubMenu {
  type: string;
  items: itemSubMenu[];
}

interface itemSubMenu {
  type: string;
  label: string;
  path?: string;
  isSubMenu?: boolean;
  icon?: string;
}
