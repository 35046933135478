import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { MatMenuModule } from '@angular/material/menu';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';

import { AgmCoreModule } from '@agm/core';
import { environment } from '@environment';

import { DialogComponent } from './components/dialog/dialog.component';
import { ConfettiComponent } from './components/confetti/confetti.component';
import { SplashComponent } from './components/splash/splash.component';
import { ImagesSlideComponent } from './components/images-slide/images-slide.component';
import { BaseLoggedComponent } from './base-logged/base-logged.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { FooterComponent } from './components/footer/footer.component';
import { TitlePageComponent } from './components/title-page/title-page.component';
// TODO: GIC-913 table Reemplazar por la tabla nueva sin generar conflictos.
import { TableComponent } from './components/table/table.component';
import { PaginatorComponent } from './components/paginator/paginator.component';
import { PercentagePipe } from './pipes/percentage.pipe';
import { CurrencyPipe } from './pipes/currency.pipe';
import { DatePipe } from './pipes/date.pipe';
import { DateTimePipe } from './pipes/datetime.pipe';
import { SidebarModalModule } from './components/sidebar-modal/sidebar-modal.module';
import { TableContainerComponent } from './components/table-container/table-container.component';
import { IconComponent } from './components/icon/icon.component';
import { PaginationSummaryComponent } from './components/pagination-summary/pagination-summary.component';
import { NavbarSelectProductComponent } from './components/navbar-select-product/navbar-select-product.component';
import { FilterButtonComponent } from './components/filter-button/filter-button.component';
import { SidePanelComponent } from './components/side-panel/side-panel.component';
import { SidePanelItemComponent } from './components/side-panel-item/side-panel-item.component';
import { ConfirmMessageComponent } from './components/confirm-message/confirm-message.component';
import { NotificationComponent } from './components/notification/notification.component';
import { MenuSidebarModule } from './components/menu-sidebar/menu-sidebar.module';

// Dumb Components
import { CardComponent } from './components/dumb/card/card.component';
import { StatusInfoComponent } from './components/dumb/status-info/status-info.component';

// Smart Components
import { StatusFilterComponent } from './components/smart/status-filter/status-filter.component';
import { UserCardComponent } from './components/smart/user-card/user-card.component';
import { UserCardMenuComponent } from './components/smart/user-card-menu/user-card-menu.component';
import { BottomBarModalComponent } from './components/bottombar-modal/bottombar-modal.component';
import { MoFormsModule } from '@app/mo-forms/mo-forms.module';
import { LayoutBComponent } from './layout-background/layout-background.component';
import { LabelIdComponent } from './components/smart/label-id/label-id.component';
import { TooltipDirective } from './directives/tooltip.directive';
import { TooltipComponent } from './components/tooltip/tooltip.component';
import { TabsManagerComponent } from './components/tabs-manager/tabs-manager.component';
import { BoxInfoComponent } from './components/box-info/box-info.component';
import { ModalComponent } from './components/modal/modal.component';
import { SupportFormComponent } from './support/support-form/support-form.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedFormsPipesModule } from '@app/shared-forms/shared-forms-pipes.module';
import { SupportSuccessComponent } from './support/support-success/support-success.component';
import { AuthResponseDataComponent } from './components/modal/geo/auth-response-data/auth-response-data.component';
import { UpgradeMyPlanComponent } from './components/modal/geo/upgrade-my-plan/upgrade-my-plan.component';
import { StepWelcomeComponent } from './components/modal/geo/step-welcome/step-welcome.component';
import { BannerRemainingComponent } from './components/banner-remaining/banner-remaining.component';

const components = [
  LabelIdComponent,
  UserCardMenuComponent,
  UserCardComponent,
  StatusFilterComponent,
  StatusInfoComponent,
  DialogComponent,
  ConfettiComponent,
  SplashComponent,
  ImagesSlideComponent,
  NavbarComponent,
  FooterComponent,
  BaseLoggedComponent,
  SupportFormComponent,
  SupportSuccessComponent,
  TitlePageComponent,
  CardComponent,
  TableComponent,
  PaginatorComponent,
  PercentagePipe,
  CurrencyPipe,
  DatePipe,
  DateTimePipe,
  TableContainerComponent,
  IconComponent,
  PaginationSummaryComponent,
  NavbarSelectProductComponent,
  FilterButtonComponent,
  SidePanelComponent,
  SidePanelItemComponent,
  ConfirmMessageComponent,
  NotificationComponent,
  BottomBarModalComponent,
  LayoutBComponent,
  TooltipDirective,
  TooltipComponent,
  TabsManagerComponent,
  BoxInfoComponent,
  ModalComponent,
  AuthResponseDataComponent,
  UpgradeMyPlanComponent,
  StepWelcomeComponent,
  BannerRemainingComponent,
];

@NgModule({
  declarations: [...components],
  imports: [
    CommonModule,
    RouterModule,
    MatSnackBarModule,
    MatDialogModule,
    MatMenuModule,
    MatProgressSpinnerModule,
    MatIconModule,
    FontAwesomeModule,
    SidebarModalModule,
    MenuSidebarModule,
    MoFormsModule,
    FormsModule,
    ReactiveFormsModule,
    SharedFormsPipesModule,
    AgmCoreModule.forRoot({
      apiKey: environment.API_Key_Google_maps,
      libraries: ['places'],
    }),
  ],
  entryComponents: [DialogComponent],
  exports: [...components, MenuSidebarModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class LayoutModule {}
