import { Component, OnDestroy, OnInit } from '@angular/core';
import { FETCH, INSIGHT } from '@app/core/constants/products.constant';
import { Product } from '@app/core/models/app.model';
import { AppFacade } from '@app/facades/app.facade';
import { BUTTON_SIZE, BUTTON_TEMPLATE } from '@app/mo-forms/enums/button.type';
import * as insightActions from '@app/store/insight/insight.actions';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'layout-support-success',
  templateUrl: './support-success.component.html',
  styleUrls: ['./support-success.component.scss'],
})
export class SupportSuccessComponent implements OnInit, OnDestroy {
  public productSelected$: Observable<Product>;
  private _productSelected$: Subscription;
  public buttonTemplate: BUTTON_TEMPLATE;
  public BUTTON_SIZE = BUTTON_SIZE;

  constructor(private _store: Store, private _appFacade: AppFacade) {}

  ngOnInit(): void {
    this.productSelected$ = this._appFacade.productSelected$;
    this._setButtonColorSubscription();
  }

  ngOnDestroy(): void {
    this._productSelected$.unsubscribe();
  }

  private _setButtonColorSubscription(): void {
    this._productSelected$ = this.productSelected$.subscribe(
      (product: Product) => {
        switch (product?.backendName.toLowerCase()) {
          case INSIGHT.backendName: {
            this.buttonTemplate = BUTTON_TEMPLATE.PRIMARY;
            break;
          }
          case FETCH.backendName: {
            this.buttonTemplate = BUTTON_TEMPLATE.INDIGO;
            break;
          }
          default: {
            this.buttonTemplate = BUTTON_TEMPLATE.PRIMARY;
            break;
          }
        }
      }
    );
  }

  onGoBack() {
    this._store.dispatch(insightActions.leaveSuccessSuport());
  }
}
