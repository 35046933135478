<div class="box-content-bottom-forms" *ngIf="step$ | async as step">
  <form
    *ngIf="step === 1"
    [formGroup]="newConfigurationForm"
    (ngSubmit)="onSubmitForm()"
  >
    <div class="row">
      <div class="col-sm-6">
        <mo-input
          cleanRegex
          [formDirective]="newConfigurationForm"
          [controlNameDirective]="'name'"
          [regex]="regexCode"
          controlName="name"
          placeHolder="Name"
          [type]="'text'"
          [form]="newConfigurationForm"
          [size]="INPUT_SIZE.MD"
        >
        </mo-input>
      </div>
      <div class="col-sm-6">
        <mo-input
          controlName="description"
          placeHolder="Description"
          [type]="'text'"
          [form]="newConfigurationForm"
          [size]="INPUT_SIZE.MD"
        >
        </mo-input>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-6">
        <div class="box-premium">
          <i class="uil uil-bolt"></i>
          <p>Include <span>Premium Indexes</span></p>
          <mo-toggle
            class="control"
            [form]="newConfigurationForm"
            controlName="premium_kpis"
            [template]="INPUT_TEMPLATES.INDIGO"
          ></mo-toggle>
        </div>
      </div>
    </div>

    <div class="row m-t-20">
      <div class="col-sm-10">
        <div class="select-options">
          <div>
            <mo-chips
              label="Select Groups"
              [form]="newConfigurationForm"
              [controlName]="'kpi_groups'"
              [options]="kpisValues"
            ></mo-chips>
          </div>
        </div>
      </div>
      <div class="col-sm-2">
        <mo-button-form
          class="block-box m-t-25"
          [isDisabled]="newConfigurationForm.invalid"
          [text]="'Create'"
          [isLoading]="isLoading$ | async"
          [type]="BUTON_TYPE.SUBMIT"
          [template]="BUTON_TEMPLATES.PRIMARY"
          [size]="BUTTON_SIZE.MD"
        ></mo-button-form>
      </div>
    </div>
  </form>
  <div class="messagge-info-container" *ngIf="step === 2">
    <insight-configuration-form-response
      [error]="error$ | async"
      (close)="onClose()"
      [isEditConfig]="false"
      [configurationId]="(configuration$ | async)?.config_id"
    >
    </insight-configuration-form-response>
  </div>
</div>
