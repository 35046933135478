import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { fas } from '@fortawesome/free-solid-svg-icons';

import { APP_CONSTANTS as CONSTANTS } from '@app/app.constants';
import { User } from '@app/core/models/user.model';
import { Product } from '@app/core/models/app.model';

@Component({
  selector: 'layout-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent {
  public isVisible: boolean;
  @Input() user: User;
  @Input() products: Product[];
  @Input() productSelected: Product;
  @Input() isMenuCollapsed: boolean;
  @Output() logout: EventEmitter<boolean>;
  @Output() selectProduct: EventEmitter<Product>;

  constructor(private _router: Router) {
    this.logout = new EventEmitter();
    this.selectProduct = new EventEmitter();
  }

  public getIcon(name: string) {
    return fas[name];
  }

  onGoToLanding() {
    this._router.navigate([CONSTANTS.ROUTES.LANDING]);
  }

  onOpenMenu() {
    this.isVisible = !this.isVisible;
  }

  onLogout() {
    this.isVisible = false;
    this.logout.emit(true);
  }

  onSelectProduct(product: Product) {
    this.selectProduct.emit(product);
  }
}
