import { Directive, HostListener, Input } from '@angular/core';
import { FormGroup, NgModel } from '@angular/forms';

@Directive({
  selector: '[cleanRegex]',
})
export class CleanRegex {
  @Input() formDirective: FormGroup;
  @Input() controlNameDirective: string;
  @Input() regex: RegExp;

  @HostListener('input', ['$event']) onChange(event) {
    let newValue = event.target.value;
    if (!this.regex.test(event.target.value)) {
      newValue = newValue.slice(0, -1);
    }
    this.formDirective.get(this.controlNameDirective).setValue(newValue);
  }
}
