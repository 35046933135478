import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AppFacade } from '@app/facades/app.facade';
import { BUTTON_SIZE, BUTTON_TEMPLATE } from '@app/mo-forms/enums/button.type';
import { AuthService } from '@app/services/auth/auth.service';
import { APP_CONSTANTS as CONST } from '@app/app.constants';
import { InsightFacade } from '@app/facades/insight.facade';

@Component({
  selector: 'step-welcome',
  templateUrl: './step-welcome.component.html',
  styleUrls: ['./step-welcome.component.scss'],
})
export class StepWelcomeComponent implements OnInit {
  @ViewChild('videoPlayer') videoPlayer: ElementRef;

  public BUTTON_TEMPLATES = BUTTON_TEMPLATE;
  public BUTTON_SIZE = BUTTON_SIZE;
  public steps = [1, 2];
  public progress = [];
  public step = 1;
  public defaultWidth: number;
  public tutorialUrl: string = CONST.TUTORIAL_URL;

  constructor(
    private _appFacade: AppFacade,
    private _authService: AuthService,
    private _insightFacade: InsightFacade
  ) {}

  ngOnInit() {
    this.progress.push(this.step);
    this.defaultWidth = this.calculateWidthProgressBar();
  }

  public hasStepInProgress(step: number): boolean {
    return this.progress.some((stepProgress) => stepProgress === step);
  }

  public calculateWidthProgressBar(): number {
    return (this.progress.length / this.steps.length) * 100;
  }

  public nextStep() {
    this.step++;
    this.progress.push(this.step);
    this.defaultWidth = this.calculateWidthProgressBar();
  }

  public toggleVideo(event: any) {
    this.videoPlayer.nativeElement.play();
  }

  public downloadAndFinish() {
    this._authService.saveFlagShowWelcomeModal();
    this._insightFacade.downloadManualDocument();
    this._appFacade.closeModal();
  }
}
