<div class="progress-container">
  <div class="progress" [ngStyle]="{ width: defaultWidth + '%' }"></div>

  <div
    *ngFor="let step of steps"
    class="circle-step"
    [ngClass]="{ 'active-step': progress.length >= step }"
  >
    {{ step }}
  </div>
</div>
<div class="content-steps">
  <div [ngSwitch]="step">
    <div *ngSwitchCase="1">
      <div class="step-video">
        <h3>How to use GEO</h3>
        <p>
          This quick tutorial will get you ready to start experiencing the power
          of Geo
        </p>
        <div class="video">
          <video width="420" controls (click)="toggleVideo()" #videoPlayer>
            <source [src]="tutorialUrl" type="video/mp4" />
            Browser not supported
          </video>
        </div>
        <div class="box-action-button">
          <mo-button-form
            (click)="nextStep()"
            text="Continue"
            [template]="BUTTON_TEMPLATES.PRIMARY"
            [size]="BUTTON_SIZE.MD"
          ></mo-button-form>
        </div>
      </div>
    </div>
    <div *ngSwitchCase="2">
      <div class="step-pdf">
        <h3>User manual</h3>
        <img src="./assets/img/icon/instruction-manual-animate.svg" />
        <p>
          "Geo's user manual will help you understand how to incorporate our
          data into your credit risk models. For optimal results, it's critical
          you read through the manual.
        </p>
        <div class="box-action-button">
          <mo-button-form
            (click)="downloadAndFinish()"
            text="Download and finish"
            [template]="BUTTON_TEMPLATES.PRIMARY"
            [size]="BUTTON_SIZE.MD"
          ></mo-button-form>
        </div>
      </div>
    </div>
  </div>
</div>
