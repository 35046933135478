import { Component, Input, OnInit } from '@angular/core';
import { FormNewBatchComponent } from '@app/pages/insight/request/forms/new-batch/form-new-batch.component';
import { FormNewRequestComponent } from '@app/pages/insight/request/forms/new-request/form-new-request.component';
import { TabsManagerConfig } from './tabs-manager-config.interface';

@Component({
  selector: 'tabs-manager-component',
  templateUrl: './tabs-manager.component.html',
  styleUrls: ['./tabs-manager.component.scss'],
})
export class TabsManagerComponent implements OnInit {
  @Input() config: Array<TabsManagerConfig>;
  public selectedTab: TabsManagerConfig;

  ngOnInit() {
    this.selectedTab = this.config[0];
  }

  public selectTab(config: TabsManagerConfig): void {
    this.selectedTab = config;
  }
}
