<div
  class="bg-form-group"
  [ngClass]="{ 'with-out-spaces': controlWithoutSpaces }"
  [formGroup]="form"
>
  <div class="common-form-group">
    <label #labelId class="mo-forms" *ngIf="label">{{ label }} </label>
    <div class="relative-box">
      <input
        #input
        [class]="'common-form-control ' + template + ' ' + size"
        [placeholder]="placeholder"
        [id]="controlName"
        [formControlName]="controlName"
        type="text"
      />
    </div>
  </div>
  <mo-error
    *ngIf="_formUtilService.validErrors(form, controlName)"
    [error]="_formUtilService.renderKeyError(form.get(controlName)?.errors)"
  ></mo-error>
</div>
