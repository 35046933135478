<div class="base">
  <layout-menu-sidebar
    class="sidebar"
    typeSidebar="FIXED"
    [menu]="menu$ | async"
    [isCollapsed]="isMenuCollapsed$ | async"
    (logout)="onLogout()"
  >
    <layout-navbar
      class="navbar"
      (logout)="onLogout()"
      [user]="user$ | async"
      (selectProduct)="onSelectProduct($event)"
      (toggleMenu)="onToggleCollapsedMenu($event)"
      [isMenuCollapsed]="isMenuCollapsed$ | async"
      [products]="products$ | async"
      [productSelected]="productSelected$ | async"
    ></layout-navbar>
    <div class="wrapper">
      <div class="content">
        <router-outlet></router-outlet>
      </div>
    </div>
    <layout-bottombar-modal
      [config]="bottomBarConfiguration"
    ></layout-bottombar-modal>
  </layout-menu-sidebar>
</div>
