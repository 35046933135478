import { FilesViewerComponent } from '@app/pages/fetch/documents/components/files-viewer/files-viewer.component';
import { AuthResponseDataComponent } from './geo/auth-response-data/auth-response-data.component';
import { DoneModal } from '@app/pages/fetch/support/pages/propose-a-template/components/done/done.modal';
import { AllFileRequirementsComponent } from './geo/container-components/all-file-requirements.component';
import { StepWelcomeComponent } from './geo/step-welcome/step-welcome.component';
import { UpgradeMyPlanComponent } from './geo/upgrade-my-plan/upgrade-my-plan.component';

export enum MODULES {
  GEO = 'GEO',
  FETCH = 'FETCH',
}

export enum COMPONENTS {
  FILE_REQUIREMENTS = 'FILE_REQUIREMENTS',
  FILES_VIEWER = 'FILES_VIEWER',
  AUTH_RESPONSE_DATA = 'AUTH_RESPONSE_DATA',
  UPGRADE_MY_PLAN = 'UPGRADE_MY_PLAN',
  STEP_WELCOME = 'STEP_WELCOME',
  DONE_UPLOAD_FILE = 'DONE_UPLOAD_FILE',
}

export enum MODAL_SIZE {
  SM = 'modal-sm',
  MD = 'modal-md',
  LG = 'modal-lg',
}

export const MODAL_CONTENT_TYPE = {
  GEO: {
    FILE_REQUIREMENTS: AllFileRequirementsComponent,
    AUTH_RESPONSE_DATA: AuthResponseDataComponent,
    UPGRADE_MY_PLAN: UpgradeMyPlanComponent,
    STEP_WELCOME: StepWelcomeComponent,
  },
  FETCH: {
    FILES_VIEWER: FilesViewerComponent,
    DONE_UPLOAD_FILE: DoneModal,
  },
};
