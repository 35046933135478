import { Component, Input, OnInit } from '@angular/core';
import { UIService } from '@app/services/ui.service';
import { UtilService } from '@app/services/util.service';
import { StringifyOptions } from 'querystring';

@Component({
  selector: 'mo-copy',
  templateUrl: './mo-copy.component.html',
  styleUrls: ['./mo-copy.component.scss'],
})
export class MoCopyComponent implements OnInit {
  @Input() secretValue: string;
  @Input() label: string;

  public hiddenValue: string;
  public showSecret: boolean;

  constructor(
    private _utilService: UtilService,
    private _uiService: UIService
  ) {}

  ngOnInit() {
    if (this.secretValue) {
      this.hiddenValue = this.secretValue.replace(
        this.secretValue,
        Array(this.secretValue.length + 1).join('*')
      );
    }
  }

  public showValue() {
    this.showSecret = !this.showSecret;
  }

  public copyValue() {
    this._utilService.copyClipboard(this.secretValue);
    // TODO replace to new toast service
    this._uiService.showSnackbar(
      'API Key copied',
      2000,
      'mat-simple-snackbar-success'
    );
  }
}
