import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
  CanActivateChild,
} from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, first, map, switchMap, tap } from 'rxjs/operators';

import { Permission, ResponseInitial, User } from '../models/user.model';
import { UserAPI } from '@app/services/user/user.api';
import { StorageService } from '../services/storage.service';
import { APP_CONSTANTS as CONST } from '@app/app.constants';
import { APP_PRODUCTS } from '@app/app.products';
import * as userSelectors from '@store/user/user.selectors';
import * as userActions from '@store/user/user.actions';
import * as appActions from '@store/app/app.actions';

@Injectable({
  providedIn: 'root',
})
export class LoggedGuard implements CanActivate, CanActivateChild {
  constructor(
    private _router: Router,
    private _store: Store,
    private _userApi: UserAPI,
    private _storageService: StorageService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | boolean {
    return this._auxiliar(route, state);
  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | boolean {
    return this._auxiliar(route, state);
  }

  private _auxiliar(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | boolean {
    const token$ = this._storageService.getItem(CONST.PROP_STORAGE_TOKEN);
    return token$.pipe(
      switchMap((token) => {
        if (!token) {
          this._router.navigateByUrl(CONST.ROUTES.LOGIN);
          return of(false);
        }
        return this._store.select(userSelectors.selectUserData).pipe(
          first(),
          switchMap((user: User) => {
            if (user && user.permissions)
              return of(this._checkHasPermission(state.url, user.permissions));

            return this._userApi.getUserInfo().pipe(
              map((response: ResponseInitial) => {
                this._store.dispatch(
                  userActions.responseConsultUserGuard({
                    user: { ...response },
                    catalogs: response.catalogs,
                    products: response.products,
                  })
                );
                return this._checkProducts(state.url, response.products);
              }),
              catchError((_) => {
                this._store.dispatch(userActions.logout());
                return of(false);
              })
            );
          })
        );
      })
    );
  }

  private _checkProducts(url: string, products?: string[]) {
    const product = APP_PRODUCTS.find(
      (p) => products.includes(p.backendName) && url.indexOf(p.route) > -1
    );

    if (!!product) {
      this._store.dispatch(appActions.changeProductGuard({ product }));
      return true;
    }

    const firstProduct = APP_PRODUCTS.find((p) =>
      products.includes(p.backendName)
    );
    if (firstProduct) this._router.navigateByUrl(firstProduct.route);
    return false;
  }

  private _checkHasPermission(
    url: string,
    permissions?: Permission[]
  ): boolean {
    const hasPermission = !!permissions.find((p) => url.indexOf(p.route) > -1);
    if (!hasPermission) {
      this._router.navigateByUrl(permissions[0].route);
    }
    return hasPermission;
  }
}
