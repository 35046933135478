import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ISelectOption } from '@app/mo-forms/interfaces/select-option.interface';

@Component({
  selector: 'mo-chips',
  templateUrl: './mo-chips.component.html',
  styleUrls: ['./mo-chips.component.scss'],
})
export class MoChips {
  @Input() options!: Array<ISelectOption<boolean>>;

  @Input() form!: FormGroup;
  @Input() controlName!: string;
  @Input() label: string;

  public selectedItems: Array<ISelectOption<boolean>> = [];

  public selectedItem(option: ISelectOption<boolean>) {
    option.value = !option.value;
    this.selectedItems = this.options.filter((option) => option.value);
    this.form.get(this.controlName).setValue(this.selectedItems);
  }
}
