import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { DocumentFile } from '@app/core/models/fetch.model';
import { FetchFacade } from '@app/facades/fetch.facade';
import { UtilService } from '@app/services/util.service';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'files-viewer',
  templateUrl: './files-viewer.component.html',
  styleUrls: ['./files-viewer.component.scss'],
})
export class FilesViewerComponent implements OnDestroy {
  public documentsFiles$: Observable<DocumentFile[]>;
  public selectedFile: DocumentFile;
  private _documentsFiles$: Subscription;
  public imageScale: number = 1;

  constructor(
    private _fetchFacade: FetchFacade,
    public _utilService: UtilService
  ) {
    this.documentsFiles$ = this._fetchFacade.documentsFiles$;
    this._documentsFiles$ = this.documentsFiles$.subscribe(
      (files: DocumentFile[]) => {
        if (files?.length > 0) {
          this.selectedFile = files[0];
        }
      }
    );
  }

  ngOnDestroy(): void {
    this._documentsFiles$.unsubscribe();
  }

  public openFile() {
    window.open(this.selectedFile.location, '_blank');
  }

  public onSelectFile(file: DocumentFile) {
    this.selectedFile = file;
    this.imageScale = 1;
  }

  onZoom(scale: number) {
    if (this.selectedFile?.extension === 'pdf') return;
    this.imageScale = this.imageScale + scale;
  }

  onDownload() {
    if (this.selectedFile?.extension === 'pdf') return;
    const name =
      this.selectedFile?.request_id +
      '-detections-at-' +
      this.selectedFile?.updated_at +
      '.' +
      this.selectedFile?.extension;
    this._utilService.downloadImage(this.selectedFile?.location, name);
  }
}
