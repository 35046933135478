<div
  class="container-sidebar"
  [ngClass]="{ 'base-sidebar-collapsed': isCollapsed }"
>
  <aside class="sidebar" [class.closed]="isCollapsed">
    <div class="float-button" (click)="onToggle()">
      <img
        [src]="
          '/assets/img/icon/menu/' +
          (isCollapsed ? 'arrow-right.svg' : 'arrow-left.svg')
        "
      />
    </div>
    <div class="container-product-logo">
      <img
        [@productLogoAnimation]
        [src]="menu.productLogo"
        *ngIf="isCollapsed"
      />
      <img
        [@productLogoAnimation]
        [src]="menu.productLogoCollapsed"
        *ngIf="!isCollapsed"
      />
    </div>
    <ng-container *ngFor="let option of menu.items">
      <primary-item
        *ngIf="option.type === 'item'"
        [active]="isOptionSelected(option.path)"
        [icon]="option.icon"
        [label]="option.label"
        [product]="menu.product"
        (click)="goTo(option.path)"
      ></primary-item>

      <menu-title
        *ngIf="option.type === 'title'"
        [label]="isCollapsed ? option.shortLabel : option.label"
      ></menu-title>

      <menu-accordeon-item
        *ngIf="option.type === 'item-accordeon'"
        [active]="isSubMenuOptionSelected()"
        [icon]="option.icon"
        [label]="option.label"
        [collapsed]="!isCollapsed"
        [product]="menu.product"
        (toggleAccordeon)="onToggleSubMenu($event, option.path)"
      >
        <sub-menu-list *ngIf="option.item as content">
          <ng-container *ngIf="content.type === 'sub-menu'">
            <ng-container *ngFor="let subMenuOption of content.items">
              <sub-menu-item
                *ngIf="subMenuOption.type === 'sub-menu-item'"
                [label]="subMenuOption.label"
                [active]="isOptionSelected(subMenuOption.path)"
                [isSubMenu]="subMenuOption?.isSubMenu"
                [product]="menu.product"
                (click)="goTo(subMenuOption.path)"
              ></sub-menu-item>

              <sub-menu-title
                *ngIf="subMenuOption.type === 'sub-menu-title'"
                [label]="
                  isCollapsed ? subMenuOption.shortLabel : subMenuOption.label
                "
              ></sub-menu-title>

              <sub-menu-item
                *ngIf="subMenuOption.type === 'sub-menu-item-icon'"
                [isSubMenu]="subMenuOption?.isSubMenu"
                [active]="isOptionSelected(subMenuOption.path)"
                (click)="goTo(subMenuOption.path)"
              >
                <ng-container slot="item">
                  <i class="uil" [ngClass]="subMenuOption.icon"></i>
                  {{ subMenuOption.label }}
                </ng-container>
              </sub-menu-item>
            </ng-container>
          </ng-container>
        </sub-menu-list>
      </menu-accordeon-item>
    </ng-container>
  </aside>
  <article>
    <ng-content></ng-content>
  </article>
</div>
