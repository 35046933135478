import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IStatusInfo } from '@app/core/models/status-info.model';

@Component({
  selector: 'status-filter',
  templateUrl: './status-filter.component.html',
  styleUrls: ['./status-filter.component.scss'],
})
export class StatusFilterComponent implements OnInit {
  @Input() statuses: IStatusInfo[];
  @Input() activeStatus: IStatusInfo;
  @Output() statusSelected: EventEmitter<IStatusInfo>;

  constructor() {
    this.statusSelected = new EventEmitter<IStatusInfo>();
  }

  ngOnInit(): void {
    if(this.statuses?.length > 0) {
      this.onClick(this.statuses[0]);
    }
  }

  public onClick(item: IStatusInfo): void {
    this.activeStatus = item;
    this.statusSelected.emit(this.activeStatus);
  }
}
