<div class="bg-upload">
  <label>{{ label }}</label>
  <div [class]="'upload-grid ' + direction">
    <section [class]="'section-a ' + direction">
      <div class="box-drag" [ngClass]="{ dragged: fileOver }">
        <input
          type="file"
          #fileDropRef
          id="fileDropRef"
          (change)="fileBrowseHandler($event.target.files)"
        />
        <i class="uil uil-upload"></i>
        Drag and drop or <label for="fileDropRef">Browse</label>
      </div>
      <p class="info-component">
        <span
          *ngIf="
            !form?.get(controlName)?.errors ||
            _formUtilService.renderKeyError(form?.get(controlName)?.errors) ===
              FORM_CONSTANTS.GENERIC_KEY_ERRORS.INPUT_ERROR_REQUIRED
          "
          >{{ info }}</span
        >
        <span
          class="error-info"
          *ngIf="
            form?.get(controlName)?.errors &&
            _formUtilService.renderKeyError(form?.get(controlName)?.errors) !==
              FORM_CONSTANTS.GENERIC_KEY_ERRORS.INPUT_ERROR_REQUIRED
          "
          >{{
            _formUtilService.renderKeyError(form?.get(controlName)?.errors)
          }}</span
        >
      </p>
    </section>
    <section class="section-b">
      <div class="file-info" *ngIf="file">
        <div class="file-info-container">
          <div class="icon-box">
            <div class="box-file-icon">
              <i class="uil uil-file-alt"></i>
            </div>
          </div>
          <div class="detail-box">
            <h3>{{ file.name }}</h3>
            <p>{{ formatBytes(file?.size) }}</p>
          </div>
          <div class="icon-trash" (click)="onDelete()" *ngIf="showButtonDelete">
            <i class="uil uil-trash-alt"></i>
          </div>
        </div>
      </div>
    </section>
  </div>
</div>
