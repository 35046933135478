import { Component, OnInit } from '@angular/core';
import { APP_PRODUCTS } from '@app/app.products';
import { BOTTOM_BAR_CONFIG } from '@app/core/constants/insigth.constant';
import { Product } from '@app/core/models/app.model';
import { Menu, Shortcut } from '@app/core/models/menu.model';
import { User } from '@app/core/models/user.model';
import { AppFacade } from '@app/facades/app.facade';
import { UserFacade } from '@app/facades/user.facade';
import { Observable } from 'rxjs';
import {
  IBottomBar,
  IBottonBarComponent,
} from '../components/bottombar-modal/bottombar-interface';

@Component({
  selector: 'app-base-logged',
  templateUrl: './base-logged.component.html',
  styleUrls: ['./base-logged.component.scss'],
})
export class BaseLoggedComponent implements OnInit {
  public menu$: Observable<Menu>;
  public shortcuts$: Observable<Shortcut[]>;
  public isMenuCollapsed$: Observable<boolean>;
  public user$: Observable<User>;
  public productSelected$: Observable<Product>;
  public products$: Observable<Product[]>;

  public bottomBarConfiguration: IBottomBar<IBottonBarComponent>[];

  constructor(private _appFacade: AppFacade, private _userFacade: UserFacade) {}

  ngOnInit() {
    this._getGlobalData();
  }

  onLogout() {
    this._appFacade.logout();
  }

  onSelectProduct(product: Product) {
    this._appFacade.selectProduct(product);
  }

  onToggleCollapsedMenu(isCollapsed: boolean) {
    isCollapsed ? this._appFacade.collapseMenu() : this._appFacade.expandMenu();
  }

  onClickShortcut(shortcut: Shortcut) {
    this._appFacade.openShortcut(shortcut.name);
  }

  private _getGlobalData() {
    this.menu$ = this._appFacade.menu$;
    this.shortcuts$ = this._appFacade.shortcuts$;
    this.user$ = this._userFacade.user$;
    this.products$ = this._appFacade.products$;
    this.productSelected$ = this._appFacade.productSelected$;
    this.isMenuCollapsed$ = this._appFacade.isMenuCollapsed$;

    this._setBottomBarConfigurationObserver();
  }

  private _setBottomBarConfigurationObserver() {
    this.productSelected$.subscribe((product: Product) => {
      this.bottomBarConfiguration = [];
      if (
        APP_PRODUCTS[0]?.backendName.toUpperCase() ==
        `${product?.backendName.toUpperCase()}`
      ) {
        this.bottomBarConfiguration = BOTTOM_BAR_CONFIG;
      }
    });
  }
}
