import { Menu, Shortcut } from './menu.model';
import { IStatusInfo } from './status-info.model';

export interface AppData {
  message: string;
}

export interface Token {
  access_token: string;
  token_type?: 'Bearer' | 'Token';
  country?: string;
  days_missing_free_trial?: number;
  detail?: string;
  is_premium?: boolean;
}

export enum NOTIFICATION_TYPE {
  SUCCESS = 'success',
  ERROR = 'error',
  WARNING = 'warning',
}

export type NotificationType = NOTIFICATION_TYPE;

export interface INotificationParams {
  title?: string;
  icon?: string;
  iconType?: string;
  message: string;
  type: NotificationType;
  className?: string;
  callback?: Function;
}

export interface Product {
  backendName: string;
  name: string;
  logo?: string;
  logoCollapsed?: string;
  image: string;
  route: string;
  SEOImage: string;
  menu: Menu;
  shortcuts: Shortcut[];
  statuses?: IStatusInfo[];
  backgrounds?: IProducBackgrounds;
}

export interface IProducBackgrounds {
  imageLeft: string;
  imageRight: string;
}

export interface IFAQ {
  question: string;
  response: string;
  data?: any;
}
