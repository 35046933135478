import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { STATUSES } from '@app/core/constants/insigth.constant';
import { IStatus } from '@app/core/models/status-info.model';

@Component({
  selector: 'status-info',
  templateUrl: './status-info.component.html',
  styleUrls: ['./status-info.component.scss'],
})
export class StatusInfoComponent implements OnInit, OnChanges {
  @Input() status: IStatus;
  @Input() active: boolean;
  @Input() label!: string;
  @Input() isFull!: boolean;

  public templateLabel: string;

  ngOnInit(): void {
    this._setLabel();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this._setLabel();
  }

  private _setLabel() {
    this.templateLabel =
      this.label ??
      STATUSES.filter((item) => {
        if (item?.status === this.status) {
          return item?.label;
        }
      })[0]?.label;
  }
}
