import { MapsAPILoader } from '@agm/core';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { COLOR_TEMPLATE, INPUT_SIZE } from '@app/mo-forms/enums/input.type';
import { FormUtilService } from '@app/mo-forms/services/form-util.service';

@Component({
  selector: 'mo-places',
  templateUrl: './mo-places.component.html',
  styleUrls: ['./mo-places.component.scss'],
})
export class MoPlaces implements OnInit {
  @Input() form!: FormGroup;
  @Input() controlName!: string;
  @Input() placeholder: string;
  @Input() controlWithoutSpaces: boolean;
  @Input() label!: string;
  @Input() isFloatingLabel: boolean;
  @Input() template: COLOR_TEMPLATE;
  @Input() size: INPUT_SIZE;

  @Output() valueResponse: EventEmitter<any> = new EventEmitter();
  @ViewChild('input') searchElementRef: ElementRef;

  // Public
  public autocomplete: any;
  public value: string;

  constructor(
    private _mapsApiLoader: MapsAPILoader,
    public _formUtilService: FormUtilService
  ) {}

  ngOnInit() {
    this._mapsApiLoader.load().then(() => {
      this.autocomplete = new google.maps.places.Autocomplete(
        this.searchElementRef.nativeElement
      );

      console.log(this.autocomplete);

      google.maps.event.addListener(this.autocomplete, 'place_changed', () => {
        const place = this.autocomplete.getPlace();
        this.value = place.formatted_address;
        this.form.get(this.controlName).setValue(this.value);
        this.valueResponse.emit(place);
      });
    });
  }
}
