import { Component } from '@angular/core';
import { FETCH, INSIGHT } from '@app/core/constants/products.constant';
import { Product } from '@app/core/models/app.model';
import { AppFacade } from '@app/facades/app.facade';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-layout-background',
  templateUrl: './layout-background.component.html',
  styleUrls: ['./layout-background.component.scss'],
})
export class LayoutBComponent {
  public isMenuCollapsed$: Observable<boolean>;
  public productSelected$: Observable<Product>;
  public _productSelected$: Subscription;

  public imageLeft: string;
  public imageRight: string;

  constructor(private _appFacade: AppFacade) {
    this.productSelected$ = this._appFacade.productSelected$;
    this.isMenuCollapsed$ = this._appFacade.isMenuCollapsed$;
  }
}
