export const OVERVIEW_PERFORMANCE_SUCCESS = {
  data: {
    total_failed_type_error: {
      total: 7,
      list: [
        {
          total: 3,
          name: 'Entity not found',
          percent: 42.86,
        },
        {
          total: 4,
          name: 'File with password',
          percent: 57.14,
        },
      ],
    },
    average_time_requests: 2263,
    template_submision_requests: 12,
  },
  response_code: 200,
  errors: [],
};
