import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'layout-filter-button',
  templateUrl: './filter-button.component.html',
  styleUrls: ['./filter-button.component.scss'],
})
export class FilterButtonComponent implements OnInit {
  @Input() isMobileControl: boolean;
  @Output() toggle: EventEmitter<boolean>;

  public isFilterCollapsed: boolean;
  constructor() {
    this.isMobileControl = true;
    this.toggle = new EventEmitter();
  }

  ngOnInit(): void {
    this.isFilterCollapsed = true;
  }

  onToggleFilter() {
    this.isFilterCollapsed = !this.isFilterCollapsed;
    this.toggle.emit(this.isFilterCollapsed);
  }
}
