<div class="configuration-sidebar">
  <div *ngIf="step$ | async as step">
    <header class="sidebar-header" *ngIf="step === 1">
      <h1 class="title">
        {{ isEditing ? "Edit a configuration" : "New configuration" }}
      </h1>
      <button
        class="btn-icon icon-close mo-close"
        [ngClass]="{ hidden: disableClose }"
        (click)="onCloseSidebar()"
      ></button>
    </header>
    <configuration-sidebar-form
      *ngIf="step === 1"
      [groups]="(infoCountry$ | async)?.groups"
      [countries]="countries$ | async"
      [selectedConfig]="selectedConfig"
      (continue)="onSubmit($event)"
    ></configuration-sidebar-form>

    <insight-configuration-form-response
      *ngIf="step === 2"
      [error]="error$ | async"
      [isEditConfig]="isEditing"
      [configurationId]="(configuration$ | async)?.config_id"
      (close)="onCloseSidebar()"
    >
    </insight-configuration-form-response>
  </div>
</div>
