import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import {
  ISidebarModal,
  ISidebarModalParams,
} from '@app/core/models/sidebar-modal.model';
import { SIDEBAR_CONSTANTS as CONST } from './sidebar.constants';

@Injectable({
  providedIn: 'root',
})
export class SidebarModalService {
  public currentComponent: ComponentType<ISidebarModal>;
  public currentModule: Function;
  public dataComponent: ISidebarModalParams;

  public openSubject: BehaviorSubject<string>;
  public open$: Observable<string>;
  public titleSubject: BehaviorSubject<string>;
  public title$: Observable<string>;

  constructor() {
    this.openSubject = new BehaviorSubject('');
    this.open$ = this.openSubject.asObservable();

    this.titleSubject = new BehaviorSubject('');
    this.title$ = this.titleSubject.asObservable();
  }

  open(
    component: ComponentType<ISidebarModal>,
    data: ISidebarModalParams,
    lazyModule?: Function
  ) {
    this.currentComponent = component;
    this.currentModule = lazyModule;
    this.dataComponent = data;
    this.openSubject.next(CONST.STATUS.OPEN);
  }

  close() {
    this.currentComponent = null;
    this.currentModule = null;
    this.dataComponent = null;
    this.openSubject.next(CONST.STATUS.CLOSE);
  }

  changeTitle(title: string) {
    this.titleSubject.next(title);
  }
}
