<div
  class="box-dropdown-bg"
  [ngClass]="{
    'with-out-spaces': controlWithoutSpaces,
    'middle-spaces': controlWithMiddleSpace
  }"
>
  <label class="mo-forms">{{ label }}</label>
  <div
    [class]="'mo-dropdown ' + template + ' ' + size"
    [ngClass]="{
      'error-control': formUtilService.validErrors(form, controlName)
    }"
  >
    <div class="mo-dropdown-content item-option">
      <ng-container *ngIf="selectedValue; then asignedValue; else defaultValue">
      </ng-container>
      <ng-template #asignedValue>
        <span class="break">{{ selectedValue }}</span>
      </ng-template>
      <ng-template #defaultValue>
        <span class="selected-option break">{{ defaultOptionName }}</span>
      </ng-template>
    </div>
    <i [class]="'icon uil uil-angle-down ' + size"></i>
    <div *ngIf="isOpen" [class]="'mo-options-box ' + size">
      <div
        class="mo-option"
        *ngFor="let option of options"
        (click)="selectOption(option)"
      >
        {{ option.key }}
      </div>
    </div>
  </div>
</div>
