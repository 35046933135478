import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import {
  map,
  switchMap,
  withLatestFrom,
  filter,
  debounceTime,
  catchError,
  tap,
} from 'rxjs/operators';

import { FetchAPI } from '@app/services/fetch/fetch.api';
import {
  Country,
  Document,
  IDocumentDetails,
  DocumentFile,
  IDocumentStates,
  Template,
  TemplateVersion,
  TemplateVersionParams,
  TypeTemplate,
  IDocumentDetailTransactions,
  IOverviewHeaderInfo,
  IOverviewUseInfo,
  IOverviewPerformanceInfo,
  IOverviewSubmisionInfo,
} from '@app/core/models/fetch.model';
import { APP_CONSTANTS as CONST } from '@app/app.constants';
import * as fetchActions from './fetch.actions';
import * as fetchSelectors from './fetch.selectors';
import { of } from 'rxjs';
import { PagedResponse } from '@app/core/models/table.model';
import { UtilService } from '@app/services/util.service';

@Injectable()
export class FetchEffects {
  constructor(
    private _actions$: Actions,
    private _fetchAPI: FetchAPI,
    private _store: Store,
    private _utilService: UtilService
  ) {}

  public countriesEffect$ = createEffect(() =>
    this._actions$.pipe(
      ofType(
        fetchActions.initTemplatesPage,
        fetchActions.initProposeATemplatePage
      ),
      switchMap(() =>
        this._fetchAPI.getCountries().pipe(
          map((countries: Country[]) =>
            fetchActions.responseCountries({ countries })
          ),
          catchError(() => of(fetchActions.failureCountries))
        )
      )
    )
  );

  public statesEffect$ = createEffect(() =>
    this._actions$.pipe(
      ofType(fetchActions.initDocumentsPage),
      switchMap(() =>
        this._fetchAPI.getStates().pipe(
          map((states: IDocumentStates[]) => {
            return fetchActions.responseStates({ states });
          }),
          catchError(() => of(fetchActions.failureStates))
        )
      )
    )
  );

  public defaultSelectCountry$ = createEffect(() =>
    this._actions$.pipe(
      ofType(fetchActions.responseCountries),
      map(({ countries }) =>
        fetchActions.selectCountry({ country: countries[0] })
      )
    )
  );

  public typeTemplatesEffect$ = createEffect(() =>
    this._actions$.pipe(
      ofType(fetchActions.initTemplatesPage, fetchActions.selectCountry),
      withLatestFrom(this._store.select(fetchSelectors.selectCountry)),
      filter(([_, country]) => !!country?.code),
      switchMap(([_, { code }]) => {
        return this._fetchAPI.getTypeTemplates(code).pipe(
          map((typeTemplates: TypeTemplate[]) =>
            fetchActions.responseTypeTemplates({ typeTemplates })
          ),
          catchError(() => of(fetchActions.failureTypeTemplates))
        );
      })
    )
  );

  public tempaltesEffect$ = createEffect(() =>
    this._actions$.pipe(
      ofType(
        fetchActions.changeTemplatesFilters,
        fetchActions.responseUpdateStatusTemplates,
        fetchActions.failureUpdateStatusTemplate
      ),
      debounceTime(CONST.TIME_WAITING_IN_FILTERS),
      withLatestFrom(this._store.select(fetchSelectors.templatesFilters)),
      filter(
        ([_, templateFilters]) =>
          !!templateFilters?.country_code && !!templateFilters?.template_type_id
      ),
      switchMap(([_, templateFilters]) => {
        return this._fetchAPI.getTemplates(templateFilters).pipe(
          map((templates: PagedResponse<Template[]>) =>
            fetchActions.responseTemplates({ response: templates })
          ),
          catchError(() => of(fetchActions.failureTemplates))
        );
      })
    )
  );

  public tempaltesVersionEffect$ = createEffect(() =>
    this._actions$.pipe(
      ofType(fetchActions.selectTemplateIdDetails),
      switchMap(({ template_id }) => {
        const templateVersionParams = {
          template_id,
        } as TemplateVersionParams;
        return this._fetchAPI.getTemplatesVersion(templateVersionParams).pipe(
          map(({ data }: PagedResponse<TemplateVersion[]>) =>
            fetchActions.responseTemplatesVersion({
              templateVersions: data,
            })
          ),
          catchError(() => of(fetchActions.failureTemplatesVersion))
        );
      })
    )
  );

  public documentsEffect$ = createEffect(() =>
    this._actions$.pipe(
      ofType(
        fetchActions.changeDocumentsFilters,
        fetchActions.initDocumentsPage
      ),
      debounceTime(CONST.TIME_WAITING_IN_FILTERS),
      withLatestFrom(this._store.select(fetchSelectors.selectDocumentsFilters)),
      switchMap(([_, documentsFilters]) => {
        return this._fetchAPI.getDocuments(documentsFilters).pipe(
          map((documents: PagedResponse<Document[]>) =>
            fetchActions.responseDocuments({
              documents,
            })
          ),
          catchError(() => of(fetchActions.failureDocuments))
        );
      })
    )
  );

  public documentDetailEffect$ = createEffect(() =>
    this._actions$.pipe(
      ofType(fetchActions.getDocumentDetailById),
      switchMap(({ id }) =>
        this._fetchAPI.getDocumentDetail(id).pipe(
          map((documentDetails: IDocumentDetails) => {
            return fetchActions.responseDocumentDetails({
              documentDetails,
              id,
            });
          }),
          catchError(() => of(fetchActions.failureDocumentDetails))
        )
      )
    )
  );

  public documentDetailTransactionsEffect$ = createEffect(() =>
    this._actions$.pipe(
      ofType(fetchActions.initDocumentDetailTransactions),
      withLatestFrom(this._store.select(fetchSelectors.selectDocumentDetailId)),
      switchMap(([_, documentDetailId]) =>
        this._fetchAPI.getDocumentDetailTransactions(documentDetailId).pipe(
          map((documentDetailTransactions: IDocumentDetailTransactions[]) => {
            return fetchActions.responseDocumentDetailTransactions({
              documentDetailTransactions,
              id: documentDetailId,
            });
          }),
          catchError(() => of(fetchActions.failureDocumentDetailTransactions))
        )
      )
    )
  );

  public downloadMultipleDocumentEffect$ = createEffect(() =>
    this._actions$.pipe(
      ofType(fetchActions.downloadDocument),
      switchMap(({ id }) =>
        this._fetchAPI.getDocumentsFiles(id).pipe(
          map((documentsFiles: DocumentFile[]) => {
            return fetchActions.responseMultipleDocumentFile({
              documentsFiles,
            });
          }),
          catchError(() => of(fetchActions.failureMultipleDocumentFile))
        )
      )
    )
  );

  public downloadDocumentFileEffect$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(fetchActions.responseDocumentFile),
        tap(({ documentFile }) =>
          this._utilService.downloadFileFromURL({ url: documentFile?.location })
        )
      ),
    { dispatch: false }
  );

  public downloadMultipleDocumentsFilesZipEffect$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(fetchActions.downloadMultipleDocumentsFilesZip),
        tap(({ documentFileZip, zipName }) =>
          this._utilService.downloadFilesAsZip(documentFileZip, zipName)
        )
      ),
    { dispatch: false }
  );

  public toggleConfigurationEffect$ = createEffect(() =>
    this._actions$.pipe(
      ofType(fetchActions.toggleStatus),
      withLatestFrom(this._store.select(fetchSelectors.selectCountry)),
      switchMap(([{ templates }, country]) => {
        return this._fetchAPI.updateTemplates(templates, country).pipe(
          map(() => fetchActions.responseUpdateStatusTemplates()),
          catchError(() => of(fetchActions.failureUpdateStatusTemplate))
        );
      })
    )
  );

  public uploadFileEffect$ = createEffect(() =>
    this._actions$.pipe(
      ofType(fetchActions.uploadFile),
      switchMap(({ fileData }) => {
        return this._fetchAPI.uploadFile(fileData).pipe(
          map((res) => {
            fileData = {
              ...fileData,
              file_name: res?.file_name,
            };
            return fetchActions.responseUploadFile({ fileData });
          }),
          catchError(() => of(fetchActions.failureUploadFile()))
        );
      })
    )
  );

  public saveSuggestion$ = createEffect(() =>
    this._actions$.pipe(
      ofType(fetchActions.responseUploadFile),
      switchMap(({ fileData }) => {
        return this._fetchAPI.saveSuggestion(fileData).pipe(
          map(() => {
            return fetchActions.responseSaveSuggestions();
          }),
          catchError(() => of(fetchActions.failureSaveSuggestions()))
        );
      })
    )
  );

  public onInitHeaderOverviewFetch$ = createEffect(() =>
    this._actions$.pipe(
      ofType(
        fetchActions.onInitHeaderOverviewFetch,
        fetchActions.onChangeFiltersOverviewFetch
      ),
      debounceTime(CONST.TIME_WAITING_IN_FILTERS),
      switchMap(({ filters }) => {
        return this._fetchAPI.getOverviewHeader(filters).pipe(
          map((overviewHeaderInfo: IOverviewHeaderInfo) => {
            return fetchActions.responseOverviewHeader({ overviewHeaderInfo });
          }),
          catchError(() => of(fetchActions.failureSaveSuggestions()))
        );
      })
    )
  );

  public onInitUseOverviewFetch$ = createEffect(() =>
    this._actions$.pipe(
      ofType(fetchActions.onInitUseOverviewFetch),
      switchMap(() => {
        return this._fetchAPI.getOverviewUse().pipe(
          map((overviewUseInfo: IOverviewUseInfo) => {
            return fetchActions.responseOverviewUse({ overviewUseInfo });
          }),
          catchError(() => of(fetchActions.failureOverviewUse()))
        );
      })
    )
  );

  public onInitPerformanceOverviewFetch$ = createEffect(() =>
    this._actions$.pipe(
      ofType(fetchActions.onInitPerformanceOverviewFetch),
      switchMap(() => {
        return this._fetchAPI.getOverviewPerformance().pipe(
          map((overviewPerformanceInfo: IOverviewPerformanceInfo) => {
            return fetchActions.responseOverviewPerformance({
              overviewPerformanceInfo,
            });
          }),
          catchError(() => of(fetchActions.failureOverviewPerformance()))
        );
      })
    )
  );

  public onInitSubmisionOverviewFetch$ = createEffect(() =>
    this._actions$.pipe(
      ofType(fetchActions.onInitPerformanceOverviewFetch),
      switchMap(() => {
        return this._fetchAPI.getOverviewSubmision().pipe(
          map((overviewSubmisionInfo: IOverviewSubmisionInfo[]) => {
            return fetchActions.responseOverviewSubmision({
              overviewSubmisionInfo,
            });
          }),
          catchError(() => of(fetchActions.failureOverviewSubmision()))
        );
      })
    )
  );
}
