import { createReducer, on, Action } from '@ngrx/store';
import * as fetchActions from './fetch.actions';
import { FetchState } from './fetch.state';

export const initialFetchState: FetchState = {
  data: null,
  response: null,
  isLoadingPage: false,
  countries: [],
  typeTemplates: [],
  typeTemplate: null,
  templates: null,
  templatesFilters: {},
  templateVersionDetail: null,
  isLoadingTemplates: false,
  isLoadingTemplateVersion: false,
  templateVersions: null,
  documents: null,
  isLoadingDocuments: false,
  documentDetails: null,
  documentDetailId: null,
  isLoadingDocumentDetails: null,
  documentsFilters: null,
  documentFile: null,
  documentsFiles: null,
  isLoadingDocumentFile: false,
  states: null,
  documentDetailTransactions: null,
  isLoadingDocumentDetailTransactions: null,
  isLoadingUploadFileFetch: null,
  completeUploadFileFetch: null,
  isLoadingOverviewHeaderInfo: false,
  overviewHeaderInfo: null,
  isLoadingOverviewUseInfo: false,
  overviewUseInfo: null,
  isLoadingOverviewPerformanceInfo: false,
  overviewPerformanceInfo: null,
  overviewSubmisionInfo: null,
};

const _fetchReducer = createReducer(
  initialFetchState,

  on(fetchActions.action, (state, { data }) => {
    return {
      ...state,
      data,
      isLoadingPage: true,
    };
  }),
  on(fetchActions.successAction, (state, { response }) => {
    return {
      ...state,
      response,
      isLoadingPage: false,
    };
  }),
  on(fetchActions.failureAction, (state, { error }) => {
    return {
      ...state,
      response: error.message,
      isLoadingPage: false,
    };
  }),
  on(fetchActions.responseCountries, (state, { countries }) => {
    return {
      ...state,
      countries,
    };
  }),
  on(fetchActions.responseStates, (state, { states }) => {
    return {
      ...state,
      states,
    };
  }),
  on(fetchActions.responseTypeTemplates, (state, { typeTemplates }) => {
    return {
      ...state,
      typeTemplates,
    };
  }),
  on(fetchActions.responseTemplates, (state, { response }) => {
    return {
      ...state,
      templates: response,
      isLoadingTemplates: false,
    };
  }),
  on(fetchActions.selectCountry, (state, { country }) => {
    return {
      ...state,
      country,
      isLoadingTemplates: true,
    };
  }),
  on(fetchActions.selectTypeTemplate, (state, { typeTemplate }) => {
    return {
      ...state,
      typeTemplate,
      isLoadingTemplates: true,
    };
  }),
  on(fetchActions.changeTemplatesFilters, (state, { templatesFilters }) => {
    return {
      ...state,
      templatesFilters,
      isLoadingTemplates: true,
    };
  }),
  on(fetchActions.changeDocumentsFilters, (state, { documentsFilters }) => {
    return {
      ...state,
      documentsFilters,
      isLoadingDocuments: true,
    };
  }),
  on(fetchActions.initTemplatesPage, (state) => {
    return { ...state, isLoadingTemplates: true };
  }),
  on(fetchActions.hideTemplatesLoader, (state) => {
    return { ...state, isLoadingTemplates: false };
  }),
  on(fetchActions.selectTemplateIdDetails, (state) => {
    return {
      ...state,
      isLoadingTemplateVersion: true,
    };
  }),
  on(fetchActions.responseTemplatesVersion, (state, { templateVersions }) => {
    return {
      ...state,
      templateVersions,
      isLoadingTemplateVersion: true,
    };
  }),
  on(fetchActions.failureTemplatesVersion, (state) => {
    return {
      ...state,
      templateVersions: null,
      isLoadingTemplateVersion: false,
    };
  }),
  on(
    fetchActions.setTemplateVersionDetail,
    (state, { templateVersionDetail }) => {
      return {
        ...state,
        templateVersionDetail,
      };
    }
  ),
  on(fetchActions.clearTemplateVersionAndFields, (state) => {
    return {
      ...state,
      templateVersions: null,
      templateVersionsFields: null,
      isLoadingTemplateVersion: true,
    };
  }),
  on(fetchActions.initDocumentsPage, (state) => {
    return { ...state, isLoadingDocuments: true };
  }),
  on(fetchActions.responseDocuments, (state, { documents }) => {
    return {
      ...state,
      documents,
      isLoadingDocuments: false,
    };
  }),
  on(fetchActions.failureDocuments, (state) => {
    return {
      ...state,
      documents: null,
      isLoadingDocuments: false,
    };
  }),
  on(fetchActions.getDocumentDetailById, (state, { id }) => {
    return {
      ...state,
      documentDetailId: id,
      isLoadingDocumentDetails: {
        ...state?.isLoadingDocumentDetails,
        [id]: true,
      },
      isLoadingDocumentDetailTransactions: {
        ...state?.isLoadingDocumentDetailTransactions,
        [id]: true,
      },
    };
  }),
  on(fetchActions.downloadDocument, (state) => {
    return { ...state, isLoadingDocumentFile: true };
  }),
  on(fetchActions.responseDocumentFile, (state, { documentFile }) => {
    return { ...state, documentFile, isLoadingDocumentFile: false };
  }),
  on(fetchActions.failureDocumentFile, (state) => {
    return { ...state, isLoadingDocumentFile: false };
  }),
  on(fetchActions.responseDocumentDetails, (state, { documentDetails, id }) => {
    return {
      ...state,
      documentDetails: {
        ...state?.documentDetails,
        [id]: documentDetails,
      },
      isLoadingDocumentDetails: false,
    };
  }),
  on(fetchActions.failureDocumentDetails, (state) => {
    return {
      ...state,
      documentDetails: undefined,
      isLoadingDocumentDetails: false,
    };
  }),
  on(
    fetchActions.responseDocumentDetailTransactions,
    (state, { documentDetailTransactions, id }) => {
      return {
        ...state,
        documentDetailTransactions: {
          ...state?.documentDetailTransactions,
          [id]: documentDetailTransactions,
        },
        isLoadingDocumentDetailTransactions: {
          ...state?.isLoadingDocumentDetailTransactions,
          [id]: false,
        },
      };
    }
  ),
  on(fetchActions.failureDocumentDetailTransactions, (state, { id }) => {
    return {
      ...state,
      documentDetailTransactions: null,
      isLoadingDocumentDetailTransactions: {
        ...state?.isLoadingDocumentDetailTransactions,
        [id]: false,
      },
    };
  }),
  on(fetchActions.responseMultipleDocumentFile, (state, { documentsFiles }) => {
    return {
      ...state,
      documentsFiles,
    };
  }),
  on(fetchActions.failureMultipleDocumentFile, (state) => {
    return {
      ...state,
      documentsFiles: null,
    };
  }),
  on(fetchActions.uploadFile, (state) => {
    return {
      ...state,
      isLoadingUploadFileFetch: true,
    };
  }),
  on(fetchActions.responseUploadFile, (state) => {
    return {
      ...state,
      isLoadingUploadFileFetch: true,
    };
  }),
  on(fetchActions.failureUploadFile, (state) => {
    return {
      ...state,
      isLoadingUploadFileFetch: false,
    };
  }),
  on(fetchActions.responseSaveSuggestions, (state) => {
    return {
      ...state,
      isLoadingUploadFileFetch: false,
      completeUploadFileFetch: true,
    };
  }),
  on(fetchActions.failureSaveSuggestions, (state) => {
    return {
      ...state,
      isLoadingUploadFileFetch: false,
      completeUploadFileFetch: false,
    };
  }),
  on(fetchActions.onInitHeaderOverviewFetch, (state) => {
    return {
      ...state,
      isLoadingOverviewHeaderInfo: true,
    };
  }),
  on(fetchActions.onChangeFiltersOverviewFetch, (state) => {
    return {
      ...state,
      isLoadingOverviewHeaderInfo: true,
    };
  }),
  on(fetchActions.responseOverviewHeader, (state, { overviewHeaderInfo }) => {
    return {
      ...state,
      isLoadingOverviewHeaderInfo: false,
      overviewHeaderInfo,
    };
  }),
  on(fetchActions.onInitUseOverviewFetch, (state) => {
    return {
      ...state,
      isLoadingOverviewUseInfo: true,
    };
  }),
  on(fetchActions.responseOverviewUse, (state, { overviewUseInfo }) => {
    return {
      ...state,
      isLoadingOverviewUseInfo: false,
      overviewUseInfo,
    };
  }),
  on(fetchActions.failureOverviewUse, (state) => {
    return {
      ...state,
      isLoadingOverviewUseInfo: false,
      overviewUseInfo: null,
    };
  }),
  on(fetchActions.onInitPerformanceOverviewFetch, (state) => {
    return {
      ...state,
      isLoadingOverviewPerformanceInfo: true,
    };
  }),
  on(
    fetchActions.responseOverviewPerformance,
    (state, { overviewPerformanceInfo }) => {
      return {
        ...state,
        isLoadingOverviewPerformanceInfo: false,
        overviewPerformanceInfo,
      };
    }
  ),
  on(fetchActions.failureOverviewPerformance, (state) => {
    return {
      ...state,
      isLoadingOverviewPerformanceInfo: false,
      overviewPerformanceInfo: null,
    };
  }),
  on(
    fetchActions.responseOverviewSubmision,
    (state, { overviewSubmisionInfo }) => {
      return {
        ...state,
        overviewSubmisionInfo,
      };
    }
  ),
  on(fetchActions.failureOverviewPerformance, (state) => {
    return {
      ...state,
      overviewSubmisionInfo: null,
    };
  })
);

export function FetchReducers(state: FetchState | undefined, action: Action) {
  return _fetchReducer(state, action);
}
