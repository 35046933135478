import { Component, Input, OnInit } from '@angular/core';
import { UIService } from '@app/services/ui.service';
import { UtilService } from '@app/services/util.service';

@Component({
  selector: 'mo-copy-label',
  templateUrl: './mo-copy-label.component.html',
  styleUrls: ['./mo-copy-label.component.scss'],
})
export class MoCopyLabel implements OnInit {
  @Input() value: string;
  @Input() messageCopied: string;
  @Input() color: 'success' | 'indigo' = 'success';

  constructor(
    private _utilService: UtilService,
    private _uiService: UIService
  ) {}

  ngOnInit() {}

  public copyValue() {
    const message = this.messageCopied || 'Copied';
    this._utilService.copyClipboard(this.value);
    // TODO replace to new toast service
    this._uiService.showSnackbar(message, 2000, 'mat-simple-snackbar-success');
  }
}
