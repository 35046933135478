import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormUtilService } from '../../services/form-util.service';
import {
  ICON_POSITION,
  COLOR_TEMPLATE,
  INPUT_SIZE,
} from '../../enums/input.type';

@Component({
  selector: 'mo-input',
  templateUrl: './mo-input.component.html',
  styleUrls: ['./mo-input.component.scss'],
})
export class MoInputComponent implements OnInit, AfterViewInit {
  @Input() type: string = 'text';
  @Input() form!: FormGroup;
  @Input() controlName!: string;
  @Input() label!: string;
  @Input() placeHolder: string;
  @Input() isFloatingLabel: boolean;
  @Input() icons: Array<string>;
  @Input() iconPosition: ICON_POSITION;
  @Input() isRequired: boolean;
  @Input() template: COLOR_TEMPLATE;
  @Input() size: INPUT_SIZE;
  @Input() controlWithoutSpaces: boolean;
  @Input() controlWithMiddleSpace: boolean;

  @ViewChild('labelId')
  labelId: ElementRef;
  @ViewChild('boxIcon')
  boxIcon: ElementRef;

  public labelWidth;
  public boxIconWidth;
  public TEMPLATES = COLOR_TEMPLATE;

  constructor(public formUtilService: FormUtilService) {}

  ngOnInit(): void {
    this.labelWidth = 0;
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.labelWidth = this.labelId?.nativeElement?.offsetWidth + 20;
      if (this.icons && this.icons.length > 0) {
        this.boxIconWidth = this.boxIcon?.nativeElement?.offsetWidth + 30;
      }
    }, 0);
  }

  public buildPaddingInputWhenHasIcons() {
    const styles = {};
    styles[`padding-${this.iconPosition}`] = `${this.boxIconWidth}px`;

    return styles;
  }
}
