<div
  *ngIf="modal?.show"
  class="bg-modal"
  [class.close-in-progress]="onCloseStylesAnimationInProgress"
>
  <div
    [class]="'modal ' + modal?.size"
    [class.without-padding]="modal?.withoutPadding"
    [class.big-modal]="modal?.isBigModal"
    [class.close-in-progress]="onCloseStylesAnimationInProgress"
  >
    <div class="header-modal" [class.without-padding]="modal?.withoutPadding">
      <h3 *ngIf="modal?.title">
        {{ modal?.title }}
      </h3>
      <i
        *ngIf="modal.showExitIcon"
        (click)="close()"
        class="uil uil-multiply"
      ></i>
    </div>
    <div class="container-modal">
      <ng-container *ngComponentOutlet="contentComponent"></ng-container>
    </div>
    <div class="footer-container" *ngIf="modal?.textButton">
      <mo-button-form
        [text]="modal?.textButton"
        [template]="BUTTON_TEMPLATES.PRIMARY"
        [size]="BUTTON_SIZE.MD"
        (click)="close()"
      ></mo-button-form>
    </div>
  </div>
</div>
