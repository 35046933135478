import { createReducer, on, Action } from '@ngrx/store';
import { AppState } from './app.state';
import { APP_PRODUCTS } from '@app/app.products';
import * as appActions from './app.actions';
import * as userActions from '../user/user.actions';
import { BOTTOM_BAR_TYPES } from '@app/layout/components/bottombar-modal/bottombar.types';

export const initialAppState: AppState = {
  productSelected: APP_PRODUCTS[0],
  products: [],
  catalogs: null,
  sidebarParams: null,
  isMenuCollapsed: true,
  bottomBarConfig: {
    isBottomBarModalClose: true,
    type: BOTTOM_BAR_TYPES.NEW_CONFIGURATION,
  },
  modalConfig: {
    show: false,
  },
};

const _appReducer = createReducer(
  initialAppState,
  on(
    appActions.changeProduct,
    appActions.changeProductGuard,
    appActions.responseInitialProduct,
    (state, { product }) => {
      return { ...state, productSelected: product };
    }
  ),
  on(
    userActions.responseConsultUser,
    userActions.responseConsultUserGuard,
    (state, { catalogs }) => {
      return { ...state, catalogs };
    }
  ),
  on(appActions.responseProductsActive, (state, { products }) => {
    return { ...state, products };
  }),
  on(appActions.setSidebarParams, (state, { sidebarParams }) => {
    return { ...state, sidebarParams };
  }),
  on(appActions.collapseMenu, (state) => {
    return { ...state, isMenuCollapsed: true };
  }),
  on(appActions.expandMenu, (state) => {
    return { ...state, isMenuCollapsed: false };
  }),
  on(appActions.closeBottomBarModal, (state) => {
    const bottomBarConfig = { isBottomBarModalClose: true };
    return { ...state, bottomBarConfig };
  }),
  on(appActions.openBottomBarModal, (state, { bottomBarConfig }) => {
    return { ...state, bottomBarConfig };
  }),
  on(appActions.openModal, (state, { modalConfig }) => {
    return { ...state, modalConfig };
  }),
  on(appActions.closeModal, (state) => {
    const modalConfig = { show: false };
    return { ...state, modalConfig };
  })
);

export function AppReducers(state: AppState | undefined, action: Action) {
  return _appReducer(state, action);
}
