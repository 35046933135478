import { Component, Input } from '@angular/core';

@Component({
  selector: 'mo-error',
  templateUrl: './mo-error.component.html',
  styleUrls: ['./mo-error.component.scss'],
})
export class MoErrorComponent {
  @Input() error!: string;
}
