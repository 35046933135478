import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

import { SidebarModal } from '@app/core/class/sidebar-modal.class';
import { Configuration } from '@app/core/models/insight.model';
import { RequestForm, RequestSuccess } from '@app/core/models/request.model';
import { InsightFacade } from '@app/facades/insight.facade';
import { SidebarModalRef } from '@app/layout/components/sidebar-modal/sidebar-modal.ref';
import { UIService } from '@app/services/ui.service';
import { filter, first } from 'rxjs/operators';
import { GoogleAnalyticsService } from '@app/services/google-analytics.service';
import { GA } from '@app/core/constants/google-analytics.constant';
import { Country } from '@app/core/models/insight.model';

@Component({
  selector: 'insight-request-sidebar-create',
  templateUrl: './sidebar-create.component.html',
  styleUrls: ['./sidebar-create.component.scss'],
})
export class SidebarCreateComponent
  extends SidebarModal
  implements OnInit, OnDestroy
{
  public isResponded$: Observable<boolean>;
  public isLoading$: Observable<boolean>;
  public isLoadingConfigs$: Observable<boolean>;
  public error$: Observable<string>;
  public success$: Observable<RequestSuccess>;
  public configurations$: Observable<Configuration[]>;
  public countries$: Observable<Country[]>;

  constructor(
    private _sidebarModalRef: SidebarModalRef,
    private _insightFacade: InsightFacade,
    private _uiService: UIService,
    private _gaService: GoogleAnalyticsService,
  ) {
    super(_sidebarModalRef);
  }

  ngOnInit(): void {
    this._getGlobalData();
  }

  ngOnDestroy(): void {
    this._insightFacade.leaveRequestForms();
  }

  public onCreate(request: RequestForm) {
    this._gaService.sendEvent(GA.DOWNLOAD.REQUEST);
    this._insightFacade.createRequest(request);
  }

  public onClose() {
    this._sidebarModalRef.close();
  }

  private _getGlobalData() {
    this._insightFacade.initRequestForms();
    this.isLoadingConfigs$ = this._insightFacade.isLoadingConfigsRequests$;
    this.success$ = this._insightFacade.requestSuccess$;
    this.error$ = this._insightFacade.errorRequestForm$;
    this.isLoading$ = this._insightFacade.isLoadingRequestForm$;
    this.isResponded$ = this._insightFacade.isRespondedRequestForm$;
    this.configurations$ = this._insightFacade.configurations$;
    this.countries$ = this._insightFacade.countries$;

    this.isResponded$
      .pipe(filter((isResponded) => !!isResponded))
      .pipe(first())
      .subscribe(() => this._uiService.changeTitleSidebar(' '));
  }
}
