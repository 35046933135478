import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { RequestSuccess } from '@app/core/models/request.model';
import { APP_CONSTANTS as CONST } from '@app/app.constants';
import { BUTTON_SIZE, BUTTON_TEMPLATE } from '@app/mo-forms/enums/button.type';
import { AppFacade } from '@app/facades/app.facade';

@Component({
  selector: 'insight-configuration-form-response',
  templateUrl: './form-response.component.html',
  styleUrls: ['./form-response.component.scss'],
})
export class FormResponseConfigurationComponent {
  @Input() request: RequestSuccess;
  @Input() error?: string;
  @Input() isEditConfig: boolean;
  @Input() configurationId: string;
  @Output() close = new EventEmitter();

  public BUTON_TEMPLATES = BUTTON_TEMPLATE;
  public BUTTON_SIZE = BUTTON_SIZE;

  constructor(private _router: Router, private _appFacade: AppFacade) {}

  public onClose() {
    this._appFacade.closeBottomBar();
  }

  public onGotoConfigs() {
    this._router.navigateByUrl(CONST.ROUTES.PRODUCTS.INSIGHT.CONFIGURATION);
    this._appFacade.closeBottomBar();
  }
}
