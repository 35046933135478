import { APP_CONSTANTS as CONST } from '@app/app.constants';
import {
  DOCUMENT_STATUS,
  IDocumentStatusInfo,
  STATUS,
} from '../models/fetch.model';
import { Shortcut, ShortcutsName } from '../models/menu.model';

export const MENU_FETCH = {
  productLogo: '/assets/img/icon/menu/fetch/mo-fetch.svg',
  productLogoCollapsed: '/assets/img/icon/menu/fetch/mo-fetch-text.svg',
  product: 'mofetch',
  items: [
    {
      type: 'item',
      icon: '/assets/img/icon/menu/fetch/overview.svg',
      label: 'Overview',
      path: CONST.ROUTES.PRODUCTS.FETCH.HOME,
    },
    {
      type: 'item',
      icon: '/assets/img/icon/menu/fetch/templates.svg',
      label: 'Templates',
      path: CONST.ROUTES.PRODUCTS.FETCH.TEMPLATES,
    },
    {
      type: 'item',
      icon: '/assets/img/icon/menu/fetch/documents.svg',
      label: 'Documents',
      path: CONST.ROUTES.PRODUCTS.FETCH.DOCUMENTS,
    },
    {
      type: 'item',
      icon: '/assets/img/icon/menu/fetch/connect.svg',
      label: 'Connect',
      path: CONST.ROUTES.PRODUCTS.FETCH.API_CONNECT,
    },
    {
      type: 'title',
      label: 'NEED HELP?',
      shortLabel: 'HELP?',
    },
    {
      type: 'item-accordeon',
      icon: '/assets/img/icon/menu/fetch/support.svg',
      label: 'Support',
      path: CONST.ROUTES.PRODUCTS.FETCH.HELP,
      item: {
        type: 'sub-menu',
        items: [
          {
            type: 'sub-menu-item',
            label: 'FAQs',
            path: CONST.ROUTES.PRODUCTS.FETCH.FAQS,
          },
          {
            type: 'sub-menu-item',
            label: 'Propose a Template',
            path: CONST.ROUTES.PRODUCTS.FETCH.PROPOSE_A_TEMPLATE,
          },
        ],
      },
    },
  ],
};

export const SHORTCUTS_FETCH: Shortcut[] = [
  {
    name: ShortcutsName.NEW_REQUEST,
    icon: 'mo-request',
    title: 'Download',
  },
  {
    name: ShortcutsName.NEW_CONFIGURATION,
    icon: 'mo-configuration',
    title: 'Create configuration',
    data: { title: 'New configuration' },
  },
];

export const DOCUMENT_STATUSES: IDocumentStatusInfo[] = [
  {
    status: STATUS.ALL,
    label: 'all',
    data: null,
  },
  {
    status: STATUS.COMPLETED,
    label: 'Completed',
    data: DOCUMENT_STATUS.COMPLETED,
  },
  {
    status: STATUS.PENDING,
    label: 'Pending',
    data: DOCUMENT_STATUS.PENDING,
  },
  {
    status: STATUS.PENDING,
    label: 'Pending',
    data: DOCUMENT_STATUS.PENDING_1,
  },
  {
    status: STATUS.FAILED,
    label: 'failed',
    data: DOCUMENT_STATUS.FAILED,
  },
];
