import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FORM_CONSTANTS, FORM_ERRORS_FX } from '../constants/form.constants';

@Injectable({
  providedIn: 'root',
})
export class FormUtilService {
  public validErrors(form: FormGroup, formControl: string): boolean {
    return (
      form.get(formControl)?.errors &&
      (form.get(formControl)?.dirty || form.get(formControl)?.touched)
    );
  }

  public renderKeyError(errors: any) {
    const error: any = Object.keys(errors)
      .map((key) => {
        if (errors[key]) {
          return {
            data: errors[key],
            key: `INPUT_ERROR_${key.toUpperCase()}`,
          };
        }
      })
      .shift();

    return FORM_ERRORS_FX(error.data, error.key);
  }
}
