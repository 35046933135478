<div class="layout-bg-forms">
  <div class="render-components">
    <div class="form-content">
      <div [formGroup]="inputForms">
        <h1>Forms controls</h1>

        <!-- :::::::::::::: Inputs block :::::::::::::: -->
        <h3>Inputs</h3>
        <mo-input
          controlName="default"
          type="text"
          label="Label default "
          placeHolder="Placeholder default"
          [isRequired]="true"
          [form]="inputForms"
        >
        </mo-input>

        <mo-input
          controlName="defaultLight"
          type="text"
          label="Label default light template "
          placeHolder="Placeholder default"
          [isRequired]="true"
          [template]="INPUT_TEMPLATES.LIGHT"
          [form]="inputForms"
        >
        </mo-input>

        <mo-input
          controlName="withOutLabel"
          type="text"
          placeHolder="Placeholder with out label"
          [form]="inputForms"
        >
        </mo-input>

        <mo-input
          controlName="email"
          type="email"
          label="Label email "
          placeHolder="Placeholder email"
          [isRequired]="true"
          [form]="inputForms"
          [isFloatingLabel]="true"
          [icons]="['uil-envelope']"
          [iconPosition]="ICON_POSITION.LEFT"
        >
        </mo-input>

        <mo-input
          controlName="iconRight"
          type="text"
          label="Label icon right "
          placeHolder="Placeholder icon right"
          [isRequired]="true"
          [form]="inputForms"
          [isFloatingLabel]="false"
          [icons]="['uil-search']"
          [iconPosition]="ICON_POSITION.LEFT"
          [template]="INPUT_TEMPLATES.LIGHT"
          [size]="'size-md'"
        >
        </mo-input>

        <mo-input
          controlName="iconLeft"
          type="text"
          label="Label icon left "
          placeHolder="Placeholder icon left default"
          [isRequired]="true"
          [form]="inputForms"
          [isFloatingLabel]="true"
          [icons]="['uil-comment-message']"
          [iconPosition]="ICON_POSITION.LEFT"
        >
        </mo-input>

        <mo-input
          controlName="dualIcon"
          type="text"
          label="Label dual icon "
          placeHolder="Placeholder dual icon"
          [isRequired]="true"
          [form]="inputForms"
          [isFloatingLabel]="true"
          [icons]="['uil-search', 'uil-comment-search']"
          [iconPosition]="ICON_POSITION.LEFT"
        >
        </mo-input>

        <mo-input
          controlName="disabledInput"
          type="text"
          label="Label disabled  "
          placeHolder="Placeholder disabled"
          [isRequired]="true"
          [form]="inputForms"
          [isFloatingLabel]="true"
          [icons]="['uil-ban']"
          [iconPosition]="ICON_POSITION.LEFT"
        >
        </mo-input>

        <!-- :::::::::::::: Checks block :::::::::::::: -->
        <h3 class="m-b-20">Checkbox</h3>

        <mo-checkbox
          label="Active"
          [form]="checkboksForm"
          [controlName]="'active'"
          [template]="INPUT_TEMPLATES.PRIMARY"
        ></mo-checkbox>

        <mo-checkbox
          label="InActive"
          [form]="checkboksForm"
          [controlName]="'inActive'"
        ></mo-checkbox>

        <mo-checkbox
          label="Disabled value"
          [form]="checkboksForm"
          [controlName]="'disabledValue'"
        ></mo-checkbox>

        <!-- :::::::::::::: Checks block :::::::::::::: -->
        <h3 class="m-b-25">Toggles</h3>

        <mo-toggle
          [form]="checkboksForm"
          [controlName]="'toggle'"
          [template]="INPUT_TEMPLATES.PRIMARY"
        ></mo-toggle>

        <!-- :::::::::::::: Selects block :::::::::::::: -->
        <h3 class="m-b-25">Selects</h3>

        <mo-select
          [form]="selectsForm"
          [controlName]="'default'"
          [defaultOptionName]="'Select default option example'"
          [options]="[{ value: 'VAL', key: 'Opcion 1' }]"
          [template]="INPUT_TEMPLATES.DEFAULT"
          [size]="'size-lg'"
        ></mo-select>

        <mo-select
          [form]="selectsForm"
          [controlName]="'light'"
          [template]="INPUT_TEMPLATES.PRIMARY"
          [options]="[{ value: 'VAL', key: 'Opcion 1' }]"
        ></mo-select>

        <mo-select
          [form]="selectsForm"
          [controlName]="'disabled'"
          [defaultOptionName]="'Select default disabled'"
          [options]="[{ value: 'VAL', key: 'Opcion 1' }]"
        ></mo-select>

        <!-- :::::::::::::: Dronpdowns :::::::::::::: -->
        <h3 class="m-b-25">Dropdowns</h3>
        <mo-drop-down
          [form]="selectsForm"
          [options]="[{ value: 'VAL', key: 'Opcion 1' }]"
          [controlName]="'dropDown'"
        ></mo-drop-down>

        <mo-drop-down
          [form]="selectsForm"
          [template]="INPUT_TEMPLATES.PRIMARY"
          [size]="'size-md'"
          [options]="[
            { value: 'VAL', key: 'Opcion 1' },
            { value: 'VAL2', key: 'Opcion 2' }
          ]"
          [controlName]="'dropDownLight'"
        ></mo-drop-down>

        <div>
          <h3>Places</h3>
          <mo-places
            [form]="othersForm"
            controlName="place"
            placeholder="My placeholder places"
            [template]="INPUT_TEMPLATES.LIGHT"
            [size]="BUTTON_SIZE.MD"
            (valueResponse)="onSetPlace($event)"
          ></mo-places>
        </div>

        <!-- :::::::::::::: Others components :::::::::::::: -->
        <form (ngSubmit)="onSubmit()" [formGroup]="othersForm">
          <h3>Others</h3>
          <mo-copy [secretValue]="'sjfm43klfm43lk34FSAD·$gdsfdsads'"></mo-copy>

          <div>
            <h3 class="m-b-20">Upload</h3>
            <mo-upload
              [form]="othersForm"
              controlName="upload"
              direction="direction-row"
              label="File"
              info="* For file sizes larger than XXGB, please contact us to complete your request."
            ></mo-upload>
          </div>

          <!-- :::::::::::::: Buttons :::::::::::::: -->
          <h3 class="m-t-20">Buttons</h3>

          <!-- :::::::::::::: Buttons SM  :::::::::::::: -->
          <div class="text-right m-t-20 m-b-20">
            <div class="flex-h">
              <mo-button-form
                [isDisabled]="false"
                [text]="'Button sm 1'"
                [size]="BUTTON_SIZE.SM"
                [template]="BUTON_TEMPLATES.PRIMARY"
              ></mo-button-form>
              <mo-button-form
                [isDisabled]="false"
                [text]="'Button sm 2 '"
                [isLoading]="true"
                [size]="BUTTON_SIZE.SM"
                [template]="BUTON_TEMPLATES.OUTLINE_PRIMARY"
              ></mo-button-form>
              <mo-button-form
                [isDisabled]="true"
                [text]="'Button sm disabled'"
                [size]="BUTTON_SIZE.SM"
                [template]="BUTON_TEMPLATES.PRIMARY"
              ></mo-button-form>
            </div>
          </div>
          <!-- :::::::::::::: Buttons MD  :::::::::::::: -->
          <div class="text-right m-t-20 m-b-20">
            <div class="flex-h">
              <mo-button-form
                [isDisabled]="false"
                [text]="'Button md 1'"
                [isLoading]="true"
                [template]="BUTON_TEMPLATES.PRIMARY"
                [size]="BUTTON_SIZE.MD"
              ></mo-button-form>
              <mo-button-form
                [isDisabled]="false"
                [text]="'Button md 2'"
                [template]="BUTON_TEMPLATES.OUTLINE_PRIMARY"
                [size]="BUTTON_SIZE.MD"
              ></mo-button-form>
              <mo-button-form
                [isDisabled]="true"
                [text]="'Button md disable'"
                [template]="BUTON_TEMPLATES.OUTLINE_PRIMARY"
                [size]="BUTTON_SIZE.MD"
              ></mo-button-form>
            </div>
          </div>
          <!-- :::::::::::::: Buttons LG  :::::::::::::: -->
          <div class="text-right m-t-20 m-b-20">
            <div class="flex-h">
              <mo-button-form
                [isDisabled]="false"
                [text]="'Button lg 1'"
                [icon]="'uil-eye-slash'"
                [isLoading]="true"
                [size]="BUTTON_SIZE.LG"
                [template]="BUTON_TEMPLATES.PRIMARY"
                [iconPosition]="ICON_POSITION.LEFT"
              ></mo-button-form>
              <mo-button-form
                [isDisabled]="false"
                [text]="'Button lg 2'"
                [icon]="'uil-eye-slash'"
                [size]="BUTTON_SIZE.LG"
                [template]="BUTON_TEMPLATES.OUTLINE_PRIMARY"
                [iconPosition]="ICON_POSITION.RIGTH"
              ></mo-button-form>
              <mo-button-form
                [isDisabled]="true"
                [text]="'Button lg disabled'"
                [icon]="'uil-eye-slash'"
                [size]="BUTTON_SIZE.LG"
                [template]="BUTON_TEMPLATES.PRIMARY"
                [iconPosition]="ICON_POSITION.RIGTH"
              ></mo-button-form>
            </div>
          </div>
          <!-- :::::::::::::: Buttons Alternatives SM  :::::::::::::: -->
          <div class="text-right m-t-20 m-b-20">
            <div class="flex-h">
              <mo-button-form
                [isDisabled]="false"
                [text]="'Button lg alternative'"
                [icon]="'uil-plus'"
                [size]="BUTTON_SIZE.SM"
                [template]="BUTON_TEMPLATES.ALTERNATIVE"
                [iconPosition]="ICON_POSITION.LEFT"
              ></mo-button-form>
              <mo-button-form
                [isDisabled]="false"
                [text]="'Button lg alternative'"
                [icon]="'uil-plus'"
                [size]="BUTTON_SIZE.SM"
                [template]="BUTON_TEMPLATES.ALTERNATIVE"
                [iconPosition]="ICON_POSITION.LEFT"
              ></mo-button-form>
              <mo-button-form
                [isDisabled]="true"
                [text]="'Button lg disabled'"
                [icon]="'uil-plus'"
                [size]="BUTTON_SIZE.SM"
                [template]="BUTON_TEMPLATES.ALTERNATIVE"
                [iconPosition]="ICON_POSITION.LEFT"
              ></mo-button-form>
            </div>
          </div>
          <!-- :::::::::::::: Buttons Alternatives  :::::::::::::: -->
          <div class="text-right m-t-20 m-b-20">
            <div class="flex-h">
              <mo-button-form
                [isDisabled]="false"
                [text]="'Button lg alternative'"
                [icon]="'uil-plus'"
                [size]="BUTTON_SIZE.MD"
                [template]="BUTON_TEMPLATES.ALTERNATIVE"
                [iconPosition]="ICON_POSITION.LEFT"
              ></mo-button-form>
              <mo-button-form
                [isDisabled]="false"
                [text]="'Button lg alternative'"
                [icon]="'uil-plus'"
                [size]="BUTTON_SIZE.MD"
                [template]="BUTON_TEMPLATES.ALTERNATIVE"
                [iconPosition]="ICON_POSITION.LEFT"
              ></mo-button-form>
              <mo-button-form
                [isDisabled]="true"
                [text]="'Button lg disabled'"
                [icon]="'uil-plus'"
                [size]="BUTTON_SIZE.MD"
                [template]="BUTON_TEMPLATES.ALTERNATIVE"
                [iconPosition]="ICON_POSITION.LEFT"
              ></mo-button-form>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div class="json-data">
    <h1>JSON data</h1>

    <div class="flex-h">
      <div>
        <h3>Inputs</h3>
        <code class="code-box">
          <pre>
            <p>{{ inputForms.value | json }}</p>
          </pre>
        </code>
      </div>
      <div>
        <h3>Checkboxs</h3>
        <code class="code-box">
          <pre>
            <p>{{ checkboksForm.value | json }}</p>
          </pre>
        </code>
      </div>
    </div>

    <div class="flex-h">
      <div>
        <h3>Selects</h3>
        <code class="code-box">
          <pre>
            <p>{{ selectsForm.value | json }}</p>
          </pre>
        </code>
      </div>

      <div>
        <h3>Others</h3>
        <code class="code-box">
          <pre>
            <p>{{ othersForm.value | json }}</p>
          </pre>
        </code>
      </div>
    </div>
  </div>
</div>
