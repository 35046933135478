import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, pluck } from 'rxjs/operators';
import {
  Country,
  Document,
  IDocumentDetails,
  DocumentDetailsResponse,
  DocumentFile,
  DocumentsFilters,
  IDocumentStates,
  Template,
  TemplatesFilters,
  TemplateVersion,
  TemplateVersionParams,
  TypeTemplate,
  IDocumentDetailTransactions,
  IFileData,
  IOverviewHeaderInfo,
  IOverviewUseInfo,
  IOverviewPerformanceInfo,
  IOverviewSubmisionInfo,
} from '@app/core/models/fetch.model';
import { PagedResponse } from '@app/core/models/table.model';
import { APP_CONSTANTS as CONST } from '@app/app.constants';
import { UtilService } from '../util.service';

@Injectable({ providedIn: 'root' })
export class FetchAPI {
  constructor(private _http: HttpClient, private _utilService: UtilService) {}

  public getCountries(): Observable<Country[]> {
    return this._http.get<Country[]>(CONST.URLS.COUNTRY).pipe(pluck('data'));
  }

  public getStates(): Observable<IDocumentStates[]> {
    return this._http
      .get<IDocumentStates[]>(CONST.URLS.STATES)
      .pipe(pluck('data'));
  }

  public getTypeTemplates(countryCode: string): Observable<TypeTemplate[]> {
    const url = CONST.URLS.TEMPLATES_TYPE.replace('/.+/', `/${countryCode}/`);
    return this._http.get<TypeTemplate[]>(url).pipe(pluck('data'));
  }

  public getTemplates(
    filters: TemplatesFilters
  ): Observable<PagedResponse<Template[]>> {
    const params = this._utilService.createAndValidateParams(filters);
    return this._http.get<PagedResponse<Template[]>>(CONST.URLS.TEMPLATES, {
      params,
    });
  }

  public getTemplatesVersion(
    templateVersionParams: TemplateVersionParams
  ): Observable<PagedResponse<TemplateVersion[]>> {
    const params = this._utilService.createAndValidateParams(
      templateVersionParams
    );
    return this._http.get<PagedResponse<TemplateVersion[]>>(
      CONST.URLS.TEMPLATES_VERSION,
      { params }
    );
  }

  public updateTemplates(
    templates: Template[],
    { code }: Country
  ): Observable<Template[]> {
    const list = templates.map((template) =>
      this._mappingUpdateTemplate(template, code)
    );
    return this._http
      .patch<Template[]>(CONST.URLS.TEMPLATES, { list })
      .pipe(pluck('data'));
  }

  public getDocuments(
    filters?: DocumentsFilters
  ): Observable<PagedResponse<Document[]>> {
    const params = this._utilService.createAndValidateParams(filters);
    return this._http.get<PagedResponse<Document[]>>(CONST.URLS.DOCUMENTS, {
      params,
    });
  }

  public getDocumentDetail(id: string): Observable<IDocumentDetails> {
    const url = CONST.URLS.DOCUMENT_DETAILS.replace('/.+/', `/${id}/`);
    return this._http.get<DocumentDetailsResponse>(url).pipe(pluck('data'));
  }

  public getDocumentDetailTransactions(
    id: string
  ): Observable<IDocumentDetailTransactions[]> {
    const url = CONST.URLS.DOCUMENT_DETAILS_TRANSACTIONS.replace(
      '/.+/',
      `/${id}/`
    );
    const params = this._utilService.createAndValidateParams({ size: 20 });
    return this._http
      .get<IDocumentDetailTransactions[]>(url, { params })
      .pipe(pluck('data'));
  }

  public getDocumentsFiles(id: string): Observable<DocumentFile[]> {
    const url = CONST.URLS.DOCUMENT_FILE.replace('/.+/', `/${id}/`);
    return this._http.get<DocumentFile[]>(url).pipe(pluck('data'));
  }

  public saveSuggestion(fileData: IFileData): Observable<any> {
    const url = CONST.URLS.SAVE_SUGGESTIONS;
    fileData = {
      ...fileData,
      suggestion_name: CONST.SUGGESTION_NAME_FETCH,
    };
    delete fileData?.file;
    return this._http.post<any>(url, fileData);
  }

  public uploadFile(fileData: IFileData): Observable<any> {
    const url = CONST.URLS.UPLOAD_FILE;

    var formData: any = new FormData();

    formData.append('file', fileData?.file);
    formData.append('file_name', this._generateFileName(fileData?.file_name));

    return this._http.post<any>(url, formData).pipe(pluck('data'));
  }

  public getOverviewHeader(filters: any): Observable<IOverviewHeaderInfo> {
    const params = this._utilService.createAndValidateParams(filters);
    return this._http
      .get<IOverviewHeaderInfo>(CONST.URLS.OVERVIEW_HEADER, {
        params,
      })
      .pipe(pluck('data'));
  }

  public getOverviewUse(): Observable<IOverviewUseInfo> {
    return this._http
      .get<IOverviewUseInfo>(CONST.URLS.OVERVIEW_USE)
      .pipe(pluck('data'));
  }

  public getOverviewPerformance(): Observable<IOverviewPerformanceInfo> {
    return this._http
      .get<IOverviewPerformanceInfo>(CONST.URLS.OVERVIEW_PERFORMANCE)
      .pipe(pluck('data'));
  }

  public getOverviewSubmision(): Observable<IOverviewSubmisionInfo[]> {
    return this._http
      .get<IOverviewSubmisionInfo[]>(CONST.URLS.OVERVIEW_SUBMISION)
      .pipe(pluck('data'));
  }

  private _generateFileName(fileName: string): string {
    // Separete file name and extension
    const ext = fileName.substring(fileName.lastIndexOf('.'));
    let name = fileName.split('.').slice(0, -1).join('.');
    // Remove special characteres
    name = name.replace(/[&\/\\#,+()$~%.'"-_¨¡!:*¿?<>{}/\s]/g, '');
    // Generate unique id by date
    name = `${name}${new Date().getTime()}`;
    // Limit name (without extension) to 100 characteres
    name = name.substring(0, 100);
    // Add extension to the name
    name = `${name}${ext}`;
    return name;
  }

  private _mappingUpdateTemplate(
    { name, status }: Template,
    country_code: string
  ) {
    return {
      template_name: name,
      country_code: country_code,
      status,
    };
  }
}
