<div class="support-success">
  <layout-confirm-message
    title="Your message has been successfully sent"
  ></layout-confirm-message>

  <div class="m-t-15">
    <mo-button-form
      text="Finish"
      (click)="onGoBack()"
      [template]="buttonTemplate"
      [size]="BUTTON_SIZE.MD"
    ></mo-button-form>
  </div>
</div>
