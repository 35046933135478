import { Component } from '@angular/core';

@Component({
  selector: 'modal-all-file-requirement',
  templateUrl: './all-file-requirements.component.html',
  styleUrls: ['./all-file-requirements.component.scss'],
})
export class AllFileRequirementsComponent {

}
