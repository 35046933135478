import { Component, OnInit } from '@angular/core';
import { InsightFacade } from '@app/facades/insight.facade';
import { AuthService } from '@app/services/auth/auth.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'banner-remaining',
  templateUrl: './banner-remaining.component.html',
  styleUrls: ['./banner-remaining.component.scss'],
})
export class BannerRemainingComponent implements OnInit {
  public requestRemaining$: Observable<number>;
  public daysLeft$: Observable<number>;

  constructor(
    private _authService: AuthService,
    private _insightFacade: InsightFacade
  ) {}

  ngOnInit() {
    this.daysLeft$ = this._insightFacade.daysLeft$;
    this.requestRemaining$ = this._insightFacade.requestRemaining$
  }
}
