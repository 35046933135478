export const TEMPLATES_SUCCESS = {
  data: [
    {
      id: 1,
      name: 'Template A',
      description: null,
      template_type: 'Extracto',
      org_name: 'Organization X',
      org_uuid: 111222333,
      status: false,
    },
    {
      id: 2,
      name: 'Template B',
      description: null,
      template_type: 'Extracto',
      org_name: 'Organization X',
      org_uuid: 111222333,
      status: false,
    },
    {
      id: 3,
      name: 'Template C ',
      description: null,
      template_type: 'Extracto',
      org_name: 'Organization X',
      org_uuid: 111222333,
      status: false,
    },
    {
      id: 4,
      name: 'Template D',
      description: null,
      template_type: 'Extracto',
      org_name: 'Organization X',
      org_uuid: 111222333,
      status: true,
    },
    {
      id: 5,
      name: 'Template E',
      description: null,
      template_type: 'Extracto',
      org_name: 'Organization X',
      org_uuid: 111222333,
      status: true,
    },
    {
      id: 6,
      name: 'Template F',
      description: null,
      template_type: 'Extracto',
      org_name: 'Organization X',
      org_uuid: 111222333,
      status: true,
    },
    {
      id: 7,
      name: 'Template G',
      description: null,
      template_type: 'Extracto',
      org_name: 'Organization X',
      org_uuid: 111222333,
      status: true,
    },
  ],
  response_code: null,
  errors: null,
  total: 7,
  page: 0,
  size: 10,
};
