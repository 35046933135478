import { Group } from './insight.model';
import { ISidebarExtraData } from './sidebar-modal.model';
import { Filters } from './table.model';

export interface RequestSidebarParams extends ISidebarExtraData {
  request: Request;
}

export enum REQUEST_STATES {
  SUCCESS = 'comp',
  FAIL = 'fail',
  PENDING = 'pend',
}

export type RequestStatus = REQUEST_STATES;

export interface RequestNotification {
  id?: string;
  status: RequestStatus;
}

export interface Request {
  id?: string | null;
  request_id: string | null;
  config_id: string | null;
  config_name?: string | null;
  creation_date?: string;
  kpis_obtained?: string;
  datapoints_obtained?: string;
  status_key: string | null;
  status_name?: string | null;
  groups?: Group[];
}

export interface RequestDetail {
  id?: string | null;
  status: RequestStatus;
}

export interface RequestFilters extends Filters {
  status?: string;
  country?: string;
  start_date?: Date;
  end_date?: Date;
  request_id?: string;
  config_id?: string;
}

export interface RequestMapFilters {
  creation_date?: Date;
  config_id?: string;
}

export interface RequestSummary {
  total_requests: number;
  approved_requests: number;
  pending_requests: number;
  rejected_requests: number;
  requests_made?: number;
  last_requests: Request[];
}

export enum LOCATION_TYPES {
  COORDINATES = 'coordinates',
  ZIP_CODE = 'zip_code',
  ADDRESS = 'address',
}

export type TypeLocation = LOCATION_TYPES;

export interface RequestCoordinates extends RequestForm {
  latitude: number;
  longitude: number;
  radius: number;
}

export interface RequestAddress extends RequestForm {
  address: string;
  radius: number;
}

export interface RequestForm {
  type?: TypeLocation;
  config_id?: string;
  country?: string; // This attribute should be set by the effect
}

export interface RequestSuccess {
  request_id: string;
}

export enum TYPE_FILE {
  EXCEL = 'xlsx',
  CSV = 'csv',
  JSON = 'json',
}

export type TypeFile = TYPE_FILE;

export interface RequestDownload {
  request_id: string;
  type: TypeFile;
}
