import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of, throwError } from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators';

import { APP_CONSTANTS as CONST } from '@app/app.constants';
import {
  ConfigurationFilters,
  Country,
  CountryFilter,
  KPI,
  KPIFilters,
  ParentKpis,
  PremiumFilters,
  ResponseApiKey,
} from '@app/core/models/insight.model';
import { InsightAPI } from '@app/services/insight/insight.api';

import * as requestActions from '@store/insight-request/insight-request.actions';
import * as appSelectors from './../app/app.selectors';
import * as appActions from './../app/app.actions';
import * as insightActions from './insight.actions';
import * as insightSelectors from './insight.selectors';
import { PagedResponse } from '@app/core/models/table.model';
import { GoogleAnalyticsService } from '@app/services/google-analytics.service';
import { GA } from '@app/core/constants/google-analytics.constant';
import { UtilService } from '../../services/util.service';
import { tap, delay } from 'rxjs/operators';
import { AppFacade } from '../../facades/app.facade';
import {
  COMPONENTS,
  MODULES,
  MODAL_SIZE,
} from '../../layout/components/modal/modal-content.type';

@Injectable()
export class InsightEffects {
  constructor(
    private _actions$: Actions,
    private _insightAPI: InsightAPI,
    private _store: Store,
    private _gaService: GoogleAnalyticsService,
    private _utilService: UtilService,
    private _appFacade: AppFacade
  ) {}

  public consultApiData$ = createEffect(() =>
    this._actions$.pipe(
      ofType(insightActions.consultApiData, requestActions.initFormsSidebar),
      withLatestFrom(this._store.select(appSelectors.selectProduct)),
      switchMap(([_, productSelected]) => {
        const productName = productSelected.backendName;
        return this._insightAPI.getApiKey(productName).pipe(
          map((response: ResponseApiKey) => {
            return insightActions.responseConsultApi({ response });
          }),
          catchError((error) => {
            insightActions.failureConsultApi({ error });
            return throwError(error);
          })
        );
      })
    )
  );

  public getCountriesToInsight$ = createEffect(() =>
    this._actions$.pipe(
      ofType(appActions.responseInitialProduct),
      filter(({ product }) => product.backendName === CONST.PRODUCTS.INSIGHT),
      switchMap(() => this._getCountries())
    )
  );

  public infoByCountryEffect$ = createEffect(() =>
    this._actions$.pipe(
      ofType(insightActions.changeCountryKPI),
      withLatestFrom(this._store.select(insightSelectors.selectCountry)),
      filter(([_, country]) => !!country),
      switchMap(([_, country]) =>
        this._insightAPI
          .getInfoByCountry(country)
          .pipe(
            map((info) =>
              insightActions.responseInfoCountry({ infoCountry: info })
            )
          )
      )
    )
  );

  public getConfigsEffect$ = createEffect(() => {
    const triggerActions = [
      insightActions.changeCountryKPI,
      insightActions.changeCountrySlideBar,
      insightActions.responseToggleConfiguration,
    ];
    return this._actions$.pipe(
      ofType(...triggerActions),
      withLatestFrom(this._store.select(insightSelectors.selectCountry)),
      filter(([_, country]) => !!country),
      switchMap(([_, country]) => this._getConfigsAPI({ country }))
    );
  });

  public getConfigsEffectSidebar$ = createEffect(() => {
    const triggerActions = [insightActions.changeCountrySlideBar];
    return this._actions$.pipe(
      ofType(...triggerActions),
      withLatestFrom(this._store.select(insightSelectors.selectCountry)),
      filter(([_, country]) => !!country),
      switchMap(([_, country]) => this._getConfigsAPI({ country }))
    );
  });

  public filterConfigEffect$ = createEffect(() => {
    const triggerActions = [
      insightActions.initConfigPage,
      insightActions.changeCountryKPI,
      insightActions.changeFiltersConfigs,
      insightActions.responseDeleteConfig,
      insightActions.responseToggleConfiguration,
    ];

    return this._actions$.pipe(
      ofType(...triggerActions),
      debounceTime(CONST.TIME_WAITING_IN_FILTERS),
      distinctUntilChanged(),
      withLatestFrom(this._store.select(insightSelectors.selectFilterKPIPage)),
      filter(([_, filters]) => {
        // TODO: Add validation when country develop: !!filters?.country &&
        return filters ? filters['isConfigPage'] : null;
      }),
      switchMap(([_, filters]) => {
        if (filters) {
          return this._getConfigsDetails(filters);
        }
      })
    );
  });

  public deleteConfigurationSubmit$ = createEffect(() =>
    this._actions$.pipe(
      ofType(insightActions.deleteConfig),
      switchMap(({ id }) => this._deleteConfigAPI(id))
    )
  );

  public filterKPIsEffect$ = createEffect(() =>
    this._actions$.pipe(
      ofType(
        insightActions.changeFiltersLibrary,
        insightActions.initLibraryPage,
        insightActions.changeCountryKPI
      ),
      debounceTime(CONST.TIME_WAITING_IN_FILTERS),
      distinctUntilChanged(),
      withLatestFrom(this._store.select(insightSelectors.selectFilterKPIPage)),
      filter(([_, filters]) => !!filters?.country && filters['isLibraryPage']),
      switchMap(([_, filters]) => this._getParents(filters))
    )
  );

  public createConfigurationEffect$ = createEffect(() =>
    this._actions$.pipe(
      ofType(insightActions.createConfigurationSubmit),
      switchMap((payload) =>
        this._insightAPI.createConfiguration(payload.configuration).pipe(
          map((response) => {
            this._gaService.sendEvent(GA.CONFIGURATION.REQUEST_SUCCESS);
            return insightActions.responseCreateConfiguration({
              configuration: response,
            });
          }),
          catchError((_) => {
            this._gaService.sendEvent(GA.CONFIGURATION.REQUEST_ERROR);
            console.error(_);
            const error = {
              message:
                'There was an error creating your record. Please try again or contact us for support.',
            };
            return of(insightActions.failureCreateConfiguration({ error }));
          })
        )
      )
    )
  );

  public toggleConfigurationEffect$ = createEffect(() =>
    this._actions$.pipe(
      ofType(insightActions.toggleConfiguration),
      switchMap(({ configuration }) =>
        this._insightAPI.editConfiguration(configuration).pipe(
          map((_) => insightActions.responseToggleConfiguration()),
          catchError((_) => {
            const action = configuration.status ? 'active' : 'inactive';
            const error = { message: `Error when toggle config to ${action}` };
            return of(insightActions.failureEditConfiguration({ error }));
          })
        )
      )
    )
  );
  public editConfigurationEffect$ = createEffect(() =>
    this._actions$.pipe(
      ofType(insightActions.editConfigurationSubmit),
      switchMap((payload) =>
        this._insightAPI.editConfiguration(payload.configuration).pipe(
          map((configuration) =>
            insightActions.responseEditConfiguration({
              configuration,
            })
          ),
          catchError((_) => {
            const error = { message: 'Configuration could not be edited' };
            return of(insightActions.failureEditConfiguration({ error }));
          })
        )
      )
    )
  );

  public sendSupportEffect$ = createEffect(() =>
    this._actions$.pipe(
      ofType(insightActions.sendSupportSubmit),
      switchMap((payload) =>
        this._insightAPI.createSupport(payload.support).pipe(
          map(() => {
            return insightActions.responseSendSupport();
          }),
          catchError((_) => {
            const error = { message: 'Support message could not be submit' };
            return of(insightActions.failureSendSupport({ error }));
          })
        )
      )
    )
  );

  public sendUpgradePlanSubmit$ = createEffect(() =>
    this._actions$.pipe(
      ofType(insightActions.sendUpgradePlanSubmit),
      switchMap((payload) =>
        this._insightAPI.createSupport(payload.support).pipe(
          map(() => {
            this._appFacade.closeModal();
            return insightActions.responseUpgradePlanSubmit();
          }),
          catchError((_) => {
            const error = { message: 'Upgrade request could not be submit' };
            return of(insightActions.failureUpgradePlanSubmit({ error }));
          })
        )
      )
    )
  );

  public responseUpgradePlanSubmit$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(insightActions.responseUpgradePlanSubmit),
        delay(1200),
        tap((_) => {
          this._appFacade.openModal({
            content: {
              component: COMPONENTS.AUTH_RESPONSE_DATA,
              module: MODULES.GEO,
            },
            show: true,
            size: MODAL_SIZE.MD,
            showExitIcon: false,
            metadata: {
              state: 'success-info',
              title: 'Done',
              info: 'Thanks! A sales representative will come back to you shortly!',
            },
          });
        })
      ),
    { dispatch: false }
  );

  public filterKPIsPremiumEffect$ = createEffect(() =>
    this._actions$.pipe(
      ofType(
        insightActions.changeFiltersPremium,
        insightActions.initPremiumPage
      ),
      debounceTime(CONST.TIME_WAITING_IN_FILTERS),
      distinctUntilChanged(),
      withLatestFrom(
        this._store.select(insightSelectors.selectFilterPremiumPage)
      ),
      filter(([_, filters]) => !!filters?.country),
      switchMap(([_, filters]) => this._getPremiumKPIs(filters))
    )
  );

  private _getCountries() {
    return this._insightAPI
      .getCountries()
      .pipe(
        map((countries: Country[]) =>
          insightActions.responseCountries({ countries })
        )
      );
  }

  private _getConfigsAPI(filter?: CountryFilter) {
    return this._insightAPI.getConfigs(filter).pipe(
      map((response) => insightActions.responseGetConfigs({ response })),
      catchError((_) => {
        const error = {
          message: 'Occur an error when we get the configurations',
        };
        return of(insightActions.failureGetConfigs({ error }));
      })
    );
  }

  private _getConfigsDetails(filters?: ConfigurationFilters) {
    delete filters['isConfigPage'];
    return this._insightAPI.getConfigsDetails(filters).pipe(
      map((response) => insightActions.responseGetConfigsDetails({ response })),
      catchError((_) => {
        const error = { message: 'Something went wrong' };
        return of(insightActions.failureGetConfigsDetails({ error }));
      })
    );
  }

  private _deleteConfigAPI(id: string) {
    return this._insightAPI.deleteConfig(id).pipe(
      map((_) => insightActions.responseDeleteConfig()),
      catchError((_) => {
        const error = {
          message: 'Ocurrió un error mientras se eliminaba la configuración',
        };
        return of(insightActions.failureDeleteConfig({ error }));
      })
    );
  }

  private _getPremiumKPIs(filters?: PremiumFilters) {
    return this._insightAPI.getPremiumKPIsParents(filters).pipe(
      map((response) => {
        return insightActions.responseGetPremiumKPIsParents({ response });
      }),
      catchError((_) => {
        const error = { message: 'Something went wrong' };
        return of(insightActions.failureGetPremiumKPIsParents({ error }));
      })
    );
  }

  private _getParents(filters?: KPIFilters) {
    delete filters['isLibraryPage'];
    return this._insightAPI.getParents(filters).pipe(
      map((response) => {
        return insightActions.responseGetParents({ response });
      }),
      catchError((_) => {
        const error = { message: 'Something went wrong' };
        return of(insightActions.failureGetParents({ error }));
      })
    );
  }

  public getKpisByParentName$ = createEffect(() =>
    this._actions$.pipe(
      ofType(insightActions.selectParent),
      withLatestFrom(
        this._store.select(insightSelectors.selectParent),
        this._store.select(insightSelectors.selectFilterKPIPage),
        this._store.select(insightSelectors.selectParentKpis)
      ),
      filter(
        ([_, selectParent]) => !!selectParent,
        ([_, filters]) => !!filters?.country && filters['isLibraryPage']
      ),
      switchMap(([_, selectParent, filters, parentKpis]) => {
        if (parentKpis && parentKpis[selectParent]) {
          const response: PagedResponse<KPI[]> = parentKpis[selectParent];
          return of(insightActions.responseGetKpis({ response }));
        }
        return this._insightAPI.getKpis(selectParent, filters).pipe(
          map((response: PagedResponse<KPI[]>) => {
            return insightActions.responseGetKpis({ response });
          }),
          catchError((_) => {
            const error = { message: 'Something went wrong' };
            return of(insightActions.failureGetKpis({ error }));
          })
        );
      })
    )
  );

  public setParentKpis$ = createEffect(() =>
    this._actions$.pipe(
      ofType(insightActions.responseGetKpis),
      withLatestFrom(
        this._store.select(insightSelectors.selectParent),
        this._store.select(insightSelectors.selectKPIs)
      ),
      filter(
        ([_, selectParent]) => !!selectParent,
        ([_, selectKPIs]) => !!selectKPIs
      ),
      map(([_, selectParent, selectKPIs]) => {
        const parentKpis: ParentKpis = {
          [selectParent]: selectKPIs,
        };
        return insightActions.responseParentKpi({ parentKpis });
      })
    )
  );

  public downloadManualDocument$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(insightActions.downloadManualDocument),
        tap((_) => this._utilService.downloadFiles(CONST.MANUAL_URL))
      ),
    { dispatch: false }
  );
}
