export const KPIS_PARENTS_SUCCESS = {
  data: [
    {
      parent_name: 'Market',
      total_kpis: 2,
    },
    {
      parent_name: 'Electronics store',
      total_kpis: 32,
    },
    {
      parent_name: 'Government office',
      total_kpis: 2,
    },
  ],
  page: 0,
  size: 3,
  total: 3,
  response_code: {},
  errors: [],
};
