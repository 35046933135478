export const ORGANIZATIONS_SUCCESS = {
  data: {
    owner_name: 'Ralp Edwards',
    owner_email: 'email_owner',
    org_name: 'org name',
    org_id: 'id',
    products: ['insight', 'fetch'],
    catalogs: {
      request_status: [
        {
          key: 'comp',
          name: 'Completed',
        },
        {
          key: 'fail',
          name: 'Failed',
        },
        {
          key: 'pend',
          name: 'Pending',
        },
      ],
    },
  },
};
