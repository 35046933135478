import { createReducer, on, Action } from '@ngrx/store';
import * as authActions from './auth.actions';
import * as userActions from '../user/user.actions';
import { AuthState } from './auth.state';

export const initialAuthState: AuthState = {
  isLogged: false,
  loginData: null,
  forgotPasswordData: null,
  createPasswordData: null,
  response: null,
  errorLogin: null,
  isLoadingPage: false,
  isPasswordSuccess: false,
  isForgotPasswordSuccess: false,
};

const _authReducer = createReducer(
  initialAuthState,
  on(authActions.loginSubmit, (state, { loginData }) => {
    return {
      ...state,
      loginData,
      isLoadingPage: true,
      errorLogin: null
    };
  }),
  on(authActions.failureLogin, (state, { error }) => {
    return { ...state, isLoadingPage: false, errorLogin: error };
  }),
  on(authActions.logout, userActions.logout, userActions.logoutGuard, (state) => {
    return { ...state, isLogged: false };
  }),
  on(userActions.responseConsultUser, (state) => {
    return { ...state, isLoadingPage: false, isLogged: true };
  }),
  on(authActions.failureLogin, (state, { error }) => {
    return {
      ...state,
      response: error.message,
      isLoadingPage: false,
    };
  }),
  on(authActions.forgotPasswordSubmit, (state, { data }) => {
    return {
      ...state,
      forgotPasswordData: data,
      isLoadingPage: true,
      error: null
    };
  }),
  on(authActions.successForgotPassword, (state) => {
    return {
      ...state,
      isLoadingPage: false,
      isForgotPasswordSuccess: true,
    };
  }),
  on(authActions.failureForgotPassword, (state, { error }) => {
    return {
      ...state,
      response: error.message,
      isLoadingPage: false,
    };
  }),
  on(authActions.createPasswordSubmit, (state, { data }) => {
    return {
      ...state,
      createPasswordData: data,
      isLoading: true,
    };
  }),
  on(authActions.successCreatePassword, (state) => {
    return {
      ...state,
      isLoading: false,
      isPasswordSuccess: true,
    };
  }),
  on(authActions.failureCreatePassword, (state, { error }) => {
    return {
      ...state,
      response: error.message,
      isLoading: false,
    };
  }),
  on(authActions.successPasswordToken, (state) => {
    return {
      ...state,
      isLoading: false,
    };
  }),
  on(authActions.failurePasswordToken, (state, { error }) => {
    return {
      ...state,
      response: error.message,
      isLoading: false,
    };
  }),
  on(authActions.resetAuthPages, (state) => {
    return {
      ...state,
      isPasswordSuccess: false,
      isForgotPasswordSuccess: false,
    };
  })
);

export function AuthReducers(state: AuthState | undefined, action: Action) {
  return _authReducer(state, action);
}
