import { Error } from '@app/core/models/error';
import { Catalog, Catalogs, User } from '@app/core/models/user.model';
import { createAction, props } from '@ngrx/store';

export const consultUserLogin = createAction('[Login Page] Consult User');
export const responseConsultUser = createAction('[User API] Response User', props<{ user: User, catalogs: Catalogs, products: string[] }>());  
export const responseConsultUserGuard = createAction('[Guard - User API] Response User', props<{ user: User, catalogs: Catalogs, products: string[] }>());  
export const errorLogin = createAction('[User API] Error Login', props<{ error: Error }>());
export const logoutGuard = createAction('[Guard - User API] Logout');
export const logout = createAction('[User API] Logout');
