import { Error } from '@app/core/models/error';
import { createAction, props } from '@ngrx/store';
import {
  Request,
  RequestCoordinates,
  RequestSummary,
  RequestSuccess,
  RequestAddress,
  RequestForm,
  RequestNotification,
  RequestDownload,
} from '@app/core/models/request.model';
import { Filters, PagedResponse } from '@app/core/models/table.model';
import { BatchFile, RequestFileResponse } from '@app/core/models/insight.model';

export const responseGetRequestSummary = createAction(
  '[Insight API] Response get request summary',
  props<{ response: RequestSummary }>()
);
export const failureGetRequestSummary = createAction(
  '[Insight API] Failure get request summary',
  props<{ error: Error }>()
);

// Request table
export const initRequestPage = createAction('[Request Page] Enter');
export const reloadRequestWithOutLoading = createAction(
  '[Request Page] Reload request with out loading'
);
export const changeFiltersRequests = createAction(
  '[Request Page] Change filters to search requests',
  props<{ filters: Filters }>()
);
export const responseGetRequests = createAction(
  '[Insight API] Response requests',
  props<{ response: PagedResponse<Request> }>()
);
export const failureGetRequests = createAction(
  '[Insight API] Failure get requests',
  props<{ error: Error }>()
);

// Request form to create (sidebar)
export const initFormsSidebar = createAction('[Request form sidebar] Enter');
export const leaveFormsSidebar = createAction('[Request form sidebar] Leave');
export const createByCoordinates = createAction(
  '[Request form sidebar] Create request by coordinates',
  props<{ coordinates: RequestCoordinates }>()
);
export const createByAddress = createAction(
  '[Request form sidebar] Create request by address',
  props<{ address: RequestAddress }>()
);
export const createRequest = createAction(
  '[Request form sidebar] Create request',
  props<{ request: RequestForm }>()
);
export const responseCreateRequest = createAction(
  '[Insight API] Response Create Request',
  props<{ confirmation: RequestSuccess }>()
);
export const responseCreateRequestWS = createAction(
  'Response Create Request for WS',
  props<{ confirmation: RequestSuccess }>()
);
export const responseRefreshRequestWS = createAction(
  'Response refresh Request for WS',
  props<{ confirmation: RequestSuccess }>()
);
export const failureCreateRequest = createAction(
  '[Insight API] Failure Create Request',
  props<{ error: Error }>()
);
/// Polling
export const responseRequestStatus = createAction(
  '[Insight API] Response request with new status',
  props<{ request: RequestNotification }>()
);
export const closeRequestStatusPolling = createAction(
  '[Insight API] Close polling request with new status'
);

// Request download
export const downloadResultRequest = createAction(
  '[Request detail sidebar] Download file',
  props<{ request: RequestDownload }>()
);
export const responsePresignURL = createAction(
  '[Insight API] Generate presign URL',
  props<{ fileResponse: RequestFileResponse }>()
);
export const failurePresignURL = createAction(
  '[Insight API] Failure presign URL',
  props<{ error: Error }>()
);
export const uploadFileWithRequests = createAction(
  '[Insight API] upload bash',
  props<{ request: BatchFile }>()
);

export const responseUploadBatchRequest = createAction(
  '[Insight API] Response Upload bash Request',
  props<{ confirmation: RequestSuccess }>()
);
