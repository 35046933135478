export const FETCH_STATES_SUCCESS = {
  data: [
    {
      status_name: 'Pending',
      status_code: 200,
    },
    {
      status_name: 'Complete',
      status_code: 202,
    },
  ],
};
