import { Component, Input, OnInit } from '@angular/core';
import { BOTTOM_BAR_CONFIG } from '@app/core/constants/insigth.constant';
import { AppFacade } from '@app/facades/app.facade';
import { BUTTON_SIZE, BUTTON_TEMPLATE } from '@app/mo-forms/enums/button.type';
import { ICON_POSITION } from '@app/mo-forms/enums/input.type';
import { FormNewConfigurationComponent } from '@app/pages/insight/request/forms/new-configuration/form-new-configuration.component';
import { Observable } from 'rxjs';
import { IBottomBar, IBottonBarComponent } from './bottombar-interface';
import { BOTTOM_BAR_TYPES } from './bottombar.types';

@Component({
  selector: 'layout-bottombar-modal',
  templateUrl: './bottombar-modal.component.html',
  styleUrls: ['./bottombar-modal.component.scss'],
})
export class BottomBarModalComponent implements OnInit, IBottonBarComponent {
  @Input() config: IBottomBar<IBottonBarComponent>[] = [];

  public isMenuCollapsed$: Observable<boolean>;
  public isBottomBarClose$: Observable<boolean>;

  public ICON_POSITION = ICON_POSITION;
  public BUTON_TEMPLATES = BUTTON_TEMPLATE;
  public BUTTON_SIZE = BUTTON_SIZE;

  public isClose: boolean;
  public componentRef;
  public selectedConfig: IBottomBar<any>;

  constructor(private _appFacade: AppFacade) {}

  ngOnInit() {
    this.isClose = true;
    this.isMenuCollapsed$ = this._appFacade.isMenuCollapsed$;
    this._appFacade.isBottomBarModalClose$.subscribe((bottomBarModalConfig) => {
      this.isClose = bottomBarModalConfig.isBottomBarModalClose;
      if (this.isClose) {
        this.selectedConfig = null;
      } else {
        this._buildSelectedComponent(bottomBarModalConfig.type);
      }
    });
  }

  public toggleModal() {
    this.isClose = !this.isClose;
    this._toggleStateGlobal();
  }

  public closeModal() {
    this.isClose = true;
    this.selectedConfig = null;
    this._toggleStateGlobal();
  }

  public selectedForm(selectedConfig: IBottomBar<any>) {
    this.selectedConfig = selectedConfig;
    if (this.isClose) {
      this.toggleModal();
    }
  }

  private _toggleStateGlobal() {
    if (this.isClose) {
      this._appFacade.closeBottomBar();
    } else {
      this._appFacade.openBottomBar(this.selectedConfig.type);
    }
  }

  private _buildSelectedComponent(type: BOTTOM_BAR_TYPES) {
    this.selectedConfig = BOTTOM_BAR_CONFIG.filter(
      (configBar) => configBar.type === type
    ).shift();
  }
}
