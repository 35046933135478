import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FILE_TYPE } from '@app/core/enums/file.type';
import { BUTTON_SIZE, BUTTON_TEMPLATE } from '@app/mo-forms/enums/button.type';
import { ICON_POSITION, COLOR_TEMPLATE } from '@app/mo-forms/enums/input.type';
import { CustomValidators } from '@app/mo-forms/validators/custom.validators';

@Component({
  selector: 'app-inputs',
  templateUrl: './render-page-input.component.html',
  styleUrls: ['./render-page-input.component.scss'],
})
export class RenderPageInputComponents {
  public inputForms!: FormGroup;
  public checkboksForm!: FormGroup;
  public selectsForm!: FormGroup;
  public othersForm!: FormGroup;
  public ICON_POSITION = ICON_POSITION;
  public INPUT_TEMPLATES = COLOR_TEMPLATE;
  public BUTON_TEMPLATES = BUTTON_TEMPLATE;
  public BUTTON_SIZE = BUTTON_SIZE;

  constructor(private _formBuilder: FormBuilder, private http: HttpClient) {
    this.inputForms = this._formBuilder.group({
      default: ['', [Validators.required, CustomValidators.isNumber]],
      defaultLight: ['', [Validators.required]],
      withOutLabel: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      iconRight: ['', [Validators.required]],
      iconLeft: ['', [Validators.required]],
      dualIcon: ['', [Validators.required]],
      disabledInput: [{ value: '', disabled: true }, [Validators.required]],
    });

    this.checkboksForm = this._formBuilder.group({
      active: [true],
      inActive: [false],
      disabledValue: [{ value: false, disabled: true }],
      toggle: [false],
    });

    this.selectsForm = this._formBuilder.group({
      default: ['', Validators.required],
      light: ['', Validators.required],
      dropDown: ['', Validators.required],
      dropDownLight: ['', Validators.required],
      disabled: [{ value: '', disabled: true }, Validators.required],
    });

    this.othersForm = this._formBuilder.group({
      dateRange: ['', Validators.required],
      place: [''],
      upload: [
        '',
        [
          CustomValidators.maxSizeFile(200000),
          CustomValidators.allowedExtensions([
            FILE_TYPE.CSV,
            FILE_TYPE.XLS,
          ]),
        ],
      ],
    });
  }

  onSubmit() {
    // TODO Remove this method when finish tests
    console.log('submit');
  }

  public onSetPlace(place) {
    console.log(place);
  }
}
