export enum STYLES_MAP {
  LIGHT='light',
  DARK='dark'
}

export type StyleMap = STYLES_MAP;

export enum MODES_MAP {
  REQUEST='request', // By result of requests
  HEATMAP='heatmap', // By KPIs
}

export type ModeMap = MODES_MAP;

export interface GeoJSON {
  type: string;
  features: Feature[];
}

export interface Feature {
  id?: string;
  type: string;
  geometry: Geometry;
  properties?: HexagonProperty;
}

export interface Geometry {
  type: string;
  coordinates: any[];
}

export interface HexagonProperty {
  POBTOT: number /** This is the value of poblation total */;
  RegularValue: number;
  VPH_SINLTC: number;
  WeightedValue: number;
  center: any;
  clusterGMM: number;
  features: any[];
  properties: Property;
  type: string;
}

export interface Property {
  lower_left: number;
  upper_right: number;
  cluster_id?: number;
  fillColor?: number[];
}

export enum FeatureType {
  GLOBAL_FEATURE = 'Feature',
  HEXAGON_FEATURE = 'FeatureCollection',
}

export enum GeometricType {
  POLYGON = 'Polygon',
}

export type Coordinates = [number, number];
export type GeometryCoordinates = [Coordinates];
