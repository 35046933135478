import { Component, OnInit } from '@angular/core';
import { AppFacade } from '@app/facades/app.facade';
import { BUTTON_SIZE, BUTTON_TEMPLATE } from '@app/mo-forms/enums/button.type';

@Component({
  selector: 'auth-response-data-component',
  templateUrl: './auth-response-data.component.html',
  styleUrls: ['./auth-response-data.component.scss'],
})
export class AuthResponseDataComponent implements OnInit {
  public BUTTON_TEMPLATES = BUTTON_TEMPLATE;
  public BUTTON_SIZE = BUTTON_SIZE;
  public metadata;
  public classIcon;

  constructor(private _appFacade: AppFacade) {}

  ngOnInit() {
    this._appFacade.modalConfig$.subscribe((data) => {
      if (data && data.metadata) {
        this.metadata = data.metadata;
        this.buildClassIcon();
      }
    });
  }

  public onCloseModal() {
    this._appFacade.closeModal();
  }

  private buildClassIcon() {
    switch (this.metadata?.state) {
      case 'success-info':
        this.classIcon = 'uil uil-check';
        return;
      case 'error-info':
        this.classIcon = 'uil uil-multiply';
        return;
    }
  }
}
