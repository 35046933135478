import { ROWS_PER_PAGE } from '@app/core/models/table.model';
import { createReducer, on, Action } from '@ngrx/store';

import { InsightRequestState } from './insight-request.state';
import * as actions from './insight-request.actions';

export const initialInsightRequestState: InsightRequestState = {
  isLoadingForm: false,
  isLoadingRequests: false,
  requests: null,
  filterRequests: {
    size: ROWS_PER_PAGE.toString(),
  },
};

const _insightRequestReducer = createReducer(
  initialInsightRequestState,
  on(actions.responseGetRequestSummary, (state, { response }) => {
    return { ...state, requestSummary: response, isLoadingHome: false };
  }),
  on(actions.failureGetRequestSummary, (state, _) => {
    return { ...state, requestSummary: null, isLoadingHome: false };
  }),
  on(actions.initRequestPage, (state) => {
    return {
      ...state,
      isLoadingRequests: true,
    };
  }),
  on(actions.reloadRequestWithOutLoading, (state) => {
    return {
      ...state,
    };
  }),
  on(actions.changeFiltersRequests, (state, { filters }) => {
    return {
      ...state,
      isLoadingPage: true,
      filterRequests: filters,
      isLoadingRequests: true,
    };
  }),
  on(actions.responseGetRequests, (state, { response }) => {
    return { ...state, requests: response, isLoadingRequests: false };
  }),
  on(actions.failureGetRequests, (state, { error }) => {
    return { ...state, requests: null, isLoadingRequests: false };
  }),
  on(actions.leaveFormsSidebar, (state) => {
    return {
      ...state,
      isLoadingForm: false,
      isRespondedForm: false,
      requestSuccess: undefined,
      errorForm: undefined,
    };
  }),
  on(actions.createRequest, (state) => {
    return { ...state, isLoadingForm: true };
  }),
  on(actions.uploadFileWithRequests, (state) => {
    return { ...state, isLoadingForm: true };
  }),
  on(actions.responseCreateRequest, (state, { confirmation }) => {
    return {
      ...state,
      requestSuccess: confirmation,
      isLoadingForm: false,
      isRespondedForm: true,
    };
  }),
  on(actions.responseCreateRequestWS, (state, { confirmation }) => {
    return {
      ...state,
      requestSuccess: confirmation,
      isLoadingForm: false,
      isRespondedForm: false,
    };
  }),

  on(actions.failureCreateRequest, (state, { error }) => {
    return {
      ...state,
      errorForm: error.message,
      isLoadingForm: false,
      isRespondedForm: true,
    };
  }),
  on(actions.downloadResultRequest, (state) => {
    return { ...state, isLoadingDownloadFile: true };
  }),
  on(actions.responsePresignURL, (state, { fileResponse }) => {
    return {
      ...state,
      isLoadingDownloadFile: false,
      downloadFile: fileResponse,
    };
  }),
  on(actions.failurePresignURL, (state, { error }) => {
    return { ...state, isLoadingDownloadFile: false };
  })
);

export function InsightRequestReducers(
  state: InsightRequestState | undefined,
  action: Action
) {
  return _insightRequestReducer(state, action);
}
