import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'mo-textarea',
  templateUrl: './mo-text-area.component.html',
  styleUrls: ['./mo-text-area.component.scss'],
})
export class MoTextAreaComponent {
  @Input() form!: FormGroup;
  @Input() controlName!: string;
  @Input() rows: number;
  @Input() cols: number;
  @Input() placeholder: string;
}
