import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormValidateDirective } from './directives/form-validate.directive';

@NgModule({
  declarations: [FormValidateDirective],
  imports: [CommonModule],
  exports: [FormValidateDirective],
})
export class SharedFormsPipesModule {}
