export const OVERVIEW_SUBMISION_SUCCESS = {
  data: [
    {
      id: 30,
      name: 'Suggestion tests long name 12',
      entity_name: 'Ali',
      justification:
        'A tests justification 12 A tests justification 12 A tests justification 02',
      example_location: 'b2ca004357794a8f85dc6cb2c1ce7cf9testsSuggestion.pdf',
      created_at: '2021-12-15T12:12:15.221428-05:00',
      country_name: 'Colombia',
      country_code: 'col',
      organization_name: 'JavierAlphaTests004',
      status_name: 'PENDING_APPROVAL',
    },
    {
      id: 29,
      name: 'Suggestion tests long name 11',
      entity_name: 'Ali',
      justification:
        'A tests justification 11 A tests justification 11 A tests justification 02',
      example_location: 'b2ca004357794a8f85dc6cb2c1ce7cf9testsSuggestion.pdf',
      created_at: '2021-12-15T12:07:55.434145-05:00',
      country_name: 'Mexico',
      country_code: 'mex',
      organization_name: 'JavierAlphaTests004',
      status_name: 'PENDING_APPROVAL',
    },
    {
      id: 28,
      name: 'Suggestion tests long name 10',
      entity_name: 'Amazon',
      justification:
        'A tests justification 10 A tests justification 10 A tests justification 02',
      example_location: 'b2ca004357794a8f85dc6cb2c1ce7cf9testsSuggestion.pdf',
      created_at: '2021-12-15T12:07:34.065624-05:00',
      country_name: 'Mexico',
      country_code: 'mex',
      organization_name: 'JavierAlphaTests004',
      status_name: 'PENDING_APPROVAL',
    },
    {
      id: 27,
      name: 'Suggestion tests 09',
      entity_name: 'Amazon',
      justification:
        'A tests justification 09 A tests justification 09 A tests justification 02',
      example_location: 'b2ca004357794a8f85dc6cb2c1ce7cf9testsSuggestion.pdf',
      created_at: '2021-12-15T12:06:58.272921-05:00',
      country_name: 'Mexico',
      country_code: 'mex',
      organization_name: 'JavierAlphaTests004',
      status_name: 'PENDING_APPROVAL',
    },
    {
      id: 26,
      name: 'Suggestion tests 08',
      entity_name: 'Tienda de don Juan',
      justification:
        'A tests justification 08 A tests justification 08 A tests justification 02',
      example_location: 'b2ca004357794a8f85dc6cb2c1ce7cf9testsSuggestion.pdf',
      created_at: '2021-12-15T12:06:29.673225-05:00',
      country_name: 'Mexico',
      country_code: 'mex',
      organization_name: 'JavierAlphaTests004',
      status_name: 'PENDING_APPROVAL',
    },
    {
      id: 25,
      name: 'Suggestion tests 07',
      entity_name: 'Tienda de don Juan',
      justification:
        'A tests justification 07 A tests justification 07 A tests justification 02',
      example_location: 'b2ca004357794a8f85dc6cb2c1ce7cf9testsSuggestion.pdf',
      created_at: '2021-12-15T12:05:37.044976-05:00',
      country_name: 'Colombia',
      country_code: 'col',
      organization_name: 'JavierAlphaTests004',
      status_name: 'PENDING_APPROVAL',
    },
    {
      id: 23,
      name: 'Suggestion tests 06',
      entity_name: 'Microsoft',
      justification:
        'A tests justification 06 A tests justification 02 A tests justification 02',
      example_location: 'b2ca004357794a8f85dc6cb2c1ce7cf9testsSuggestion.pdf',
      created_at: '2021-12-15T12:05:02.748381-05:00',
      country_name: 'Colombia',
      country_code: 'col',
      organization_name: 'JavierAlphaTests004',
      status_name: 'PENDING_APPROVAL',
    },
  ],
  response_code: 200,
  errors: [],
};
