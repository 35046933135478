import { createFeatureSelector, createSelector } from '@ngrx/store';
import { FetchState } from './fetch.state';

export const getFetchFeatureState = createFeatureSelector('fetch');

export const selectFetchData = createSelector(
  getFetchFeatureState,
  (state: FetchState) => state.data
);

export const selectCountries = createSelector(
  getFetchFeatureState,
  (state: FetchState) => state.countries
);

export const selectStates = createSelector(
  getFetchFeatureState,
  (state: FetchState) => state.states
);

export const selectCountry = createSelector(
  getFetchFeatureState,
  (state: FetchState) => state.country
);

export const selectTypeTemplates = createSelector(
  getFetchFeatureState,
  (state: FetchState) => state.typeTemplates
);

export const selectTypeTemplate = createSelector(
  getFetchFeatureState,
  (state: FetchState) => state.typeTemplate
);

export const templatesFilters = createSelector(
  getFetchFeatureState,
  (state: FetchState) => state.templatesFilters
);

export const selectDocumentsFilters = createSelector(
  getFetchFeatureState,
  (state: FetchState) => state.documentsFilters
);

export const selectTemplates = createSelector(
  getFetchFeatureState,
  (state: FetchState) => state.templates
);

export const selectDocumentDetailId = createSelector(
  getFetchFeatureState,
  (state: FetchState) => state.documentDetailId
);

export const selectIsLoadingDocumentDetailTransactions = createSelector(
  getFetchFeatureState,
  (state: FetchState) => state.isLoadingDocumentDetailTransactions
);

export const selectDocumentDetailTransactions = createSelector(
  getFetchFeatureState,
  (state: FetchState) => state.documentDetailTransactions
);

export const selectIsLoadingTemplates = createSelector(
  getFetchFeatureState,
  (state: FetchState) => state.isLoadingTemplates
);

export const selectTemplateIdDetails = createSelector(
  getFetchFeatureState,
  (state: FetchState) => state.selectTemplateIdDetails
);

export const selectTemplateVersions = createSelector(
  getFetchFeatureState,
  (state: FetchState) => state.templateVersions
);

export const selectTemplateVersionDetail = createSelector(
  getFetchFeatureState,
  (state: FetchState) => state.templateVersionDetail
);

export const selectDocuments = createSelector(
  getFetchFeatureState,
  (state: FetchState) => state.documents
);

export const selectIsLoadingDocuments = createSelector(
  getFetchFeatureState,
  (state: FetchState) => state.isLoadingDocuments
);

export const selectDocumentDetails = createSelector(
  getFetchFeatureState,
  (state: FetchState) => state.documentDetails
);

export const selectIsLoadingDocumentDetails = createSelector(
  getFetchFeatureState,
  (state: FetchState) => state.isLoadingDocumentDetails
);

export const selectDocumentsFiles = createSelector(
  getFetchFeatureState,
  (state: FetchState) => state.documentsFiles
);

export const selectIsLoadingDocumentFile = createSelector(
  getFetchFeatureState,
  (state: FetchState) => state.isLoadingDocumentFile
);

export const selectIsLoadingUploadFileFetch = createSelector(
  getFetchFeatureState,
  (state: FetchState) => state.isLoadingUploadFileFetch
);

export const selectCompleteUploadFileFetch = createSelector(
  getFetchFeatureState,
  (state: FetchState) => state.completeUploadFileFetch
);

export const selectIsLoadingOverviewHeaderInfo = createSelector(
  getFetchFeatureState,
  (state: FetchState) => state.isLoadingOverviewHeaderInfo
);

export const selectOverviewHeaderInfo = createSelector(
  getFetchFeatureState,
  (state: FetchState) => state.overviewHeaderInfo
);

export const selectIsLoadingOverviewUseInfo = createSelector(
  getFetchFeatureState,
  (state: FetchState) => state.isLoadingOverviewUseInfo
);

export const selectOverviewUseInfo = createSelector(
  getFetchFeatureState,
  (state: FetchState) => state.overviewUseInfo
);

export const selectIsLoadingOverviewPerformanceInfo = createSelector(
  getFetchFeatureState,
  (state: FetchState) => state.isLoadingOverviewPerformanceInfo
);

export const selectOverviewPerformanceInfo = createSelector(
  getFetchFeatureState,
  (state: FetchState) => state.overviewPerformanceInfo
);

export const selectOverviewSubmisionInfo = createSelector(
  getFetchFeatureState,
  (state: FetchState) => state.overviewSubmisionInfo
);
