<form
  class="form"
  [formGroup]="form"
  [appFormValidate]="form"
  (ngSubmit)="onContinue()"
>
  <mo-drop-down
    label="Subject"
    [form]="form"
    [options]="subjects"
    controlName="subject"
  ></mo-drop-down>

  <mo-textarea
    controlName="message"
    rows="4"
    cols="50"
    placeholder="Type your message here"
    [form]="form"
  ></mo-textarea>
  <div class="container-submit">
    <a
      class="info-sm m-t-10 m-b-10"
      [href]="privacyPolicy"
      target="_blank"
      noreferrer
      noopener
    >
      By submitting this form, I confirm that I have read and understood MO’s
      Privacy Statement.
    </a>
    <div class="row end-xs">
      <div class="col-xs-6">
        <div class="box">
          <mo-button-form
            text="Submit"
            type="submit"
            [isDisabled]="form.invalid"
            [isLoading]="loading$ | async"
            [template]="buttonTemplate"
            [size]="BUTTON_SIZE.MD"
          ></mo-button-form>
        </div>
      </div>
    </div>
  </div>
</form>
