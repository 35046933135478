export const GA = {
  SINGUP: {
    PAGE_VIEW: {
      category: 'Sign up',
      action: 'View',
      label: 'Password_setup',
    },
    REQUEST: {
      category: 'Sign up',
      action: 'Request',
      label: 'Set_password',
    },
    REQUEST_SUCCESS: {
      category: 'Sign up',
      action: 'Response_succesful',
      label: 'Set_password',
    },
    REQUEST_ERROR: {
      category: 'Sign up',
      action: 'Response_error',
      label: 'Set_password',
    },
  },
  CONFIGURATION: {
    PAGE_VIEW: {
      category: 'Configuration',
      action: 'View',
      label: 'Create_configuration',
    },
    REQUEST: {
      category: 'Configuration',
      action: 'Request',
      label: 'Create_configuration',
    },
    REQUEST_SUCCESS: {
      category: 'Configuration',
      action: 'Response_succesful',
      label: 'Create_configuration',
    },
    REQUEST_ERROR: {
      category: 'Configuration',
      action: 'Response_error',
      label: 'Create_configuration',
    },
  },
  DOWNLOAD: {
    PAGE_VIEW: {
      category: 'Download',
      action: 'View',
      label: 'Create_download',
    },
    REQUEST: {
      category: 'Download',
      action: 'Request',
      label: 'Create_download',
    },
    REQUEST_SUCCESS: {
      category: 'Download',
      action: 'Response_succesful',
      label: 'Create_download',
    },
    REQUEST_ERROR: {
      category: 'Download',
      action: 'Response_error',
      label: 'Create_download',
    },
    CLICK_DOWNLOAD: {
      category: 'Download',
      action: 'Click_download',
      label: 'Create_download',
    },
  },
};
