import { createFeatureSelector, createSelector } from '@ngrx/store';
import { InsightRequestState } from './insight-request.state';

export const getState = createFeatureSelector('insightRequest');

export const selectFilterRequests = createSelector(
  getState,
  (state: InsightRequestState) => state.filterRequests
);

export const selectIsLoadingRequests = createSelector(
  getState,
  (state: InsightRequestState) => state.isLoadingRequests
);

export const selectIsRespondedForm = createSelector(
  getState,
  (state: InsightRequestState) => state.isRespondedForm
);

export const selectRequests = createSelector(
  getState,
  (state: InsightRequestState) => state.requests
);

export const selectIsLoadingHome = createSelector(
  getState,
  (state: InsightRequestState) => state.isLoadingHome
);

export const selectRequestSummary = createSelector(
  getState,
  (state: InsightRequestState) => state.requestSummary
);

export const selectIsLoadingForm = createSelector(
  getState,
  (state: InsightRequestState) => state.isLoadingForm
);

export const selectErrorForm = createSelector(
  getState,
  (state: InsightRequestState) => state.errorForm
);

export const selectRequestSuccess = createSelector(
  getState,
  (state: InsightRequestState) => state.requestSuccess
);

export const selectURLDownloadFile = createSelector(
  getState,
  (state: InsightRequestState) => state.downloadFile
);

export const selectIsLoadingDownloadFile = createSelector(
  getState,
  (state: InsightRequestState) => state.isLoadingDownloadFile
);
