import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoggedGuard } from './core/guard/logged.guard';
import { UnloggedGuard } from './core/guard/unlogged.guard';
import { BaseLoggedComponent } from './layout/base-logged/base-logged.component';

const routes: Routes = [
  {
    path: '',
    component: BaseLoggedComponent,
    children: [
      { path: '', redirectTo: 'insight', pathMatch: 'full' },
      {
        path: 'insight',
        canActivate: [LoggedGuard],
        loadChildren: () =>
          import('./pages/insight/insight.module').then((m) => m.InsightModule),
      },
      {
        path: 'fetch',
        canActivate: [LoggedGuard],
        loadChildren: () =>
          import('./pages/fetch/fetch.module').then((m) => m.FetchModule),
      },
    ],
  },
  {
    path: 'auth',
    canActivateChild: [UnloggedGuard],
    loadChildren: () =>
      import('./pages/auth/auth.module').then((m) => m.AuthPageModule),
  },
  {
    path: 'forms',
    loadChildren: () =>
      import('./mo-forms/mo-forms.module').then((m) => m.MoFormsModule),
  },
  {
    path: 'table',
    loadChildren: () =>
      import('./mo-table/table.module').then((m) => m.TableModule),
  },
  {
    path: '**',
    redirectTo: '',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
