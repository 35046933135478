<insight-request-form-create
  [configurations]="configurations$ | async"
  [countries]="countries$ | async"
  [isLoading]="isLoading$ | async"
  [isLoadingConfigs]="isLoadingConfigs$ | async"
  (create)="onCreate($event)"
  *ngIf="!(isResponded$ | async)"
>
</insight-request-form-create>

<insight-request-form-response
  [request]="success$ | async"
  (close)="onClose()"
  [error]="error$ | async"
  *ngIf="isResponded$ | async"
>
</insight-request-form-response>
