<div
  class="content-layout-b"
  [ngClass]="{ 'open-left-menu': isMenuCollapsed$ | async }"
>
  <!-- TODO add boxes background -->
  <div class="content">
    <ng-container
      *ngIf="(productSelected$ | async)?.backgrounds as backgrounds"
    >
      <img class="image-left" [src]="backgrounds?.imageLeft" />
      <img class="image-right" [src]="backgrounds?.imageRight" />
    </ng-container>
    <ng-content></ng-content>
  </div>
</div>
