import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { COLOR_TEMPLATE } from '@app/mo-forms/enums/input.type';

@Component({
  selector: 'mo-toggle',
  templateUrl: './mo-toggle.component.html',
  styleUrls: ['./mo-toggle.component.scss'],
})
export class MoToggleComponent {
  @Input() form!: FormGroup;
  @Input() controlName!: string;
  @Input() template: COLOR_TEMPLATE;
}
