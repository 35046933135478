<div [class]="'box-info ' + template">
  <div class="box-container">
    <div class="icon">
      <div class="box-icon">
        <i [class]="'uil ' + icon"></i>
      </div>
    </div>
    <div class="content-info">
      <div class="box-content">
        <ng-content></ng-content>
      </div>
    </div>
  </div>

  <div class="more-action text-right">
    <a (click)="triggerCallback()">{{ textCallback }}</a>
  </div>
</div>
