import { TemplateRef } from '@angular/core';

export type ITypeSort = 'string' | 'number' | 'date';

export enum TypeColumn {
  ID = 'id',
  LABEL = 'label',
  DATE = 'date',
  DATE_TIME = 'datetime',
  STATUS = 'status',
  TOOLTIP = 'tooltip',
  PREMIUM = 'premium',
  DATA_POINT = 'datapoint',
}

export interface ITableHeader {
  template?: TemplateRef<IContextColumn>;
  label?: string;
  size?: number; // number between 1 to 12 for cols
  isSortable?: boolean;
  typeSort?: ITypeSort;
  isCopyable?: boolean;
  messageCopied?: string;
  typeColumn?: TypeColumn;
  tooltipTemplate?: TemplateRef<IContextColumn>;
  dataKey?: string;
  color?: string;
  customClass?: ICustomClass;
  transform?: Function;
}

export interface IColumnSort {
  column: string;
  sortBy: 'ASC' | 'DESC';
  typeSort?: ITypeSort;
}

export interface IContextColumn {
  column: ITableHeader;
  onChange?: Function;
  onClick?: Function;
  onCopyValue?: Function;
}

export interface IPageSizeOptions {
  value: string | number;
  label: string;
}

export interface ICustomClass {
  header: string;
  row: string;
}
