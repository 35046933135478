<div class="container-files-viewer">
  <div class="row start-xs">
    <div class="col-xs">
      <layout-title-page [title]="'Files'"></layout-title-page>
    </div>
  </div>

  <div class="row">
    <div class="col-xs-4">
      <div *ngIf="documentsFiles$ | async as files" class="container-options">
        <div
          class="option-card"
          *ngFor="let option of files"
          [class.selected]="option?.id == selectedFile?.id"
          (click)="onSelectFile(option)"
        >
          <i class="uil uil-file-alt"></i>
          <label class="container-title-file">
            {{
              option?.request_id +
                "-detections-at-" +
                option?.updated_at +
                "." +
                option?.extension
            }}
          </label>
          <i class="uil uil-circle"></i>
        </div>
      </div>
    </div>

    <div class="col-xs-8 container-pdf-viewer">
      <ng-container
        *ngIf="selectedFile?.extension === 'pdf'; else imageTemplate"
      >
        <ngx-extended-pdf-viewer
          theme="dark"
          height="50vh"
          [useBrowserLocale]="true"
          [src]="selectedFile?.location"
          [customToolbar]="customToolbarPdf"
          [backgroundColor]="'#f4f6f9'"
          [filenameForDownload]="
            selectedFile?.request_id +
            '-detections-at-' +
            selectedFile?.updated_at +
            '.' +
            selectedFile?.extension
          "
        >
        </ngx-extended-pdf-viewer>
      </ng-container>
      <ng-template #imageTemplate>
        <ng-container *ngTemplateOutlet="customToolbarPdf"></ng-container>
        <div class="container-image-viewer">
          <img
            [src]="selectedFile?.location"
            [style.transform]="'scale(' + imageScale + ')'"
          />
        </div>
      </ng-template>
    </div>
  </div>
</div>

<ng-template #customToolbarPdf>
  <div class="row middle-xs end-xs container-toolbar-pdf-template">
    <div class="col-xs-1 container-icon" id="download" (click)="onDownload()">
      <i class="uil uil-file-download-alt"></i>
    </div>
    <div class="col-xs-1 container-icon" (click)="openFile()">
      <i class="uil uil-expand-arrows-alt"></i>
    </div>
    <div class="col-xs-1 container-icon" id="zoomIn" (click)="onZoom(0.1)">
      <i class="uil uil-search-plus"></i>
    </div>
    <div class="col-xs-1 container-icon" id="zoomOut" (click)="onZoom(-0.1)">
      <i class="uil uil-search-minus"></i>
    </div>
  </div>
</ng-template>
