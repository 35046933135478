<div *ngIf="metadata" class="content-auth-response text-center">
  <div [class]="'circle-info ' + metadata?.state">
    <i [class]="classIcon"></i>
  </div>
  <h3 [class]="metadata?.state">{{ metadata?.title }}</h3>
  <p>{{ metadata?.info }}</p>
  <div class="box-button-action">
    <mo-button-form
      [text]="metadata?.textButton || 'Finish'"
      [template]="BUTTON_TEMPLATES.INDIGO"
      [size]="BUTTON_SIZE.MD"
      (click)="onCloseModal()"
    ></mo-button-form>
  </div>
</div>
