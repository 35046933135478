export const DOCUMENTS_DETAIL_SUCCESS = {
  data: {
    id: 2172,
    organization: {
      id: 3,
      name: 'Organization to fetch tests',
      uuid: 'f4727041-6714-443c-9247-e33ac381a94b',
      timezone: 'America/Bogota',
      s3_bucket_files: 'temp-fetch-alpha-s3-documents',
      owner_email: 'stivenson.rpm@gmail.com',
      sqs_message_id: null,
      environment_name: 'tests',
      created_at: '2021-06-01T23:56:59.675066+00:00',
      updated_at: '2021-06-01T23:56:59.675066+00:00',
      deleted_at: null,
      organization_countrys: [
        {
          id: 6,
          country: {
            code: 'mex',
            name: 'Mexico',
            created_at: '2021-05-21T16:22:26.533813+00:00',
            updated_at: '2021-05-21T16:22:26.533813+00:00',
          },
          created_at: '2021-06-11T22:10:18.295816+00:00',
          updated_at: '2021-06-11T22:10:18.295816+00:00',
          organization_country_templates: [
            {
              id: 30,
              status: true,
            },
            {
              id: 31,
              status: true,
            },
            {
              id: 32,
              status: true,
            },
            {
              id: 33,
              status: true,
            },
            {
              id: 34,
              status: true,
            },
            {
              id: 35,
              status: true,
            },
            {
              id: 36,
              status: true,
            },
            {
              id: 37,
              status: true,
            },
            {
              id: 38,
              status: true,
            },
            {
              id: 39,
              status: true,
            },
            {
              id: 40,
              status: true,
            },
            {
              id: 114,
              status: true,
            },
            {
              id: 26,
              status: true,
            },
            {
              id: 28,
              status: true,
            },
            {
              id: 133,
              status: true,
            },
            {
              id: 152,
              status: true,
            },
            {
              id: 171,
              status: true,
            },
            {
              id: 188,
              status: true,
            },
            {
              id: 189,
              status: true,
            },
            {
              id: 190,
              status: true,
            },
            {
              id: 191,
              status: true,
            },
            {
              id: 192,
              status: true,
            },
            {
              id: 193,
              status: true,
            },
            {
              id: 194,
              status: true,
            },
            {
              id: 195,
              status: true,
            },
            {
              id: 196,
              status: true,
            },
          ],
        },
        {
          id: 7,
          country: {
            code: 'col',
            name: 'Colombia',
            created_at: '2021-05-21T16:22:26.533813+00:00',
            updated_at: '2021-05-21T16:22:26.533813+00:00',
          },
          created_at: '2021-09-14T17:06:22.206441+00:00',
          updated_at: '2021-09-14T17:06:22.206441+00:00',
          organization_country_templates: [
            {
              id: 29,
              status: true,
            },
          ],
        },
      ],
      support_contact_forms: [],
      suggested_templates: [
        {
          id: 8,
          name: 'A template suggested 289',
          justification: 'A fuck justification 289',
          example_location: null,
          entity_name: null,
          country: {
            code: 'col',
            name: 'Colombia',
            created_at: '2021-05-21T16:22:26.533813+00:00',
            updated_at: '2021-05-21T16:22:26.533813+00:00',
          },
          suggested_template_status: {
            description: 'PENDING_APPROVAL',
            created_at: '2021-05-26T03:22:40.706939+00:00',
            updated_at: '2021-05-26T03:22:40.706939+00:00',
          },
          created_at: '2021-06-02T00:01:44.397031+00:00',
          updated_at: '2021-06-02T00:01:44.397049+00:00',
        },
        {
          id: 9,
          name: 'A template suggested 290',
          justification: 'A fuck justification 290',
          example_location: null,
          entity_name: null,
          country: {
            code: 'col',
            name: 'Colombia',
            created_at: '2021-05-21T16:22:26.533813+00:00',
            updated_at: '2021-05-21T16:22:26.533813+00:00',
          },
          suggested_template_status: {
            description: 'APPROVED',
            created_at: '2021-05-26T03:22:40.706939+00:00',
            updated_at: '2021-05-26T03:22:40.706939+00:00',
          },
          created_at: '2021-06-09T23:16:27.996589+00:00',
          updated_at: '2021-06-10T22:55:27.730026+00:00',
        },
        {
          id: 11,
          name: 'A template suggested 291',
          justification: 'A fuck justification 291',
          example_location: null,
          entity_name: null,
          country: {
            code: 'col',
            name: 'Colombia',
            created_at: '2021-05-21T16:22:26.533813+00:00',
            updated_at: '2021-05-21T16:22:26.533813+00:00',
          },
          suggested_template_status: {
            description: 'APPROVED',
            created_at: '2021-05-26T03:22:40.706939+00:00',
            updated_at: '2021-05-26T03:22:40.706939+00:00',
          },
          created_at: '2021-06-10T22:54:02.698299+00:00',
          updated_at: '2021-06-10T22:57:08.433505+00:00',
        },
      ],
    },
    entity: {
      name: 'Bancomer',
      created_at: '2021-08-12T00:57:46.845128+00:00',
      updated_at: '2021-08-12T00:57:46.845128+00:00',
    },
    data: {
      data: {
        org_id: 'f4727041-6714-443c-9247-e33ac381a94b',
        templates: [
          {
            entity: '155ca451-177f-437e-8909-c6f12b3f494d',
            fields: [],
            country: 'mex',
            version: 'v2',
            document_types: 'bank_statements',
          },
          {
            entity: 'e117a950-4881-4113-9311-b1a0bfc6fe7e',
            fields: [],
            country: 'mex',
            version: 'v1',
            document_types: 'bank_statements',
          },
          {
            entity: '1a7cd2d3-1dfa-41a3-9ecd-03ac3d600853',
            fields: [],
            country: 'mex',
            version: 'v1',
            document_types: 'bank_statements',
          },
          {
            entity: '19916d44-9d7f-4b76-b074-255595ced5aa',
            fields: [],
            country: 'mex',
            version: 'v1',
            document_types: 'bank_statements',
          },
          {
            entity: '4ca7634c-338e-45ac-afdb-768749a4b6f9',
            fields: [],
            country: 'mex',
            version: 'v1',
            document_types: 'bank_statements',
          },
          {
            entity: '9418417a-0871-4a40-b73b-6717fbc5657b',
            fields: [],
            country: 'mex',
            version: 'v1',
            document_types: 'bank_statements',
          },
          {
            entity: '58f5733b-3161-4271-a6f9-384d793baee5',
            fields: [],
            country: 'mex',
            version: 'v1',
            document_types: 'bank_statements',
          },
          {
            entity: 'cede8e6c-a8de-4f38-ab5c-bb868aa26391',
            fields: [],
            country: 'mex',
            version: 'v1',
            document_types: 'bank_statements',
          },
          {
            entity: '5a82f581-48f7-4b52-adb5-b978ac9e73f7',
            fields: [],
            country: 'mex',
            version: 'v1',
            document_types: 'bank_statements',
          },
          {
            entity: '2a231e36-16ff-4f26-bdfb-1455f9785473',
            fields: [],
            country: 'mex',
            version: 'v1',
            document_types: 'bank_statements',
          },
          {
            entity: '3f32c40b-b9a0-4de2-903b-536af20007f7',
            fields: [],
            country: 'mex',
            version: 'v1',
            document_types: 'bank_statements',
          },
          {
            entity: '155ca451-177f-437e-8909-c6f12b3f494d',
            fields: [],
            country: 'mex',
            version: 'v1',
            document_types: 'bank_statements',
          },
          {
            entity: '1dc33474-841c-4af7-932e-8d164b87e76a',
            fields: [],
            country: 'mex',
            version: 'v1',
            document_types: 'bank_statements',
          },
          {
            entity: '7ffb0441-7a11-4e38-9393-78a5cf13c682',
            fields: [],
            country: 'mex',
            version: 'v1',
            document_types: 'bank_statements',
          },
          {
            entity: '98d03600-f1a7-4df4-8a14-795f5b9e9642',
            fields: [],
            country: 'mex',
            version: 'v1',
            document_types: 'bank_statements',
          },
          {
            entity: '51a20bcb-ee8c-4d35-b495-cfa07ca56b1f',
            fields: [],
            country: 'mex',
            version: 'v1',
            document_types: 'bank_statements',
          },
          {
            entity: '9d3b9360-b904-46dd-aa51-5715fd361ff4',
            fields: [],
            country: 'mex',
            version: 'v1',
            document_types: 'bank_statements',
          },
          {
            entity: 'b6de26cb-49b9-4747-88cb-c0b85d08758d',
            fields: [],
            country: 'mex',
            version: 'v1',
            document_types: 'bank_statements',
          },
          {
            entity: 'bd60912f-9ff6-46d6-aceb-f1c488c25ef2',
            fields: [],
            country: 'mex',
            version: 'v1',
            document_types: 'bank_statements',
          },
          {
            entity: 'a968a3db-2896-416c-a9c9-b093762449eb',
            fields: [],
            country: 'mex',
            version: 'v1',
            document_types: 'bank_statements',
          },
          {
            entity: '2422a495-b0b3-41ce-9aec-7d6b05ec2335',
            fields: [],
            country: 'mex',
            version: 'v1',
            document_types: 'bank_statements',
          },
          {
            entity: '92552e8d-e5d8-4faa-be83-512fd630ca8f',
            fields: [],
            country: 'mex',
            version: 'v1',
            document_types: 'bank_statements',
          },
          {
            entity: '1c905f0f-bbd6-4ada-b547-4156196f5e43',
            fields: [],
            country: 'col',
            version: 'v1',
            document_types: 'bank_statements',
          },
          {
            entity: '155ca451-177f-437e-8909-c6f12b3f494d',
            fields: [],
            country: 'mex',
            version: 'v1',
            document_types: 'bank_statements',
          },
          {
            entity: '155ca451-177f-437e-8909-c6f12b3f494d',
            fields: [],
            country: 'mex',
            version: 'v1',
            document_types: 'bank_statements',
          },
          {
            entity: '155ca451-177f-437e-8909-c6f12b3f494d',
            fields: [],
            country: 'mex',
            version: 'v1',
            document_types: 'bank_statements',
          },
          {
            entity: '155ca451-177f-437e-8909-c6f12b3f494d',
            fields: [],
            country: 'mex',
            version: 'v1',
            document_types: 'bank_statements',
          },
        ],
        request_id: '2172',
        external_id: '123456',
        paths_gcs_files: [
          {
            path: 'api/original/banamex_big__file_1_org_f4727041-6714-443c-9247-e33ac381a94b_202111-1114-0716_.pdf',
            metadata: {
              message:
                'File uploaded successfully to api/original/banamex_big__file_1_org_f4727041-6714-443c-9247-e33ac381a94b_202111-1114-0716_.pdf',
              page_number: 1,
            },
          },
        ],
      },
      errors: [],
    },
    status_code: 202,
    status_description: {
      message: {
        name: 'projects/fetch-mo-project/locations/us-east4/queues/fetch-alpha-queue-ocr-start-process/tasks/request_id_2172_21_11_11_14_07_17_007284',
        httpRequest: {
          url: 'https://us-east4-fetch-mo-project.cloudfunctions.net/fetch-alpha-ocr-start-process',
          httpMethod: 'POST',
          headers: { 'User-Agent': 'Google-Cloud-Tasks' },
        },
        scheduleTime: '2021-11-11T14:07:17.156982Z',
        createTime: '2021-11-11T14:07:17Z',
        dispatchDeadline: '1800s',
        view: 'BASIC',
      },
      gcp_status: 0,
      task_id_processed: 'request_id_2172_21_11_11_14_07_17_007284',
    },
    created_at: '2021-11-11T14:07:16.152435+00:00',
    updated_at: '2021-11-11T14:07:16.152435+00:00',
    finished_at: '2021-11-11T14:09:50.056235+00:00',
    request_files: [
      {
        id: 2197,
        location: 'banamex_big.pdf',
        extension: 'pdf',
        created_at: '2021-11-11T14:07:16.157102+00:00',
        updated_at: '2021-11-11T14:07:16.157128+00:00',
      },
    ],
    request_responses: [
      {
        id: 1892,
        data: {
          message_id: '1636639749.3646433eeb6e171-0c82-4f03-b27e-1f2bf97d32b7',
          response_body: {
            entity: '155ca451-177f-437e-8909-c6f12b3f494d',
            org_id: '',
            status: 'DFE101',
            tables: [
              {
                rows: [
                  {
                    fecha: '17 nov',
                    saldo: '365.68',
                    retiros: '',
                    concepto: 'saldo anterior',
                    depositos: '',
                  },
                  {
                    fecha: '25 nov',
                    saldo: '3.68',
                    retiros: '362.00',
                    concepto: 'deposito inversion perfiles',
                    depositos: '',
                  },
                  {
                    fecha: '25 nov',
                    saldo: '365.68',
                    retiros: '',
                    concepto: 'traspaso ref 25089 de fo 0000009770042117',
                    depositos: '362.00',
                  },
                  {
                    fecha: '10 dic',
                    saldo: '15,365.68',
                    retiros: '',
                    concepto: 'traspaso ref 234074 de fo 0000009770042117',
                    depositos: '15,000.00',
                  },
                  {
                    fecha: '10 dic',
                    saldo: '7,365.68',
                    retiros: '8,000.00',
                    concepto:
                      'pago interbancario a bbva bancomer al benef . mary cruz , garcia reyes dato no verificado por esta institucion cta.beneficiario 004152313615777104 clave rastreo 085902381830334495 ref . 0101219 prestamo mismo dia',
                    depositos: '',
                  },
                  {
                    fecha: '10 dic',
                    saldo: '0.68',
                    retiros: '7,365.00',
                    concepto:
                      'pago interbancario a bbva bancomer al benef . mary cruz , garcia reyes dato no verificado por esta institucion cta.beneficiario 004152313615777104 clave rastreo 085903339590334495 ref . 0101219 amex mismo dia',
                    depositos: '',
                  },
                  {
                    fecha: '13 dic',
                    saldo: '25,907.07',
                    retiros: '',
                    concepto:
                      'abono nomina takeda administracion mexico , s.a. d',
                    depositos: '25,906.39',
                  },
                  {
                    fecha: '13 dic',
                    saldo: '21,907.07',
                    retiros: '4,000.00',
                    concepto:
                      'pago interbancario a bbva bancomer al benef . mary cruz , garcia reyes dato no verificado por esta institucion cta.beneficiario 004152313615777104 clave rastreo 085902193950334796 ref . 0131219 varios mismo dia',
                    depositos: '',
                  },
                  {
                    fecha: '13 dic',
                    saldo: '907.07',
                    retiros: '21,000.00',
                    concepto: 'deposito inversion perfiles',
                    depositos: '',
                  },
                  {
                    fecha: '16 dic',
                    saldo: '807.07',
                    retiros: '100.00',
                    concepto: 'deposito inversion perfiles',
                    depositos: '',
                  },
                  {
                    fecha: '16 dic',
                    saldo: '0.04',
                    retiros: '807.03',
                    concepto: 'deposito inversion perfiles',
                    depositos: '',
                  },
                  {
                    fecha: '16 dic',
                    saldo: '900.04',
                    retiros: '',
                    concepto: 'traspaso ref 763389 de fo 0000009770042117',
                    depositos: '900.00',
                  },
                  {
                    fecha: '16 dic',
                    saldo: '0.04',
                    retiros: '',
                    concepto: 'deposito inversion perfiles',
                    depositos: '',
                  },
                ],
                table_name: 'table_0_product_0',
              },
            ],
            user_id: '',
            version: 'v1',
            end_time: '1636639749.2580721',
            warnings: [''],
            detections: [
              {
                bank_name: 'BANCO BANAMEX',
              },
              {
                customer_name: 'Mary Cruz Garcia Reyes',
              },
              {
                address: 'Tlaxpana 11370 Miguel Hidalgo , Ciudad De Mexico Ej',
              },
              {
                rfc: 'GARM931115EYO',
              },
              {
                bank_date: '2019-12',
              },
              {
                account_type: 'PERFIL EJECUTIVO',
              },
              {
                account_number: '97700312995',
              },
              {
                period: 'Monthly',
              },
              {
                initial_balance: 365.68,
              },
              {
                deposits_payments: 42168.39,
              },
              {
                withdraws_charges: -42534.03,
              },
              {
                final_balance: 20190.04,
              },
            ],
            request_id: 2172,
            start_time: '1636639639.1167054',
            entity_name: 'BANCO BANAMEX',
            documents_successful_rate: 1,
            extracted_fields_successful_rate: '1.0',
          },
          response_code: 200,
        },
        created_at: '2021-11-11T14:09:50.050743+00:00',
        updated_at: '2021-11-11T14:09:50.050759+00:00',
        sub_items: [
          {
            id: 88,
            name: 'Req_2172_ReqRes_1892_Type_1_trans_1',
            data: {
              id: 1,
              fecha: '17 nov',
              saldo: '365.68',
              retiros: '',
              concepto: 'saldo anterior',
              depositos: '',
              table_name: 'table_0_product_0',
            },
            sub_item_type: {
              id: 1,
              name: 'transaction',
              created_at: '2021-10-28T16:33:47.063472+00:00',
              updated_at: '2021-10-28T16:33:47.063472+00:00',
              deleted_at: null,
            },
            created_at: '2021-11-11T14:09:50.067574+00:00',
            updated_at: '2021-11-11T14:09:50.067574+00:00',
            deleted_at: null,
          },
          {
            id: 89,
            name: 'Req_2172_ReqRes_1892_Type_1_trans_2',
            data: {
              id: 1,
              fecha: '25 nov',
              saldo: '3.68',
              retiros: '362.00',
              concepto: 'deposito inversion perfiles',
              depositos: '',
              table_name: 'table_0_product_0',
            },
            sub_item_type: {
              id: 1,
              name: 'transaction',
              created_at: '2021-10-28T16:33:47.063472+00:00',
              updated_at: '2021-10-28T16:33:47.063472+00:00',
              deleted_at: null,
            },
            created_at: '2021-11-11T14:09:50.067574+00:00',
            updated_at: '2021-11-11T14:09:50.067574+00:00',
            deleted_at: null,
          },
          {
            id: 90,
            name: 'Req_2172_ReqRes_1892_Type_1_trans_3',
            data: {
              id: 1,
              fecha: '25 nov',
              saldo: '365.68',
              retiros: '',
              concepto: 'traspaso ref 25089 de fo 0000009770042117',
              depositos: '362.00',
              table_name: 'table_0_product_0',
            },
            sub_item_type: {
              id: 1,
              name: 'transaction',
              created_at: '2021-10-28T16:33:47.063472+00:00',
              updated_at: '2021-10-28T16:33:47.063472+00:00',
              deleted_at: null,
            },
            created_at: '2021-11-11T14:09:50.067574+00:00',
            updated_at: '2021-11-11T14:09:50.067574+00:00',
            deleted_at: null,
          },
          {
            id: 91,
            name: 'Req_2172_ReqRes_1892_Type_1_trans_4',
            data: {
              id: 1,
              fecha: '10 dic',
              saldo: '15,365.68',
              retiros: '',
              concepto: 'traspaso ref 234074 de fo 0000009770042117',
              depositos: '15,000.00',
              table_name: 'table_0_product_0',
            },
            sub_item_type: {
              id: 1,
              name: 'transaction',
              created_at: '2021-10-28T16:33:47.063472+00:00',
              updated_at: '2021-10-28T16:33:47.063472+00:00',
              deleted_at: null,
            },
            created_at: '2021-11-11T14:09:50.067574+00:00',
            updated_at: '2021-11-11T14:09:50.067574+00:00',
            deleted_at: null,
          },
          {
            id: 92,
            name: 'Req_2172_ReqRes_1892_Type_1_trans_5',
            data: {
              id: 1,
              fecha: '10 dic',
              saldo: '7,365.68',
              retiros: '8,000.00',
              concepto:
                'pago interbancario a bbva bancomer al benef . mary cruz , garcia reyes dato no verificado por esta institucion cta.beneficiario 004152313615777104 clave rastreo 085902381830334495 ref . 0101219 prestamo mismo dia',
              depositos: '',
              table_name: 'table_0_product_0',
            },
            sub_item_type: {
              id: 1,
              name: 'transaction',
              created_at: '2021-10-28T16:33:47.063472+00:00',
              updated_at: '2021-10-28T16:33:47.063472+00:00',
              deleted_at: null,
            },
            created_at: '2021-11-11T14:09:50.067574+00:00',
            updated_at: '2021-11-11T14:09:50.067574+00:00',
            deleted_at: null,
          },
          {
            id: 93,
            name: 'Req_2172_ReqRes_1892_Type_1_trans_6',
            data: {
              id: 1,
              fecha: '10 dic',
              saldo: '0.68',
              retiros: '7,365.00',
              concepto:
                'pago interbancario a bbva bancomer al benef . mary cruz , garcia reyes dato no verificado por esta institucion cta.beneficiario 004152313615777104 clave rastreo 085903339590334495 ref . 0101219 amex mismo dia',
              depositos: '',
              table_name: 'table_0_product_0',
            },
            sub_item_type: {
              id: 1,
              name: 'transaction',
              created_at: '2021-10-28T16:33:47.063472+00:00',
              updated_at: '2021-10-28T16:33:47.063472+00:00',
              deleted_at: null,
            },
            created_at: '2021-11-11T14:09:50.067574+00:00',
            updated_at: '2021-11-11T14:09:50.067574+00:00',
            deleted_at: null,
          },
          {
            id: 94,
            name: 'Req_2172_ReqRes_1892_Type_1_trans_7',
            data: {
              id: 1,
              fecha: '13 dic',
              saldo: '25,907.07',
              retiros: '',
              concepto: 'abono nomina takeda administracion mexico , s.a. d',
              depositos: '25,906.39',
              table_name: 'table_0_product_0',
            },
            sub_item_type: {
              id: 1,
              name: 'transaction',
              created_at: '2021-10-28T16:33:47.063472+00:00',
              updated_at: '2021-10-28T16:33:47.063472+00:00',
              deleted_at: null,
            },
            created_at: '2021-11-11T14:09:50.067574+00:00',
            updated_at: '2021-11-11T14:09:50.067574+00:00',
            deleted_at: null,
          },
          {
            id: 95,
            name: 'Req_2172_ReqRes_1892_Type_1_trans_8',
            data: {
              id: 1,
              fecha: '13 dic',
              saldo: '21,907.07',
              retiros: '4,000.00',
              concepto:
                'pago interbancario a bbva bancomer al benef . mary cruz , garcia reyes dato no verificado por esta institucion cta.beneficiario 004152313615777104 clave rastreo 085902193950334796 ref . 0131219 varios mismo dia',
              depositos: '',
              table_name: 'table_0_product_0',
            },
            sub_item_type: {
              id: 1,
              name: 'transaction',
              created_at: '2021-10-28T16:33:47.063472+00:00',
              updated_at: '2021-10-28T16:33:47.063472+00:00',
              deleted_at: null,
            },
            created_at: '2021-11-11T14:09:50.067574+00:00',
            updated_at: '2021-11-11T14:09:50.067574+00:00',
            deleted_at: null,
          },
          {
            id: 96,
            name: 'Req_2172_ReqRes_1892_Type_1_trans_9',
            data: {
              id: 1,
              fecha: '13 dic',
              saldo: '907.07',
              retiros: '21,000.00',
              concepto: 'deposito inversion perfiles',
              depositos: '',
              table_name: 'table_0_product_0',
            },
            sub_item_type: {
              id: 1,
              name: 'transaction',
              created_at: '2021-10-28T16:33:47.063472+00:00',
              updated_at: '2021-10-28T16:33:47.063472+00:00',
              deleted_at: null,
            },
            created_at: '2021-11-11T14:09:50.067574+00:00',
            updated_at: '2021-11-11T14:09:50.067574+00:00',
            deleted_at: null,
          },
          {
            id: 97,
            name: 'Req_2172_ReqRes_1892_Type_1_trans_10',
            data: {
              id: 1,
              fecha: '16 dic',
              saldo: '807.07',
              retiros: '100.00',
              concepto: 'deposito inversion perfiles',
              depositos: '',
              table_name: 'table_0_product_0',
            },
            sub_item_type: {
              id: 1,
              name: 'transaction',
              created_at: '2021-10-28T16:33:47.063472+00:00',
              updated_at: '2021-10-28T16:33:47.063472+00:00',
              deleted_at: null,
            },
            created_at: '2021-11-11T14:09:50.067574+00:00',
            updated_at: '2021-11-11T14:09:50.067574+00:00',
            deleted_at: null,
          },
          {
            id: 98,
            name: 'Req_2172_ReqRes_1892_Type_1_trans_11',
            data: {
              id: 1,
              fecha: '16 dic',
              saldo: '0.04',
              retiros: '807.03',
              concepto: 'deposito inversion perfiles',
              depositos: '',
              table_name: 'table_0_product_0',
            },
            sub_item_type: {
              id: 1,
              name: 'transaction',
              created_at: '2021-10-28T16:33:47.063472+00:00',
              updated_at: '2021-10-28T16:33:47.063472+00:00',
              deleted_at: null,
            },
            created_at: '2021-11-11T14:09:50.067574+00:00',
            updated_at: '2021-11-11T14:09:50.067574+00:00',
            deleted_at: null,
          },
          {
            id: 99,
            name: 'Req_2172_ReqRes_1892_Type_1_trans_12',
            data: {
              id: 1,
              fecha: '16 dic',
              saldo: '900.04',
              retiros: '',
              concepto: 'traspaso ref 763389 de fo 0000009770042117',
              depositos: '900.00',
              table_name: 'table_0_product_0',
            },
            sub_item_type: {
              id: 1,
              name: 'transaction',
              created_at: '2021-10-28T16:33:47.063472+00:00',
              updated_at: '2021-10-28T16:33:47.063472+00:00',
              deleted_at: null,
            },
            created_at: '2021-11-11T14:09:50.067574+00:00',
            updated_at: '2021-11-11T14:09:50.067574+00:00',
            deleted_at: null,
          },
          {
            id: 100,
            name: 'Req_2172_ReqRes_1892_Type_1_trans_13',
            data: {
              id: 1,
              fecha: '16 dic',
              saldo: '0.04',
              retiros: '',
              concepto: 'deposito inversion perfiles',
              depositos: '',
              table_name: 'table_0_product_0',
            },
            sub_item_type: {
              id: 1,
              name: 'transaction',
              created_at: '2021-10-28T16:33:47.063472+00:00',
              updated_at: '2021-10-28T16:33:47.063472+00:00',
              deleted_at: null,
            },
            created_at: '2021-11-11T14:09:50.067574+00:00',
            updated_at: '2021-11-11T14:09:50.067574+00:00',
            deleted_at: null,
          },
        ],
      },
    ],
  },
  response_code: 200,
  errors: [],
};
