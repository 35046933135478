import { createReducer, on, Action } from '@ngrx/store';
import { InsightMapState } from './insight-map.state';
import * as insightMapActions from './insight-map.actions';

export const initialInsightMapState: InsightMapState = {
  response: null,
  isLoadingPage: false,
};

const _insightMapReducer = createReducer(initialInsightMapState);

export function InsightMapReducers(
  state: InsightMapState | undefined,
  action: Action
) {
  return _insightMapReducer(state, action);
}
