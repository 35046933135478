import {
  Component,
  OnInit,
  Input,
  TemplateRef,
  HostListener,
  OnDestroy,
  ElementRef,
  OnChanges,
} from '@angular/core';
import { APP_CONSTANTS as CONSTANTS } from '@app/app.constants';

@Component({
  selector: 'layout-images-slide',
  templateUrl: './images-slide.component.html',
  styleUrls: ['./images-slide.component.scss'],
})
export class ImagesSlideComponent implements OnInit, OnChanges, OnDestroy {
  @Input() itemTemplate: TemplateRef<any>;
  @Input() items: any[];
  @Input() alignButtons: string;
  @Input() isAbsolute: string;
  @Input() isHiddenOverflow: boolean;
  @Input() itemsPerSlide: number = 1;
  @Input() changeSlide: number;

  private _isChecked: boolean;
  private _isFirstTime: boolean;
  private _isAlive: boolean;
  public currentSlide: number;
  public extraButton: any;
  public isLarge: boolean;
  public slides: any[];
  public ctx: { action: string; currentSlide: number; textSelected: string };

  constructor(private _el: ElementRef) {}

  ngOnInit() {
    // this.itemsPerSlide = 1;
    // this.slides = [...this.items];
    this._isFirstTime = true;
    this._isAlive = true;
    this.currentSlide = 0;
    this.ctx = {
      action: '',
      currentSlide: 0,
      textSelected: this.items[0].text,
    };

    this.isLarge = window.innerWidth > CONSTANTS.SIZE_MOBILE_SCREEN;
    this.updateCurrentStep();
  }
  ngOnChanges() {
    if (this.changeSlide) {
      this.getSlides();
    }
  }

  ngOnDestroy() {
    this._isAlive = false;
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.isLarge = window.innerWidth > CONSTANTS.SIZE_MOBILE_SCREEN;
    this._isChecked = false;
    // this.getSlides();
  }

  getSlides() {
    const length =
      this.itemsPerSlide > 1 ? this.items.length + 1 : this.items.length;
    const max = Math.floor(length / this.itemsPerSlide);
    this.slides = [];
    if (max < 2) {
      return;
    }
    for (var i = 0; i < max; i++) {
      this.slides.push(i);
    }
  }

  prev() {
    this.currentSlide--;
    if (this.currentSlide < 0) {
      this.currentSlide = this.slides.length - 1;
    }

    this.ctx.action = 'prev';
    this.updateCurrentStep();
  }

  next() {
    this.currentSlide++;
    if (this.currentSlide > this.slides.length - 1) {
      this.currentSlide = 0;
    }

    this.ctx.action = 'next';
    this.updateCurrentStep();
  }
  goToSlide(i) {
    this.currentSlide = i;
    this.updateCurrentStep();
  }

  updateCurrentStep() {
    this.ctx.currentSlide = this.currentSlide;
    // this.ctx.textSelected = this.items[this.currentSlide].text;
    // this.extraButton = this.items[this.currentSlide].button;
  }
}
