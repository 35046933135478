import { environment } from '@environment';

const API_VERSION_GEO = environment.api_version_geo;
const API_VERSION_FETCH = environment.api_version_fetch;

export const APP_CONSTANTS = {
  PRODUCTS: {
    INSIGHT: 'insight',
    FETCH: 'fetch',
  },
  PROP_URL_TOKEN: 'token',
  PROP_STORAGE_VISITOR: 'prop.insight.visitor',
  PROP_HOUR_TOKEN: 'prop.insight.session-hour-token',
  PROP_STORAGE_TOKEN_MOCK: 'prop.insight.session-token-mock',
  PROP_STORAGE_TOKEN: 'prop.insight.session-token',
  PROP_STORAGE_PASSWORD_TOKEN: 'prop.insight.create-password-token',
  PROP_STORAGE_SHOW_WELCOME_MODAL: 'prop.insight.show-welcome-modal',
  SIZE_MOBILE_SCREEN: 768,
  MAX_SIZE_FILE: 50 * 1024 * 1024,
  PASSWORD_TOKEN: 'is-password-token',
  TIME_WAITING_IN_FILTERS: 500,
  TUTORIAL_URL:
    'https://s3.amazonaws.com/site.www-geo-wearemo-com/assets/GEOComercial.mp4',
  MANUAL_URL:
    'https://s3.amazonaws.com/site.www-geo-wearemo-com/assets/MOGeo-UserManual.pdf',
  LIBRARY_PREMIUM_DATA_URL:
    'https://s3.amazonaws.com/site.www-geo-wearemo-com/assets/standard_library_with_descriptions.xlsx',
  COUNTRY_SETUP: {
    CODE: 'US',
    SIGN_CURRENCY: '$',
    THOUSAND_SEPARATOR: /\./g,
    LANGUAGE: 'en',
  },

  TEST_INFO: {
    TOKEN: 'ESTO-ES-UN-TOKEN-MOCK-PARA-LOGIN',
    EMAIL: 'julieth@mo-products.com',
    USER: 'julieth@mo-products.com',
  },

  HTTP_CODES: {
    NOT_FOUND: 404,
    FORBIDDEN: 403,
    BAD_REQUEST: 400,
    UNAUTHORIZED: 401,
    CONFLICT: 409,
    OK: 200,
    SUCCESS: '00',
  },

  REGEX: {
    NUMBER: /^[0-9]$/,
    LETTERS: /^[A-Za-zá-ú\s.]+$/,
    CODE: /^[A-Za-zá-ú\s0-9._+-]+$/,
    EMAIL: /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/,
    EMAIL_INPUT: /[A-Za-z0-9._%+-@]$/,
    PASSWORD:
      /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[-!@#$%^&*()_+|~=`{}\[\]:";'<>?,.\/])(?=.{8,})/,
    WEB_SITE:
      /^(https?:\/\/)?(www\.)?([a-zA-Z0-9]+(-?[a-zA-Z0-9])*\.)+[\w]{2,}(\/\S*)?$/,
    WEB_SITE_INPUT: /[A-Za-z0-9._%+-@]$/,
  },

  SENTRY_ENVIRONMENT: {
    LOCAL: 'local',
    ALPHA: 'alpha',
    PRODUCTION: 'production',
  },

  MESSAGES: {
    ERROR: 'An error has occurred, please try again',
    ERROR_NOT_FOUND_RESOURCE:
      'The requested resource is not available, please try again',
    SESSION_FINISHED:
      'The session is invalid or has finished. Please log in again.',
    TITLE_SESSION_FINISHED: 'Session expired',
    ERROR_FIELD_REQUIRED: 'You must complete all the fields',
    ERROR_SELECTED_KPI: 'You must select at least one KPI',
    ERROR_PASSWORD_REPEAT: 'The passwords must be equals',
  },

  ROUTES: {
    API_DOCUMENTATION: `${environment.api_url}/redoc`,
    LANDING: '/inicio',
    FAQ: '/faq',
    APPLY: '/aplicar',
    SIGNUP: '/signup',
    LOGIN: '/auth/login',
    FORGOT_PASSWORD: '/auth/forgot-password',
    LANDING_CLIENT: '',
    HOME: 'insight/home',
    PRODUCTS: {
      INSIGHT: {
        HOME: '/insight/home',
        REQUEST: '/insight/request',
        CONFIGURATION: '/insight/kpi/configuration',
        KPIS: '/insight/kpi/library',
        PREMIUM: '/insight/kpi/premium',
        KPI_PREVIEW: '/insight/kpi/preview',
        GENERAL_KPI: '/insight/kpi/',
        API: '/insight/api',
        SUPPORT: '/insight/support',
      },
      FETCH: {
        HOME: '/fetch/home',
        TEMPLATES: '/fetch/templates',
        DOCUMENTS: '/fetch/documents',
        API_CONNECT: '/fetch/api-connect',
        HELP: '/fetch/support/help',
        FAQS: '/fetch/support/faqs',
        PROPOSE_A_TEMPLATE: '/fetch/support/propose-a-template',
      },
    },
  },

  DOCUMENTS: {
    PRIVACY_POLICY: `${environment.documents_bucket}/privacy-statement-mo-geo.pdf`,
    TYC: `${environment.documents_bucket}/tyc.pdf`,
    END_USER_LICENSE_AGREEMENT: `${environment.documents_bucket}/end_user_license_agreement.pdf`,
    ESIGN_DISCLOSURE: `${environment.documents_bucket}/esign_disclosure.pdf`,
  },
  API_VERSION: 'v1',
  URLS: {
    // AUTH
    LOGIN: `/app/${API_VERSION_GEO}/access/login/`,
    SIGN_UP: `/app/${API_VERSION_GEO}/access/signup/`,
    SEND_FORGOT_PASSWORD_EMAIL: `/app/${API_VERSION_GEO}/access/send-forgot-password-email/`,
    CHANGE_PASSWORD: `/app/${API_VERSION_GEO}/access/change-password/`,
    VALIDATE_TEMPORAL_TOKEN: `/app/${API_VERSION_GEO}/access/validate-temporal-token/`,
    // USER
    ORGANIZATIONS: `/app/${API_VERSION_GEO}/insight/organizations/`,
    // GEO
    COUNTRIES: `/app/${API_VERSION_GEO}/insight/countries/`,
    DATA_COUNTRIES: `/app/${API_VERSION_GEO}/insight/configurations/data_country/`,
    REQUESTS_STATES: `/app/${API_VERSION_GEO}/insight/requests/states/`,
    STATUS_REQUEST: `/api/${API_VERSION_GEO}/insight/data/`,
    REQUEST: `/app/${API_VERSION_GEO}/insight/requests/`,
    CREATE_REQUEST_COORDINATES: `/api/${API_VERSION_GEO}/insight/coordinate/`,
    CREATE_REQUEST_ADDRESS: `/api/${API_VERSION_GEO}/insight/address/`,
    DOWNLOAD_URL: `/app/${API_VERSION_GEO}/insight/requests/download/`,
    CONFIGURATIONS: `/app/${API_VERSION_GEO}/insight/configurations/`,
    CONFIGURATIONS_LIST: `/app/${API_VERSION_GEO}/insight/configurations/list/`,
    KPIS_PARENTS: `/app/${API_VERSION_GEO}/insight/kpis/parents/`,
    PREMIUM_KPIS_PARENTS: `/app/${API_VERSION_GEO}/insight/kpis/premium/parents/`,
    KPIS: `/app/${API_VERSION_GEO}/insight/kpis/`,
    PREMIUM_KPIS: `/app/${API_VERSION_GEO}/insight/kpis/premium/`,
    API_KEY: `/app/${API_VERSION_GEO}/access/api-key/`,
    SUPPORT: `/app/${API_VERSION_GEO}/communications/contact-form/`,
    UPLOAD_BATCH: `/api/${API_VERSION_GEO}/insight/batch/`,
    // FETCH
    COUNTRY: `/api/${API_VERSION_FETCH}/fetch/country`,
    STATES: `/api/${API_VERSION_FETCH}/fetch/requests/statuses/`,
    TEMPLATES_TYPE: `/api/${API_VERSION_FETCH}/fetch/country/.+/templates/types/`,
    TEMPLATES: `/api/${API_VERSION_FETCH}/fetch/templates/`,
    TEMPLATES_VERSION: `/api/${API_VERSION_FETCH}/fetch/templates/versions/`,
    TEMPLATES_VERSION_FIELDS: `/api/${API_VERSION_FETCH}/fetch/templates/versions/fields/`,
    DOCUMENTS: `/api/${API_VERSION_FETCH}/app/fetch/requests/`,
    DOCUMENT_DETAILS: `/api/${API_VERSION_FETCH}/app/fetch/requests/processed/.+/`,
    DOCUMENT_DETAILS_TRANSACTIONS: `/api/${API_VERSION_FETCH}/app/fetch/requests/processed/.+/transactions/`,
    DOCUMENT_FILE: `/api/${API_VERSION_FETCH}/fetch/requests/.+/files/`,
    UPLOAD_FILE: `/api/${API_VERSION_FETCH}/app/fetch/suggestions/up-suggested-document/`,
    SAVE_SUGGESTIONS: `/api/${API_VERSION_FETCH}/app/fetch/suggestions/`,
    OVERVIEW_HEADER: `/api/${API_VERSION_FETCH}/app/fetch/overviews/header`,
    OVERVIEW_USE: `/api/${API_VERSION_FETCH}/app/fetch/overviews/use`,
    OVERVIEW_PERFORMANCE: `/api/${API_VERSION_FETCH}/app/fetch/overviews/performance`,
    OVERVIEW_SUBMISION: `/api/${API_VERSION_FETCH}/app/fetch/suggestions/`,
  },
  SUGGESTION_NAME_FETCH: 'TEMPLATE',
};

export const PREFIX_PATHS = {
  UPLOAD_BATCH: APP_CONSTANTS.URLS.UPLOAD_BATCH,
  UPLOAD_FETCH: APP_CONSTANTS.URLS.UPLOAD_FILE,
};
