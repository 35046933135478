import { Component, Inject, OnInit } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import {
  INotificationParams,
  NOTIFICATION_TYPE,
} from '@app/core/models/app.model';

@Component({
  selector: 'layout-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
})
export class NotificationComponent {
  public title: string;
  public message: string;
  public status: { className: string };

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: INotificationParams) {
    this.title = this.data.title;
    this.message = this.data.message;

    const types = {
      [NOTIFICATION_TYPE.SUCCESS]: { className: 'success-notification'},
      [NOTIFICATION_TYPE.ERROR]: { className: 'error-notification'},
    }
    this.status = types[data.type];
  }

  onClick() {
    if (this.data?.callback) this.data.callback();
  }
}
