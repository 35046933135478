import { animate, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'menu-accordeon-item',
  templateUrl: './accordeon-item.component.html',
  styleUrls: ['./accordeon-item.component.scss'],
  animations: [
    trigger('inOutAnimation', [
      transition(':enter', [
        style({ height: 0, opacity: 0 }),
        animate('200ms 400ms ease-in', style({ height: 'auto', opacity: 1 })),
      ]),
      transition(':leave', [
        style({ height: 'auto', opacity: 1 }),
        animate('200ms ease-out', style({ height: 0, opacity: 0 })),
      ]),
    ]),
  ],
})
export class AccordeonItemComponent {
  @Input() icon: string;
  @Input() label: string;
  @Input() active: boolean = false;
  @Input() collapsed: boolean = false;
  @Input() product: string;
  @Output() toggleAccordeon: EventEmitter<boolean>;

  constructor() {
    this.toggleAccordeon = new EventEmitter(this.active);
  }

  onToggleAccordeon() {
    this.active = !this.active;
    this.toggleAccordeon.emit(this.active);
  }
}
