export const FORM_CONSTANTS = {
  GENERIC_KEY_ERRORS: {
    INPUT_ERROR_EMAIL: 'It does not correspond to a valid email',
    INPUT_ERROR_REQUIRED: 'This field is required.',
    INPUT_ERROR_MINLENGTH: 'This field cannot be less than',
    INPUT_ERROR_MAXLENGTH: 'This field cannot be longer than',
    INPUT_ERROR_MIN: 'This field don`t has min number',
    INPUT_ERROR_MAX: 'This field don`t has max number',
    INPUT_ERROR_PATTERN: 'This field contains characters that are not allowed',
    INPUT_ERROR_INVALID_NUMBER: 'This field is dont a number',
    INPUT_ERROR_INVALID_FILE_SIZE: 'File size no larger than 5MB',
    INPUT_ERROR_INVALID_FILE_EXTENSION:
      'Only CSV and excel files can be uploaded',
    INPUT_ERROR_INVALID_MATCH_EMAIL: 'Don’t match email to confirmation email',
    INPUT_ERROR_INVALID_MATCH_VERIFYEMAIL:
      'Don’t match confirmation email to email',
  },
};

export const FORM_ERRORS_FX = (data, key) => {
  if (data.requiredLength) {
    return `${FORM_CONSTANTS.GENERIC_KEY_ERRORS[key]} ${data.requiredLength} characters`;
  } else {
    return FORM_CONSTANTS.GENERIC_KEY_ERRORS[key];
  }
};
