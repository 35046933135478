<div class="container-done-modal">
  <div class="row center-xs">
    <div class="col-xs">
      <img src="./assets/img/icon/done-check.svg" />
    </div>
  </div>
  <div class="row center-xs">
    <div class="col-xs">
      <label class="title">Done</label>
    </div>
  </div>
  <div class="row center-xs">
    <div class="col-xs description">
      <label> Message received! We will reach out to you shortly. </label>
    </div>
  </div>
  <div class="row center-xs">
    <div class="col-xs">
      <mo-button-form
        text="Finish"
        [template]="BUTTON_TEMPLATES.INDIGO"
        [size]="BUTTON_SIZE.MD"
        (click)="onCloseModal()"
      ></mo-button-form>
    </div>
  </div>
</div>
