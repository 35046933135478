export const TYPES_TEMPLATES_SUCCESS = {
  data: [
    {
      id: 1,
      name: 'Bank',
      country: 'Colombia'
    },
    {
      id: 2,
      name: 'Public Services',
      country: 'Colombia'
    },
    {
      id: 3,
      name: 'Another mock',
      country: 'Colombia'
    },
    {
      id: 4,
      name: 'Another mock 2',
      country: 'Colombia'
    },
  ],
  response_code: null,
  errors: null,
  total: 7,
  page: 0,
  size: 50
}
