import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'sub-menu-item',
  templateUrl: './sub-menu-item.component.html',
  styleUrls: ['./sub-menu-item.component.scss'],
})
export class SubMenuItemComponent implements OnInit {
  @Input() active: boolean;
  @Input() label: string;
  @Input() isSubMenu: boolean = false;
  @Input() product: string;

  constructor() {}

  ngOnInit(): void {}
}
