<div class="box-content-bottom-forms">
  <form
    [formGroup]="newRequestForm"
    [appFormValidate]="newRequestForm"
    (ngSubmit)="onSubmitForm()"
    *ngIf="!(isResponded$ | async)"
  >
    <div class="row">
      <div class="col-sm-4">
        <mo-drop-down
          [form]="newRequestForm"
          [options]="configurationValues"
          [controlName]="'configId'"
          [defaultOptionName]="'Configuration'"
          [size]="INPUT_SIZE.MD"
        ></mo-drop-down>
      </div>
      <div class="col-sm-4">
        <mo-drop-down
          [form]="newRequestForm"
          [options]="locationTypes"
          [controlName]="'type'"
          [defaultOptionName]="'Selected a configuration'"
          [size]="INPUT_SIZE.MD"
        ></mo-drop-down>
      </div>
      <div class="col-sm-4">
        <div class="row" *ngIf="newRequestForm.get('type').value == 'address'">
          <div class="col-sm-12">
            <mo-places
              [form]="newRequestForm"
              controlName="address"
              placeholder="Address"
              [size]="BUTTON_SIZE.MD"
            ></mo-places>
          </div>
        </div>
        <div
          class="row"
          *ngIf="newRequestForm.get('type').value == 'coordinates'"
        >
          <div class="col-sm-6">
            <mo-input
              controlName="latitude"
              placeHolder="Latitude"
              [type]="'number'"
              [form]="newRequestForm"
              [size]="INPUT_SIZE.MD"
            >
            </mo-input>
          </div>
          <div class="col-sm-6">
            <mo-input
              controlName="longitude"
              placeHolder="Longitude"
              [type]="'number'"
              [form]="newRequestForm"
              [size]="INPUT_SIZE.MD"
            >
            </mo-input>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-4">
        <mo-input
          controlName="radius"
          type="text"
          placeHolder="Radius"
          [form]="newRequestForm"
          [size]="INPUT_SIZE.MD"
        >
        </mo-input>
      </div>
      <div class="col-sm-7">
        <mo-button-form
          class="m-t-25 block-box"
          [isDisabled]="newRequestForm.invalid"
          [text]="'Create'"
          [isLoading]="isLoading$ | async"
          [type]="BUTON_TYPE.SUBMIT"
          [template]="BUTON_TEMPLATES.PRIMARY"
          [size]="BUTTON_SIZE.MD"
        ></mo-button-form>
      </div>
    </div>
  </form>
  <div class="messagge-info-container" *ngIf="isResponded$ | async">
    <insight-request-form-response
      [request]="success$ | async"
      (close)="onClose()"
      [error]="error$ | async"
    >
    </insight-request-form-response>
  </div>
</div>
