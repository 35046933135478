import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';

import { from, Observable, throwError } from 'rxjs';
import { finalize, catchError, map, mergeMap, switchMap } from 'rxjs/operators';

import { environment } from '@environment';
import { StorageService } from '@core/services/storage.service';
import { APP_CONSTANTS as CONST, PREFIX_PATHS } from '@app/app.constants';
import { AppFacade } from '@facades/app.facade';
import { Token } from '../models/app.model';

const API_URL = environment.api_url;

@Injectable()
export class AppInterceptorService implements HttpInterceptor {
  private _requests = [];
  private _isShownModal: boolean;
  private _mapErrors: Map<any, string>;

  constructor(
    private _appFacade: AppFacade,
    private _storage: StorageService
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return this._sendRequest(req, next);
  }

  private _sendRequest(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const isRequestAmazon = req.url.indexOf('s3.amazon') > -1;
    const isRequestGCP = req.url.indexOf('project.cloudfunctions') > -1;
    const isUploadBatchFileUrl =
      req.url === PREFIX_PATHS.UPLOAD_BATCH ||
      req.url === PREFIX_PATHS.UPLOAD_FETCH;

    if (isRequestAmazon || isRequestGCP) {
      return this._continueRequest(req, next);
    }
    const headers: any = {
      'Content-Type': 'application/json',
      'Accept-Language': 'en-US',
    };

    return this.getToken().pipe(
      mergeMap((token) => {
        if (token && token.access_token) {
          headers.Authorization = `Bearer ${token.access_token}`;
        }
        if (isUploadBatchFileUrl) {
          delete headers['Content-Type'];
        }
        const reqUpdated = req.clone({
          url: API_URL + req.url,
          setHeaders: headers,
        });
        return this._continueRequest(reqUpdated, next);
      })
    );
  }

  private _continueRequest(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      finalize(() => this._hideLoader()),
      catchError((err: any) => this._catchError(req, err)),
      map((response: any) => {
        this._checkTokenResponse(response);
        return response;
      })
    );
  }

  private _checkTokenResponse(response: any) {
    if (response.token) {
      this.setToken(response.token);
    }
    if (response.body && response.body.token) {
      this.setToken(response.body.token);
    }
    if (response.headers && response.headers.get('token')) {
      this.setToken(response.headers.get('token'));
    }
  }

  public setToken(token: string): void {
    const current = new Date().getTime();
    this._storage.setItem(CONST.PROP_STORAGE_TOKEN, token);
  }

  public getToken(): Observable<Token> {
    return this._storage.getItem(CONST.PROP_STORAGE_TOKEN).pipe(
      switchMap((globalToken: Token) => {
        return this._storage
          .getItem(CONST.PROP_STORAGE_PASSWORD_TOKEN)
          .pipe(
            map((passwordToken) => (globalToken ? globalToken : passwordToken))
          );
      })
    );
  }
  public removeToken() {
    this._storage.removeItem(CONST.PROP_STORAGE_TOKEN);
  }

  private _showLoader(url: string): Observable<string> {
    this._requests.push(url);
    if (this._requests.length === 1) {
      // TODO: change state loading
      // return from(this._uiService.loadingPresent());
    }
    return from('a');
  }

  private _hideLoader() {
    this._requests.pop();
    if (this._requests.length === 0) {
      // TODO: change state loading
      // this._uiService.loadingDismiss();
    }
  }

  private _catchError(req: HttpRequest<any>, error: any) {
    const isUnathorized = error.status === CONST.HTTP_CODES.UNAUTHORIZED;
    const isLogged = req.headers.get('Authorization')?.length > 0;
    this._hideLoader();

    if (isUnathorized && isLogged) {
      // TODO: How know when the session is expired if backend send us this code error in multiple steps?
      this._showDialogSessionExpired();
      return throwError(error);
    }

    if (error.status === CONST.HTTP_CODES.NOT_FOUND) {
      if (!req.body || (req.body && !req.body.preventNotFoundError)) {
        this._showError(req, CONST.MESSAGES.ERROR_NOT_FOUND_RESOURCE);
      }
      return throwError(error);
    }
    return throwError(error);
  }

  private _showDialogSessionExpired() {
    if (this._isShownModal) return;

    const callback = () => {
      this._appFacade.logout();
      this._isShownModal = false;
    };

    this._isShownModal = true;
    this._appFacade.showGlobalMessage(
      CONST.MESSAGES.SESSION_FINISHED,
      callback,
      CONST.MESSAGES.TITLE_SESSION_FINISHED
    );
  }

  private _showError(req: any, msg = null, error = null) {
    if (!req.body || !req.body.showError) {
      return;
    }
    if (msg && msg.indexOf && msg.indexOf('This field is required') >= 0) {
      msg = CONST.MESSAGES.ERROR_FIELD_REQUIRED;
    }
    if (!msg) {
      msg = CONST.MESSAGES.ERROR;
    }
    this._appFacade.showGlobalError(msg);
  }
}
