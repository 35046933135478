<div
  class="bg-form-group"
  [ngClass]="{
    'with-out-spaces': controlWithoutSpaces,
    'middle-spaces': controlWithMiddleSpace
  }"
>
  <div class="common-form-group" [formGroup]="form">
    <label
      #labelId
      class="mo-forms"
      *ngIf="label"
      [ngClass]="{ 'is-floating-label': isFloatingLabel }"
      >{{ label }}
      <span class="asterisk-error" *ngIf="isRequired">*</span>
      <div
        class="border-float-label"
        [ngStyle]="{ 'min-width.px': labelWidth }"
      ></div>
    </label>
    <div class="relative-box">
      <input
        [class]="
          'common-form-control ' +
          form.get(controlName).status.toLocaleLowerCase() +
          ' ' +
          template +
          ' ' +
          size
        "
        [id]="controlName"
        [type]="type"
        [formControlName]="controlName"
        [placeholder]="placeHolder"
        [ngClass]="{
          'error-control': formUtilService.validErrors(form, controlName)
        }"
        [ngStyle]="buildPaddingInputWhenHasIcons()"
      />
      <div
        #boxIcon
        [class]="'box-icon ' + iconPosition + ' ' + size"
        *ngIf="icons && icons.length > 0"
      >
        <i *ngFor="let icon of icons" class="uil {{ icon }}"></i>
      </div>
    </div>
  </div>
  <mo-error
    *ngIf="formUtilService.validErrors(form, controlName)"
    [error]="formUtilService.renderKeyError(form.get(controlName)?.errors)"
  ></mo-error>
</div>
