<div class="response-container">
  <ng-container *ngIf="!error">
    <layout-confirm-message
      title="Congratulations! "
      [description]="
        isEditConfig
          ? 'Your configuration was successfully edited'
          : 'Your configuration was successfully created.'
      "
    >
    </layout-confirm-message>
    <form-input-clipboard [inputInfo]="configurationId"></form-input-clipboard>
  </ng-container>

  <layout-confirm-message *ngIf="error" [description]="error" type="fail">
  </layout-confirm-message>

  <div class="button-content">
    <mo-button-form
      class="m-t-25 block-box"
      text="Close"
      [template]="BUTON_TEMPLATES.OUTLINE_PRIMARY"
      [size]="BUTTON_SIZE.MD"
      (click)="onClose()"
    ></mo-button-form>
    <mo-button-form
      class="m-t-25 block-box"
      text="Go to configurations"
      [template]="BUTON_TEMPLATES.OUTLINE_PRIMARY"
      [size]="BUTTON_SIZE.MD"
      (click)="onGotoConfigs()"
    ></mo-button-form>
  </div>
</div>
