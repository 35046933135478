import { Injectable } from '@angular/core';
import { APP_PRODUCTS } from '@app/app.products';
import { Product } from '@app/core/models/app.model';

@Injectable({
  providedIn: 'root',
})
export class AppService {
  getProductsActive(products: string[]): Product[] {
    return APP_PRODUCTS.filter((p) => products.includes(p.backendName));
  }
}
