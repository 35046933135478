import * as Hammer from 'hammerjs';
import { HammerGestureConfig } from '@angular/platform-browser';
import { Injectable } from '@angular/core';
@Injectable({
  providedIn: 'root',
})
export class MyHammerGestureConfig extends HammerGestureConfig {
  overrides = {
    swipe: {
      direction: Hammer.DIRECTION_HORIZONTAL,
    },
    pinch: { enable: false },
    rotate: { enable: false },
  };
}
