import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AppState } from './app.state';

export const getAppFeatureState = createFeatureSelector('app');

export const selectMenu = createSelector(
  getAppFeatureState,
  (state: AppState) => state?.productSelected.menu
);
export const selectShortcuts = createSelector(
  getAppFeatureState,
  (state: AppState) => state?.productSelected.shortcuts
);

export const selectRequestStates = createSelector(
  getAppFeatureState,
  (state: AppState) => state?.catalogs?.request_status
);

export const selectSidebarParams = createSelector(
  getAppFeatureState,
  (state: AppState) => state?.sidebarParams
);

export const selectProduct = createSelector(
  getAppFeatureState,
  (state: AppState) => state?.productSelected
);

export const selectProducts = createSelector(
  getAppFeatureState,
  (state: AppState) => state?.products
);

export const isMenuCollapsed = createSelector(
  getAppFeatureState,
  (state: AppState) => state?.isMenuCollapsed
);

export const bottomBarConfig = createSelector(
  getAppFeatureState,
  (state: AppState) => state?.bottomBarConfig
);

export const isModalOpen = createSelector(
  getAppFeatureState,
  (state: AppState) => state?.modalConfig
);
