export const REQUESTS_STATES_SUCCESS = {
  data: {
    total_requests: 4,
    approved_requests: 4,
    pending_requests: 0,
    rejected_requests: 0,
    last_requests: [
      {
        request_id: '75682ce9-c244-4c84 b76e-4bb9808789dd',
        creation_date: '2021-08-23 14:58:06',
        config_id: '2fcb56d6-b86d-4ff0-87db-cece8d8e42ee',
        status_key: 'comp',
        status_name: 'Completed',
        kpis_obtained: 1151,
      },
      {
        request_id: '75682ce9-c244-4c84 b76e-4bb9808789dd',
        creation_date: '2021-08-23 14:58:06',
        config_id: '2fcb56d6-b86d-4ff0-87db-cece8d8e42ee',
        status_key: 'pend',
        status_name: 'Pending',
        kpis_obtained: 1151,
      },
      {
        request_id: '75682ce9-c244-4c84 b76e-4bb9808789dd',
        creation_date: '2021-08-23 14:58:06',
        config_id: '2fcb56d6-b86d-4ff0-87db-cece8d8e42ee',
        status_key: 'fail',
        status_name: 'Failed',
        kpis_obtained: 1151,
      },
      {
        request_id: '75682ce9-c244-4c84 b76e-4bb9808789dd',
        creation_date: '2021-08-23 14:58:06',
        config_id: '2fcb56d6-b86d-4ff0-87db-cece8d8e42ee',
        status_key: 'comp',
        status_name: 'Completed',
        kpis_obtained: 1151,
      },
      {
        request_id: '75682ce9-c244-4c84 b76e-4bb9808789dd',
        creation_date: '2021-08-23 14:58:06',
        config_id: '2fcb56d6-b86d-4ff0-87db-cece8d8e42ee',
        status_key: 'comp',
        status_name: 'Completed',
        kpis_obtained: 1151,
      },
    ],
  },
  response_code: 200,
  errors: [],
};
