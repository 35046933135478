import { Component, Input } from '@angular/core';

@Component({
  selector: 'layout-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
})
export class CardComponent {
  @Input() title: string;
  @Input() value: string;
  @Input() hasHeader: string;
  
}
