import { Component, Input, TemplateRef } from '@angular/core';

@Component({
  selector: 'mo-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss'],
})
export class TooltipComponent<T, U> {
  @Input() tooltipTemplate: TemplateRef<T>;
  @Input() tooltipData: U;
}
