export const DATA_COUNTRIES_SUCCESS = {
  data: {
    items: [
      {
        group_key: 'Comm',
        group_name: 'Commerce',
        subgroups: [
          {
            subgroup_key: 'Bank',
            subgroup_name: 'Banking',
          },
          {
            subgroup_key: 'Otse',
            subgroup_name: 'Other services',
          },
        ],
      },
      {
        group_key: 'Peop',
        group_name: 'People',
        subgroups: [
          {
            subgroup_key: 'Cult',
            subgroup_name: 'Culture/tourism',
          },
          {
            subgroup_key: 'Trav',
            subgroup_name: 'Travel',
          },
        ],
      },
      {
        group_key: 'Gove',
        group_name: 'Government/Public services',
        subgroups: [
          {
            subgroup_key: 'PuTr',
            subgroup_name: 'Public Transportation',
          },
          {
            subgroup_key: 'Heal',
            subgroup_name: 'Health',
          },
        ],
      },
      {
        group_key: 'Envi',
        group_name: 'Environment',
        subgroups: [
          {
            subgroup_key: 'Lafe',
            subgroup_name: 'Land features',
          },
          {
            subgroup_key: 'Wafe',
            subgroup_name: 'Water features',
          },
        ],
      },
    ],
    indicators: {
      group_count: 4,
      subgroup_count: 20,
    },
  },
  response_code: null,
  errors: [],
};
