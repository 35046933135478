import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of, timer } from 'rxjs';
import {
  map,
  switchMap,
  tap,
  catchError,
  withLatestFrom,
  debounceTime,
  distinctUntilChanged,
  filter,
  mergeMap,
  first,
  take,
} from 'rxjs/operators';

import { NOTIFICATION_TYPE } from '@app/core/models/app.model';
import { Filters, ROWS_PER_PAGE } from '@app/core/models/table.model';
import { BatchFile, InsightData } from '@app/core/models/insight.model';
import {
  RequestDownload,
  RequestForm,
  RequestStatus,
  RequestSuccess,
  REQUEST_STATES,
} from '@app/core/models/request.model';
import { APP_CONSTANTS, APP_CONSTANTS as CONST } from '@app/app.constants';
import { INSIGHT_CONSTANTS as INSIGHT_CONST } from '@app/pages/insight/insight.constants';
import { UIService } from '@app/services/ui.service';
import { UtilService } from '@app/services/util.service';
import { InsightAPI } from '@app/services/insight/insight.api';
import * as insightActions from '../insight/insight.actions';
import * as insightSelectors from '../insight/insight.selectors';

import * as actions from './insight-request.actions';
import * as selectors from './insight-request.selectors';
import { GoogleAnalyticsService } from '@app/services/google-analytics.service';
import { GA } from '@app/core/constants/google-analytics.constant';
import { identifierModuleUrl } from '@angular/compiler';
import { InsightFacade } from '@app/facades/insight.facade';
import { IStatusInfo } from '@app/core/models/status-info.model';
import { DEFAULT_COUNTRY } from '@app/core/constants/insigth.constant';

@Injectable()
export class InsightRequestEffects {
  constructor(
    private _actions$: Actions,
    private _store: Store,
    private _router: Router,
    private _insightAPI: InsightAPI,
    private _uiService: UIService,
    private _utilService: UtilService,
    private _gaService: GoogleAnalyticsService,
    private _insightFacade: InsightFacade
  ) {}

  public initHomeEffect$ = createEffect(() =>
    this._actions$.pipe(
      ofType(insightActions.initOverviewPage, actions.responseCreateRequest),
      switchMap(() => this._getRequestSummaryAPI())
    )
  );

  public getRequestSummaryEffect$ = createEffect(() =>
    this._actions$.pipe(
      ofType(insightActions.getRequestSummary),
      switchMap(({ status }) => this._getRequestSummaryAPI(status))
    )
  );

  public filterRequestsEffect$ = createEffect(() =>
    this._actions$.pipe(
      ofType(
        actions.changeFiltersRequests,
        actions.initRequestPage,
        actions.responseCreateRequest,
        actions.responseRefreshRequestWS
      ),
      withLatestFrom(this._store.select(selectors.selectFilterRequests)),
      debounceTime(CONST.TIME_WAITING_IN_FILTERS),
      distinctUntilChanged(),
      switchMap(([_, filters]) => {
        let mapedFilters = { ...filters };
        if (!filters.size) {
          mapedFilters.size = ROWS_PER_PAGE.toString();
        }
        if (!filters.country) {
          mapedFilters.country = DEFAULT_COUNTRY;
        }
        return this._getRequestsAPI(mapedFilters);
      })
    )
  );

  public reloadRequestDataEffect$ = createEffect(() =>
    this._actions$.pipe(
      ofType(actions.reloadRequestWithOutLoading),
      withLatestFrom(this._store.select(selectors.selectFilterRequests)),
      debounceTime(CONST.TIME_WAITING_IN_FILTERS),
      distinctUntilChanged(),
      switchMap(([_, filters]) => {
        return this._getRequestsAPI(filters);
      })
    )
  );

  public createRequestEffect$ = createEffect(() =>
    this._actions$.pipe(
      ofType(actions.createRequest),
      withLatestFrom(this._store.select(insightSelectors.selectProductData)),
      filter(([_, insight]) => !!insight?.country && !!insight?.api_key),
      switchMap(([{ request }, product]) => {
        return this._createRequest(request, product);
      })
    )
  );

  public pollingRequestStatusEffect$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(actions.responseCreateRequest, actions.responseCreateRequestWS),
      withLatestFrom(this._store.select(insightSelectors.selectProductData)),
      mergeMap(([{ confirmation }, { api_key }]) =>
        this._startPolling(confirmation.request_id, api_key)
      ),
      catchError((_) => of(actions.closeRequestStatusPolling()))
    );
  });

  public uploadBashFile$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(actions.uploadFileWithRequests),
      withLatestFrom(this._store.select(insightSelectors.selectProductData)),
      switchMap(([{ request }, { api_key }]) => {
        return this._uploadFileWithRequests(request, api_key);
      })
    );
  });

  public getPresignURLEffect$ = createEffect(() =>
    this._actions$.pipe(
      ofType(actions.downloadResultRequest),
      switchMap(({ request }) => this._getDownloadUrlAPI(request))
    )
  );

  public downloadRequestEffect$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(actions.responsePresignURL),
        tap(({ fileResponse }) =>
          this._utilService.downloadFileFromURL(fileResponse)
        )
      ),
    { dispatch: false }
  );

  private _createRequest(request: RequestForm, insight: InsightData) {
    this._gaService.sendEvent(GA.DOWNLOAD.REQUEST_SUCCESS);
    const { api_key, country } = insight;
    const data = { ...request, country };
    const api$ = this._insightAPI.createRequest(data, api_key, request.type);
    return api$.pipe(
      map((confirmation) => {
        this._startPolling(confirmation.request_id, api_key);
        return actions.responseCreateRequest({ confirmation });
      }),
      catchError((_) => {
        this._gaService.sendEvent(GA.DOWNLOAD.REQUEST_ERROR);
        const message = `There was an error trying to create the Download record. Please try again or contact us for support.`;
        const error = { message };
        return of(actions.failureCreateRequest({ error }));
      })
    );
  }

  private _getRequestsAPI(filters?: Filters) {
    return this._insightAPI.getRequests(filters).pipe(
      map((response) => actions.responseGetRequests({ response })),
      catchError((_) => {
        const error = { message: 'Ocurrió un error en la consulta' };
        return of(actions.failureGetRequests({ error }));
      })
    );
  }

  private _getRequestSummaryAPI(status?: IStatusInfo) {
    return this._insightAPI.getRequestSummary(status).pipe(
      map((response) => actions.responseGetRequestSummary({ response })),
      catchError((_) => {
        const error = {
          message: 'The organization does not have requests',
        };
        return of(actions.failureGetRequestSummary({ error }));
      })
    );
  }

  private _startPolling(idRequest: string, APIKey: string) {
    const waitInterval = INSIGHT_CONST.TIME_INTERVAL_REQUEST_STATUS;
    return timer(waitInterval, waitInterval).pipe(
      take(INSIGHT_CONST.ATTEMPS_REQUEST_STATUS),
      mergeMap((i) => this._insightAPI.getStatusRequest(idRequest, APIKey)),
      filter(({ status }) => status !== REQUEST_STATES.PENDING),
      first(),
      map((response) => this._responsePolling(idRequest, response.status))
    );
  }

  private _uploadFileWithRequests(request: BatchFile, apiKey: string) {
    return this._insightAPI.uploadBashFile(request, apiKey).pipe(
      map((response) =>
        actions.responseCreateRequest({ confirmation: response })
      ),
      catchError((err) => {
        const message =
          err?.error?.detail ?? err?.error?.errors[0]?.description;
        const error = !!message
          ? { message }
          : {
              message:
                'There was an error trying to create the Download record. Please try again or contact us for support.',
            };
        return of(actions.failureCreateRequest({ error }));
      })
    );
  }

  private _startPollingWS(idRequest: string, APIKey: string) {
    const waitInterval = INSIGHT_CONST.TIME_INTERVAL_REQUEST_STATUS;
    return timer(waitInterval, waitInterval).pipe(
      take(INSIGHT_CONST.ATTEMPS_REQUEST_STATUS),
      mergeMap((i) => this._insightAPI.getStatusRequest(idRequest, APIKey)),
      filter(({ status }) => status !== REQUEST_STATES.PENDING),
      first(),
      map((response) => this._responsePollingWS(idRequest))
    );
  }

  private _responsePolling(id: string, status: RequestStatus) {
    this._insightFacade.responseRefreshRequestWS(id);
    const request = { id, status };
    const isSucces = status === REQUEST_STATES.SUCCESS;
    // isSucces ? this._showRequestSuccess(id) : this._showRequestFail(id);
    return actions.responseRequestStatus({ request });
  }

  private _responsePollingWS(id: string) {
    const request: RequestSuccess = {
      request_id: id,
    };
    return actions.responseCreateRequestWS({ confirmation: request });
  }

  private _showRequestSuccess(id: string) {
    const notification = {
      title: 'Download complete',
      icon: 'mo-request',
      message: `Your download (${id}) was completed succesfully, go to download section and see the results`,
      type: NOTIFICATION_TYPE.SUCCESS,
      callback: () =>
        this._router.navigateByUrl(CONST.ROUTES.PRODUCTS.INSIGHT.REQUEST),
    };
    this._uiService.showNotificationParams(notification);
  }

  private _showRequestFail(id: string) {
    const notification = {
      title: 'Download failed',
      icon: 'mo-request',
      message: `Your download (${id}) was completed failed`,
      type: NOTIFICATION_TYPE.ERROR,
    };
    this._uiService.showNotificationParams(notification);
  }

  private _getDownloadUrlAPI(request: RequestDownload) {
    return this._insightAPI.getDownloadUrl(request).pipe(
      map((fileResponse) => actions.responsePresignURL({ fileResponse })),
      catchError((_) => {
        const msg = APP_CONSTANTS.MESSAGES.ERROR;
        const error = { message: msg };
        return of(actions.failurePresignURL({ error }));
      })
    );
  }
}
