import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { APP_CONSTANTS as CONST } from '@app/app.constants';
import { GA } from '@app/core/constants/google-analytics.constant';
import { Configuration, Group } from '@app/core/models/insight.model';
import { GoogleAnalyticsService } from '@app/services/google-analytics.service';
import KPIGroupsValidator from '@app/shared-forms/validators/check-kpi-groups.validator';
import { Country } from '@app/core/models/insight.model';

@Component({
  selector: 'configuration-sidebar-form',
  templateUrl: './configuration-sidebar-form.component.html',
  styleUrls: ['./configuration-sidebar-form.component.scss'],
})
export class ConfigurationSidebarFormComponent implements OnInit, OnChanges {
  public form: FormGroup;
  public regexCode: RegExp;
  @Input() selectedConfig: Configuration;
  @Input() groups: Group[];
  @Input() countries: Country[];
  @Output() continue: EventEmitter<Configuration>;

  constructor(
    private _formBuilder: FormBuilder,
    private _gaService: GoogleAnalyticsService
  ) {
    this.continue = new EventEmitter();
    this.regexCode = CONST.REGEX.CODE;
  }

  ngOnInit() {
    this._gaService.sendEvent(GA.CONFIGURATION.PAGE_VIEW);
    this._setupForm();
    this._setInitialValues();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.groups && this.groups) {
      this.groups = this._getGroupsWithSelected();
    }
  }

  onContinue() {
    if (this.form.invalid) return false;
    const config: Configuration = {
      name: this.form.get('name').value,
      description: this.form.get('description').value,
      groups: this.getSelectedGroups(),
      country_code: this.form.get('country').value,
    };
    if (this.selectedConfig != null) {
      delete config.country_code;
      config.config_id = this.selectedConfig.config_id;
      config.status = this.selectedConfig.status;
    }
    this.continue.emit(config);
  }

  private _setupForm() {
    this.form = this._formBuilder.group(
      {
        name: ['', [Validators.required]],
        description: ['', [Validators.required]],
        kpi_groups: this._formBuilder.array([]),
        country: ['', [Validators.required]],
      },
      { validators: [KPIGroupsValidator] }
    );
  }

  get kpi_groups() {
    return this.form.get('kpi_groups') as FormArray;
  }

  private _addKpiGroupControl() {
    this.groups?.forEach((group) => {
      this.kpi_groups.push(
        this._formBuilder.control(group.is_selected, [Validators.required])
      );
    });
  }

  getSelectedGroups() {
    for (let i = 0; i <= this.groups.length - 1; i++) {
      this.groups[i].is_selected = this.form.get('kpi_groups').value[i];
    }
    return this.groups.filter((group) => group.is_selected);
  }

  private _setInitialValues() {
    this._addKpiGroupControl();
    this._setInputValues();
  }

  private _setInputValues() {
    if (this.selectedConfig != null) {
      this.form.get('name').setValue(this.selectedConfig.name);
      this.form.get('description').setValue(this.selectedConfig.description);
    }
  }

  private _getGroupsWithSelected(): Group[] {
    return this.groups.map((group) => ({
      ...group,
      is_selected: this._isSelectGroup(group),
    }));
  }

  private _isSelectGroup(group: Group): boolean {
    if (!this.selectedConfig || !this.selectedConfig.groups) return false;
    const selected = this.selectedConfig?.groups.find(
      (element) => element.group_key == group.group_key
    );
    return !!selected;
  }
}
