import { Component, Input } from '@angular/core';
import { UIService } from '@app/services/ui.service';
import { UtilService } from '@app/services/util.service';

@Component({
  selector: 'label-id',
  templateUrl: './label-id.component.html',
  styleUrls: ['./label-id.component.scss'],
})
export class LabelIdComponent {
  @Input() labelId: string;
  @Input() messageCopied?: string;
  @Input() isCopyable = true;
  @Input() isTable = true;

  constructor(
    private _utilService: UtilService,
    private _uiService: UIService
  ) {}

  public onCopy() {
    const message = this.messageCopied || 'Copied';
    this._utilService.copyClipboard(this.labelId);
    this._uiService.showSnackbar(message, 2000, 'mat-simple-snackbar-success');
  }
}
