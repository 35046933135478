import { AuthEffects } from './auth/auth.effects';
import { AuthReducers } from './auth/auth.reducers';
import { AuthState } from './auth/auth.state';
import { AppReducers } from './app/app.reducers';
import { AppState } from './app/app.state';
import { AppEffects } from './app/app.effects';
import { UserEffects } from './user/user.effects';
import { UserReducers } from './user/user.reducers';
import { UserState } from './user/user.state';
import { InsightEffects } from './insight/insight.effects';
import { InsightReducers } from './insight/insight.reducers';
import { InsightState } from './insight/insight.state';
import { FetchEffects } from './fetch/fetch.effects';
import { FetchReducers } from './fetch/fetch.reducers';
import { FetchState } from './fetch/fetch.state';
import { InsightRequestEffects } from './insight-request/insight-request.effects';
import { InsightRequestReducers } from './insight-request/insight-request.reducers';
import { InsightRequestState } from './insight-request/insight-request.state';
import { InsightMapEffects } from './insight-map/insight-map.effects';
import { InsightMapReducers } from './insight-map/insight-map.reducers';
import { InsightMapState } from './insight-map/insight-map.state';

export interface RootState {
  auth: AuthState;
  app: AppState;
  user: UserState;
  insight: InsightState;
  fetch: FetchState;
  insightRequest: InsightRequestState;
  insightMap: InsightMapState;
}
export const appReducers = {
  auth: AuthReducers,
  app: AppReducers,
  user: UserReducers,
  insight: InsightReducers,
  fetch: FetchReducers,
  insightRequest: InsightRequestReducers,
  insightMap: InsightMapReducers,
};
export const appEffects = [
  AppEffects,
  AuthEffects,
  UserEffects,
  InsightEffects,
  FetchEffects,
  InsightRequestEffects,
  InsightMapEffects,
];
