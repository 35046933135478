import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { APP_CONSTANTS as CONSTANTS } from '@app/app.constants';
import { environment } from '../../../environments/environment';

@Injectable()
export class AppTrackInterceptorService implements HttpInterceptor {
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const reqAnalytics = req.url.indexOf('/metrics/put_record') > -1;
    const isEnableTrack =
      environment.isEnablePutRecord || environment.production;

    if (reqAnalytics) {
      if (isEnableTrack) {
        const reqClone = req.clone({
          responseType: 'text',
        });
        return next.handle(reqClone);
      }
      console.warn('Here put_record service is disabled');
      return of(
        new HttpResponse({ status: CONSTANTS.HTTP_CODES.OK, body: '' })
      );
    }

    return next.handle(req);
  }
}
