import { Error } from '@app/core/models/error';
import { PagedResponse } from '@app/core/models/table.model';
import {
  Country,
  Document,
  IDocumentDetails,
  DocumentFile,
  DocumentsFilters,
  FetchData,
  IDocumentStates,
  Template,
  TemplatesFilters,
  TemplateVersion,
  TypeTemplate,
  IDocumentDetailTransactions,
  DocumentFilesZip,
  IFileData,
  IOverviewHeaderInfo,
  IOverviewUseInfo,
  IOverviewPerformanceInfo,
  IOverviewSubmisionInfo,
} from '@core/models/fetch.model';
import { createAction, props } from '@ngrx/store';

export const action = createAction(
  '[Fetch Page] Action',
  props<{ data: FetchData }>()
);
export const successAction = createAction(
  '[Fetch API] Succes Action',
  props<{ response: object }>()
);
export const failureAction = createAction(
  '[Fetch API] Failure Action',
  props<{ error: Error }>()
);
export const initTemplatesPage = createAction('[Templates Page] Enter');
export const hideTemplatesLoader = createAction('[Templates Page] Hide loader');
export const responseCountries = createAction(
  '[Fetch API] Response countries',
  props<{ countries: Country[] }>()
);
export const responseStates = createAction(
  '[Fetch API] Response states',
  props<{ states: IDocumentStates[] }>()
);
export const failureStates = createAction('[Fetch API] Failure states');
export const failureCountries = createAction('[Fetch API] Failure countries');
export const responseTypeTemplates = createAction(
  '[Fetch API] Response type templates',
  props<{ typeTemplates: TypeTemplate[] }>()
);
export const failureTypeTemplates = createAction(
  '[Fetch API] Failure type templates'
);
export const responseTemplates = createAction(
  '[Fetch API] Response templates',
  props<{ response: PagedResponse<Template[]> }>()
);
export const failureTemplates = createAction('[Fetch API] failure templates');
export const selectCountry = createAction(
  '[Template Page] Select country',
  props<{ country: Country }>()
);
export const selectTypeTemplate = createAction(
  '[Template Page] Select type template',
  props<{ typeTemplate: TypeTemplate }>()
);
export const changeTemplatesFilters = createAction(
  '[Template Page] Update template filters',
  props<{ templatesFilters: TemplatesFilters }>()
);
export const changeDocumentsFilters = createAction(
  '[Documents Page] Update documents filters',
  props<{ documentsFilters: DocumentsFilters }>()
);
export const toggleStatus = createAction(
  '[Template Page] toggle status templates',
  props<{ templates: Template[] }>()
);
export const responseUpdateStatusTemplates = createAction(
  '[Fetch API] Response update status templates'
);
export const failureUpdateStatusTemplate = createAction(
  '[Fetch API] Failure update status templates'
);
export const selectTemplateIdDetails = createAction(
  '[Fetch API] Details template',
  props<{ template_id }>()
);
export const responseTemplatesVersion = createAction(
  '[Fetch API] Response templates version',
  props<{ templateVersions: TemplateVersion[] }>()
);
export const failureTemplatesVersion = createAction(
  '[Fetch API] failure templates version'
);
export const setTemplateVersionDetail = createAction(
  '[Templates Page Detail] set template version detail',
  props<{ templateVersionDetail: string }>()
);
export const selectTemplateVersionId = createAction(
  '[Fetch API] Details template version fields',
  props<{ version_id }>()
);
export const clearTemplateVersionAndFields = createAction(
  '[Fetch API] Clear details template version fields'
);
export const initDocumentsPage = createAction('[Documents Page] Enter');
export const responseDocuments = createAction(
  '[Fecth API] Response documents',
  props<{ documents: PagedResponse<Document[]> }>()
);
export const failureDocuments = createAction('[Fetch API] failure documents');
export const getDocumentDetailById = createAction(
  '[Document page Detail row] Get document detail by id on toggle accordeon row',
  props<{ id }>()
);
export const responseDocumentDetails = createAction(
  '[Fecth API] Response documents details',
  props<{ documentDetails: IDocumentDetails; id: string }>()
);
export const failureDocumentDetails = createAction(
  '[Fetch API] failure documents details'
);
export const initDocumentDetailTransactions = createAction(
  '[Document Detail Page] Transactions'
);
export const responseDocumentDetailTransactions = createAction(
  '[Fecth API] Response document detail transactions',
  props<{
    documentDetailTransactions: IDocumentDetailTransactions[];
    id: string;
  }>()
);
export const failureDocumentDetailTransactions = createAction(
  '[Fecth API] Failure document detail transactions',
  props<{
    documentDetailTransactions: IDocumentDetailTransactions[];
    id: string;
  }>()
);

// for template page
// TODO: Refactor this for templates
export const downloadDocument = createAction(
  '[Download Document] download click',
  props<{ id }>()
);
export const responseDocumentFile = createAction(
  '[Fecth API] Response documents File',
  props<{ documentFile: DocumentFile }>()
);
export const failureDocumentFile = createAction(
  '[Fetch API] Failure documents file'
);

// for document page
export const responseMultipleDocumentFile = createAction(
  '[Fecth API] Response multiple documents files',
  props<{ documentsFiles: DocumentFile[] }>()
);
export const failureMultipleDocumentFile = createAction(
  '[Fetch API] Failure multiple documents files'
);
export const downloadMultipleDocumentsFilesZip = createAction(
  '[Documents page download files button] Download multiple documents files',
  props<{ documentFileZip: DocumentFilesZip[]; zipName: string }>()
);

// support page - propose a template
export const initProposeATemplatePage = createAction(
  '[Propose a template Page] Enter'
);
export const uploadFile = createAction(
  '[Propose a template Page] upload file',
  props<{ fileData: IFileData }>()
);
export const responseUploadFile = createAction(
  '[Fetch API] Response upload file',
  props<{ fileData: IFileData }>()
);
export const failureUploadFile = createAction(
  '[Fetch API] Failure upload file'
);
export const responseSaveSuggestions = createAction(
  '[Fetch API] Response save suggestion'
);
export const failureSaveSuggestions = createAction(
  '[Fetch API] Failure save suggestion'
);

/***************************
 * overview fetch block ****
 * ************************/

// header
export const onInitHeaderOverviewFetch = createAction(
  '[Fecth Overview Header Component] init header overview fetch component',
  props<{ filters }>()
);
export const onChangeFiltersOverviewFetch = createAction(
  '[Fecth Overview Header Component] on change filters header overview fetch component',
  props<{ filters }>()
);
export const responseOverviewHeader = createAction(
  '[Fetch API] Response overview header info',
  props<{ overviewHeaderInfo: IOverviewHeaderInfo }>()
);
export const failureOverviewHeader = createAction(
  '[Fetch API] Failure overview header info'
);

// Tab Use
export const onInitUseOverviewFetch = createAction(
  '[Fecth Overview use Component] init use overview fetch component'
);
export const responseOverviewUse = createAction(
  '[Fetch API] Response overview use info',
  props<{ overviewUseInfo: IOverviewUseInfo }>()
);
export const failureOverviewUse = createAction(
  '[Fetch API] Failure overview use info'
);

// Tab Performance
export const onInitPerformanceOverviewFetch = createAction(
  '[Fecth Overview Performance Component] init Performance overview fetch component'
);
export const responseOverviewPerformance = createAction(
  '[Fetch API] Response overview Performance info',
  props<{ overviewPerformanceInfo: IOverviewPerformanceInfo }>()
);
export const failureOverviewPerformance = createAction(
  '[Fetch API] Failure overview Performance info'
);

// submision on tab performance
export const responseOverviewSubmision = createAction(
  '[Fetch API] Response overview Submision info',
  props<{ overviewSubmisionInfo: IOverviewSubmisionInfo[] }>()
);
export const failureOverviewSubmision = createAction(
  '[Fetch API] Failure overview Submision info'
);
