import { Product } from '@app/core/models/app.model';
import { ISidebarModalParams } from '@app/core/models/sidebar-modal.model';
import { IBoostrapBottomBar } from '@app/layout/components/bottombar-modal/bottombar-interface';
import { ModalConfig } from '@app/layout/components/modal/modal-config.interface';
import { createAction, props } from '@ngrx/store';

export const changeProduct = createAction(
  '[Navbar] Change product',
  props<{ product: Product }>()
);
export const changeProductGuard = createAction(
  '[Guard] Change product',
  props<{ product: Product }>()
);
export const responseInitialProduct = createAction(
  '[App Service] Response initial(first) product',
  props<{ product: Product }>()
);
export const responseProductsActive = createAction(
  '[App Service] Response products active by user',
  props<{ products: Product[] }>()
);
export const setSidebarParams = createAction(
  '[SidebarModal] Set Sidebar Modal Params',
  props<{ sidebarParams: ISidebarModalParams }>()
);

export const collapseMenu = createAction(
  '[Navbar] Click to collapse sidebar menu'
);
export const expandMenu = createAction('[Navbar] Click to expand sidebar menu');

export const closeBottomBarModal = createAction(
  '[BottomBar] Click to close bottom bar modal'
);
export const openBottomBarModal = createAction(
  '[BottomBar] Click to open bottom bar modal',
  props<{ bottomBarConfig: IBoostrapBottomBar }>()
);

export const openModal = createAction(
  '[Modal] Click to open modal',
  props<{ modalConfig: ModalConfig }>()
);
export const closeModal = createAction('[Modal] Click to close modal');
