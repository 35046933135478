import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import {
  Country,
  Document,
  IDocumentDetails,
  DocumentsFilters,
  FetchData,
  IDocumentStates,
  Template,
  TemplatesFilters,
  TemplateVersion,
  TypeTemplate,
  IDocumentDetailTransactions,
  DocumentFile,
  DocumentFilesZip,
  IFileData,
  IOverviewHeaderInfo,
  IOverviewUseInfo,
  IOverviewPerformanceInfo,
  IOverviewSubmisionInfo,
} from '@core/models/fetch.model';

import * as fetchActions from '@store/fetch/fetch.actions';
import * as fetchSelectors from '@store/fetch/fetch.selectors';
import { PagedResponse } from '@app/core/models/table.model';

@Injectable({ providedIn: 'root' })
export class FetchFacade {
  public countries$: Observable<Country[]>;
  public states$: Observable<IDocumentStates[]>;
  public typeTemplates$: Observable<TypeTemplate[]>;
  public templates$: Observable<PagedResponse<Template[]>>;
  public fetch$: Observable<FetchData>;
  public isLoadingTemplates$: Observable<boolean>;
  public templatesVersions$: Observable<TemplateVersion[]>;
  public templatesVersionDetail$: Observable<string>;
  public documents$: Observable<PagedResponse<Document[]>>;
  public isLoadingDocuments$: Observable<boolean>;
  public documentDetails$: Observable<IDocumentDetails[]>;
  public documentDetailsTransactions$: Observable<any>;
  public isLoadingDocumentDetails$: Observable<{ [key: string]: boolean }>;
  public documentDetailTransactions$: Observable<IDocumentDetailTransactions[]>;
  public isLoadingDocumentDetailTransactions$: Observable<{
    [key: string]: boolean;
  }>;
  public isLoadingUploadFileFetch$: Observable<boolean>;
  public documentsFiles$: Observable<DocumentFile[]>;
  public isLoadingDocumentFile$: Observable<boolean>;
  public completeUploadFileFetch$: Observable<boolean>;
  public isLoadingOverviewHeaderInfo$: Observable<boolean>;
  public overviewHeaderInfo$: Observable<IOverviewHeaderInfo>;
  public isLoadingOverviewUseInfo$: Observable<boolean>;
  public overviewUseInfo$: Observable<IOverviewUseInfo>;
  public isLoadingOverviewPerformanceInfo$: Observable<boolean>;
  public overviewPerformanceInfo$: Observable<IOverviewPerformanceInfo>;
  public overviewSubmisionInfo$: Observable<IOverviewSubmisionInfo[]>;

  constructor(private _store: Store) {
    this.fetch$ = this._store.select(fetchSelectors.selectFetchData);
    this.countries$ = this._store.select(fetchSelectors.selectCountries);
    this.states$ = this._store.select(fetchSelectors.selectStates);
    this.typeTemplates$ = this._store.select(
      fetchSelectors.selectTypeTemplates
    );
    this.templates$ = this._store.select(fetchSelectors.selectTemplates);
    this.documents$ = this._store.select(fetchSelectors.selectDocuments);
    this.isLoadingDocuments$ = this._store.select(
      fetchSelectors.selectIsLoadingDocuments
    );
    this.isLoadingUploadFileFetch$ = this._store.select(
      fetchSelectors.selectIsLoadingUploadFileFetch
    );
    this.documentDetails$ = this._store.select(
      fetchSelectors.selectDocumentDetails
    );
    this.isLoadingDocumentDetails$ = this._store.select(
      fetchSelectors.selectIsLoadingDocumentDetails
    );
    this.isLoadingTemplates$ = this._store.select(
      fetchSelectors.selectIsLoadingTemplates
    );
    this.templatesVersions$ = this._store.select(
      fetchSelectors.selectTemplateVersions
    );
    this.templatesVersionDetail$ = this._store.select(
      fetchSelectors.selectTemplateVersionDetail
    );
    this.documentDetailTransactions$ = this._store.select(
      fetchSelectors.selectDocumentDetailTransactions
    );
    this.isLoadingDocumentDetailTransactions$ = this._store.select(
      fetchSelectors.selectIsLoadingDocumentDetailTransactions
    );
    this.documentsFiles$ = this._store.select(
      fetchSelectors.selectDocumentsFiles
    );
    this.isLoadingDocumentFile$ = this._store.select(
      fetchSelectors.selectIsLoadingDocumentFile
    );
    this.completeUploadFileFetch$ = this._store.select(
      fetchSelectors.selectCompleteUploadFileFetch
    );
    this.isLoadingOverviewHeaderInfo$ = this._store.select(
      fetchSelectors.selectIsLoadingOverviewHeaderInfo
    );
    this.overviewHeaderInfo$ = this._store.select(
      fetchSelectors.selectOverviewHeaderInfo
    );
    this.isLoadingOverviewUseInfo$ = this._store.select(
      fetchSelectors.selectIsLoadingOverviewUseInfo
    );
    this.overviewUseInfo$ = this._store.select(
      fetchSelectors.selectOverviewUseInfo
    );
    this.isLoadingOverviewPerformanceInfo$ = this._store.select(
      fetchSelectors.selectIsLoadingOverviewPerformanceInfo
    );
    this.overviewPerformanceInfo$ = this._store.select(
      fetchSelectors.selectOverviewPerformanceInfo
    );
    this.overviewSubmisionInfo$ = this._store.select(
      fetchSelectors.selectOverviewSubmisionInfo
    );
  }

  public action(data: FetchData) {
    this._store.dispatch(fetchActions.action({ data }));
  }

  public initTemplatesPage() {
    this._store.dispatch(fetchActions.initTemplatesPage());
  }

  public hideTemplatesLoader() {
    this._store.dispatch(fetchActions.hideTemplatesLoader());
  }

  public selectCountry(country: Country) {
    this._store.dispatch(fetchActions.selectCountry({ country }));
  }

  public selectTypeTemplates(typeTemplate: TypeTemplate) {
    this._store.dispatch(fetchActions.selectTypeTemplate({ typeTemplate }));
  }

  public changeTemplatesFilters(templatesFilters: TemplatesFilters) {
    this._store.dispatch(
      fetchActions.changeTemplatesFilters({ templatesFilters })
    );
  }

  public changeDocumentsFilters(documentsFilters: DocumentsFilters) {
    this._store.dispatch(
      fetchActions.changeDocumentsFilters({ documentsFilters })
    );
  }

  public toggleStatus(templates: Template[]) {
    this._store.dispatch(fetchActions.toggleStatus({ templates }));
  }

  public selectTemplateDetails(template_id: string) {
    this._store.dispatch(fetchActions.selectTemplateIdDetails({ template_id }));
  }

  public selectTemplateVersionDetail(templateVersionDetail: string) {
    this._store.dispatch(
      fetchActions.setTemplateVersionDetail({ templateVersionDetail })
    );
  }

  public selectTemplateVersionId(version_id: string) {
    this._store.dispatch(fetchActions.selectTemplateVersionId({ version_id }));
  }

  public clearTemplateVersionAndFields() {
    this._store.dispatch(fetchActions.clearTemplateVersionAndFields());
  }

  public initDocumentsPage() {
    this._store.dispatch(fetchActions.initDocumentsPage());
  }

  public initDocumentDetailTransactions() {
    this._store.dispatch(fetchActions.initDocumentDetailTransactions());
  }

  public getDocumentDetailById(id: string) {
    this._store.dispatch(fetchActions.getDocumentDetailById({ id }));
  }

  public downloadDocument(id: string) {
    this._store.dispatch(fetchActions.downloadDocument({ id }));
  }

  public downloadDocumentsFilesZip(
    documentFileZip: DocumentFilesZip[],
    zipName: string
  ) {
    this._store.dispatch(
      fetchActions.downloadMultipleDocumentsFilesZip({
        documentFileZip,
        zipName,
      })
    );
  }

  public initProposeATemplatePage() {
    this._store.dispatch(fetchActions.initProposeATemplatePage());
  }

  public uploadFile(fileData: IFileData) {
    this._store.dispatch(fetchActions.uploadFile({ fileData }));
  }

  // Overview
  // header
  public onInitHeaderOverviewFetch(filters) {
    this._store.dispatch(fetchActions.onInitHeaderOverviewFetch({ filters }));
  }

  public onChangeFiltersOverviewFetch(filters) {
    this._store.dispatch(
      fetchActions.onChangeFiltersOverviewFetch({ filters })
    );
  }

  // Tab use
  public onInitUseOverviewFetch() {
    this._store.dispatch(fetchActions.onInitUseOverviewFetch());
  }

  // Tab performance
  public onInitPerformanceOverviewFetch() {
    this._store.dispatch(fetchActions.onInitPerformanceOverviewFetch());
  }
}
