import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FILE_TYPE } from '@app/core/enums/file.type';
import { Token } from '@app/core/models/app.model';
import { BatchFile } from '@app/core/models/insight.model';
import { RequestSuccess } from '@app/core/models/request.model';
import { AppFacade } from '@app/facades/app.facade';
import { InsightFacade } from '@app/facades/insight.facade';
import { BOX_INFO_TEMPLATE_TYPE } from '@app/layout/components/box-info/box-info-template.type';
import {
  COMPONENTS,
  MODAL_SIZE,
  MODULES,
} from '@app/layout/components/modal/modal-content.type';
import {
  BUTON_TYPE,
  BUTTON_SIZE,
  BUTTON_TEMPLATE,
} from '@app/mo-forms/enums/button.type';
import { COLOR_TEMPLATE, INPUT_SIZE } from '@app/mo-forms/enums/input.type';
import { ISelectOption } from '@app/mo-forms/interfaces/select-option.interface';
import { CustomValidators } from '@app/mo-forms/validators/custom.validators';
import { AuthService } from '@app/services/auth/auth.service';
import { Observable } from 'rxjs';
import { filter, first } from 'rxjs/operators';

@Component({
  selector: 'form-new-batch',
  templateUrl: './form-new-batch.component.html',
  styleUrls: ['./form-new-batch.component.scss'],
})
export class FormNewBatchComponent {
  public isLoading$: Observable<boolean>;
  public isResponded$: Observable<boolean>;
  public error$: Observable<string>;
  public success$: Observable<RequestSuccess>;

  // Constants
  public BOX_TEMPLATES = BOX_INFO_TEMPLATE_TYPE;
  public INPUT_SIZE = INPUT_SIZE;
  public BUTTON_TEMPLATES = BUTTON_TEMPLATE;
  public BUTTON_SIZE = BUTTON_SIZE;
  public BUTTON_TYPE = BUTON_TYPE;
  public INPUT_TEMPLATES = COLOR_TEMPLATE;

  public boxInfoCallBack;
  public newRequestInBatchForm!: FormGroup;
  public configurationValues: Array<ISelectOption<string>>;
  public selectedCountry: string;

  public requestRemaining: number;
  public daysLeft: number;
  public tokenData: Token;

  constructor(
    private _appFacade: AppFacade,
    private _insightFacade: InsightFacade,
    private _formBuilder: FormBuilder,
    private _authService: AuthService
  ) {
    this._mapListConfigurations();
    this._setupForm();
    this._setDaysToLeft();
    this._setRequestRemaining();
    this._getToken();
    this.isLoading$ = this._insightFacade.isLoadingRequestForm$;
    this.isResponded$ = this._insightFacade.isRespondedRequestForm$;
    this.isResponded$
      .pipe(filter((isResponded) => !!isResponded))
      .pipe(first())
      .subscribe(() => {});
    this.success$ = this._insightFacade.requestSuccess$;
    this.error$ = this._insightFacade.errorRequestForm$;
    this._insightFacade.country$.subscribe(
      (country) => (this.selectedCountry = country)
    );
  }

  public openModal() {
    this._appFacade.openModal({
      title: 'All file requirements ',
      content: {
        component: COMPONENTS.FILE_REQUIREMENTS,
        module: MODULES.GEO,
      },
      show: true,
      textButton: 'Continue',
      showExitIcon: true,
    });
  }

  public onSubmitForm() {
    if (this.newRequestInBatchForm.invalid) return;
    const file = this.newRequestInBatchForm.get('file').value;
    if (
      !this.tokenData?.is_premium &&
      (this.daysLeft <= 0 || this.requestRemaining <= 0)
    ) {
      this._showUpgradeModal();
    } else {
       const configBatchFile: BatchFile = {
        file,
        config_id: this.newRequestInBatchForm.get('configId').value,
        country: this.selectedCountry,
        radius: '0.5',
        file_name: file?.name,
      };
      this._insightFacade.uploadBashFile(configBatchFile);
    }
  }

  public onClose() {
    this._insightFacade.leaveRequestForms();
  }

  private _setupForm() {
    this.newRequestInBatchForm = this._formBuilder.group({
      configId: ['', Validators.required],
      file: [
        '',
        [
          Validators.required,
          CustomValidators.maxSizeFile(5000000),
          CustomValidators.allowedExtensions([FILE_TYPE.CSV, FILE_TYPE.XLSX]),
        ],
      ],
    });
  }

  private _mapListConfigurations() {
    this._insightFacade.configurations$.subscribe((configurations) => {
      this.configurationValues = configurations.map((config) => {
        return { value: config.config_id, key: config.name };
      });
    });
  }

  private _setDaysToLeft() {
    this._insightFacade.daysLeft$.subscribe((daysLeft) => {
      this.daysLeft = daysLeft;
    });
  }

  private _setRequestRemaining() {
    this._insightFacade.requestRemaining$.subscribe((requestRemaining) => {
      this.requestRemaining = requestRemaining;
    });
  }

  private _getToken() {
    this._authService.getToken().subscribe((token) => (this.tokenData = token));
  }

  private _showUpgradeModal() {
    this._appFacade.openModal({
      content: {
        component: COMPONENTS.UPGRADE_MY_PLAN,
        module: MODULES.GEO,
      },
      show: true,
      size: MODAL_SIZE.MD,
      showExitIcon: true,
      withoutPadding: true,
    });
  }
}
