import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, switchMap, catchError } from 'rxjs/operators';

import { UserAPI } from '@app/services/user/user.api';
import { ResponseInitial, User } from '@app/core/models/user.model';
import { APP_CONSTANTS as CONST } from '@app/app.constants';
import * as userActions from './user.actions';

@Injectable()
export class UserEffects {
  constructor(
    private _actions$: Actions,
    private _router: Router,
    private _userAPI: UserAPI
  ) {}

  public actionEffect$ = createEffect(() =>
    this._actions$.pipe(
      ofType(userActions.consultUserLogin),
      switchMap(() =>
        this._userAPI.getUserInfo().pipe(
          map((response: ResponseInitial) => {
            this._router.navigateByUrl('');
            return userActions.responseConsultUser({
              user: { ...response },
              catalogs: response.catalogs,
              products: response.products,
            });
          }),
          catchError((error: HttpErrorResponse) => {
            const isUnathorized =
              error.status === CONST.HTTP_CODES.UNAUTHORIZED;
            return of(userActions.logout());
          })
        )
      )
    )
  );
}
