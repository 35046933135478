import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'layout-user-card-menu',
  templateUrl: './user-card-menu.component.html',
  styleUrls: ['./user-card-menu.component.scss'],
})
export class UserCardMenuComponent implements OnInit {
  @Output() logout: EventEmitter<boolean>;

  constructor() {
    this.logout = new EventEmitter<boolean>(false);
  }

  ngOnInit(): void {}

  onLogout() {
    this.logout.emit(true);
  }
}
