<div class="box-copy-bg">
  <div class="container-label">
    <label *ngIf="label">{{ label }}</label>
  </div>
  <div class="mo-copy-content">
    <div class="container-key">
      <span class="key-value">{{
        showSecret ? secretValue : hiddenValue
      }}</span>
    </div>
  </div>
  <div class="box-icon right">
    <i (click)="showValue()" class="uil uil-eye"></i>
    <i (click)="copyValue()" class="uil uil-copy"></i>
  </div>
</div>
