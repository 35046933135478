import { ComponentType } from '@angular/cdk/portal';
import { APP_CONSTANTS as CONST } from '@app/app.constants';
import {
  IBottomBar,
  IBottonBarComponent,
} from '@app/layout/components/bottombar-modal/bottombar-interface';
import { BOTTOM_BAR_TYPES } from '@app/layout/components/bottombar-modal/bottombar.types';
import { ITableConst } from '@app/mo-table/constants/table.constant';
import { TypeColumn } from '@app/mo-table/interfaces/table.interface';
import { FormNewConfigurationComponent } from '@app/pages/insight/request/forms/new-configuration/form-new-configuration.component';
import { FormNewRequestComponent } from '@app/pages/insight/request/forms/new-request/form-new-request.component';
import { TabsNewRequestComponent } from '@app/pages/insight/request/forms/tabs-new-request/tabs-new-request.component';
import { PAGES_TYPE } from '../enums/pages.type';
import { CallBackType, EmptyConfig } from '../models/empty-config.model';
import { Menu, Shortcut, ShortcutsName } from '../models/menu.model';
import { IStatusInfo, STATUS } from '../models/status-info.model';

export const MENU_INSIGHT: Menu = {
  productLogo: '/assets/img/icon/menu/mo-geo.svg',
  productLogoCollapsed: '/assets/img/icon/menu/mo-geo-text.svg',
  product: 'mogeo',
  items: [
    {
      type: 'item',
      icon: '/assets/img/icon/menu/overview.svg',
      label: 'Overview',
      path: CONST.ROUTES.PRODUCTS.INSIGHT.HOME,
    },
    {
      type: 'title',
      label: 'EXPLORE',
      shortLabel: 'EXP',
    },
    {
      type: 'item-accordeon',
      icon: '/assets/img/icon/menu/database.svg',
      label: 'Data',
      path: '/insight/kpi/configuration',
      item: {
        type: 'sub-menu',
        items: [
          {
            type: 'sub-menu-item',
            label: 'Configurations',
            path: CONST.ROUTES.PRODUCTS.INSIGHT.CONFIGURATION,
          },
          {
            type: 'sub-menu-title',
            label: 'LIBRARIES',
          },
          {
            type: 'sub-menu-item',
            label: 'Standard Data',
            path: CONST.ROUTES.PRODUCTS.INSIGHT.KPIS,
            isSubMenu: true,
          },
          {
            type: 'sub-menu-item-icon',
            label: 'Premium Indexes',
            path: CONST.ROUTES.PRODUCTS.INSIGHT.PREMIUM,
            isSubMenu: true,
            icon: 'uil-bolt',
          },
        ],
      },
    },
    {
      type: 'item',
      icon: '/assets/img/icon/menu/code.svg',
      label: 'Requests',
      path: CONST.ROUTES.PRODUCTS.INSIGHT.REQUEST,
    },
    {
      type: 'item',
      icon: '/assets/img/icon/menu/connect.svg',
      label: 'Connect',
      path: CONST.ROUTES.PRODUCTS.INSIGHT.API,
    },
    {
      type: 'title',
      label: 'NEED HELP?',
      shortLabel: 'HELP?',
    },
    {
      type: 'item',
      icon: '/assets/img/icon/menu/chat.svg',
      label: 'Support',
      path: CONST.ROUTES.PRODUCTS.INSIGHT.SUPPORT,
    },
  ],
};

export const SHORTCUTS_INSIGHT: Shortcut[] = [
  {
    name: ShortcutsName.NEW_REQUEST,
    icon: 'mo-request',
    title: 'Create download',
  },
  {
    name: ShortcutsName.NEW_CONFIGURATION,
    icon: 'mo-configuration',
    title: 'Create configuration',
    data: { title: 'New configuration' },
  },
];

export const STATUSES: IStatusInfo[] = [
  {
    status: STATUS.ALL,
    label: 'all',
  },
  {
    status: STATUS.COMPLETED,
    label: 'Completed',
  },
  {
    status: STATUS.PENDING,
    label: 'pending',
  },
  {
    status: STATUS.FAILED,
    label: 'failed',
  },
];

export const BOTTOM_BAR_CONFIG: IBottomBar<IBottonBarComponent>[] = [
  {
    type: BOTTOM_BAR_TYPES.NEW_CONFIGURATION,
    title: 'New configuration',
    buttonText: 'New configuration',
    componentRef:
      FormNewConfigurationComponent as ComponentType<IBottonBarComponent>,
  },
  {
    type: BOTTOM_BAR_TYPES.NEW_REQUEST,
    title: 'New request',
    buttonText: 'New request',
    componentRef: TabsNewRequestComponent as ComponentType<IBottonBarComponent>,
  },
];

export const DEFAULT_COUNTRY = 'USA';

export const REQUEST_TABLE: ITableConst<any> = {
  HEADERS: [
    {
      label: 'Request ID',
      size: 2,
      isCopyable: true,
      typeColumn: TypeColumn.ID,
      dataKey: 'request_id',
      customClass: {
        header: 'text-start',
        row: 'text-start',
      },
    },
    {
      label: 'Config ID',
      size: 2,
      isCopyable: true,
      typeColumn: TypeColumn.ID,
      dataKey: 'config_id',
      customClass: {
        header: 'text-start',
        row: 'text-start',
      },
    },
    {
      label: 'Config name',
      size: 3,
      dataKey: 'config_name',
      customClass: {
        header: 'text-start',
        row: 'text-start',
      },
    },
    {
      label: 'Date created',
      size: 2,
      isSortable: true,
      typeColumn: TypeColumn.DATE,
      dataKey: 'creation_date',
      customClass: {
        header: 'text-start',
        row: 'text-start',
      },
    },
    {
      label: 'Status',
      size: 2,
      isSortable: true,
      typeColumn: TypeColumn.STATUS,
      dataKey: 'status_key',
    },
  ],
  DATA: [],
};

export const EMPTY_DATA: { [key in PAGES_TYPE]: EmptyConfig } = {
  // TODO add every texts
  [PAGES_TYPE.REQUEST]: {
    with_empty_response: {
      title: '',
      description: '',
      button_label: '',
      call_back_type: CallBackType.OPEN_FORM_BUTTOM_BAR,
    },
    with_filters: {
      title: '',
      description: '',
      button_label: '',
      call_back_type: CallBackType.CLEAR_FILTERS,
    },
  },
  [PAGES_TYPE.CONFIGURATION]: {
    with_empty_response: {
      title: '',
      description: '',
      button_label: '',
      call_back_type: CallBackType.OPEN_FORM_BUTTOM_BAR,
    },
    with_filters: {
      title: '',
      description: '',
      button_label: '',
      call_back_type: CallBackType.CLEAR_FILTERS,
    },
  },
  [PAGES_TYPE.OVERVIEW]: {
    with_empty_response: {
      title: '',
      description: '',
      button_label: '',
      call_back_type: CallBackType.OPEN_FORM_BUTTOM_BAR,
    },
    with_filters: {
      title: '',
      description: '',
      button_label: '',
      call_back_type: CallBackType.CLEAR_FILTERS,
    },
  },
  [PAGES_TYPE.PREMIUM_INDEXES]: {
    with_empty_response: {
      title: '',
      description: '',
      button_label: '',
      call_back_type: CallBackType.OPEN_FORM_BUTTOM_BAR,
    },
    with_filters: {
      title: '',
      description: '',
      button_label: '',
      call_back_type: CallBackType.CLEAR_FILTERS,
    },
  },
  [PAGES_TYPE.STANDARD_DATA]: {
    with_empty_response: {
      title: 'No records found',
      description: 'Contact with our support team to get help',
      button_label: 'Go to',
      call_back_type: CallBackType.OPEN_FORM_BUTTOM_BAR,
    },
    with_filters: {
      title: 'No records found matching the search criteria',
      description: null,
      button_label: 'Clear filters',
      call_back_type: CallBackType.CLEAR_FILTERS,
    },
  },
};
