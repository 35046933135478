import { Component, OnInit } from '@angular/core';
import { AppFacade } from '@app/facades/app.facade';
import { BUTTON_SIZE, BUTTON_TEMPLATE } from '@app/mo-forms/enums/button.type';
import { ModalConfig } from './modal-config.interface';
import { MODAL_CONTENT_TYPE } from './modal-content.type';

@Component({
  selector: 'modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})
export class ModalComponent implements OnInit {
  public BUTTON_TEMPLATES = BUTTON_TEMPLATE;
  public BUTTON_SIZE = BUTTON_SIZE;

  public modal: ModalConfig;
  public onCloseStylesAnimationInProgress: boolean;
  public contentComponent;

  constructor(private _appFacade: AppFacade) {}

  public ngOnInit() {
    this._appFacade.modalConfig$.subscribe((data) => {
      if (data && data.show && data.content) {
        this.modal = data;
        this.contentComponent =
          MODAL_CONTENT_TYPE[data.content.module][data.content.component];
      }
      if (data && !data.show) {
        this.onClose(data);
      }
    });
  }

  public close() {
    this._appFacade.closeModal();
  }

  public onClose(data) {
    this.onCloseStylesAnimationInProgress = true;
    setTimeout(() => {
      this.modal = data;
      this.onCloseStylesAnimationInProgress = false;
    }, 900);
  }
}
