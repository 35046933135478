import { APP_CONSTANTS as CONSTANTS } from '@app/app.constants';
import { PipeTransform, Pipe } from '@angular/core';
import { formatDate } from '@angular/common';

@Pipe({
  name: 'appDate',
})
export class DatePipe implements PipeTransform {
  transform(value: any, ...args: any[]): any {
    if (!value) value = new Date();
    const date = formatDate(
      value,
      'MMM dd, yyyy',
      CONSTANTS.COUNTRY_SETUP.LANGUAGE
    );
    return date.replace(/\w\S*/g, (txt) => {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }
}
