import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { SidebarModalComponent } from './sidebar-modal.component';
import { SidebarModalDirective } from './sidebar-modal.directive';

@NgModule({
  declarations: [SidebarModalComponent, SidebarModalDirective],
  imports: [CommonModule, FontAwesomeModule, ReactiveFormsModule, FormsModule],
  exports: [SidebarModalComponent],
})
export class SidebarModalModule {}
