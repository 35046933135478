import { Component, OnInit } from '@angular/core';
import { TabsManagerConfig } from '@app/layout/components/tabs-manager/tabs-manager-config.interface';
import { FormNewBatchComponent } from '../new-batch/form-new-batch.component';
import { FormNewRequestComponent } from '../new-request/form-new-request.component';

@Component({
  selector: 'tabs-new-request-component',
  templateUrl: './tabs-new-request.component.html',
})
export class TabsNewRequestComponent {
  public config: Array<TabsManagerConfig> = [
    {
      title: 'Individual',
      component: FormNewRequestComponent,
    },
    {
      title: 'Batch',
      component: FormNewBatchComponent,
    },
  ];
}
