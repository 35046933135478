import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { throwError } from 'rxjs';
import { map, switchMap, tap, catchError } from 'rxjs/operators';

import { InsightAPI } from '@app/services/insight/insight.api';
import * as insightMapActions from './insight-map.actions';

@Injectable()
export class InsightMapEffects {
  constructor(private actions$: Actions, private _insightAPI: InsightAPI) {}
}
