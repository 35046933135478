import { Component, Input } from '@angular/core';

@Component({
  selector: 'primary-item',
  templateUrl: './primary-item.component.html',
  styleUrls: ['./primary-item.component.scss'],
})
export class PrimaryItemComponent {
  @Input() icon: string;
  @Input() label: string;
  @Input() active: boolean = false;
  @Input() product: string;
}
