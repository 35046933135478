import { createReducer, on, Action } from '@ngrx/store';
import { UserState } from './user.state';
import * as userActions from './user.actions';
import * as authActions from '../auth/auth.actions';
import { APP_PRODUCTS } from '@app/app.products';

export const initialUserState: UserState = {
  user: null,
  isLoadingPage: false,
};

const _userReducer = createReducer(
  initialUserState,
  on(
    userActions.responseConsultUser,
    userActions.responseConsultUserGuard,
    (state, { user, products }) => {
      const permissions = APP_PRODUCTS.filter((p) =>
        products.includes(p.backendName)
      ).map((p) => ({ route: p.route }));
      return { ...state, user: { ...user, permissions }, isLoadingPage: false };
    }
  ),
  on(
    authActions.logout,
    userActions.logout,
    userActions.logoutGuard,
    (state) => {
      return { ...state, user: null };
    }
  ),
  on(userActions.errorLogin, (state, { error }) => {
    return {
      ...state,
      response: error.message,
      isLoadingPage: false,
    };
  })
);

export function UserReducers(state: UserState | undefined, action: Action) {
  return _userReducer(state, action);
}
