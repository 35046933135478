<div class="row">
  <div class="col-xs-12">
    <h4>File requirements:</h4>
    <ul>
      <li>File size no larger than 5MB</li>
      <li>A file can only contain addresses or coordinates, not both</li>
      <li>Only CSV and excel files can be uploaded</li>
      <li>Cannot be PW protected</li>
    </ul>
  </div>
  <div class="col-xs-6">
    <h4>Address</h4>
    <ul>
      <li>Each location must be contained within one column</li>
    </ul>
    <div class="sub-item">
      <h5>Example</h5>
      <p>285 Fulton St, New York, Ny 10007</p>
    </div>
  </div>
  <div class="col-xs-6">
    <h4>Coordinates</h4>
    <ul>
      <li>Latitude is the first column, longitude the second column</li>
      <li>No special characters except decimal points.</li>
    </ul>
    <div class="sub-item">
      <h5>Example</h5>
      <p>40.7127 - 74.0134</p>
    </div>
  </div>
</div>
