import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'layout-confirm-message',
  templateUrl: './confirm-message.component.html',
  styleUrls: ['./confirm-message.component.scss'],
})
export class ConfirmMessageComponent {
  @Input() type: 'success' | 'fail' | 'warning';
  @Input() title: string;
  @Input() description: string;

  constructor() {
    this.type = 'success';
  }
}
