export const CONFIGURATIONS_LIST_SUCCESS = {
  data: [
    {
      config_id: '4f2fb31b-48aa-4ae3-86ba-b4fae93e378e',
      name: 'Segunda configuración',
      description:
        'Esta configuración se crea después de hacer la migración jeje',
    },
    {
      config_id: '2fcb56d6-b86d-4ff0-87db-cece8d8e42ee',
      name: 'Configuration test Julieth',
      description:
        'Julieth está probando la edición de las configuraciones con People ',
    },
  ],
  response_code: null,
  errors: [],
};
