import { createAction, props } from '@ngrx/store';
import { Error } from '@app/core/models/error';
import { Filters, PagedResponse } from '@app/core/models/table.model';
import {
  Country,
  ResponseApiKey,
  Configuration,
  KPI,
  InfoCountry,
  ConfigurationResponse,
  Support,
  KPIParent,
  ParentKpis,
  KPIFilters,
  PremiumFilters,
} from '@core/models/insight.model';
import { IStatusInfo } from '@app/core/models/status-info.model';

export const consultApiData = createAction('[Insight Page] Consult API');
export const responseConsultApi = createAction(
  '[Insight API] Response Consult API',
  props<{ response: ResponseApiKey }>()
);
export const failureConsultApi = createAction(
  '[Insight API] Failure Consult API',
  props<{ error: Error }>()
);

export const initOverviewPage = createAction('[Overview Page] Enter');
export const getRequestSummary = createAction(
  '[Overview Page] Get Request Summary by status',
  props<{ status: IStatusInfo }>()
);

export const initSelectedConfig = createAction(
  '[Configuration Page] Init Selected Config',
  props<{ configuration: Configuration }>()
);
export const changeStepConfiguration = createAction(
  '[Configuration Page] Change Step Configuration',
  props<{ step: number }>()
);
export const leaveSelectedConfig = createAction(
  '[Configuration Page] Leave Selected Config'
);

export const createConfigurationSubmit = createAction(
  '[Configuration Page] Create Configuration Submit',
  props<{ configuration: Configuration }>()
);
export const responseCreateConfiguration = createAction(
  '[Configuration API] Response Create Configuration',
  props<{ configuration: ConfigurationResponse }>()
);
export const failureCreateConfiguration = createAction(
  '[Configuration API] Failure Create Configuration',
  props<{ error: Error }>()
);

export const editConfigurationSubmit = createAction(
  '[Configuration Page] Edit Configuration Submit',
  props<{ configuration: Configuration }>()
);
export const responseEditConfiguration = createAction(
  '[Configuration API] Response Edit Configuration',
  props<{ configuration: ConfigurationResponse }>()
);
export const failureEditConfiguration = createAction(
  '[Configuration API] Failure Edit Configuration',
  props<{ error: Error }>()
);

export const sendSupportSubmit = createAction(
  '[Support Page] Send Support Submit',
  props<{ support: Support }>()
);
export const responseSendSupport = createAction(
  '[Support API] Response Send Support'
);
export const failureSendSupport = createAction(
  '[Support API] Failure Send Support',
  props<{ error: Error }>()
);

export const sendUpgradePlanSubmit = createAction(
  '[Support Page] Send Upgrade Plan Submit',
  props<{ support: Support }>()
);
export const responseUpgradePlanSubmit = createAction(
  '[Support API] Response Upgrade Plan Submit'
);
export const failureUpgradePlanSubmit = createAction(
  '[Support API] Failure Upgrade Plan Submit',
  props<{ error: Error }>()
);

export const leaveSuccessSuport = createAction(
  '[Support Page] Leave Success Support'
);

export const failureAction = createAction(
  '[Insight API] Failure Action',
  props<{ error: Error }>()
);

export const initKPIPages = createAction('[KPI Pages] Enter to one KPI Pages');
export const changeCountryKPI = createAction(
  '[KPI Pages] Change country',
  props<{ country: string }>()
);
export const changeCountrySlideBar = createAction(
  'Change country',
  props<{ country: string }>()
);
export const responseCountries = createAction(
  '[Insight API] Response countries',
  props<{ countries: Country[] }>()
);
export const responseInfoCountry = createAction(
  '[Insight API] Response info by country',
  props<{ infoCountry: InfoCountry }>()
);

export const initConfigPage = createAction('[Configuration Page] Enter');
export const changeFiltersConfigs = createAction(
  '[Config Page] Change filters to search configs',
  props<{ filters: Filters }>()
);
export const responseGetConfigsDetails = createAction(
  '[Insight API] Response configs with details (for table)',
  props<{ response: PagedResponse<Configuration[]> }>()
);
export const failureGetConfigsDetails = createAction(
  '[Insight API] Failure get configs with details (for table)',
  props<{ error: Error }>()
);
export const responseGetConfigs = createAction(
  '[Insight API] Response configs list',
  props<{ response: Configuration[] }>()
);
export const failureGetConfigs = createAction(
  '[Insight API] Failure get configs list',
  props<{ error: Error }>()
);
export const deleteConfig = createAction(
  '[Config Page] Click in delete button config item',
  props<{ id: string }>()
);
export const responseDeleteConfig = createAction(
  '[Insight API] Response delete config'
);
export const failureDeleteConfig = createAction(
  '[Insight API] Failure delete config',
  props<{ error: Error }>()
);
export const toggleConfiguration = createAction(
  '[Configuration Page] Toggle active/inactive configuration',
  props<{ configuration: Configuration }>()
);
export const responseToggleConfiguration = createAction(
  '[Insight API] Response toggle active/inactive configuration'
);
export const failureToggleConfiguration = createAction(
  '[Insight API] Failure toggle active/inactive configuration',
  props<{ error: Error }>()
);

export const initLibraryPage = createAction('[Library Page] Enter');
export const changeFiltersLibrary = createAction(
  '[Library Page] Change filters to search KPIs',
  props<{ filters: Filters }>()
);
export const responseGetParents = createAction(
  '[Insight API] Response Parents',
  props<{ response: PagedResponse<KPIParent> }>()
);
export const failureGetParents = createAction(
  '[Insight API] Failure get Parents',
  props<{ error: Error }>()
);
export const responseGetKpis = createAction(
  '[Insight API] Response KPIs',
  props<{ response: PagedResponse<KPI[]> }>()
);
export const failureGetKpis = createAction(
  '[Insight API] Failure get KPIs',
  props<{ error: Error }>()
);
export const selectParent = createAction(
  '[Insight API] Get KPIs by Parent name',
  props<{ selectParent: string; filters: KPIFilters }>()
);
export const responseParentKpi = createAction(
  '[Insight API] Array response parent kpi',
  props<{ parentKpis: ParentKpis }>()
);
export const initPremiumPage = createAction('[Premium Page] Enter');
export const changeFiltersPremium = createAction(
  '[Premium Page] Change filters to search premiums KPIs',
  props<{ filters: Filters }>()
);

export const selectPremiumParent = createAction(
  '[Premium Page] Get premium KPIs by Parent name',
  props<{ selectParent: string; filters: PremiumFilters }>()
);
export const responseGetPremiumKPIsParents = createAction(
  '[Premium Page] Response premium kpis Parents',
  props<{ response: PagedResponse<any> }>() // TODO: Add interface response
);
export const failureGetPremiumKPIsParents = createAction(
  '[Premium Page] Failure get premium kpis Parents',
  props<{ error: Error }>()
);

export const setRequestRemaining = createAction(
  '[App Page] Set request remaining',
  props<{ requestRemaining: number }>()
);

export const setDaysLeft = createAction(
  '[App Page] Set days left',
  props<{ daysLeft: number }>()
);

export const downloadManualDocument = createAction(
  '[App Page] Download manual document'
);
