export const environment = {
  production: true,
  testServeWait: 1000,
  test: false,
  isEnablePutRecord: false,
  isTestServer: false,
  ga_key: 'UA-TODO-3',
  gtag_id: 'G-8GG5J4HV36',
  gtm_id: 'GTM-NXP99H9',
  hotjar_id: 2892349,
  hotjar_version: 6,
  api_url: 'https://mo-services-main-production.moprestamo.com',
  ws_url: 'wss:/mo-services-main-production.moprestamo.com/insight/requests/ws',
  documents_bucket: '/assets/documents',
  sentry_dsn:
    'https://0fc5e19084244c19ac2b8ce6ad0cdc04@o80596.ingest.sentry.io/5650941',
  sentry_enviroment: 'production',
  release: 'f9af7b059d7e688a97d18970df1e58f5631cdc04',
  API_Key_Google_maps: 'AIzaSyDtvNLBTA7Dm6JCFzqHO8DEL-YGvs2xldc',
  api_version_geo: 'v1',
  api_version_fetch: 'v1',
  // TODO add site key
  captcha_site_key: '6LfPP-ceAAAAAFmzBuO0h-TeKGTIPCemdPBVVN_J',
};
