import { ElementRef, Injectable } from '@angular/core';
import { ComponentType } from '@angular/cdk/portal';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { UIService } from '@app/services/ui.service';
import { UtilService } from '@app/services/util.service';

import { IDialogParams } from '@app/core/models/dialog.model';
import { Dialog } from '@app/core/class/dialog.class';
import {
  ISidebarModal,
  ISidebarModalParams,
} from '@app/core/models/sidebar-modal.model';

import { ErrorService } from '@app/services/error.service';
import { Product } from '@app/core/models/app.model';
import {
  Menu,
  Shortcut,
  ShortcutName,
  ShortcutsName,
} from '@app/core/models/menu.model';
import * as appSelectors from '@store/app/app.selectors';
import * as appActions from '@store/app/app.actions';
import { ConfigurationSidebarComponent } from '@app/pages/insight/kpi/configuration/components/configuration-sidebar/configuration-sidebar.component';
import { InsightService } from '@app/services/insight/insight.service';
import { SidebarCreateComponent } from '@app/pages/insight/request/components/sidebar-create/sidebar-create.component';

import * as authActions from '@store/auth/auth.actions';
import { BOTTOM_BAR_TYPES } from '@app/layout/components/bottombar-modal/bottombar.types';
import { IBoostrapBottomBar } from '@app/layout/components/bottombar-modal/bottombar-interface';
import { ModalConfig } from '@app/layout/components/modal/modal-config.interface';

@Injectable({ providedIn: 'root' })
export class AppFacade {
  public products$: Observable<Product[]>;
  public productSelected$: Observable<Product>;
  public menu$: Observable<Menu>;
  public shortcuts$: Observable<Shortcut[]>;
  public sidebarParams$: Observable<ISidebarModalParams>;
  public isMenuCollapsed$: Observable<boolean>;
  public isBottomBarModalClose$: Observable<IBoostrapBottomBar>;
  public modalConfig$: Observable<ModalConfig>;

  constructor(
    private _uiService: UIService,
    private _insighService: InsightService,
    private _utilService: UtilService,
    private _errorService: ErrorService,
    private _store: Store
  ) {
    this.menu$ = this._store.select(appSelectors.selectMenu);
    this.shortcuts$ = this._store.select(appSelectors.selectShortcuts);
    this.isMenuCollapsed$ = this._store.select(appSelectors.isMenuCollapsed);
    this.sidebarParams$ = this._store.select(appSelectors.selectSidebarParams);
    this.productSelected$ = this._store.select(appSelectors.selectProduct);
    this.products$ = this._store.select(appSelectors.selectProducts);
    this.isBottomBarModalClose$ = this._store.select(
      appSelectors.bottomBarConfig
    );
    this.modalConfig$ = this._store.select(appSelectors.isModalOpen);
  }

  public logout() {
    this._store.dispatch(authActions.logout());
  }

  public showLoading() {
    console.log('TODO: Show loading');
  }

  public hideLoading() {
    console.log('TODO: Hide loading');
  }

  public showSplash(splash: ElementRef) {
    this._utilService.initLoader(splash);
  }

  public hideSplash() {
    this._utilService.hideLoaderReq();
  }

  public showGlobalError(message: string) {
    this._errorService.showGlobalError(message);
  }

  public showGlobalMessage(
    message: string,
    callback: Function,
    title?: string
  ) {
    const data = {
      message,
      callbacks: {
        ok: () => callback(),
      },
    } as IDialogParams;
    if (title) {
      data.title = title;
    }
    this._uiService.showDialog(data);
  }

  public showCustomDialog(data: any, dialog: ComponentType<Dialog>) {
    this._uiService.showDialog(data, dialog);
  }

  public setSidebarParams(sidebarParams: ISidebarModalParams) {
    this._store.dispatch(appActions.setSidebarParams({ sidebarParams }));
  }

  public selectProduct(product: Product) {
    this._store.dispatch(appActions.changeProduct({ product }));
  }

  public collapseMenu() {
    this._store.dispatch(appActions.collapseMenu());
  }

  public expandMenu() {
    this._store.dispatch(appActions.expandMenu());
  }

  public openBottomBar(type: BOTTOM_BAR_TYPES) {
    const bottomBarConfig: IBoostrapBottomBar = {
      type,
      isBottomBarModalClose: false,
    };
    this._store.dispatch(appActions.openBottomBarModal({ bottomBarConfig }));
  }

  public closeBottomBar() {
    this._store.dispatch(appActions.closeBottomBarModal());
  }

  public openShortcut(shortcutName: ShortcutName) {
    let component: ComponentType<ISidebarModal>;
    let data: ISidebarModalParams;
    let module: Function; // Function to load lazy module

    switch (shortcutName) {
      case ShortcutsName.NEW_REQUEST:
        module = this._insighService.getRequestModule();
        component = SidebarCreateComponent;
        data = { title: 'New download' };
        break;
      case ShortcutsName.NEW_CONFIGURATION:
        module = this._insighService.getConfigModule();
        component = ConfigurationSidebarComponent;
        data = { hasTitle: false };
        break;
    }

    if (component) this._uiService.showDetailSidebar(component, data, module);
  }

  public openModal(modalConfig: ModalConfig) {
    this._store.dispatch(appActions.openModal({ modalConfig }));
  }

  public closeModal() {
    this._store.dispatch(appActions.closeModal());
  }
}
