<div
  *ngIf="!isClose"
  class="opacity-bg"
  [ngClass]="{ 'is-colapsed-menu': isMenuCollapsed$ | async }"
></div>
<div class="bg-bottom-bar-modal" [ngClass]="{ open: !isClose }">
  <div
    class="box-bottom-bar"
    [ngClass]="{ 'is-colapsed-menu': isMenuCollapsed$ | async }"
  >
    <div class="header-bottom-bar">
      <mo-button-form
        *ngFor="let item of config"
        class="item m-r-20"
        [text]="item.buttonText"
        [icon]="'uil-plus'"
        [size]="BUTTON_SIZE.SM"
        [template]="
          selectedConfig?.title === item?.title
            ? BUTON_TEMPLATES.ALTERNATIVE_ACTIVE
            : BUTON_TEMPLATES.ALTERNATIVE
        "
        [iconPosition]="ICON_POSITION.LEFT"
        (click)="selectedForm(item)"
      ></mo-button-form>
      <div
        (click)="closeModal()"
        *ngIf="!isClose"
        class="close-icon-bottom-bar"
      >
        <i class="uil uil-times"></i>
      </div>
    </div>
    <div class="content-bottom-bar">
      <div *ngIf="!isClose && selectedConfig">
        <h3>{{ selectedConfig.title }}</h3>
        <ng-container
          *ngComponentOutlet="selectedConfig.componentRef"
        ></ng-container>
      </div>
    </div>
  </div>
</div>
