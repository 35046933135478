<form
  [formGroup]="newRequestInBatchForm"
  [appFormValidate]="newRequestInBatchForm"
  (ngSubmit)="onSubmitForm()"
>
  <div class="row">
    <div class="col-xs-4">
      <mo-drop-down
        [form]="newRequestInBatchForm"
        [options]="configurationValues"
        [controlName]="'configId'"
        [template]="INPUT_TEMPLATES.LIGHT"
        [defaultOptionName]="'Configuration'"
        [size]="INPUT_SIZE.MD"
        [controlWithoutSpaces]="true"
      ></mo-drop-down>
      <box-info-component
        icon="uil-exclamation-triangle"
        textCallback="View all requirements"
        [template]="BOX_TEMPLATES.WARNING"
        (clickedMoreInfo)="openModal()"
      >
        <ul class="no-margin">
          <li>File size no larger than 5MB</li>
          <li>A file can only contain addresses or coordinates, not both</li>
          <li>Only CSV and excel files can be uploaded</li>
          <li>Cannot be PW protected</li>
        </ul>
      </box-info-component>
    </div>
    <div class="col-xs-8 relative-box">
      <mo-upload
        [form]="newRequestInBatchForm"
        controlName="file"
        direction="direction-row"
        label="File"
        info="* For file sizes larger than 5MB, please contact us to complete your request."
      ></mo-upload>
      <div class="button-action">
        <mo-button-form
          class="m-t-25 block-box"
          [isLoading]="isLoading$ | async"
          [isDisabled]="newRequestInBatchForm.invalid"
          [text]="'Create'"
          [type]="BUTTON_TYPE.SUBMIT"
          [template]="BUTTON_TEMPLATES.PRIMARY"
          [size]="BUTTON_SIZE.MD"
        ></mo-button-form>
      </div>
    </div>
  </div>
</form>
<div class="messagge-info-container" *ngIf="isResponded$ | async">
  <insight-request-form-response
    [request]="success$ | async"
    (close)="onClose()"
    [error]="error$ | async"
  >
  </insight-request-form-response>
</div>
