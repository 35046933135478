import { APP_CONSTANTS as CONSTANTS } from '@app/app.constants';

export const CONSTANTS_FORMS = {
  MAX_ATTEMPTS_SEND_CODE: 4,
  MAX_ATTEMPTS_SUBMIT_CODE: 4,
  MAX_SIZE_FILE: 50 * 1024 * 1024,
  SIZE_MOBILE_SCREEN: 990,

  COUNTRY_SETUP: CONSTANTS.COUNTRY_SETUP,
  TIME_WAITING_IN_FILTERS: CONSTANTS.TIME_WAITING_IN_FILTERS,
  TIME_WAITING_INPUT_SEARCH: CONSTANTS.TIME_WAITING_IN_FILTERS + 1000,

  REGEX: CONSTANTS.REGEX,

  MESSAGES: {
    ERROR: 'Ha ocurrido un error, intente nuevamente',
    ERROR_FIELD_REQUIRED: 'Please fill in all fields',
    ERROR_EMAIL_INVALID: 'Please fill in a valid email',
    ERROR_LEGAL_FIELD_REQUIRED: 'Please accept {{name}} to continue',
    ERROR_LETTER_FIELD_INVALID:
      'Please fill the {{name}} without special characters',
    ERROR_NUMBER_FIELD_INVALID:
      'Please fill the {{name}} with a valid number',
    ERROR_PASSWORD_CONFIRMATION: 'Passwords must be equals',
    ERROR_PASSWORD_INVALID:
      'La contraseña debe cumplir con los elementos de seguridad',
    ERROR_WEBSITE_INVALID: 'Please fill in a valid website',
    ERROR_UPLOADING_FILE:
      'Ocurrió un error mientras se subía el archivo, inténtalo nuevamente',
    ERROR_NOT_FOUND_RESOURCE:
      'El recurso solicitado no está disponible, intente nuevamente',
    ERROR_FILE_EMPTY:
      'El archivo subido no contiene información, inténtalo nuevamente.',
    ERROR_FILE_HEAVY:
      'El archivo subido es demasiado grande, inténtalo nuevamente.',
    ERROR_FILE_EXTENSION:
      'Recuerda que el archivo debe ser un documento PDF o una imagen, inténtalo nuevamente.',
    ERROR_FILE_REQUIRED: 'Debe seleccionar un archivo',
    ERROR_UPLOADING:
      'Ocurrió un error mientras se subía el archivo, inténtalo nuevamente',
    SESSION_FINISHED:
      'The session is invalid or has finished. Please log in again.',
    ERROR_MINIMAL_VALUE_LOAN:
      'You should request an amount loan greater than {{value}}',
    ERROR_MAXIMUM_VALUE_LOAN:
      'You should request an amount loan less than {{value}}',
  },
};
