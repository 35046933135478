import { ISidebarExtraData } from './sidebar-modal.model';
import { Filters } from './table.model';

export interface FetchData {
  message: string;
}

export interface Country {
  code: string;
  name: string;
  created_at: string;
  updated_at: string;
  id?: string;
}

export interface IDocumentStates {
  status_name: string;
  status_code: string;
}

export interface TypeTemplate {
  id: number;
  name: string;
  country: string;
}

export interface Template {
  id: number;
  name: string;
  description: string;
  template_type: string;
  template_name: string;
  org_name: string;
  org_uuid: number;
  status: boolean;
  isMenuOpened?: boolean;
}

export interface TemplatesFilters extends Filters {
  country_code?: string;
  template_name?: string;
  template_type_id?: string;
  status?: string;
  template_id?: string;
  org_uuid?: string;
  org_name?: string;
}

export interface DocumentsFilters extends Filters {
  country_code?: string;
  entity_name?: string;
  request_start_date?: string;
  request_end_date?: string;
  status_code?: string;
}

export interface TemplateVersionParams extends Filters {
  template_id: string;
  version_id?: string;
}

export interface TemplateVersionFieldsParams extends Filters {
  version_id?: string;
}

export interface TemplateVersion {
  id: string;
  name: string;
  description: string;
  preview_url: string;
}

export interface Document {
  id: string;
  data: DocumentsData;
  status_code: number;
  created_at: string;
  updated_at: string;
  country: string;
  organization: string;
  org_uuid: string;
  finished_at: string;
  entity_name: string;
}

export interface DocumentSidebarParams extends ISidebarExtraData {
  document: Document;
}

interface DocumentsData {
  data: {
    org_id: string;
    templates: Templates[];
    request_id: string;
    external_id: string;
    paths_gcs_files: PathsGcsFiles[];
  };
  errors: Error[];
}

interface Templates {
  entity: string;
  country: string;
  version: string;
  fields_ids: FieldsIds[];
  template_id: string;
}
interface FieldsIds {
  message: string;
}

interface Error {
  code: string;
  code_name: string;
  description: string;
}

interface Metadata {
  message: string;
  page_number: string;
}

interface PathsGcsFiles {
  path: string;
  metadata: Metadata;
}

export interface DocumentFile {
  id: string;
  request_id: string;
  location: string;
  extension: string;
  created_at: string;
  updated_at: string;
}

export interface DocumentFilesZip {
  documentFile: DocumentFile;
  name: string;
}

export enum STATUS {
  COMPLETED = 'comp',
  PENDING = 'pend',
  FAILED = 'fail',
  ALL = 'all',
}

export enum DOCUMENT_STATUS {
  COMPLETED = '202',
  PENDING = '200',
  FAILED = '500',
  PENDING_1 = '100',
}

export type IDocumentStatus =
  | DOCUMENT_STATUS.COMPLETED
  | DOCUMENT_STATUS.PENDING
  | DOCUMENT_STATUS.PENDING_1
  | DOCUMENT_STATUS.FAILED;

export interface IDocumentStatusInfo {
  status: STATUS;
  label: string;
  data: IDocumentStatus;
}

export interface DocumentDetailsResponse {
  data: IDocumentDetails;
  response_code: number;
  errors: any[];
}

export interface IDocumentDetails {
  id: number;
  organization: IOrganization;
  entity: IEntity;
  data: IDocumentDetailsData;
  status_code: number;
  status_description: string;
  created_at: Date;
  updated_at: Date;
  finished_at: Date;
  request_files: IRequestFile[];
  request_responses: IRequestResponse[];
}

export interface IOrganization {
  id: number;
  name: string;
  uuid: string;
  timezone: string;
  s3_bucket_files: string;
  owner_email: string;
  sqs_message_id?: any;
  environment_name: string;
  created_at: Date;
  updated_at: Date;
  deleted_at?: any;
  organization_countrys: IOrganizationCountry[];
  support_contact_forms: any[];
  suggested_templates: SuggestedTemplate[];
}

export interface IOrganizationCountry {
  id: number;
  country: Country;
  created_at: Date;
  updated_at: Date;
  organization_country_templates: OrganizationCountryTemplate[];
}

export interface OrganizationCountryTemplate {
  id: number;
  status: boolean;
}

export interface SuggestedTemplate {
  id: number;
  name: string;
  justification: string;
  example_location?: any;
  entity_name?: any;
  country: Country;
  suggested_template_status: ISuggestedTemplateStatus;
  created_at: Date;
  updated_at: Date;
}

export interface ISuggestedTemplateStatus {
  description: string;
  created_at: Date;
  updated_at: Date;
}

export interface IEntity {
  name: string;
  created_at: Date;
  updated_at: Date;
}

export interface IDocumentDetailsData {
  data: IDocumentDetailsDataData;
  errors: any[];
}

export interface IDocumentDetailsDataData {
  org_id: string;
  templates: IDocumentDetailTemplate[];
  request_id: string;
  external_id: string;
  paths_gcs_files: PathsGcsFile[];
}

export interface IDocumentDetailTemplate {
  entity: string;
  fields: any[];
  country: string;
  version: string;
  document_types: string;
}

export interface PathsGcsFile {
  path: string;
  metadata: Metadata;
}

export interface IRequestFile {
  id: number;
  location: string;
  extension: string;
  created_at: Date;
  updated_at: Date;
}

export interface IRequestResponse {
  id: number;
  data: IRequestResponseData;
  created_at: Date;
  updated_at: Date;
  sub_items: ISubItem[];
}

export interface IRequestResponseData {
  message_id: string;
  response_body: IResponseBody;
  response_code: number;
}

export interface IResponseBody {
  entity: string;
  org_id: string;
  status: string;
  tables: IResponseBodyTable[];
  user_id: string;
  version: string;
  end_time: string;
  warnings: string[];
  detections: IResponseBodyDetection[];
  request_id: number;
  start_time: string;
  entity_name: string;
  documents_successful_rate: number;
  extracted_fields_successful_rate: string;
}

export interface IResponseBodyTable {
  rows: IResponseBodyRow[];
  table_name: string;
}

export interface IResponseBodyRow {
  fecha: string;
  saldo: string;
  retiros: string;
  concepto: string;
  depositos: string;
}

export interface IResponseBodyDetection {
  bank_name: string;
  customer_name: string;
  address: string;
  rfc: string;
  bank_date: string;
  account_type: string;
  account_number: string;
  period: string;
  initial_balance?: number;
  deposits_payments?: number;
  withdraws_charges?: number;
  final_balance?: number;
}

export interface ISubItem {
  id: number;
  name: string;
  data: ISubItemData;
  sub_item_type: ISubItemType;
  created_at: Date;
  updated_at: Date;
  deleted_at?: any;
}

export interface ISubItemData {
  id: number;
  fecha: string;
  saldo: string;
  retiros: string;
  concepto: string;
  depositos: string;
  table_name: string;
}

export interface ISubItemType {
  id: number;
  name: string;
  created_at: Date;
  updated_at: Date;
  deleted_at?: any;
}

export interface IDocumentDetailTransactions {
  id: number;
  data: IDocumentDetailsTransactionsData;
}

export interface IDocumentDetailsTransactionsData {
  id: number;
  fecha: string;
  saldo: string;
  retiros: string;
  concepto: string;
  depositos: string;
  table_name: string;
}

export interface IFileData {
  suggestion_name: string;
  justification: string;
  file_name: string;
  file: File;
  entity_name: string;
  country_id: 0;
  document_type_id: 0;
}

/**
 * /overview/header?filter=montly&year=2019&month=12
/overview/header?filter=15_days
/overview/header?filter=all
 */

export enum OVERVIEW_FILTER_VALUE {
  ALL = 'all',
  LAST_15_DAYS = '15_days',
  MONTHLY = 'monthly',
}

export type IOverviewFilter =
  | OVERVIEW_FILTER_VALUE.ALL
  | OVERVIEW_FILTER_VALUE.LAST_15_DAYS
  | OVERVIEW_FILTER_VALUE.MONTHLY;

export interface IOverviewFilters {
  filter: IOverviewFilter;
  label: string;
}

export interface IOverviewHeaderInfo {
  submited_docs: {
    total: number;
    percent_previous_month: number;
  };
  accepted: number;
  not_accepted: number;
  failed: number;
  successful: number;
}

export interface IOverviewHeaderFilters {
  filter: string;
  year?: number;
  month?: number;
}

export interface IOverviewUseInfo {
  active_template: IOverviewUseTemplates;
  active_entity: IOverviewUseEntity;
  total_number_page: number;
  total_number_field_processed: number;
  total_number_error: number;
}

export interface IOverviewUseTemplates {
  total: number;
  list: IOverviewList[];
}

export interface IOverviewUseEntity {
  list: IOverviewList[];
}

export interface IOverviewList {
  total: number;
  name: string;
  percent: number;
}

export interface IOverviewPerformanceInfo {
  average_time_requests: number;
  template_submision_requests: number;
  total_failed_type_error: IOverviewUseTemplates;
}

export interface IOverviewSubmisionInfo {
  id: number;
  name: string;
  entity_name: string;
  justification: string;
  example_location: string;
  created_at: string;
  country_name: string;
  country_code: string;
  organization_name: string;
  status_name: string;
}
