import { environment } from '@environment';

export function AppInitializer() {
  const _hotjarId = environment.hotjar_id;
  const _hotjarVersion = environment.hotjar_version;
  const _gtagId = environment.gtag_id;
  const _gtmId = environment.gtm_id;

  return async () => {
    // HOTJAR
    if (!!_hotjarId) {
      (function (h: any, o: any, t: any, j: any, a?: any, r?: any) {
        h.hj =
          h.hj ||
          function () {
            (h.hj.q = h.hj.q || []).push(arguments);
          };
        h._hjSettings = { hjid: _hotjarId, hjsv: _hotjarVersion || 6 };
        a = o.getElementsByTagName('head')[0];
        r = o.createElement('script');
        r.async = 1;
        r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
        a.appendChild(r);
      })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');
    } else {
      console.warn('WITHOUT INFORMATION IN HOTJAR');
    }

    // GTM
    if (_gtagId) {
      (function (w: any, d, s, l, i, n) {
        w[l] = w[l] || [];
        w[n] =
          w[n] ||
          function () {
            w[l].push(arguments);
          };
        w[n]('js', new Date());
        w[n]('config', _gtagId);
        var f = d.getElementsByTagName(s)[0],
          j = d.createElement(s),
          dl = l != 'dataLayer' ? '&l=' + l : '';
        j['async'] = true;
        j['src'] = 'https://www.googletagmanager.com/gtag/js?id=' + i + dl;
        f.parentNode.insertBefore(j, f);
      })(window, document, 'script', 'dataLayer', _gtagId, 'gtag');
    } else {
      console.warn('WITHOUT INFORMATION IN GTM');
    }

    if (!!_gtmId) {
      (function (w, d, s, l, i) {
        w[l] = w[l] || [];
        w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
        var f = d.getElementsByTagName(s)[0],
          j: any = d.createElement(s),
          dl = l != 'dataLayer' ? '&l=' + l : '';
        j.async = true;
        j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
        f.parentNode.insertBefore(j, f);
      })(window, document, 'script', 'dataLayer', _gtmId);

      const noscript = document.createElement('noscript');
      const iframe = document.createElement('iframe');
      iframe['src'] = `https://www.googletagmanager.com/ns.html?id=${_gtmId}`;
      iframe['height'] = '0';
      iframe['width'] = '0';
      iframe.setAttribute('style', 'display: none; visibility: hidden');
      noscript.appendChild(iframe);
      document.body.insertBefore(noscript, document.body.firstChild);
    }
  };
}
