<h5 mat-dialog-title [innerHTML]="title" *ngIf="title.length > 0"></h5>
<div mat-dialog-content [innerHTML]="message"></div>
<div mat-dialog-actions class="dialog-actions modal-buttons">
  <button
    (click)="onCancelClick()"
    *ngIf="infoButton.showButtonCancel"
    class="btn-primary"
  >
    <layout-icon class="icon" icon="faTimes"></layout-icon>
    {{ infoButton.textCancel }}
  </button>
  <button
    *ngIf="infoButton.showButtonOk"
    [ngClass]="{ 'button-center center': !infoButton.showButtonCancel }"
    cdkFocusInitial
    (click)="onOkClick()"
    [mat-dialog-close]="true"
    [class]="'btn-primary-bg '+ infoButton?.classNameOk"
  >
    <layout-icon class="icon" icon="faCheck"></layout-icon>
    {{ infoButton.textOk }}
  </button>
</div>
