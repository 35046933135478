import {
  Directive,
  Input,
  ElementRef,
  HostListener,
  Renderer2,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';

@Directive({
  selector: '[tooltip]',
})
export class TooltipDirective<U> {
  @Input('tooltip') tooltipTemplate: TemplateRef<any> | string;
  @Input('tooltipData') tooltipData: U;
  @Input() placement: 'top' | 'bottom' | 'left' | 'right';
  @Input() delay: number;
  tooltip: HTMLElement;
  offset = 10;

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    private _viewContainerRef: ViewContainerRef
  ) {}

  @HostListener('click') onClick() {
    if (!this.tooltip) {
      this.show();
      return;
    }
    this.hide();
  }

  @HostListener('mouseenter') onMouseEnter() {
    if (!this.tooltip) {
      this.show();
    }
  }

  @HostListener('mouseleave') onMouseLeave() {
    if (this.tooltip) {
      this.hide();
    }
  }

  show() {
    this.create();
    this.setPosition();
    this.renderer.addClass(this.tooltip, 'ng-tooltip-show');
  }

  hide() {
    this.renderer.removeClass(this.tooltip, 'ng-tooltip-show');
    window.setTimeout(() => {
      this.renderer.removeChild(document.body, this.tooltip);
      this.tooltip = null;
    }, this.delay);
  }

  create() {
    this.tooltip = this.renderer.createElement('div');

    if (this.tooltipTemplate instanceof TemplateRef) {
      // Template
      const embeddedViewRef = this._viewContainerRef.createEmbeddedView(
        this.tooltipTemplate,
        { row: this.tooltipData }
      );
      embeddedViewRef.detectChanges();

      embeddedViewRef.rootNodes.forEach((n) => {
        this.renderer.appendChild(this.tooltip, n);
      });
    } else {
      // Label
      this.renderer.appendChild(
        this.tooltip,
        this.renderer.createText(this.tooltipTemplate)
      );

      this.renderer.appendChild(document.body, this.tooltip);
    }

    this.renderer.appendChild(this.el.nativeElement, this.tooltip);

    this.renderer.addClass(this.tooltip, 'ng-tooltip');
    this.renderer.addClass(this.tooltip, `ng-tooltip-${this.placement}`);

    this.renderer.setStyle(
      this.tooltip,
      '-webkit-transition',
      `opacity ${this.delay}ms`
    );
    this.renderer.setStyle(
      this.tooltip,
      '-moz-transition',
      `opacity ${this.delay}ms`
    );
    this.renderer.setStyle(
      this.tooltip,
      '-o-transition',
      `opacity ${this.delay}ms`
    );
    this.renderer.setStyle(
      this.tooltip,
      'transition',
      `opacity ${this.delay}ms`
    );
  }

  setPosition() {
    const hostPos = this.el.nativeElement.getBoundingClientRect();
    const tooltipPos = this.tooltip.getBoundingClientRect();

    const scrollPos =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop ||
      0;

    let top, left;

    if (this.placement === 'top') {
      top = hostPos.top - tooltipPos.height - this.offset;
      left = hostPos.left + (hostPos.width - tooltipPos.width) / 2;
    }

    if (this.placement === 'bottom') {
      top = hostPos.bottom + this.offset;
      left = hostPos.left + (hostPos.width - tooltipPos.width) / 2;
    }

    if (this.placement === 'left') {
      top = hostPos.top + (hostPos.height - tooltipPos.height) / 2;
      left = hostPos.left - tooltipPos.width - this.offset;
    }

    if (this.placement === 'right') {
      top = hostPos.top + (hostPos.height - tooltipPos.height) / 2;
      left = hostPos.right + this.offset;
    }

    this.renderer.setStyle(this.tooltip, 'top', `${top + scrollPos}px`);
    this.renderer.setStyle(this.tooltip, 'left', `${left}px`);
  }
}
