import { Injectable } from '@angular/core';
import { IDialogParams } from '@app/core/models/dialog.model';
import { Store } from '@ngrx/store';

import { UIService } from './ui.service';

@Injectable({ providedIn: 'root' })
export class ErrorService {
  constructor(private _uiService: UIService, private _store: Store) {}
  public showGlobalError(message: string) {
    this._uiService.showSnackbar(message);
  }
  public showConfirmationError(message: string, callback?: Function) {
    if (callback) {
      callback = () => {}
    }
    const data = {
      message,
      callbacks: {
        ok: () => callback(),
      },
    } as IDialogParams;
    this._uiService.showDialog(data);
  }
}
