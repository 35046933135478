import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { SidebarModal } from '@app/core/class/sidebar-modal.class';
import { GA } from '@app/core/constants/google-analytics.constant';
import {
  Configuration,
  Group,
  InfoCountry,
} from '@app/core/models/insight.model';
import { InsightFacade } from '@app/facades/insight.facade';
import { SidebarModalRef } from '@app/layout/components/sidebar-modal/sidebar-modal.ref';
import { GoogleAnalyticsService } from '@app/services/google-analytics.service';
import { Observable, Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Country } from '@app/core/models/insight.model';


@Component({
  selector: 'insight-configuration-sidebar',
  templateUrl: './configuration-sidebar.component.html',
  styleUrls: ['./configuration-sidebar.component.scss'],
})
export class ConfigurationSidebarComponent
  extends SidebarModal
  implements OnInit, OnDestroy
{
  public form: FormGroup;
  public step$: Observable<number>;
  public infoCountry$: Observable<InfoCountry>;
  public loading$: Observable<boolean>;
  public error$: Observable<string>;
  public configuration$: Observable<Configuration>;
  public countries$: Observable<Country[]>;
  public unsubscribeSignal: Subject<boolean> = new Subject<boolean>();

  public country: string;
  public kpiGroups: Group[];
  public isEditing: boolean;
  public selectedConfig: Configuration;
  public values: Configuration;

  constructor(
    private _sidebarModalRef: SidebarModalRef,
    private _insightFacade: InsightFacade,
    private _gaService: GoogleAnalyticsService,
  ) {
    super(_sidebarModalRef);
  }

  ngOnInit(): void {
    this._setInitialValues();
  }

  onSubmit(config: Configuration) {
    this.values = config;

    if (this.isEditing) {
      this._insightFacade.submitEditConfiguration(this.values);
      return;
    }
    this._gaService.sendEvent(GA.CONFIGURATION.REQUEST);
    this._insightFacade.submitCreateConfiguration(this.values);
  }

  onGoBack(step: number) {
    this._insightFacade.changeStepConfiguration(step);
  }

  onCloseSidebar() {
    this._insightFacade.initConfigPage();
    this.sidebarModalRef.close();
  }

  private _setInitialValues() {
    const config = this.sidebarModalRef.getData() as Configuration;
    this.selectedConfig = config;
    this.isEditing = !!config?.config_id;
    this.step$ = this._insightFacade.configurationStep$;
    this.loading$ = this._insightFacade.isloadingForms$;
    this.error$ = this._insightFacade.errorConfigForms$;

    this._insightFacade.country$
      .pipe(takeUntil(this.unsubscribeSignal))
      .subscribe((response) => {
        this.country = response;
      });

    this.configuration$ = this._insightFacade.configuration$;
    this.countries$ = this._insightFacade.countries$;
    this.infoCountry$ = this._insightFacade.infoCountry$;
  }

  ngOnDestroy() {
    this.unsubscribeSignal.next(true);
    this._insightFacade.leaveSelectedConfig();
  }
}
