import { Product } from '../models/app.model';
import * as INSIGHT_CONSTANTS from './insigth.constant';
import * as FETCH_CONSTANTS from './fetch.constant';

export const INSIGHT = {
  backendName: 'insight',
  name: 'Insight',
  logoCollapsed: '/assets/img/logo/logo-insight-collapsed.svg',
  logo: '/assets/img/logo/logo-insight.svg',
  route: '/insight',
  menu: INSIGHT_CONSTANTS.MENU_INSIGHT,
  shortcuts: INSIGHT_CONSTANTS.SHORTCUTS_INSIGHT,
  statuses: INSIGHT_CONSTANTS.STATUSES,
  backgrounds: {
    imageLeft: './assets/img/layout/insight/box-layout-b-left.svg',
    imageRight: './assets/img/layout/insight/box-layout-b-right.svg',
  },
} as Product;

export const FETCH = {
  backendName: 'fetch',
  name: 'Fetch',
  route: '/fetch',
  menu: FETCH_CONSTANTS.MENU_FETCH,
  shortcuts: FETCH_CONSTANTS.SHORTCUTS_FETCH,
  backgrounds: {
    imageLeft: './assets/img/layout/fetch/box-layout-b-left.svg',
    imageRight: './assets/img/layout/fetch/box-layout-b-right.svg',
  },
} as Product;
