export const OVERVIEW_USE_SUCCESS = {
  data: {
    active_template: {
      total: 5,
      list: [
        {
          total: 1,
          name: 'Template_BANCO HSBC_Col_1',
          percent: 50,
        },
        {
          total: 1,
          name: 'Template_SANTANDER_Mex_1',
          percent: 45,
        },
        {
          total: 1,
          name: 'Template_BANCO SCOTIA_Mex_1',
          percent: 10,
        },
        {
          total: 2105,
          name: 'Template_Banco INTERACCIONES_Mex_1',
          percent: 99,
        },
        {
          total: 2,
          name: 'Template_BANREGIO_Mex_1',
          percent: 50,
        },
      ],
    },
    active_entity: {
      list: [
        {
          total: 2,
          name: 'BANREGIO',
          percent: 0.09,
        },
        {
          total: 1,
          name: 'SANTANDER',
          percent: 0.05,
        },
        {
          total: 2105,
          name: 'Banco INTERACCIONES',
          percent: 99.76,
        },
        {
          total: 1,
          name: 'BANCO HSBC',
          percent: 0.05,
        },
        {
          total: 1,
          name: 'BANCO SCOTIA',
          percent: 0.05,
        },
      ],
    },
    total_number_page: 0,
    total_number_field_processed: 1116,
    total_number_error: 17,
  },
  response_code: 200,
  errors: [],
};
