import { Injectable } from '@angular/core';
import { APP_CONSTANTS as CONST } from '@app/app.constants';
import { Token } from '@app/core/models/app.model';
import { SessionStorageService } from '@app/core/services/session-storage.service';
import { StorageService } from '@app/core/services/storage.service';

@Injectable({ providedIn: 'root' })
export class AuthService {
  constructor(
    private _storage: StorageService,
    private _sessionStorage: SessionStorageService
  ) {}

  saveToken(token: Token) {
    this._storage.setItem(CONST.PROP_STORAGE_TOKEN, token);
  }

  removeToken() {
    this._storage.removeItem(CONST.PROP_STORAGE_TOKEN);
    this._storage.removeItem(CONST.PROP_STORAGE_TOKEN_MOCK);
  }

  saveTokenPassword(token: Token) {
    this._storage.setItem(CONST.PROP_STORAGE_PASSWORD_TOKEN, token);
  }

  removeTokenPassword() {
    this._storage.removeItem(CONST.PROP_STORAGE_PASSWORD_TOKEN);
  }

  getToken() {
    return this._storage.getItem(CONST.PROP_STORAGE_TOKEN);
  }

  getTokenSync() {
    return this._storage.getValueSync(CONST.PROP_STORAGE_TOKEN);
  }

  saveFlagShowWelcomeModal() {
    this._sessionStorage.setItem(CONST.PROP_STORAGE_SHOW_WELCOME_MODAL, 1);
  }

  getIsOpenWelcomeModal() {
    return this._sessionStorage.getValueSync(
      CONST.PROP_STORAGE_SHOW_WELCOME_MODAL
    );
  }
}
