export const DOCUMENT_DETAILS_TRANSACTIONS_SUCCESS = {
  data: [
    {
      id: 220,
      data: {
        id: 1,
        fecha: '17 nov',
        saldo: '365.68',
        retiros: '',
        concepto: 'saldo anterior',
        depositos: '',
        table_name: 'table_0_product_0',
      },
    },
    {
      id: 221,
      data: {
        id: 1,
        fecha: '25 nov',
        saldo: '3.68',
        retiros: '362.00',
        concepto: 'deposito inversion perfiles',
        depositos: '',
        table_name: 'table_0_product_0',
      },
    },
    {
      id: 222,
      data: {
        id: 1,
        fecha: '25 nov',
        saldo: '365.68',
        retiros: '',
        concepto: 'traspaso ref 25089 de fo 0000009770042117',
        depositos: '362.00',
        table_name: 'table_0_product_0',
      },
    },
    {
      id: 223,
      data: {
        id: 1,
        fecha: '10 dic',
        saldo: '15,365.68',
        retiros: '',
        concepto: 'traspaso ref 234074 de fo 0000009770042117',
        depositos: '15,000.00',
        table_name: 'table_0_product_0',
      },
    },
    {
      id: 224,
      data: {
        id: 1,
        fecha: '10 dic',
        saldo: '7,365.68',
        retiros: '8,000.00',
        concepto:
          'pago interbancario a bbva bancomer al benef . mary cruz , garcia reyes dato no verificado por esta institucion cta.beneficiario 004152313615777104 clave rastreo 085902381830334495 ref . 0101219 prestamo mismo dia',
        depositos: '',
        table_name: 'table_0_product_0',
      },
    },
    {
      id: 225,
      data: {
        id: 1,
        fecha: '10 dic',
        saldo: '0.68',
        retiros: '7,365.00',
        concepto:
          'pago interbancario a bbva bancomer al benef . mary cruz , garcia reyes dato no verificado por esta institucion cta.beneficiario 004152313615777104 clave rastreo 085903339590334495 ref . 0101219 amex mismo dia',
        depositos: '',
        table_name: 'table_0_product_0',
      },
    },
    {
      id: 226,
      data: {
        id: 1,
        fecha: '13 dic',
        saldo: '25,907.07',
        retiros: '',
        concepto: 'abono nomina takeda administracion mexico , s.a. d',
        depositos: '25,906.39',
        table_name: 'table_0_product_0',
      },
    },
    {
      id: 227,
      data: {
        id: 1,
        fecha: '13 dic',
        saldo: '21,907.07',
        retiros: '4,000.00',
        concepto:
          'pago interbancario a bbva bancomer al benef . mary cruz , garcia reyes dato no verificado por esta institucion cta.beneficiario 004152313615777104 clave rastreo 085902193950334796 ref . 0131219 varios mismo dia',
        depositos: '',
        table_name: 'table_0_product_0',
      },
    },
    {
      id: 228,
      data: {
        id: 1,
        fecha: '13 dic',
        saldo: '907.07',
        retiros: '21,000.00',
        concepto: 'deposito inversion perfiles',
        depositos: '',
        table_name: 'table_0_product_0',
      },
    },
    {
      id: 229,
      data: {
        id: 1,
        fecha: '16 dic',
        saldo: '807.07',
        retiros: '100.00',
        concepto: 'deposito inversion perfiles',
        depositos: '',
        table_name: 'table_0_product_0',
      },
    },
  ],
  response_code: 200,
  errors: null,
  total: 39,
  total_unfiltered: 39,
  page: 0,
  size: 10,
};
