import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { Menu, MenuItem } from '@app/core/models/menu.model';
import {
  faAngleDoubleLeft,
  IconDefinition,
} from '@fortawesome/free-solid-svg-icons';
import { animate, style, transition, trigger } from '@angular/animations';
import { AppFacade } from '@app/facades/app.facade';

@Component({
  selector: 'layout-menu-sidebar',
  templateUrl: './menu-sidebar.component.html',
  styleUrls: ['./menu-sidebar.component.scss'],
  animations: [
    trigger('productLogoAnimation', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('200ms ease-in', style({ opacity: 1 })),
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('200ms ease-out', style({ opacity: 0 })),
      ]),
    ]),
  ],
})
export class MenuSidebarComponent implements OnInit, OnChanges, OnDestroy {
  @Input() menu: Menu;
  @Input() typeSidebar: 'FIXED' | 'FLOAT';
  @Input() isCollapsed: boolean;
  @Output() logout: EventEmitter<boolean>;

  subMenuActive: boolean = false;

  public faIcon: IconDefinition;
  public optionOpened: string;
  public faTime: IconDefinition;

  private destroyed$ = new Subject();
  private _routerSubs: Subscription;
  private _currenURL: string;

  constructor(private _router: Router, private _appfacade: AppFacade) {
    this.logout = new EventEmitter();
    this._setupCheckSelectedOptionMenu();
  }

  ngOnInit(): void {
    this.faIcon = faAngleDoubleLeft;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.isCollapsed && this.isCollapsed) {
      this.optionOpened = null;
      this.isSubMenuOptionSelected();
    }
  }

  ngOnDestroy() {
    if (this._routerSubs) this._routerSubs.unsubscribe();
    this.destroyed$.next();
  }

  public goTo(url: string) {
    this._appfacade.closeBottomBar();
    this._router.navigateByUrl(url);
  }

  public onToggleSubMenu(active: boolean, path: string) {
    this.isCollapsed = false;
    this.expadeOrCollapseMenu();
    if (!active) return;
    this.goTo(path);
  }

  public isSubMenuOptionSelected(): boolean {
    let subMenuOptionsPaths;
    this.menu.items.map((item) => {
      if (item.type !== 'item-accordeon') return;
      const accordeon = item;
      if (accordeon?.item.type !== 'sub-menu') return;
      const subMenu = accordeon?.item;
      const subMenuOptions = subMenu?.items;
      subMenuOptionsPaths = subMenuOptions
        .filter((item) => {
          return item?.path;
        })
        .map((item) => item?.path);
    });
    if (subMenuOptionsPaths) {
      this.subMenuActive = !!subMenuOptionsPaths.filter((item) =>
        this._currenURL?.includes(item)
      ).length;
    }
    return this.subMenuActive;
  }

  public isOptionSelected(url: string) {
    return this._currenURL?.includes(url);
  }

  public isOptionOpened(option: MenuItem) {
    return option.label === this.optionOpened;
  }

  public onToggleOption(option: MenuItem) {
    if (!this.isCollapsed) {
      this.optionOpened = this.isOptionOpened(option) ? null : option.label;
    }
  }

  public onToggle() {
    this.isCollapsed = !this.isCollapsed;
    this.expadeOrCollapseMenu();
  }

  public onClickOption() {
    this.optionOpened = '';
  }

  public onLogout() {
    this.logout.emit(true);
  }

  private _setupCheckSelectedOptionMenu() {
    this._routerSubs = this._router.events
      .pipe(filter((e) => e instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => (this._currenURL = event.url));
  }

  private expadeOrCollapseMenu() {
    if (!this.isCollapsed) {
      this._appfacade.expandMenu();
    } else {
      this._appfacade.collapseMenu();
    }
  }
}
