export const TEMPLATES_VERSION_SUCCESS = {
  data: [
    {
      id: 1,
      name: 'v1',
      description: [
        {
          name: 'bank_name',
          description: 'bank_description',
          metadata: {
            example_value: 'This is only a mock sructure',
          },
        },
        {
          name: 'bank_name',
          description: 'bank_description',
          metadata: {
            example_value: 'This is only a mock sructure',
          },
        },
      ],
      preview_url: './assets/documents/end_user_license_agreement.pdf',
      entity_name: 'Banamex',
    },
    {
      id: 2,
      name: 'v2',
      description: [
        {
          name: 'bank_name',
          description: 'bank_description',
          metadata: {
            example_value: 'This is only a mock sructure',
          },
        },
        {
          name: 'bank_name',
          description: 'bank_description',
          metadata: {
            example_value: 'This is only a mock sructure',
          },
        },
      ],
      preview_url: './assets/documents/end_user_license_agreement.pdf',
      entity_name: 'Banamex 2',
    },
  ],
  response_code: null,
  errors: null,
  total: 1,
  page: 0,
  size: 10,
};
