import { Injectable } from '@angular/core';
import { GAEvent } from '@app/core/models/google-analytics';

declare let gtag: any;

@Injectable({
  providedIn: 'root',
})
export class GoogleAnalyticsService {
  constructor() {}

  sendEvent(event: GAEvent) {
    const strLabel = event?.label ? "-" + event?.label : "";
    const customEvent = `${event?.category}-${event?.action}${strLabel}`;

    gtag('event', customEvent);
  }
}
