import { Component, Input } from '@angular/core';
import { BUTTON_SIZE } from '@app/mo-forms/enums/button.type';
import { COLOR_TEMPLATE } from '@app/mo-forms/enums/input.type';

@Component({
  selector: 'mo-loading',
  templateUrl: './mo-loading.component.html',
  styleUrls: ['./mo-loading.component.scss'],
})
export class MoLoadingComponent {
  @Input() size: BUTTON_SIZE;
  @Input() color: COLOR_TEMPLATE;
}
