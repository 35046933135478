import { TemplateRef } from '@angular/core';

export const ROWS_PER_PAGE = 10;
export const defaultFilters = {
  page: '0',
  size: ROWS_PER_PAGE.toString(),
};

export enum TableVariationEnum {
  COMMON = 'common',
  CARD = 'card',
}

export type TableVariation = TableVariationEnum;

export const RowsPerPage = [
  { value: 10, text: '10' },
  { value: 20, text: '20' },
  { value: 50, text: '50' },
];
export interface Column {
  title: string;
  attribute?: string;
  hasCopyAction?: boolean;
  messageCopyAction?: string;
  isSortable?: boolean;
  typeSort?: 'string' | 'number' | 'date';
  transform?: Function;
  className?: string;
  type?: 'BUTTON' | 'ICON';
  icon?: string;
  iconType?: 'image' | 'material' | 'fontAwesome' | 'mo';
  width?: string;
  callback?: Function;
  callbackChange?: Function;
  template?: TemplateRef<ContextColumn>;
}

export interface ContextColumn {
  item: any;
  column: Column;
  onChange: Function;
  onClick: Function;
  onCopyValue: Function;
}

export interface ColumnSort {
  column?: string;
  typeSort?: 'string' | 'number' | 'date';
  direction: 'ASC' | 'DESC';
}

export interface PagedResponse<T> {
  total: number;
  page: number;
  size: number;
  data: T;
  total_unfiltered?: number;
}

export interface OrderFilter {
  sort?: string;
  order_by?: string;
}

export interface Filters {
  page?: string;
  size?: string;
  sort?: string;
  order_by?: string;
}

/// TODO: Delete these 2 interfaces
export interface MetaData {
  total_pages: number | null;
}

export interface LinkData {
  self: string | null;
  first: string | null;
  prev: string | null;
  next: string | null;
  last: string | null;
}

export interface ContextPaginator {
  pageLength: number;
  dataLength: number;
  rowPerPage: number;
  // totalPages: number;
  currentPage: number;
  onChange(): void;
}

export interface ContextTable<T> {
  data: T;
  columns: Column[];
  onSort(): void;
}

export interface ContextFilter<T> {
  onFilter(): void;
  onClick?: Function;
  data?: T;
}
