export enum INPUT_SIZE {
  SM = 'size-sm',
  MD = 'size-md',
  LG = 'size-lg',
}

export enum ICON_POSITION {
  LEFT = 'left',
  RIGTH = 'right',
}
export enum COLOR_TEMPLATE {
  DEFAULT = 'default',
  LIGHT = 'light',
  PRIMARY = 'primary',
  INDIGO = 'indigo',
}
