import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { FILE_TYPE } from '@app/core/enums/file.type';

export class CustomValidators {
  static isNumber(control: AbstractControl): { [key: string]: boolean } {
    if (isNaN(parseInt(control.value))) {
      return { invalid_number: true };
    }
    return null;
  }
  static maxSizeFile(size: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (size <= control?.value?.size) {
        return { invalid_file_size: true };
      }

      return null;
    };
  }

  static allowedExtensions(extensions: Array<FILE_TYPE>): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.value?.type) {
        const match = extensions.filter(
          (extension) => extension === control.value?.type
        );
        if (match && match.length > 0) {
          return null;
        } else {
          return { invalid_file_extension: true };
        }
      }

      return null;
    };
  }

  static isMatch(controlNameMatch: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const otherValueToValidation = control?.parent?.controls[
        controlNameMatch
      ] as AbstractControl;

      if (!otherValueToValidation?.value || !control?.value) {
        return null;
      }

      if (control?.value !== otherValueToValidation.value) {
        const error = {};
        error[`invalid_match_${controlNameMatch.toLowerCase()}`] = true;

        return error;
      }

      return null;
    };
  }
}
