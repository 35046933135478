import { KPIS_SUCCESS } from '@app/core/mocks/data/insight/kpis.mock';
import { ROWS_PER_PAGE } from '@app/core/models/table.model';
import { PAGE_SIZE_DEFAULT } from '@app/mo-table/constants/page-size.constant';
import { createReducer, on, Action } from '@ngrx/store';
import * as insightActions from './insight.actions';
import { InsightState } from './insight.state';

export const initialInsightState: InsightState = {
  apiKey: null,
  filtersConfigs: {
    size: PAGE_SIZE_DEFAULT.toString(),
  },
  isLoadingConfigs: false,
  parents: null,
  kpis: null,
  parentKpis: null,
  selectParent: null,
  isLoadingParents: false,
  isLoadingConfigsRequests: false,
  isLoadingKpis: false,
  filtersKPIs: {
    size: PAGE_SIZE_DEFAULT.toString(),
  },
  currentKPIPage: null,
  countries: null,
  country: null,
  infoCountry: null,
  response: null,
  isLoadingPage: false,
  configuration: null,
  configurations: [],
  selectedConfig: null,
  configurationStep: 1,
  support: null,
  isSuccessSupport: false,
  isLoadingForms: false,
  filtersPremium: {
    size: PAGE_SIZE_DEFAULT.toString(),
  },
  premiumKPIsParents: null,
  premiumKpis: null,
  isLoadingPremiumKPIsParents: false,
  requestRemaining: null,
  daysLeft: null,
};

const _insightReducer = createReducer(
  initialInsightState,
  on(insightActions.responseConsultApi, (state, { response }) => {
    return {
      ...state,
      apiKey: response,
      isLoadingPage: false,
    };
  }),
  on(insightActions.failureConsultApi, (state, { error }) => {
    return {
      ...state,
      response: error.message,
      isLoadingPage: false,
    };
  }),
  on(insightActions.responseCountries, (state, { countries }) => {
    return { ...state, countries };
  }),
  on(insightActions.responseInfoCountry, (state, { infoCountry }) => {
    return { ...state, infoCountry };
  }),
  // TODO: Remove when all design is stable
  // on(insightActions.initConfigPage, (state) => {
  //   return { ...state, isLoadingHome: true };
  // }),
  on(insightActions.changeCountryKPI, (state, { country }) => {
    return { ...state, country, isLoadingConfigsRequests: true };
  }),
  on(insightActions.changeCountrySlideBar, (state, { country }) => {
    return { ...state, country, isLoadingConfigsRequests: true };
  }),
  on(insightActions.responseGetConfigs, (state, { response }) => {
    return { ...state, configurations: response };
  }),
  on(insightActions.failureGetConfigs, (state, { error }) => {
    return { ...state, configurations: [] };
  }),
  on(insightActions.initConfigPage, (state) => {
    return { ...state, isLoadingConfigs: true, currentKPIPage: 'CONFIG' };
  }),
  on(insightActions.responseGetConfigsDetails, (state, { response }) => {
    return {
      ...state,
      configurationsDetails: response,
      isLoadingConfigs: false,
    };
  }),
  on(insightActions.failureGetConfigsDetails, (state, { error }) => {
    return { ...state, configurationsDetails: null, isLoadingConfigs: false };
  }),
  on(insightActions.changeFiltersConfigs, (state, { filters }) => {
    return { ...state, isLoadingConfigs: true, filtersConfigs: filters };
  }),
  on(insightActions.initSelectedConfig, (state, { configuration }) => {
    return { ...state, selectedConfig: configuration };
  }),
  on(insightActions.leaveSelectedConfig, (state) => {
    return {
      ...state,
      selectedConfig: null,
      configurationStep: 1,
      isLoadingForms: false,
      errorConfigForms: undefined,
    };
  }),
  on(insightActions.createConfigurationSubmit, (state) => {
    return { ...state, isLoadingForms: true };
  }),
  on(insightActions.responseCreateConfiguration, (state, { configuration }) => {
    return {
      ...state,
      configuration,
      configurationStep: 2,
      isLoadingForms: false,
    };
  }),
  on(insightActions.failureCreateConfiguration, (state, { error }) => {
    return {
      ...state,
      response: error.message,
      configurationStep: 2,
      isLoadingForms: false,
      errorConfigForms: error.message,
    };
  }),
  on(insightActions.editConfigurationSubmit, (state) => {
    return { ...state, isLoadingForms: true };
  }),
  on(insightActions.responseEditConfiguration, (state, { configuration }) => {
    return {
      ...state,
      configuration,
      configurationStep: 2,
      isLoadingForms: false,
    };
  }),
  on(insightActions.failureEditConfiguration, (state, { error }) => {
    return { ...state, response: error.message, isLoadingForms: false };
  }),
  on(insightActions.changeStepConfiguration, (state, { step }) => {
    return { ...state, configurationStep: step };
  }),

  on(insightActions.initLibraryPage, (state) => {
    return { ...state, isLoadingParents: true, currentKPIPage: 'LIBRARY' };
  }),
  on(insightActions.changeFiltersLibrary, (state, { filters }) => {
    return { ...state, isLoadingParents: true, filtersKPIs: filters };
  }),
  on(insightActions.responseGetParents, (state, { response }) => {
    return { ...state, parents: response, isLoadingParents: false };
  }),
  on(insightActions.responseGetKpis, (state, { response }) => {
    return { ...state, kpis: response, isLoadingParents: false };
  }),
  on(insightActions.failureGetParents, (state, { error }) => {
    return { ...state, parents: null, isLoadingParents: false };
  }),
  on(insightActions.selectParent, (state, { selectParent }) => {
    return { ...state, selectParent, isLoadingKpis: true };
  }),
  on(insightActions.responseParentKpi, (state, { parentKpis }) => {
    const newParentKpis = { ...state.parentKpis, ...parentKpis };
    return { ...state, parentKpis: newParentKpis, isLoadingKpis: false };
  }),
  on(insightActions.failureSendSupport, (state, { error }) => {
    return { ...state, error, isLoadingForms: false };
  }),
  on(insightActions.sendSupportSubmit, (state, { support }) => {
    return { ...state, support, isLoadingForms: true };
  }),
  on(insightActions.responseSendSupport, (state) => {
    return { ...state, isLoadingForms: false, isSuccessSupport: true };
  }),

  on(insightActions.sendUpgradePlanSubmit, (state, { support }) => {
    return { ...state, support, isLoadingForms: true };
  }),
  on(insightActions.responseUpgradePlanSubmit, (state) => {
    return { ...state, isLoadingForms: false, isSuccessSupport: true };
  }),
  on(insightActions.failureUpgradePlanSubmit, (state, { error }) => {
    return { ...state, error, isLoadingForms: false };
  }),

  on(insightActions.leaveSuccessSuport, (state) => {
    return { ...state, isSuccessSupport: false };
  }),
  on(insightActions.failureGetKpis, (state) => {
    return { ...state, kpis: null };
  }),
  on(insightActions.initPremiumPage, (state) => {
    return { ...state, isLoadingPremiumKPIsParents: true };
  }),
  on(insightActions.changeFiltersPremium, (state, { filters }) => {
    return {
      ...state,
      isLoadingPremiumKPIsParents: true,
      filtersPremium: filters,
    };
  }),
  on(insightActions.selectPremiumParent, (state, { selectParent }) => {
    return { ...state, selectParent, isLoadingPremiumKPIsParents: true };
  }),
  on(insightActions.responseGetPremiumKPIsParents, (state, { response }) => {
    return {
      ...state,
      premiumKPIsParents: response,
      isLoadingPremiumKPIsParents: false,
    };
  }),
  on(insightActions.failureGetPremiumKPIsParents, (state, { error }) => {
    return {
      ...state,
      premiumKPIsParents: null,
      isLoadingPremiumKPIsParents: false,
    };
  }),
  on(insightActions.setDaysLeft, (state, { daysLeft }) => {
    return { ...state, daysLeft };
  }),
  on(insightActions.setRequestRemaining, (state, { requestRemaining }) => {
    return { ...state, requestRemaining };
  })
);

export function InsightReducers(
  state: InsightState | undefined,
  action: Action
) {
  return _insightReducer(state, action);
}
