import { NgModule, LOCALE_ID, APP_INITIALIZER } from '@angular/core';
import { registerLocaleData, CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import {
  BrowserModule,
  HAMMER_GESTURE_CONFIG,
  HammerModule,
} from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import locale from '@angular/common/locales/en';

import { AppInterceptorService } from '@core/interceptor/app-interceptor.service';
import { AppMockInterceptorService } from '@core/interceptor/app-mock-interceptor.service';
import { AppTrackInterceptorService } from '@core/interceptor/app-track-interceptor.service';
import { AppInitializer } from '@core/services/app-initializer.service';
import { MyHammerGestureConfig } from '@core/hammer-gesture-config';

import { AppRoutingModule } from './app-routing.module';
import { LayoutModule } from './layout/layout.module';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import { appEffects, appReducers } from '@store/index';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { NgxJsonViewerModule } from 'ngx-json-viewer';

import { AppComponent } from './app-component/app.component';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { ChartsModule } from 'ng2-charts';

@NgModule({
  declarations: [AppComponent],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    HammerModule,
    AppRoutingModule,
    LayoutModule,
    NgxJsonViewerModule,
    NgxExtendedPdfViewerModule,
    ChartsModule,
    StoreModule.forRoot(appReducers),
    EffectsModule.forRoot(appEffects),
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      logOnly: environment.production, // Restrict extension to log-only mode
    }),
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'en' },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AppMockInterceptorService,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AppInterceptorService,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AppTrackInterceptorService,
      multi: true,
    },
    { provide: HAMMER_GESTURE_CONFIG, useClass: MyHammerGestureConfig },
    { provide: APP_INITIALIZER, useFactory: AppInitializer, multi: true },
    // { provide: Sentry.TraceService, deps: [Router] },
    // {
    //   provide: APP_INITIALIZER,
    //   useFactory: () => () => {},
    //   deps: [Sentry.TraceService],
    //   multi: true,
    // },
    // { provide: ErrorHandler, useClass: SentryErrorHandler },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor() {}
}
registerLocaleData(locale, 'en');
