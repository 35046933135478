import { FormGroup, ValidatorFn } from "@angular/forms";
import { APP_CONSTANTS } from '@app/app.constants';

const KPIGroupsValidator: ValidatorFn = (form: FormGroup) => {
  const kpiGroups = form.get("kpi_groups").value;
  const isSelectedKPI = kpiGroups.filter(group => group === true);
  const error = {
    kpi_selected: {
      message: APP_CONSTANTS.MESSAGES.ERROR_SELECTED_KPI,
      control: 'kpi_groups',
    }
  };
  return (isSelectedKPI.length > 0) ? null : error;
};

export default KPIGroupsValidator;
