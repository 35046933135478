import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PrimaryItemComponent } from './components/primary-item/primary-item.component';
import { MenuSidebarComponent } from './menu-sidebar.component';
import { TitleComponent } from './components/title/title.component';
import { AccordeonItemComponent } from './components/accordeon-item/accordeon-item.component';
import { SubMenuItemComponent } from './components/sub-menu/sub-menu-item/sub-menu-item.component';
import { SubMenuListComponent } from './components/sub-menu/sub-menu-list/sub-menu-list.component';
import { SubMenuTitleComponent } from './components/sub-menu/sub-menu-title/sub-menu-title.component';

const COMPONENTS = [
  PrimaryItemComponent,
  MenuSidebarComponent,
  TitleComponent,
  AccordeonItemComponent,
  SubMenuItemComponent,
  SubMenuListComponent,
  SubMenuTitleComponent,
];

@NgModule({
  declarations: COMPONENTS,
  exports: COMPONENTS,
  imports: [CommonModule],
})
export class MenuSidebarModule {}
