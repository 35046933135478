import {
  Directive,
  EventEmitter,
  Output,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { ContextFilter } from '@app/core/models/table.model';

@Directive({
  selector: '[tableFilter]',
})
export class FilterDirective {
  constructor(public templateRef: TemplateRef<ContextFilter<any>>) {}
}
