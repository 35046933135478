import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, pluck } from 'rxjs/operators';
import { ResponseInitial } from '@app/core/models/user.model';
import { APP_CONSTANTS as CONST } from '@app/app.constants';

@Injectable({ providedIn: 'root' })
export class UserAPI {
  constructor(private _http: HttpClient) {}

  public getUserInfo(): Observable<ResponseInitial> {
    return this._http.get<ResponseInitial>(CONST.URLS.ORGANIZATIONS).pipe(
      catchError((e) => throwError(e)),
      pluck('data')
    );
  }
}
