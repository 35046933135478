import {
  Component,
  ElementRef,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { COLOR_TEMPLATE, INPUT_SIZE } from '@app/mo-forms/enums/input.type';
import { ISelectOption } from '@app/mo-forms/interfaces/select-option.interface';
import { FormUtilService } from '@app/mo-forms/services/form-util.service';

@Component({
  selector: 'mo-drop-down',
  templateUrl: './mo-drop-down.component.html',
  styleUrls: ['./mo-drop-down.component.scss'],
})
export class MoDropDown implements OnInit, OnChanges {
  @Input() form!: FormGroup;
  @Input() controlName!: string;
  @Input() template: COLOR_TEMPLATE;
  @Input() size: INPUT_SIZE;
  @Input() options: Array<ISelectOption<string>> = [];
  @Input() defaultOptionName: string = 'Default';
  @Input() label: string;
  @Input() controlWithoutSpaces: boolean;
  @Input() controlWithMiddleSpace: boolean;

  public isOpen = false;
  public TEMPLATES = COLOR_TEMPLATE;
  public selectedValue: string;

  @HostListener('document:click', ['$event'])
  clickout() {
    if (this.eRef.nativeElement.contains(event.target)) {
      this.isOpen = !this.isOpen;
    } else {
      this.isOpen = false;
    }
  }

  constructor(
    private eRef: ElementRef,
    public formUtilService: FormUtilService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (
      this.form.get(this.controlName) &&
      this.form.get(this.controlName).value !== null
    ) {
      if (changes && changes?.options && this.options?.length > 0) {
        this.selectOption(this.options[0]);
      }
    }
  }

  ngOnInit() {
    if (
      this.form.get(this.controlName) &&
      this.form.get(this.controlName).value
    ) {
      const value = this.form.get(this.controlName).value;
      const selectedOption = this.options
        .filter((option) => option.value === value)
        .shift();
      this.selectOption(selectedOption);
    }
  }

  public selectOption(option: ISelectOption<string>) {
    this.selectedValue = option.key;
    this.form.get(this.controlName).setValue(option.value);
  }
}
