<div
  class="bg-form-group"
  [ngClass]="{ 'with-out-spaces': controlWithoutSpaces }"
>
  <label class="mo-forms">{{ label }}</label>
  <div
    [class]="
      'custom-select ' +
      form.get(controlName).status.toLocaleLowerCase() +
      ' ' +
      template +
      ' ' +
      size
    "
    [formGroup]="form"
    [ngClass]="{
      'error-control': formUtilService.validErrors(form, controlName)
    }"
  >
    <div class="relative-box select-bg">
      <select
        #customSelect
        [formControlName]="controlName"
        (change)="onChange()"
      >
        <option *ngIf="defaultOptionName" value="" selected="selected">
          {{ defaultOptionName }}
        </option>
        <option
          *ngFor="let option of options"
          class="item-option"
          [value]="option?.value"
          [selected]="option?.selected ? 'selected' : ''"
        >
          {{ option.key }}
        </option>
      </select>
      <i [class]="'icon uil uil-angle-down ' + size"></i>
    </div>
  </div>
  <mo-error
    *ngIf="formUtilService.validErrors(form, controlName)"
    [error]="formUtilService.renderKeyError(form.get(controlName)?.errors)"
  ></mo-error>
</div>
