export const DOWNLOAD_URL_SUCCESS = {
  data: {
    total_requests: 4,
    approved_requests: 4,
    pending_requests: 0,
    rejected_requests: 0,
    last_requests: [
      {
        request_id: '11b1f491-1a97-46f5-bbcb-19b8f8e927ba',
        creation_date: '2021-04-09 16:31:06',
        config_id: '2fcb56d6-b86d-4ff0-87db-cece8d8e42ee',
        status_key: 'comp',
        status_name: 'Completed',
      },
      {
        request_id: 'a2330ee8-637e-4e52-be62-9d5421fcc77a',
        creation_date: '2021-04-09 17:29:02',
        config_id: '2fcb56d6-b86d-4ff0-87db-cece8d8e42ee',
        status_key: 'comp',
        status_name: 'Completed',
      },
      {
        request_id: 'a740ab21-3d5c-4763-a22b-11b799c67f8e',
        creation_date: '2021-04-08 22:57:38',
        config_id: '2fcb56d6-b86d-4ff0-87db-cece8d8e42ee',
        status_key: 'comp',
        status_name: 'Completed',
      },
      {
        request_id: 'd713c7fb-59ad-467b-844a-d23514649e04',
        creation_date: '2021-04-09 17:24:51',
        config_id: '2fcb56d6-b86d-4ff0-87db-cece8d8e42ee',
        status_key: 'comp',
        status_name: 'Completed',
      },
    ],
  },
  response_code: 200,
  errors: [],
};
