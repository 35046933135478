import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BoxInfoCallBack } from './box-info-callback.interface';
import { BOX_INFO_TEMPLATE_TYPE } from './box-info-template.type';

@Component({
  selector: 'box-info-component',
  templateUrl: './box-info.component.html',
  styleUrls: ['./box-info.component.scss'],
})
export class BoxInfoComponent {
  @Input() template: BOX_INFO_TEMPLATE_TYPE;
  @Input() icon: string;
  @Input() textCallback: string;
  @Output() clickedMoreInfo = new EventEmitter<any>();

  public triggerCallback() {
    this.clickedMoreInfo.emit();
  }
}
