import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { fas } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'layout-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss'],
})
export class IconComponent implements OnChanges {
  @Input() type: 'image' | 'material' | 'fontAwesome' | 'mo';
  @Input() icon: string;
  @Input() color: string;
  @Input() size: string;

  constructor() {
    if (!this.type) this.type = 'material';
    if (!this.color) this.color = 'transparent';
  }

  ngOnChanges(changes: SimpleChanges): void {
    const isDefaultType = !this.type || this.type === 'material';
    if (changes.icon && !!this.icon && isDefaultType) {
      const isFontAwesome = this.icon.startsWith('fa');
      const isMOIcon = this.icon.startsWith('mo-');
      if (isFontAwesome) this.type = 'fontAwesome';
      if (isMOIcon) this.type = 'mo';
    }
  }

  public getIcon(name: string) {
    return fas[name];
  }
}
