<div class="response-container">
  <layout-confirm-message
    *ngIf="!error"
    title="Congratulations! "
    description="Your download was successfully created."
  >
  </layout-confirm-message>
  <form-input-clipboard
    [inputInfo]="request?.request_id"
  ></form-input-clipboard>
  <layout-confirm-message *ngIf="error" [description]="error" type="fail">
  </layout-confirm-message>

  <div class="button-content">
    <mo-button-form
      (click)="onCloseBottomBar()"
      class="m-t-25 block-box"
      [text]="'Close'"
      [template]="BUTON_TEMPLATES.OUTLINE_PRIMARY"
      [size]="BUTTON_SIZE.MD"
    ></mo-button-form>
    <mo-button-form
      *ngIf="!error"
      (click)="onGotoRequests()"
      class="m-t-25 block-box"
      [text]="'Go to requests'"
      [template]="BUTON_TEMPLATES.PRIMARY"
      [size]="BUTTON_SIZE.MD"
    ></mo-button-form>
    <mo-button-form
      *ngIf="error"
      (click)="onCloseInfoComponent()"
      class="m-t-25 block-box"
      [text]="'New request'"
      [template]="BUTON_TEMPLATES.PRIMARY"
      [size]="BUTTON_SIZE.MD"
    ></mo-button-form>
  </div>
</div>
