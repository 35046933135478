<div class="box-content-banner-remaining">
  <div class="block-info" *ngIf="requestRemaining$ | async">
    <h3>{{ requestRemaining$ | async }}</h3>
    <p>Requests remaining</p>
  </div>
  <div
    class="block-info"
    *ngIf="(daysLeft$ | async) !== null && (daysLeft$ | async) !== undefined"
  >
    <h3>{{ daysLeft$ | async }}</h3>
    <p>Days left</p>
  </div>
</div>
