import { Component, Input } from '@angular/core';
import {
  BUTON_TYPE,
  BUTTON_SIZE,
  BUTTON_TEMPLATE,
} from '@app/mo-forms/enums/button.type';
import { ICON_POSITION } from '@app/mo-forms/enums/input.type';

@Component({
  selector: 'mo-button-form',
  templateUrl: './mo-button-form.component.html',
  styleUrls: ['./mo-button-form.component.scss'],
})
export class MoButtonFormComponent {
  @Input() text: string;
  @Input() isLoading: boolean;
  @Input() isDisabled: boolean;
  @Input() icon: string;
  @Input() iconPosition: ICON_POSITION;
  @Input() template: BUTTON_TEMPLATE;
  @Input() size: BUTTON_SIZE;
  @Input() type: BUTON_TYPE = BUTON_TYPE.BUTTON;

  public ICON_POSITIONS = ICON_POSITION;
}
