<div class="bg-mo-chips">
  <label>{{ label }}</label>
  <div
    class="mo-chip-option"
    (click)="selectedItem(option)"
    *ngFor="let option of options"
    [ngClass]="{ active: option.value }"
  >
    {{ option.label }}
    <i *ngIf="option.value" class="uil uil-times"></i>
  </div>
</div>
