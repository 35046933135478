import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from '@angular/router';
import { APP_CONSTANTS as CONST } from '@app/app.constants';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { StorageService } from '../services/storage.service';

@Injectable({
  providedIn: 'root',
})
export class UnloggedGuard implements CanActivate {
  constructor(
    private _router: Router,
    private _storageService: StorageService
  ) {}

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | boolean {
    return this._auxiliar(route, state);
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | boolean {
    return this._auxiliar(route, state);
  }

  private _auxiliar(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | boolean {
    const token$ = this._storageService.getItem(CONST.PROP_STORAGE_TOKEN);

    return token$.pipe(
      map((token) => {
        if (token) this._router.navigateByUrl(CONST.ROUTES.HOME);
        return !token;
      })
    );
  }
}
