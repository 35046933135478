export const KPIS_SUCCESS = {
  data: [
    {
      kpi_parent: 'ATM',
      kpis: [
        {
          kpi_target: 'total',
          kpi_key: 'atm_total',
          statistic: 'sum',
          last_updated: '2021 / 05',
          refresh_rate: 'continuously',
          group_name: 'Commerce',
          subgroup_name: 'Banking',
          has_heatmap: true,
        },
        {
          kpi_target: 'total',
          kpi_key: 'atm_fraction',
          statistic: 'fraction',
          last_updated: '2021 / 05',
          refresh_rate: 'continuously',
          group_name: 'Commerce',
          subgroup_name: 'Banking',
          has_heatmap: true,
        },
      ],
    },
  ],
  response_code: null,
  errors: [],
  total: 1,
  page: 0,
  size: 1,
  total_unfiltered: 12,
};
