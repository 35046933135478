import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Catalog } from '@app/core/models/user.model';
import { Filters, PagedResponse } from '@app/core/models/table.model';
import {
  Request,
  RequestForm,
  RequestSuccess,
  RequestSummary,
  RequestDownload,
} from '@app/core/models/request.model';
import {
  Configuration,
  ConfigurationFilters,
  ConfigurationResponse,
  Country,
  InfoCountry,
  KPIParent,
  KPIFilters,
  ResponseApiKey,
  Support,
  KPI,
  ParentKpis,
  PremiumKPIParent,
  PremiumFilters,
  BatchFile,
} from '@core/models/insight.model';
import { Store } from '@ngrx/store';
import * as appSelectors from '@store/app/app.selectors';
import * as insightActions from '@store/insight/insight.actions';
import * as insightSelectors from '@store/insight/insight.selectors';

import * as requestActions from '@store/insight-request/insight-request.actions';
import * as requestSelectors from '@store/insight-request/insight-request.selectors';

import * as mapActions from '@store/insight-map/insight-map.actions';
import { IStatusInfo } from '@app/core/models/status-info.model';

@Injectable({ providedIn: 'root' })
export class InsightFacade {
  public countries$: Observable<Country[]>;
  public country$: Observable<string>;
  public infoCountry$: Observable<InfoCountry>;

  public isLoadingHome$: Observable<boolean>;
  public isLoadingRequests$: Observable<boolean>;
  public isLoadingConfigsRequests$: Observable<boolean>;
  public isLoadingDownloadFile$: Observable<boolean>;
  public requests$: Observable<PagedResponse<Request>>;
  public requestSummary$: Observable<RequestSummary>;
  public isLoadingRequestForm$: Observable<boolean>;
  public isRespondedRequestForm$: Observable<boolean>;
  public errorRequestForm$: Observable<string>;
  public requestSuccess$: Observable<RequestSuccess>;

  public isloadingForms$: Observable<boolean>;
  public errorConfigForms$: Observable<string>;
  public isLoadingConfigurations$: Observable<boolean>;
  public selectedConfig$: Observable<Configuration>;
  public configurationsDetails$: Observable<PagedResponse<Configuration[]>>;
  public configurations$: Observable<Configuration[]>;
  public configuration$: Observable<ConfigurationResponse>;
  public configurationStep$: Observable<number>;

  public isLoadingParents$: Observable<boolean>;
  public isLoadingKpis$: Observable<boolean>;
  public parents$: Observable<PagedResponse<KPIParent>>;
  public kpis$: Observable<ParentKpis>;
  public APIKey$: Observable<ResponseApiKey>;
  public requestStates$: Observable<Catalog[]>;
  public premiumKpisParents$: Observable<PagedResponse<KPIParent>>;
  public premiumKpis$: Observable<ParentKpis>;
  public isLoadingPremiumParents$: Observable<boolean>;
  public requestRemaining$: Observable<number>;
  public daysLeft$: Observable<number>;

  constructor(private _store: Store) {
    this._setupGeneralSelectors();
    this._setupConfigSelectors();
    this._setupRequestSelectors();
  }

  public initOverviewPage() {
    this._store.dispatch(insightActions.initOverviewPage());
  }

  public getRequestSummary(status: IStatusInfo) {
    this._store.dispatch(insightActions.getRequestSummary({ status }));
  }

  /* #region  Requests */
  public initRequestPage() {
    this._store.dispatch(requestActions.initRequestPage());
  }

  public reloadRequestData() {
    this._store.dispatch(requestActions.reloadRequestWithOutLoading());
  }

  public responseCreateRequestWS(request_id: string) {
    const status: RequestSuccess = {
      request_id: request_id,
    };
    this._store.dispatch(
      requestActions.responseCreateRequestWS({ confirmation: status })
    );
  }

  public responseRefreshRequestWS(request_id: string) {
    const status: RequestSuccess = {
      request_id: request_id,
    };
    this._store.dispatch(
      requestActions.responseRefreshRequestWS({ confirmation: status })
    );
  }

  public changeFiltersRequests(filters: Filters) {
    this._store.dispatch(requestActions.changeFiltersRequests({ filters }));
  }

  public initRequestForms() {
    this._store.dispatch(requestActions.initFormsSidebar());
  }

  public updateConfigList(country: string) {
    this._store.dispatch(insightActions.changeCountrySlideBar({ country }));
  }

  public leaveRequestForms() {
    this._store.dispatch(requestActions.leaveFormsSidebar());
  }

  public createRequest(request: RequestForm) {
    this._store.dispatch(requestActions.createRequest({ request }));
  }

  public downloadRequestFile(request: RequestDownload) {
    this._store.dispatch(requestActions.downloadResultRequest({ request }));
  }
  /* #endregion */

  /* #region  KPI Library */
  public initKPIPages() {
    this._store.dispatch(insightActions.initKPIPages());
  }

  public initLibraryPage() {
    this._store.dispatch(insightActions.initLibraryPage());
  }

  public changeFiltersLibrary(filters: KPIFilters) {
    this._store.dispatch(insightActions.changeFiltersLibrary({ filters }));
  }

  public changeCountryKPI(country: string) {
    this._store.dispatch(insightActions.changeCountryKPI({ country }));
  }

  public changeCountrySlidebar(country: string) {
    this._store.dispatch(insightActions.changeCountrySlideBar({ country }));
  }
  /* #endregion */

  /* #region  Configuration KPIs */
  public initConfigPage() {
    this._store.dispatch(insightActions.initConfigPage());
  }

  public changeStepConfiguration(step: number) {
    this._store.dispatch(insightActions.changeStepConfiguration({ step }));
  }

  public changeFiltersConfigurations(filters: ConfigurationFilters) {
    this._store.dispatch(insightActions.changeFiltersConfigs({ filters }));
  }

  public deleteConfiguration(id: string) {
    this._store.dispatch(insightActions.deleteConfig({ id }));
  }

  public submitCreateConfiguration(configuration: Configuration) {
    this._store.dispatch(
      insightActions.createConfigurationSubmit({ configuration })
    );
  }

  public submitEditConfiguration(configuration: Configuration) {
    this._store.dispatch(
      insightActions.editConfigurationSubmit({ configuration })
    );
  }

  public toggleConfiguration(configuration: Configuration) {
    this._store.dispatch(insightActions.toggleConfiguration({ configuration }));
  }

  public leaveSelectedConfig() {
    this._store.dispatch(insightActions.leaveSelectedConfig());
  }

  public selectParent(selectParent: string, filters: KPIFilters) {
    this._store.dispatch(
      insightActions.selectParent({ selectParent, filters })
    );
  }
  /* #endregion */

  /* #region  Map page */
  public initMapPage() {
    this._store.dispatch(mapActions.initMapPage());
  }
  /* #endregion */

  /* #region  Support pages */

  public submitSendSupport(support: Support) {
    this._store.dispatch(insightActions.sendSupportSubmit({ support }));
  }

  public sendUpgradePlanSubmit(support: Support) {
    this._store.dispatch(insightActions.sendUpgradePlanSubmit({ support }));
  }

  public initAPIKeyPage() {
    this._store.dispatch(insightActions.consultApiData());
  }
  /* #endregion */

  /* #Premium KPIS */

  public initPremiumPage() {
    this._store.dispatch(insightActions.initPremiumPage());
  }

  public changeFiltersPremium(filters: PremiumFilters) {
    this._store.dispatch(insightActions.changeFiltersPremium({ filters }));
  }

  public selectPremiumParent(selectParent: string, filters: PremiumFilters) {
    this._store.dispatch(
      insightActions.selectPremiumParent({ selectParent, filters })
    );
  }

  /* #end Premium KPIS */

  /* Bash services */

  public uploadBashFile(configFile: BatchFile) {
    this._store.dispatch(
      requestActions.uploadFileWithRequests({ request: configFile })
    );
  }

  /* End Bash services */

  /* Free usage */

  public setDaysLeft(daysLeft: number) {
    this._store.dispatch(insightActions.setDaysLeft({ daysLeft }));
  }

  public setRequestRem(requestRemaining: number) {
    this._store.dispatch(
      insightActions.setRequestRemaining({ requestRemaining })
    );
  }

  public downloadManualDocument() {
    this._store.dispatch(insightActions.downloadManualDocument());
  }
  /* End free usage */

  /* #region  Initialize/Setup selectores */
  private _setupGeneralSelectors() {
    this.countries$ = this._store.select(insightSelectors.selectCountries);
    this.infoCountry$ = this._store.select(insightSelectors.selectInfoCountry);
    this.country$ = this._store.select(insightSelectors.selectCountry);
  }

  private _setupRequestSelectors() {
    this.requestStates$ = this._store.select(appSelectors.selectRequestStates);
    this.isLoadingRequestForm$ = this._store.select(
      requestSelectors.selectIsLoadingForm
    );
    this.requestSuccess$ = this._store.select(
      requestSelectors.selectRequestSuccess
    );

    this.isLoadingConfigsRequests$ = this._store.select(
      insightSelectors.isLoadingConfigsRequests
    );

    this.isLoadingParents$ = this._store.select(
      insightSelectors.selectIsLoadingParents
    );

    this.isLoadingPremiumParents$ = this._store.select(
      insightSelectors.selectIsLoadingPremiumParents
    );

    this.isLoadingKpis$ = this._store.select(
      insightSelectors.selectIsLoadingKpis
    );
    this.requests$ = this._store.select(requestSelectors.selectRequests);
    this.isLoadingHome$ = this._store.select(
      requestSelectors.selectIsLoadingHome
    );

    this.requestSummary$ = this._store.select(
      requestSelectors.selectRequestSummary
    );
    this.isLoadingRequests$ = this._store.select(
      requestSelectors.selectIsLoadingRequests
    );
    this.isRespondedRequestForm$ = this._store.select(
      requestSelectors.selectIsRespondedForm
    );
    this.errorRequestForm$ = this._store.select(
      requestSelectors.selectErrorForm
    );
    this.selectedConfig$ = this._store.select(
      insightSelectors.selectSelectedConfig
    );

    this.isLoadingDownloadFile$ = this._store.select(
      requestSelectors.selectIsLoadingDownloadFile
    );
  }

  private _setupConfigSelectors() {
    this.isLoadingConfigurations$ = this._store.select(
      insightSelectors.selectIsLoadingConfigs
    );
    this.configurationsDetails$ = this._store.select(
      insightSelectors.selectConfigsDetails
    );
    this.isLoadingConfigsRequests$ = this._store.select(
      insightSelectors.isLoadingConfigsRequests
    );
    this.configurations$ = this._store.select(insightSelectors.selectConfigs);
    this.configuration$ = this._store.select(insightSelectors.selectConfig);
    this.isloadingForms$ = this._store.select(
      insightSelectors.selectIsLoadingForms
    );
    this.errorConfigForms$ = this._store.select(
      insightSelectors.selectErrorConfigForms
    );
    this.configurationStep$ = this._store.select(
      insightSelectors.selectStepConfigs
    );

    this.parents$ = this._store.select(insightSelectors.selectParents);
    this.kpis$ = this._store.select(insightSelectors.selectParentKpis);
    this.APIKey$ = this._store.select(insightSelectors.selectConsultApi);

    // Premium
    this.premiumKpisParents$ = this._store.select(
      insightSelectors.selectPremiumKpisParents
    );
    this.premiumKpis$ = this._store.select(insightSelectors.selectPremiumKpis);

    this.requestRemaining$ = this._store.select(
      insightSelectors.selectRequestRemaining
    );

    this.daysLeft$ = this._store.select(insightSelectors.selectDaysLeft);
  }
  /* #endregion */
}
