export const REQUEST_SUCCESS = {
  data: [
    {
      request_id: '11b1f491-1a97-46f5-bbcb-19b8f8e927ba',
      config_id: '2fcb56d6-b86d-4ff0-87db-cece8d8e42ee',
      config_name: 'Configuration test Julieth',
      creation_date: '2021-04-09 16:31:06',
      status_key: 'comp',
      status_name: 'Completed',
      groups: [
        { group_key: 'Peop', group_name: 'People' },
        { group_key: 'Gove', group_name: 'Government/Public services' },
        { group_key: 'Envi', group_name: 'Environment' },
      ],
    },
    {
      request_id: 'a2330ee8-637e-4e52-be62-9d5421fcc77a',
      config_id: '2fcb56d6-b86d-4ff0-87db-cece8d8e42ee',
      config_name: 'Configuration test Julieth',
      creation_date: '2021-04-09 17:29:02',
      status_key: 'comp',
      status_name: 'Completed',
      groups: [
        { group_key: 'Peop', group_name: 'People' },
        { group_key: 'Gove', group_name: 'Government/Public services' },
        { group_key: 'Envi', group_name: 'Environment' },
      ],
    },
    {
      request_id: 'a740ab21-3d5c-4763-a22b-11b799c67f8e',
      config_id: '2fcb56d6-b86d-4ff0-87db-cece8d8e42ee',
      config_name: 'Configuration test Julieth',
      creation_date: '2021-04-08 22:57:38',
      status_key: 'comp',
      status_name: 'Completed',
      groups: [
        { group_key: 'Peop', group_name: 'People' },
        { group_key: 'Gove', group_name: 'Government/Public services' },
        { group_key: 'Envi', group_name: 'Environment' },
      ],
    },
    {
      request_id: 'd713c7fb-59ad-467b-844a-d23514649e04',
      config_id: '2fcb56d6-b86d-4ff0-87db-cece8d8e42ee',
      config_name: 'Configuration test Julieth',
      creation_date: '2021-04-09 17:24:51',
      status_key: 'comp',
      status_name: 'Completed',
      groups: [
        { group_key: 'Peop', group_name: 'People' },
        { group_key: 'Gove', group_name: 'Government/Public services' },
        { group_key: 'Envi', group_name: 'Environment' },
      ],
    },
  ],
  response_code: null,
  errors: [],
  total: 4,
  page: 0,
  size: 10,
};
