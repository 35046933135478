import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import * as userActions from '@store/user/user.actions';
import * as userSelectors from '@store/user/user.selectors';
import { User } from '@app/core/models/user.model';

@Injectable({ providedIn: 'root' })
export class UserFacade {
  public user$: Observable<User>;
  constructor(private _store: Store) {
    this.user$ = this._store.select(userSelectors.selectUserData);
  }

}