import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AppFacade } from '@app/facades/app.facade';

@Component({
  selector: 'layout-splash',
  templateUrl: './splash.component.html',
  styleUrls: ['./splash.component.scss'],
})
export class SplashComponent implements OnInit {
  @ViewChild('splash', { static: true }) content: ElementRef;
  constructor(private _appFacade: AppFacade) {}

  ngOnInit(): void {
    this._appFacade.showSplash(this.content);
  }
}
