export const DOCUMENT_FILE_SUCCESS = {
  data: [
    {
      id: 159,
      request_id: 226,
      location:
        'https://images.sampletemplates.com/wp-content/uploads/2016/12/21102637/Technical-Business-Document.jpg',
      extension: 'png',
      created_at: '2021-08-13T16:40:00.053334+00:00',
      updated_at: '2021-08-13T16:40:00.053358+00:00',
    },
    {
      id: 160,
      request_id: 225,
      location: './assets/documents/merchantfundagre.pdf',
      extension: 'pdf',
      created_at: '2021-08-13T16:40:00.053334+00:00',
      updated_at: '2021-08-13T16:40:00.053358+00:00',
    },
  ],
  response_code: 200,
  errors: [],
};
