import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, pluck } from 'rxjs/operators';
import { APP_CONSTANTS as CONST } from '@app/app.constants';

import { Filters, PagedResponse } from '@app/core/models/table.model';
import {
  Country,
  ResponseApiKey,
  Configuration,
  ConfigurationResponse,
  InfoCountry,
  ResponseBackendInfoCountry,
  Support,
  KPIParent,
  CountryFilter,
  KPI,
  PremiumKPIParent,
  PremiumFilters,
  BatchFile,
  RequestFileResponse,
} from '@app/core/models/insight.model';
import {
  Request,
  RequestSuccess,
  RequestSummary,
  RequestDetail,
  RequestDownload,
  RequestForm,
} from '@app/core/models/request.model';
import { IStatusInfo, STATUS } from '@app/core/models/status-info.model';
import { UtilService } from '../util.service';
import { AuthService } from '../auth/auth.service';

@Injectable({ providedIn: 'root' })
export class InsightAPI {
  constructor(
    private _http: HttpClient,
    private _utilService: UtilService,
    private _authService: AuthService
  ) {}

  public getCountries(): Observable<Country[]> {
    return this._http.get<Country[]>(CONST.URLS.COUNTRIES).pipe(pluck('data'));
  }

  public getInfoByCountry(country: string): Observable<InfoCountry> {
    const url = CONST.URLS.DATA_COUNTRIES;
    const params = country ? { params: { country } } : { params: {} };
    const mapFn = (response: ResponseBackendInfoCountry) => ({
      groups: response.items,
      indicators: response.indicators,
    });
    return this._http.get(url, params).pipe(pluck('data'), map(mapFn));
  }

  /* #region  Requests */
  public getRequestSummary(status?: IStatusInfo): Observable<RequestSummary> {
    let filters;
    if (status?.status !== STATUS.ALL) {
      filters = { status: status?.status };
    }
    const params = this._utilService.createAndValidateParams(filters);
    return this._http
      .get<RequestSummary>(CONST.URLS.REQUESTS_STATES, { params })
      .pipe(pluck('data'));
  }

  public getStatusRequest(
    idRequest: string,
    APIKey: string
  ): Observable<RequestDetail> {
    const params = { headers: { 'api-key': APIKey } };
    const url = CONST.URLS.STATUS_REQUEST + `${idRequest}/`;
    return this._http.get<RequestDetail>(url, params).pipe(pluck('data'));
  }

  public getRequests(filters?: Filters): Observable<PagedResponse<Request>> {
    const params = filters ? { params: { ...filters } } : { params: {} };
    return this._http.get<PagedResponse<Request>>(CONST.URLS.REQUEST, params);
  }

  public createRequest(
    request: RequestForm,
    APIKey: string,
    type: string
  ): Observable<RequestSuccess> {
    const params = { headers: { 'api-key': APIKey } };
    const locationTypes = {
      coordinates: CONST.URLS.CREATE_REQUEST_COORDINATES,
      address: CONST.URLS.CREATE_REQUEST_ADDRESS,
    };
    let url = locationTypes[type];
    const token = JSON.parse(this._authService.getTokenSync());
    return this._http
      .post<RequestSuccess>(
        url,
        { ...request, is_premium: token.is_premium },
        params
      )
      .pipe(pluck('data'));
  }

  getDownloadUrl(request: RequestDownload): Observable<RequestFileResponse> {
    const { request_id, type: file_type } = request;
    const params = { request_id, file_type };
    return this._http
      .get<string>(CONST.URLS.DOWNLOAD_URL, {
        params,
      })
      .pipe(pluck('data'));
  }

  uploadBashFile(
    request: BatchFile,
    apiKey: string
  ): Observable<RequestSuccess> {
    const token = JSON.parse(this._authService.getTokenSync());
    var formData: any = new FormData();
    formData.append('file', request.file, request.file_name);
    formData.append('file_name', request.file_name);
    formData.append('radius', request.radius);
    formData.append('config_id', request.config_id);
    formData.append('country', request.country);
    formData.append('is_premium', token.is_premium);

    return this._http
      .post<string>(CONST.URLS.UPLOAD_BATCH, formData, {
        headers: {
          'api-key': apiKey,
        },
      })
      .pipe(pluck('data'));
  }
  /* #endregion */

  /* #region  Configuration */
  public getConfigsDetails(
    filters?: Filters
  ): Observable<PagedResponse<Configuration[]>> {
    const url = CONST.URLS.CONFIGURATIONS;
    const params = filters ? { params: { ...filters } } : { params: {} };
    return this._http.get<PagedResponse<Configuration[]>>(url, params);
  }

  public getConfigs(filters: CountryFilter): Observable<Configuration[]> {
    const params = filters && { params: { ...filters } };
    return this._http
      .get<Configuration[]>(CONST.URLS.CONFIGURATIONS_LIST, params)
      .pipe(pluck('data'));
  }

  public deleteConfig(id: string): Observable<any> {
    const options = {
      headers: new HttpHeaders(),
      body: { config_id: id },
    };
    return this._http.delete(CONST.URLS.CONFIGURATIONS, options);
  }
  /* #endregion */

  public getPremiumKPIsParents(
    filters?: Filters
  ): Observable<PagedResponse<PremiumKPIParent[]>> {
    const params = filters ? { params: { ...filters } } : { params: {} };
    return this._http.get<PagedResponse<PremiumKPIParent[]>>(
      CONST.URLS.PREMIUM_KPIS_PARENTS,
      params
    );
  }

  public getParents(filters?: Filters): Observable<PagedResponse<KPIParent>> {
    const params = filters ? { params: { ...filters } } : { params: {} };
    return this._http.get<PagedResponse<KPIParent>>(
      CONST.URLS.KPIS_PARENTS,
      params
    );
  }

  public getKpis(
    name: string,
    filters?: Filters
  ): Observable<PagedResponse<KPI[]>> {
    const params = name ? { params: { name, ...filters } } : { params: {} };
    return this._http.get<PagedResponse<KPI[]>>(CONST.URLS.KPIS, params);
  }

  public getPremiumKpis(
    name: string,
    filters?: PremiumFilters
  ): Observable<PagedResponse<PremiumKPIParent>> {
    const params = name ? { params: { name, ...filters } } : { params: {} };
    return this._http.get<PagedResponse<PremiumKPIParent>>(
      CONST.URLS.PREMIUM_KPIS,
      params
    );
  }

  public getApiKey(productType: string): Observable<ResponseApiKey> {
    return this._http
      .get<ResponseApiKey>(CONST.URLS.API_KEY + `?product_type=${productType}`)
      .pipe(pluck('data'));
  }

  public createConfiguration(
    data: Configuration
  ): Observable<ConfigurationResponse> {
    const keysGroups = data.groups.map((g) => g.group_key);
    const { groups, ...newData } = { ...data, kpi_groups: keysGroups };
    return this._http
      .post<ConfigurationResponse>(CONST.URLS.CONFIGURATIONS, newData)
      .pipe(pluck('data'));
  }

  public editConfiguration(
    data: Configuration
  ): Observable<ConfigurationResponse> {
    const keysGroups = data.groups.map((g) => g.group_key);
    const { config_id, description, name, status } = data;
    const updatedConfig = {
      config_id,
      description,
      name,
      status,
      kpi_groups: keysGroups,
    };
    return this._http
      .put<ConfigurationResponse>(CONST.URLS.CONFIGURATIONS, updatedConfig)
      .pipe(pluck('data'));
  }

  public createSupport(data: Support) {
    const params = this._utilService.createAndValidateParams(data);
    return this._http.post(CONST.URLS.SUPPORT, params);
  }
}
