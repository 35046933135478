import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { map, tap } from 'rxjs/operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import * as appActions from './app.actions';
import * as userActions from '../user/user.actions';
import { AppService } from '@app/services/app.service';

@Injectable()
export class AppEffects {
  constructor(
    private _actions$: Actions,
    private _router: Router,
    private _appService: AppService
  ) {}

  public changeProduct$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(appActions.changeProduct),
        tap(({ product }) => this._router.navigateByUrl(product.route))
      ),
    { dispatch: false }
  );

  public getProductsActive$ = createEffect(() =>
    this._actions$.pipe(
      ofType(
        userActions.responseConsultUser,
        userActions.responseConsultUserGuard
      ),
      map(({ products }) => {
        const productsActive = this._appService.getProductsActive(products);
        return appActions.responseProductsActive({ products: productsActive });
      })
    )
  );

  public getInitialProduct$ = createEffect(() =>
    this._actions$.pipe(
      ofType(appActions.responseProductsActive),
      map(({ products }) =>
        appActions.responseInitialProduct({ product: products[0] })
      )
    )
  );
}
