export enum STATUS {
  COMPLETED = 'comp',
  PENDING = 'pend',
  FAILED = 'fail',
  ALL = 'all',
}

export type IStatus =
  | STATUS.ALL
  | STATUS.COMPLETED
  | STATUS.FAILED
  | STATUS.PENDING;

export interface IStatusInfo {
  status: IStatus;
  label: string;
  data?: any; // Aditional data for status mapped
}
