import { createFeatureSelector, createSelector } from '@ngrx/store';
import { InsightState } from './insight.state';

export const getState = createFeatureSelector('insight');

export const selectProductData = createSelector(
  getState,
  (state: InsightState) => ({
    api_key: state.apiKey?.api_key,
    country: state.country,
  })
);

export const selectConsultApi = createSelector(
  getState,
  (state: InsightState) => state.apiKey
);

export const selectCountries = createSelector(
  getState,
  (state: InsightState) => state.countries
);

export const selectCountry = createSelector(
  getState,
  (state: InsightState) => state.country
);
export const selectInfoCountry = createSelector(
  getState,
  (state: InsightState) => state.infoCountry
);

export const selectFilterKPIPage = createSelector(
  getState,
  (state: InsightState) => {
    const data = { country: state.country };
    switch (state.currentKPIPage) {
      case 'CONFIG':
        return { ...data, isConfigPage: true, ...state.filtersConfigs };
      case 'LIBRARY':
        return { ...data, isLibraryPage: true, ...state.filtersKPIs };
    }
  }
);

export const selectFilterPremiumPage = createSelector(
  getState,
  (state: InsightState) => {
    const data = { country: state.country };
    return { ...data, ...state.filtersPremium };
  }
);

export const selectIsLoadingConfigs = createSelector(
  getState,
  (state: InsightState) => state.isLoadingConfigs
);

export const selectErrorConfigForms = createSelector(
  getState,
  (state: InsightState) => state.errorConfigForms
);

export const selectConfigsDetails = createSelector(
  getState,
  (state: InsightState) => state.configurationsDetails
);

export const selectConfigs = createSelector(
  getState,
  (state: InsightState) => state.configurations
);

export const selectConfig = createSelector(
  getState,
  (state: InsightState) => state.configuration
);

export const selectSelectedConfig = createSelector(
  getState,
  (state: InsightState) => state.selectedConfig
);

export const selectStepConfigs = createSelector(
  getState,
  (state: InsightState) => state.configurationStep
);

export const selectIsLoadingParents = createSelector(
  getState,
  (state: InsightState) => state.isLoadingParents
);

export const isLoadingConfigsRequests = createSelector(
  getState,
  (state: InsightState) => state.isLoadingConfigsRequests
);

export const selectIsLoadingKpis = createSelector(
  getState,
  (state: InsightState) => state.isLoadingKpis
);

export const selectParents = createSelector(
  getState,
  (state: InsightState) => state.parents
);

export const selectParent = createSelector(
  getState,
  (state: InsightState) => state.selectParent
);

export const selectParentKpis = createSelector(
  getState,
  (state: InsightState) => state.parentKpis
);

export const selectKPIs = createSelector(
  getState,
  (state: InsightState) => state.kpis
);

export const selectIsSupportSuccess = createSelector(
  getState,
  (state: InsightState) => state.isSuccessSupport
);

export const selectIsLoadingForms = createSelector(
  getState,
  (state: InsightState) => state.isLoadingForms
);

export const selectPremiumKpisParents = createSelector(
  getState,
  (state: InsightState) => state.premiumKPIsParents
);

export const selectIsLoadingPremiumParents = createSelector(
  getState,
  (state: InsightState) => state.isLoadingPremiumKPIsParents
);

export const selectPremiumKpis = createSelector(
  getState,
  (state: InsightState) => state.premiumKpis
);

export const selectRequestRemaining = createSelector(
  getState,
  (state: InsightState) => state.requestRemaining
);

export const selectDaysLeft = createSelector(
  getState,
  (state: InsightState) => state.daysLeft
);
