import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Token } from '@app/core/models/app.model';
import { APP_CONSTANTS as CONST } from '@app/app.constants';

import {
  CreatePasswordDataBody,
  ForgotPasswordDataBody,
  LoginData,
  SignUpData,
  SignUpDataResponse,
  TemporalToken,
} from '@app/core/models/auth.model';
import { Observable } from 'rxjs';
import { pluck } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class AuthAPI {
  constructor(private _http: HttpClient) {}

  public login(data: LoginData): Observable<Token> {
    return this._http
      .post<{ data: Token }>(CONST.URLS.LOGIN, data)
      .pipe(pluck('data'));
  }

  public signUp(data: SignUpData): Observable<SignUpDataResponse> {
    return this._http
      .post<{ data: SignUpDataResponse }>(CONST.URLS.SIGN_UP, data)
      .pipe(pluck('data'));
  }

  public generateLink(data: ForgotPasswordDataBody) {
    return this._http.post(CONST.URLS.SEND_FORGOT_PASSWORD_EMAIL, data);
  }

  public changePassword(data: CreatePasswordDataBody) {
    return this._http.post(CONST.URLS.CHANGE_PASSWORD, data);
  }

  public validatePasswordToken(data: TemporalToken): Observable<Token> {
    return this._http
      .post<Token>(CONST.URLS.VALIDATE_TEMPORAL_TOKEN, data)
      .pipe(pluck('data'));
  }
}
