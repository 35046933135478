import { ElementRef, Injectable } from '@angular/core';
import { animate, AnimationBuilder, style } from '@angular/animations';
import { Observable, Observer } from 'rxjs';
import { RequestFileResponse } from '@app/core/models/insight.model';
import * as JSZip from 'jszip';
import * as FileSaver from 'file-saver';
import * as JSZipUtils from 'jszip-utils';
import { DocumentFilesZip } from '@app/core/models/fetch.model';

@Injectable({ providedIn: 'root' })
export class UtilService {
  private el: ElementRef;

  constructor(private _animationBuilder: AnimationBuilder) {}

  public initLoader(element: ElementRef) {
    this.el = element;
  }

  public hideLoaderReq() {
    const player = this._animationBuilder
      .build([style({ opacity: '0.5' }), animate(800, style({ opacity: '0' }))])
      .create(this.el.nativeElement);

    player.onDone(() => {
      if (typeof this.el.nativeElement.remove === 'function') {
        this.el.nativeElement.remove();
      } else {
        this.el.nativeElement.style.display = 'none';
      }
    });

    setTimeout(() => player.play(), 300);
  }

  public copyClipboard(text: string) {
    const el = document.createElement('input');
    el.value = text;
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);
    el.select();
    el.setSelectionRange(0, 99999);
    // TODO: Fix: Replace deprecated function.
    document.execCommand('copy');
  }

  public downloadFilesAsZip(files: DocumentFilesZip[], zipName: string) {
    JSZip.support.nodebuffer = false;
    var zip = new JSZip();
    files.forEach((file: DocumentFilesZip) => {
      zip.file(
        file?.name,
        this._urlFileToPromise(file?.documentFile?.location)
      );
    });
    zip.generateAsync({ type: 'blob' }).then(function (content) {
      FileSaver.saveAs(content, `${zipName}.zip`);
    });
  }

  public downloadFileFromURL(
    fileResponse: RequestFileResponse,
    fileName?: string
  ) {
    this._downloadUrl(fileResponse?.url, fileName);
    if (fileResponse?.url_batch) {
      setTimeout(() => {
        this._downloadUrl(fileResponse?.url_batch, fileName);
      }, 1800);
    }
  }

  public createAndValidateParams(filters) {
    if (!filters) return;
    let params = {};
    Object.keys(filters).map((k) => {
      if (
        filters[k] !== undefined &&
        filters[k] !== null &&
        filters[k] !== ''
      ) {
        params[k] = filters[k];
      }
    });
    return params;
  }

  downloadImage(imageUrl: string, fileName: string) {
    this._getBase64ImageFromURL(imageUrl).subscribe((base64data) => {
      const base64Image = 'data:image/jpg;base64,' + base64data;
      const link = document.createElement('a');
      document.body.appendChild(link);
      link.setAttribute('href', base64Image);
      link.setAttribute('download', fileName);
      link.click();
    });
  }

  public downloadObjectToJson(object: any, name: string) {
    var sJson = JSON.stringify(object);
    var element = document.createElement('a');
    element.setAttribute(
      'href',
      'data:text/json;charset=UTF-8,' + encodeURIComponent(sJson)
    );
    element.setAttribute('download', name);
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  }

  private _urlFileToPromise(url): Promise<any> {
    return new Promise(function (resolve, reject) {
      JSZipUtils.getBinaryContent(url, function (err, data) {
        if (err) {
          reject(err);
        } else {
          resolve(data);
        }
      });
    });
  }

  private _downloadUrl(url: string, fileName: string) {
    const a: any = document.createElement('a');
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.style = 'display: none';
    a.click();
    a.target = '_blank';
    a.remove();
  }

  private _getBase64Image(img: HTMLImageElement) {
    const canvas: HTMLCanvasElement = document.createElement('canvas');
    canvas.width = img.width;
    canvas.height = img.height;
    const ctx: CanvasRenderingContext2D = canvas.getContext('2d');
    ctx.drawImage(img, 0, 0);
    const dataURL: string = canvas.toDataURL('image/png');
    return dataURL.replace(/^data:image\/(png|jpg);base64,/, '');
  }

  private _getBase64ImageFromURL(url: string) {
    return new Observable((observer: Observer<string>) => {
      const img: HTMLImageElement = new Image();
      img.crossOrigin = 'Anonymous';
      img.src = url;
      if (!img.complete) {
        img.onload = () => {
          observer.next(this._getBase64Image(img));
          observer.complete();
        };
        img.onerror = (err) => {
          observer.error(err);
        };
      } else {
        observer.next(this._getBase64Image(img));
        observer.complete();
      }
    });
  }

  public downloadFiles(url) {
    window.open(url, '_blank', 'noopener noreferrer');
  }
}
