export const FETCH_COUNTRIES_SUCCESS = {
  data: [
    {
      code: '57',
      name: 'Colombia',
      created_at: '2021-05-20T19:06:41.588Z',
      updated_at: '2021-05-20T19:06:41.588Z',
    },
    {
      code: '52',
      name: 'Mexico',
      created_at: '2021-05-20T19:06:41.588Z',
      updated_at: '2021-05-20T19:06:41.588Z',
    },
  ],
};
