export const DOCUMENTS_SUCCESS = {
  data: [
    {
      id: 41,
      data: {
        data: {
          org_id: 'f4727041-6714-443c-9247-e33ac381a94b',
          templates: [
            {
              entity: '789123',
              country: 'COL',
              version: 'Version 1',
              fields_ids: [
                {
                  message: 'please, check all fields while MVP',
                },
              ],
              template_id: 11,
            },
            {
              entity: '789123',
              country: 'COL',
              version: 'Version 1',
              fields_ids: [
                {
                  message: 'please, check all fields while MVP',
                },
              ],
              template_id: 5,
            },
            {
              entity: '123456',
              country: 'COL',
              version: null,
              fields_ids: [
                {
                  message: 'please, check all fields while MVP',
                },
              ],
              template_id: 6,
            },
            {
              entity: '789123',
              country: 'COL',
              version: 'Version 1',
              fields_ids: [
                {
                  message: 'please, check all fields while MVP',
                },
              ],
              template_id: 5,
            },
            {
              entity: '789123',
              country: 'MEX',
              version: 'Version 7',
              fields_ids: [
                {
                  message: 'please, check all fields while MVP',
                },
              ],
              template_id: 7,
            },
            {
              entity: '123456',
              country: 'MEX',
              version: null,
              fields_ids: [
                {
                  message: 'please, check all fields while MVP',
                },
              ],
              template_id: 8,
            },
            {
              entity: '789123',
              country: 'MEX',
              version: 'Version 4',
              fields_ids: [
                {
                  message: 'please, check all fields while MVP',
                },
              ],
              template_id: 3,
            },
          ],
          request_id: 41,
          external_id: '64533',
          paths_gcs_files: [
            {
              path: '/api/original/pdf_liviano_prueba_02.pdf',
              metadata: {
                message:
                  'File uploaded successfully to /api/original/pdf_liviano_prueba_02.pdf.',
                page_number: 1,
              },
            },
          ],
        },
        errors: [],
      },
      status_code: 200,
      created_at: '2021-07-06T14:42:32.575307+00:00',
      updated_at: '2021-07-06T14:42:37.216012+00:00',
      country: null,
      organization: 'Organization to fetch tests',
      org_uuid: 'f4727041-6714-443c-9247-e33ac381a94b',
      entity_name: '',
    },
    {
      id: 2,
      data: {
        data: {
          org_id: 'xxxx',
          templates: [
            {
              entity: 1,
              fields: [1, 2, 3],
              country: 'COL',
            },
            {
              entity: 'BVA',
              fields: [1, 2, 3],
              country: 'COL',
            },
          ],
          request_id: 123,
          external_id: 'xxxx',
          paths_gcs_files: [
            {
              path: 'subfolder1/file1.png',
              metadata: {
                page_number: 1,
              },
            },
            {
              path: 'subfolder2/file2.png',
              metadata: {
                page_number: 2,
              },
            },
          ],
        },
        errors: [
          {
            code: 'BU0003',
            code_name: 'NO_ACTIVE_TEMPLATE',
            description: 'No active template found for current organization',
          },
        ],
      },
      status_code: 200,
      created_at: '2021-05-21T16:22:26.533813+00:00',
      updated_at: '2021-07-14T15:54:15.101778+00:00',
      country: 'Colombia',
      organization: 'Organization to fetch tests',
      org_uuid: 'f4727041-6714-443c-9247-e33ac381a94b',
      entity_name: 'Banco 2',
    },
    {
      id: 10,
      data: {
        data: 'data',
      },
      status_code: 200,
      created_at: '2021-05-21T16:22:26.533813+00:00',
      updated_at: '2021-07-13T19:25:49.799781+00:00',
      country: 'Colombia',
      organization: 'Organization to fetch tests',
      org_uuid: 'f4727041-6714-443c-9247-e33ac381a94b',
      entity_name: 'Banco 2',
    },
    {
      id: 32,
      data: {},
      status_code: 100,
      created_at: '2021-07-06T14:16:31.179710+00:00',
      updated_at: '2021-07-06T14:16:31.179737+00:00',
      country: null,
      organization: 'Organization to fetch tests',
      org_uuid: 'f4727041-6714-443c-9247-e33ac381a94b',
      entity_name: 'Banco 3',
    },
    {
      id: 33,
      data: {
        data: {
          org_id: 'f4727041-6714-443c-9247-e33ac381a94b',
          templates: [
            {
              entity: null,
              country: 'COL',
              version: null,
              fields_ids: [
                {
                  message: 'please, check all fields while MVP',
                },
              ],
            },
            {
              entity: null,
              country: 'COL',
              version: null,
              fields_ids: [
                {
                  message: 'please, check all fields while MVP',
                },
              ],
            },
            {
              entity: null,
              country: 'MEX',
              version: null,
              fields_ids: [
                {
                  message: 'please, check all fields while MVP',
                },
              ],
            },
            {
              entity: null,
              country: 'COL',
              version: null,
              fields_ids: [
                {
                  message: 'please, check all fields while MVP',
                },
              ],
            },
            {
              entity: null,
              country: 'MEX',
              version: null,
              fields_ids: [
                {
                  message: 'please, check all fields while MVP',
                },
              ],
            },
            {
              entity: null,
              country: 'MEX',
              version: null,
              fields_ids: [
                {
                  message: 'please, check all fields while MVP',
                },
              ],
            },
          ],
          request_id: 33,
          external_id: '64533',
          paths_gcs_files: [
            {
              path: '/api/original/pdf_liviano_prueba_02.pdf',
              metadata: {
                message:
                  'File uploaded successfully to /api/original/pdf_liviano_prueba_02.pdf.',
                page_number: 1,
              },
            },
          ],
        },
        errors: [],
      },
      status_code: 200,
      created_at: '2021-07-06T14:18:16.339065+00:00',
      updated_at: '2021-07-06T14:18:20.324156+00:00',
      country: null,
      organization: 'Organization to fetch tests',
      org_uuid: 'f4727041-6714-443c-9247-e33ac381a94b',
      entity_name: 'Banco 4',
    },
    {
      id: 63,
      data: {
        data: {
          org_id: 'f4727041-6714-443c-9247-e33ac381a94b',
          templates: [
            {
              entity: '789123',
              country: 'MEX',
              version: 'Version 4',
              fields_ids: [
                {
                  message: 'please, check all fields while MVP',
                },
              ],
            },
            {
              entity: '789123',
              country: 'COL',
              version: 'Version 1',
              fields_ids: [
                {
                  message: 'please, check all fields while MVP',
                },
              ],
            },
            {
              entity: '789123',
              country: 'COL',
              version: 'Version 1',
              fields_ids: [
                {
                  message: 'please, check all fields while MVP',
                },
              ],
            },
            {
              entity: '789123',
              country: 'COL',
              version: 'Version 1',
              fields_ids: [
                {
                  message: 'please, check all fields while MVP',
                },
              ],
            },
            {
              entity: '789123',
              country: 'MEX',
              version: 'Version 7',
              fields_ids: [
                {
                  message: 'please, check all fields while MVP',
                },
              ],
            },
          ],
          request_id: '63',
          external_id: '64533',
          paths_gcs_files: [
            {
              path: 'api/original/org_f4727041-6714-443c-9247-e33ac381a94b_202107-0710-4929_pdf_liviano_prueba_03.pdf',
              metadata: {
                message:
                  'File uploaded successfully to api/original/org_f4727041-6714-443c-9247-e33ac381a94b_202107-0710-4929_pdf_liviano_prueba_03.pdf.',
                page_number: 1,
              },
            },
            {
              path: 'api/original/org_f4727041-6714-443c-9247-e33ac381a94b_202107-0710-4942_pdf_liviano_prueba_03.pdf',
              metadata: {
                message:
                  'File uploaded successfully to api/original/org_f4727041-6714-443c-9247-e33ac381a94b_202107-0710-4942_pdf_liviano_prueba_03.pdf.',
                page_number: 1,
              },
            },
          ],
        },
        errors: [],
      },
      status_code: 200,
      created_at: '2021-07-07T15:49:22.980233+00:00',
      updated_at: '2021-07-07T15:49:48.049451+00:00',
      country: null,
      organization: 'Organization to fetch tests',
      org_uuid: 'f4727041-6714-443c-9247-e33ac381a94b',
      entity_name: 'Banco 2',
    },
    {
      id: 34,
      data: {
        data: {
          org_id: 'f4727041-6714-443c-9247-e33ac381a94b',
          templates: [
            {
              entity: '789123',
              country: 'COL',
              version: null,
              fields_ids: [
                {
                  message: 'please, check all fields while MVP',
                },
              ],
            },
            {
              entity: '789123',
              country: 'COL',
              version: null,
              fields_ids: [
                {
                  message: 'please, check all fields while MVP',
                },
              ],
            },
            {
              entity: '123456',
              country: 'COL',
              version: null,
              fields_ids: [
                {
                  message: 'please, check all fields while MVP',
                },
              ],
            },
            {
              entity: '789123',
              country: 'MEX',
              version: null,
              fields_ids: [
                {
                  message: 'please, check all fields while MVP',
                },
              ],
            },
            {
              entity: '123456',
              country: 'MEX',
              version: null,
              fields_ids: [
                {
                  message: 'please, check all fields while MVP',
                },
              ],
            },
            {
              entity: '789123',
              country: 'MEX',
              version: null,
              fields_ids: [
                {
                  message: 'please, check all fields while MVP',
                },
              ],
            },
          ],
          request_id: 34,
          external_id: '64533',
          paths_gcs_files: [
            {
              path: '/api/original/pdf_liviano_prueba_02.pdf',
              metadata: {
                message:
                  'File uploaded successfully to /api/original/pdf_liviano_prueba_02.pdf.',
                page_number: 1,
              },
            },
          ],
        },
        errors: [],
      },
      status_code: 200,
      created_at: '2021-07-06T14:22:50.542201+00:00',
      updated_at: '2021-07-06T14:22:54.726198+00:00',
      country: null,
      organization: 'Organization to fetch tests',
      org_uuid: 'f4727041-6714-443c-9247-e33ac381a94b',
      entity_name: 'Banco 2',
    },
    {
      id: 80,
      data: {
        data: {
          org_id: 'f4727041-6714-443c-9247-e33ac381a94b',
          templates: [
            {
              entity: '789123',
              country: 'MEX',
              version: 'Version 4',
              fields_ids: [
                {
                  message: 'please, check all fields while MVP',
                },
              ],
            },
            {
              entity: '789123',
              country: 'COL',
              version: 'Version 1',
              fields_ids: [
                {
                  message: 'please, check all fields while MVP',
                },
              ],
            },
            {
              entity: '789123',
              country: 'COL',
              version: 'Version 1',
              fields_ids: [
                {
                  message: 'please, check all fields while MVP',
                },
              ],
            },
            {
              entity: '789123',
              country: 'COL',
              version: 'Version 1',
              fields_ids: [
                {
                  message: 'please, check all fields while MVP',
                },
              ],
            },
            {
              entity: '789123',
              country: 'MEX',
              version: 'Version 7',
              fields_ids: [
                {
                  message: 'please, check all fields while MVP',
                },
              ],
            },
          ],
          request_id: '80',
          external_id: '64532',
          paths_gcs_files: [
            {
              path: 'api/original/file_1_org_f4727041-6714-443c-9247-e33ac381a94b_202107-0713-0603_pdf_liviano_prueba_02.pdf',
              metadata: {
                message:
                  'File uploaded successfully to api/original/file_1_org_f4727041-6714-443c-9247-e33ac381a94b_202107-0713-0603_pdf_liviano_prueba_02.pdf.',
                page_number: 1,
              },
            },
          ],
        },
        errors: [],
      },
      status_code: 200,
      created_at: '2021-07-07T18:05:56.311015+00:00',
      updated_at: '2021-07-07T18:06:27.738737+00:00',
      country: null,
      organization: 'Organization to fetch tests',
      org_uuid: 'f4727041-6714-443c-9247-e33ac381a94b',
      entity_name: 'Banco 2',
    },
    {
      id: 101,
      data: {
        data: {
          org_id: 'f4727041-6714-443c-9247-e33ac381a94b',
          templates: [
            {
              entity: '789123',
              country: 'MEX',
              version: 'Version 4',
              fields_ids: [
                {
                  message: 'please, check all fields while MVP',
                },
              ],
            },
            {
              entity: '789123',
              country: 'COL',
              version: 'Version 1',
              fields_ids: [
                {
                  message: 'please, check all fields while MVP',
                },
              ],
            },
            {
              entity: '789123',
              country: 'COL',
              version: 'Version 1',
              fields_ids: [
                {
                  message: 'please, check all fields while MVP',
                },
              ],
            },
            {
              entity: '789123',
              country: 'COL',
              version: 'Version 1',
              fields_ids: [
                {
                  message: 'please, check all fields while MVP',
                },
              ],
            },
            {
              entity: '789123',
              country: 'MEX',
              version: 'Version 7',
              fields_ids: [
                {
                  message: 'please, check all fields while MVP',
                },
              ],
            },
          ],
          request_id: '101',
          external_id: '79532',
          paths_gcs_files: [
            {
              path: 'api/original/file_1_org_f4727041-6714-443c-9247-e33ac381a94b_202107-0819-3758_imagen_liviana.jpg',
              metadata: {
                message:
                  'File uploaded successfully to api/original/file_1_org_f4727041-6714-443c-9247-e33ac381a94b_202107-0819-3758_imagen_liviana.jpg.',
                page_number: 1,
              },
            },
            {
              path: 'api/original/file_2_org_f4727041-6714-443c-9247-e33ac381a94b_202107-0819-3758_imagen_liviana.png',
              metadata: {
                message:
                  'File uploaded successfully to api/original/file_2_org_f4727041-6714-443c-9247-e33ac381a94b_202107-0819-3758_imagen_liviana.png.',
                page_number: 1,
              },
            },
          ],
        },
        errors: [],
      },
      status_code: 200,
      created_at: '2021-07-08T19:37:58.059507+00:00',
      updated_at: '2021-07-08T19:37:58.996762+00:00',
      country: null,
      organization: 'Organization to fetch tests',
      org_uuid: 'f4727041-6714-443c-9247-e33ac381a94b',
      entity_name: 'Banco 2',
    },
    {
      id: 35,
      data: {
        data: {
          org_id: 'f4727041-6714-443c-9247-e33ac381a94b',
          templates: [
            {
              entity: '789123',
              country: 'COL',
              version: null,
              fields_ids: [
                {
                  message: 'please, check all fields while MVP',
                },
              ],
            },
            {
              entity: '789123',
              country: 'COL',
              version: null,
              fields_ids: [
                {
                  message: 'please, check all fields while MVP',
                },
              ],
            },
            {
              entity: '123456',
              country: 'COL',
              version: null,
              fields_ids: [
                {
                  message: 'please, check all fields while MVP',
                },
              ],
            },
            {
              entity: '789123',
              country: 'MEX',
              version: null,
              fields_ids: [
                {
                  message: 'please, check all fields while MVP',
                },
              ],
            },
            {
              entity: '123456',
              country: 'MEX',
              version: null,
              fields_ids: [
                {
                  message: 'please, check all fields while MVP',
                },
              ],
            },
            {
              entity: '789123',
              country: 'MEX',
              version: null,
              fields_ids: [
                {
                  message: 'please, check all fields while MVP',
                },
              ],
            },
          ],
          request_id: 35,
          external_id: '64533',
          paths_gcs_files: [
            {
              path: '/api/original/pdf_liviano_prueba_02.pdf',
              metadata: {
                message:
                  'File uploaded successfully to /api/original/pdf_liviano_prueba_02.pdf.',
                page_number: 1,
              },
            },
          ],
        },
        errors: [],
      },
      status_code: 200,
      created_at: '2021-07-06T14:28:23.041662+00:00',
      updated_at: '2021-07-06T14:28:27.627370+00:00',
      country: null,
      organization: 'Organization to fetch tests',
      org_uuid: 'f4727041-6714-443c-9247-e33ac381a94b',
      entity_name: 'Banco 2',
    },
  ],
  response_code: null,
  errors: null,
  total: 147,
  page: 0,
  size: 10,
};
