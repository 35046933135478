<button
  [type]="type"
  [disabled]="isDisabled"
  [class]="'mo-button ' + size + ' ' + template"
  [ngClass]="{
    reverse: iconPosition === ICON_POSITIONS.RIGTH ? true : false,
    disabled: isDisabled
  }"
>
  <i *ngIf="iconPosition" [class]="'uil ' + icon + ' ' + iconPosition"></i>
  <span [ngClass]="{ 'is-loading': isLoading }">{{ text }}</span>
  <mo-loading *ngIf="isLoading" [size]="size" [color]="'light'"></mo-loading>
</button>
