import { Injectable } from '@angular/core';
import { INSIGHT_CONSTANTS } from '@app/pages/insight/insight.constants';

@Injectable({
  providedIn: 'root',
})
export class InsightService {
  private _filterConfigId: string;
  
  setFilterConfigId(config_id: string) {
    this._filterConfigId = config_id;
  }

  getFilterConfigId(): string {
    return this._filterConfigId;
  }

  getConfigModule() {
    return () =>
      import('@app/pages/insight/kpi/configuration/configuration.module').then(
        (m) => m.ConfigurationPageModule
      );
  }

  getRequestModule() {
    return () =>
      import('@app/pages/insight/request/request.module').then(
        (m) => m.RequestPageModule
      );
  }

  getRequestColumnState(value: string) {
    const status = INSIGHT_CONSTANTS.REQUEST_STATES;
    switch (value.toLowerCase()) {
      case status.STATUS_FAIL.key:
        return `<span class="request-status ${status.STATUS_FAIL.className}">${status.STATUS_FAIL.name}</span>`;
      case status.STATUS_PENDING.key:
        return `<span class="request-status ${status.STATUS_PENDING.className}">${status.STATUS_PENDING.name}</span>`;
      case status.STATUS_SUCCESS.key:
        return `<span class="request-status ${status.STATUS_SUCCESS.className}">${status.STATUS_SUCCESS.name}</span>`;
    }
  }
}
