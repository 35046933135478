import { Component, OnInit } from '@angular/core';
import { AppFacade } from '@app/facades/app.facade';
import { BUTTON_SIZE, BUTTON_TEMPLATE } from '@app/mo-forms/enums/button.type';
import { COMPONENTS, MODAL_SIZE, MODULES } from '../../modal-content.type';
import { Support } from '../../../../../core/models/insight.model';
import { InsightFacade } from '../../../../../facades/insight.facade';
import { Observable } from 'rxjs';

@Component({
  selector: 'upgrade-my-plan',
  templateUrl: './upgrade-my-plan.component.html',
  styleUrls: ['./upgrade-my-plan.component.scss'],
})
export class UpgradeMyPlanComponent implements OnInit {
  public BUTTON_TEMPLATES = BUTTON_TEMPLATE;
  public BUTTON_SIZE = BUTTON_SIZE;
  public showLoader: boolean;
  public upgradeMessage: Support;
  public isloadingForms$: Observable<boolean>;

  constructor(private _insightFacade: InsightFacade) {}

  ngOnInit() {
    this.isloadingForms$ = this._insightFacade.isloadingForms$;
    this.upgradeMessage = {
      subject: 'Geo upgrade request',
      message: 'Upgrade my plan',
    };
  }

  public upgradeMyPlan() {
    this._insightFacade.sendUpgradePlanSubmit(this.upgradeMessage);
  }
}
