import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';

import * as insightActions from '@app/store/insight/insight.actions';
import * as insightSelectors from '@app/store/insight/insight.selectors';
import { APP_CONSTANTS } from '@app/app.constants';
import { ISelectOption } from '@app/mo-forms/interfaces/select-option.interface';
import { BUTTON_SIZE, BUTTON_TEMPLATE } from '@app/mo-forms/enums/button.type';
import { AppFacade } from '@app/facades/app.facade';
import { Product } from '@app/core/models/app.model';
import { FETCH, INSIGHT } from '@app/core/constants/products.constant';

@Component({
  selector: 'layout-support-form',
  templateUrl: './support-form.component.html',
  styleUrls: ['./support-form.component.scss'],
})
export class SupportFormComponent implements OnInit, OnDestroy {
  public form: FormGroup;
  public productSelected$: Observable<Product>;
  public loading$: Observable<boolean>;
  public subjects: Array<ISelectOption<string>>;
  public privacyPolicy = APP_CONSTANTS.DOCUMENTS.PRIVACY_POLICY;
  public buttonTemplate;
  public BUTTON_SIZE = BUTTON_SIZE;
  private _productSelected$: Subscription;

  constructor(
    private _formBuilder: FormBuilder,
    private _store: Store,
    private _appFacade: AppFacade
  ) {}

  ngOnInit(): void {
    this.productSelected$ = this._appFacade.productSelected$;
    this._setupForms();
    this._setButtonColorSubscription();
    this.loading$ = this._store.select(insightSelectors.selectIsLoadingForms);
  }

  public onContinue(): void {
    if (this.form.invalid) return;
    this._store.dispatch(
      insightActions.sendSupportSubmit({ support: this.form.value })
    );
  }

  private _setButtonColorSubscription(): void {
    this._productSelected$ = this.productSelected$.subscribe(
      (product: Product) => {
        switch (product?.backendName.toLowerCase()) {
          case INSIGHT.backendName: {
            this.buttonTemplate = BUTTON_TEMPLATE.PRIMARY;
            this.form.controls.product_name.setValue(INSIGHT.backendName);
            break;
          }
          case FETCH.backendName: {
            this.buttonTemplate = BUTTON_TEMPLATE.INDIGO;
            this.form.controls.product_name.setValue(INSIGHT.backendName);
            break;
          }
          default: {
            this.buttonTemplate = BUTTON_TEMPLATE.PRIMARY;
            this.form.controls.product_name.setValue('');
            break;
          }
        }
      }
    );
  }

  private _setupForms(): void {
    // TODO: Set subjects by "productSelected$" value
    this.subjects = [
      { value: 'technical_failures', key: 'Technical support' },
      { value: 'special_request', key: 'Other' },
    ];
    this.form = this._formBuilder.group({
      subject: [this.subjects[0].value, [Validators.required]],
      message: ['', [Validators.required]],
      product_name: [''],
    });
  }

  ngOnDestroy(): void {
    this.form.reset();
    this._productSelected$.unsubscribe();
  }
}
