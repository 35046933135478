<div class="container-menu-accordeon" [class.selected]="active">
  <div class="container-item-menu" [class]="product">
    <div class="item-menu" (click)="onToggleAccordeon()">
      <img [src]="icon" />
      <label class="item-label">{{ label }}</label>
      <i class="uil" [ngClass]="active ? 'uil-angle-up' : 'uil-angle-down'"></i>
    </div>
  </div>
  <div
    class="container-accordeon"
    *ngIf="active && collapsed"
    [@inOutAnimation]
  >
    <ng-content></ng-content>
  </div>
</div>
