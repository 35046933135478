import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { Dialog } from '@app/core/class/dialog.class';
import {
  INotificationParams,
  NotificationType,
} from '@app/core/models/app.model';
import { IDialogParams } from '@app/core/models/dialog.model';
import {
  ISidebarModal,
  ISidebarModalParams,
} from '@app/core/models/sidebar-modal.model';

import { DialogComponent } from '@app/layout/components/dialog/dialog.component';
import { NotificationComponent } from '@app/layout/components/notification/notification.component';
import { SidebarModalService } from '@app/layout/components/sidebar-modal/sidebar-modal.service';
import { ConfigurationSidebarComponent } from '@app/pages/insight/kpi/configuration/components/configuration-sidebar/configuration-sidebar.component';

@Injectable({ providedIn: 'root' })
export class UIService {
  private _snackRef: MatSnackBarRef<any>;

  constructor(
    private _dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private _sidebarModalService: SidebarModalService
  ) {}

  public showConfirmDelete(
    message: string,
    confirm: Function,
    cancel: Function
  ) {
    const dataDialog: IDialogParams = {
      title: '',
      message: `<p class="modal-body__message text-center"> ${message} </p> `,
      isHTMLMessage: true,
      callbacks: {
        ok: confirm,
        cancel: cancel,
      },
      infoButton: {
        showButtonOk: true,
        showButtonClose: true,
        showButtonCancel: true,
        classNameOk: 'alert',
        textCancel: 'Cancel',
        textOk: 'Delete',
      },
    };

    this.showDialog(dataDialog);
  }

  public showDialog(
    data: IDialogParams,
    component: ComponentType<Dialog> = DialogComponent
  ) {
    const SIZE_DIALOG = '30rem';
    const className = data.className ?? 'mat-modal';

    if (!data.isHTMLMessage) {
      const align = data.textAlign ? 'text-center' : '';
      data.message = `<p class="modal-body__message ${align}"> ${data.message} </p> `;
    }

    const confDialog = {
      width: SIZE_DIALOG,
      data,
      disableClose: true,
      panelClass: className,
    };
    this._dialog.open(component, confDialog);
  }

  public openDialog(
    component: ComponentType<Dialog> = DialogComponent,
    config: MatDialogConfig
  ): MatDialogRef<any> {
    return this._dialog.open(component, config);
  }

  public showNotification(msg: string, type?: NotificationType, time = 5000) {
    const notificationParams = { type, message: msg };
    this.showNotificationParams(notificationParams, time);
  }

  public showNotificationParams(params: INotificationParams, time = 5000) {
    this._snackRef = this._snackBar.openFromComponent(NotificationComponent, {
      data: params,
      duration: time,
      panelClass: 'notification',
      horizontalPosition: 'end',
      verticalPosition: 'bottom',
    });
  }

  public showSnackbar(msg: string, time = 5000, className = 'snackbar-error') {
    this._snackRef = this._snackBar.open(msg, 'x', {
      duration: time,
      panelClass: [className],
    });
    this._snackRef.onAction().subscribe(() => {
      this._snackRef.dismiss();
    });
  }

  public showSuccessSnackbar(
    msg: string,
    time = 5000,
    className = 'mat-simple-snackbar-success'
  ) {
    this.showSnackbar(msg, time, className);
  }

  public showShortcutSidebar() {
    this.showDetailSidebar(ConfigurationSidebarComponent, { title: 'TESTING' });
  }

  public showDetailSidebar(
    component: ComponentType<ISidebarModal>,
    data: ISidebarModalParams,
    module?: Function
  ) {
    this._sidebarModalService.open(component, data, module);
  }

  public openDetailSidebar(
    component: ComponentType<ISidebarModal>,
    data: ISidebarModalParams,
    module?: Function
  ) {
    this._sidebarModalService.open(component, data, module);
  }

  public closeDetailSidebar() {
    this._sidebarModalService.close();
  }

  public changeTitleSidebar(title: string) {
    this._sidebarModalService.changeTitle(title);
  }
}
