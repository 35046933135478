import {
  HttpErrorResponse,
  HttpEvent,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { LOGIN_SUCCESS } from './data/auth/login.mock';
import { FETCH_COUNTRIES_SUCCESS } from './data/fetch/countries.mock';
import { TYPES_TEMPLATES_SUCCESS } from './data/fetch/types-templates.mock';
import { TEMPLATES_SUCCESS } from './data/fetch/templates.mock';
import { KPIS_PARENTS_SUCCESS } from './data/insight/parents-kpis.mock';
import { KPIS_SUCCESS } from './data/insight/kpis.mock';
import { APP_CONSTANTS as CONST } from '@app/app.constants';
import { CHANGE_PASSWORD_SUCCESS } from './data/auth/change-password.mock';
import { VALIDATE_TEMPORAL_TOKEN_SUCCESS } from './data/auth/validate-temporal-token.mock';
import { ORGANIZATIONS_SUCCESS } from './data/user/user-info.mock';
import { INSIGHT_COUNTRIES_SUCCESS } from './data/insight/countries.mock';
import { DATA_COUNTRIES_SUCCESS } from './data/insight/data-countries.mock';
import { REQUESTS_STATES_SUCCESS } from './data/insight/request-state.mock';
import { STATUS_REQUEST_SUCCESS } from './data/insight/status-request.mock';
import { REQUEST_SUCCESS } from './data/insight/request.mock';
import { CREATE_REQUEST_COORDINATES_SUCCESS } from './data/insight/create-request-coordinates.mock';
import { CREATE_REQUEST_ADDRESS_SUCCESS } from './data/insight/create-request-address.mock';
import { DOWNLOAD_URL_SUCCESS } from './data/insight/download-url.mock';
import { CONFIGURATIONS_SUCCESS } from './data/insight/configurations.mock';
import { CONFIGURATIONS_LIST_SUCCESS } from './data/insight/configurations-list.mock';
import { API_KEY_SUCCESS } from './data/insight/api-key.mock';
import { TEMPLATES_VERSION_SUCCESS } from './data/fetch/templates-version.mock';
import { TEMPLATES_VERSION_FIELDS_SUCCESS } from './data/fetch/templates-version-fields.mock';
import { DOCUMENTS_SUCCESS } from './data/fetch/documents.mock';
import { DOCUMENTS_DETAIL_SUCCESS } from './data/fetch/document-detail.mock';
import { DOCUMENT_FILE_SUCCESS } from './data/fetch/document-file.mock';
import { FETCH_STATES_SUCCESS } from './data/fetch/states.mock';
import { DOCUMENT_DETAILS_TRANSACTIONS_SUCCESS } from './data/fetch/documents-detail-transactions.mock';
import { OVERVIEW_HEADER_SUCCESS } from './data/fetch/overview-header.mock';
import { OVERVIEW_USE_SUCCESS } from './data/fetch/overview-use.mock';
import { OVERVIEW_PERFORMANCE_SUCCESS } from './data/fetch/overview-performance.mock';
import { OVERVIEW_SUBMISION_SUCCESS } from './data/fetch/overview-suggestions.mock';

const ROUTES_MOCK = [
  // Auth
  { url: CONST.URLS.LOGIN, data: LOGIN_SUCCESS },
  { url: CONST.URLS.SEND_FORGOT_PASSWORD_EMAIL, data: {} },
  { url: CONST.URLS.CHANGE_PASSWORD, data: CHANGE_PASSWORD_SUCCESS },
  {
    url: CONST.URLS.VALIDATE_TEMPORAL_TOKEN,
    data: VALIDATE_TEMPORAL_TOKEN_SUCCESS,
  },
  // User
  { url: CONST.URLS.ORGANIZATIONS, data: ORGANIZATIONS_SUCCESS },
  // Insight
  { url: CONST.URLS.COUNTRIES, data: INSIGHT_COUNTRIES_SUCCESS },
  { url: CONST.URLS.DATA_COUNTRIES, data: DATA_COUNTRIES_SUCCESS },
  { url: CONST.URLS.REQUESTS_STATES, data: REQUESTS_STATES_SUCCESS },
  { url: CONST.URLS.STATUS_REQUEST + '.+/', data: STATUS_REQUEST_SUCCESS },
  { url: CONST.URLS.REQUEST, data: REQUEST_SUCCESS },
  {
    url: CONST.URLS.CREATE_REQUEST_COORDINATES,
    data: CREATE_REQUEST_COORDINATES_SUCCESS,
  },
  {
    url: CONST.URLS.CREATE_REQUEST_ADDRESS,
    data: CREATE_REQUEST_ADDRESS_SUCCESS,
  },
  { url: CONST.URLS.DOWNLOAD_URL, data: DOWNLOAD_URL_SUCCESS },
  {
    url: CONST.URLS.CONFIGURATIONS,
    data: CONFIGURATIONS_SUCCESS,
    method: 'PUT',
  },
  {
    url: CONST.URLS.CONFIGURATIONS,
    data: CONFIGURATIONS_SUCCESS,
    method: 'POST',
  },
  {
    url: CONST.URLS.CONFIGURATIONS,
    data: CONFIGURATIONS_SUCCESS,
    method: 'DELETE',
  },
  {
    url: CONST.URLS.CONFIGURATIONS,
    data: CONFIGURATIONS_SUCCESS,
    method: 'GET',
  },
  { url: CONST.URLS.CONFIGURATIONS_LIST, data: CONFIGURATIONS_LIST_SUCCESS },
  { url: CONST.URLS.KPIS_PARENTS, data: KPIS_PARENTS_SUCCESS },
  { url: CONST.URLS.KPIS, data: KPIS_SUCCESS },
  { url: CONST.URLS.API_KEY, data: API_KEY_SUCCESS },
  { url: CONST.URLS.SUPPORT, data: {} },
  // Fetch
  { url: CONST.URLS.COUNTRY, data: FETCH_COUNTRIES_SUCCESS },
  { url: CONST.URLS.STATES, data: FETCH_STATES_SUCCESS },
  { url: CONST.URLS.TEMPLATES_TYPE, data: TYPES_TEMPLATES_SUCCESS },
  { url: CONST.URLS.TEMPLATES, data: TEMPLATES_SUCCESS },
  { url: CONST.URLS.TEMPLATES_VERSION, data: TEMPLATES_VERSION_SUCCESS },
  {
    url: CONST.URLS.TEMPLATES_VERSION_FIELDS,
    data: TEMPLATES_VERSION_FIELDS_SUCCESS,
  },
  { url: CONST.URLS.DOCUMENTS, data: DOCUMENTS_SUCCESS },
  { url: CONST.URLS.DOCUMENT_DETAILS, data: DOCUMENTS_DETAIL_SUCCESS },
  {
    url: CONST.URLS.DOCUMENT_DETAILS_TRANSACTIONS,
    data: DOCUMENT_DETAILS_TRANSACTIONS_SUCCESS,
  },
  { url: CONST.URLS.DOCUMENT_FILE, data: DOCUMENT_FILE_SUCCESS },
  { url: CONST.URLS.OVERVIEW_HEADER, data: OVERVIEW_HEADER_SUCCESS },
  { url: CONST.URLS.OVERVIEW_USE, data: OVERVIEW_USE_SUCCESS },
  { url: CONST.URLS.OVERVIEW_PERFORMANCE, data: OVERVIEW_PERFORMANCE_SUCCESS },
  { url: CONST.URLS.OVERVIEW_SUBMISION, data: OVERVIEW_SUBMISION_SUCCESS },
];

export class ServerMock {
  public request(req: HttpRequest<any>): Observable<HttpEvent<any>> {
    const statusResponse = 200;
    const response = ROUTES_MOCK.find((route) => {
      const isSameRoute = new RegExp(`(^${route.url}$)`, 'g').test(req.url);
      const isSameMethod = !route?.method || route?.method === req.method;
      return isSameRoute && isSameMethod;
    });

    if (!response) {
      return throwError(
        new HttpErrorResponse({
          status: 404,
          statusText: `The route was not found ${req.url}`,
        })
      );
    }

    return new Observable((obs: any) => {
      setTimeout(() => {
        obs.next(
          new HttpResponse({ status: statusResponse, body: response.data })
        );
        obs.complete();
      }, environment.testServeWait);
    });
  }
}
