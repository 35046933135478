import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { APP_CONSTANTS } from '@app/app.constants';
import { GA } from '@app/core/constants/google-analytics.constant';
import { Configuration, Group } from '@app/core/models/insight.model';

import { RequestSuccess } from '@app/core/models/request.model';
import { AppFacade } from '@app/facades/app.facade';
import { InsightFacade } from '@app/facades/insight.facade';
import {} from '@app/layout/components/bottombar-modal/bottombar-interface';
import {
  BUTON_TYPE,
  BUTTON_SIZE,
  BUTTON_TEMPLATE,
} from '@app/mo-forms/enums/button.type';
import { COLOR_TEMPLATE, INPUT_SIZE } from '@app/mo-forms/enums/input.type';
import { ISelectOption } from '@app/mo-forms/interfaces/select-option.interface';
import { GoogleAnalyticsService } from '@app/services/google-analytics.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'form-new-configuration',
  templateUrl: './form-new-configuration.component.html',
})
export class FormNewConfigurationComponent implements OnInit {
  public newConfigurationForm!: FormGroup;
  public kpisValues: Array<ISelectOption<boolean>>;
  public regexCode: RegExp;
  public groups: Group[];
  public selectedCountry: string;

  public INPUT_SIZE = INPUT_SIZE;
  public INPUT_TEMPLATES = COLOR_TEMPLATE;
  public BUTON_TEMPLATES = BUTTON_TEMPLATE;
  public BUTTON_SIZE = BUTTON_SIZE;
  public BUTON_TYPE = BUTON_TYPE;

  public isResponded$: Observable<boolean>;
  public isLoading$: Observable<boolean>;
  public isLoadingConfigs$: Observable<boolean>;
  public error$: Observable<string>;
  public configuration$: Observable<Configuration>;
  public step$: Observable<number>;
  public success$: Observable<RequestSuccess>;

  constructor(
    private _insightFacade: InsightFacade,
    private _formBuilder: FormBuilder,
    private _gaService: GoogleAnalyticsService
  ) {
    this.regexCode = APP_CONSTANTS.REGEX.CODE;
  }

  public ngOnInit() {
    this.error$ = this._insightFacade.errorConfigForms$;
    this.isLoading$ = this._insightFacade.isloadingForms$;
    this.configuration$ = this._insightFacade.configuration$;
    this.step$ = this._insightFacade.configurationStep$;
    this._gaService.sendEvent(GA.CONFIGURATION.PAGE_VIEW);
    this._mapGroups();
    this._setupForm();
    this._loadDefaultCountry();
  }

  public ngOnDestroy() {
    this._resetForms();
  }

  // Public methods
  public onSubmitForm() {
    if (this.newConfigurationForm.invalid) return;
    const config: Configuration = {
      name: this.newConfigurationForm.get('name').value,
      description: this.newConfigurationForm.get('description').value,
      groups: this._getSelectedGroups(),
      country_code: this.selectedCountry,
      premium_kpis: this.newConfigurationForm.get('premium_kpis').value,
    };

    this._insightFacade.submitCreateConfiguration(config);
  }

  public get kpi_groups() {
    return this.newConfigurationForm.get('kpi_groups') as FormArray;
  }

  public onClose() {
    this._resetForms();
  }

  // Private methods

  public _resetForms() {
    this._insightFacade.leaveRequestForms();
    this._insightFacade.changeStepConfiguration(1);
  }

  private _setupForm() {
    this.newConfigurationForm = this._formBuilder.group({
      name: [
        '',
        [Validators.required, Validators.pattern(APP_CONSTANTS.REGEX.CODE)],
      ],
      description: ['', [Validators.required]],
      kpi_groups: [[], [Validators.required]],
      premium_kpis: [false, [Validators.required]],
    });
  }

  private _mapGroups() {
    this._insightFacade.infoCountry$.subscribe(({ groups }) => {
      this.groups = groups;
      this.kpisValues = groups.map((group) => {
        return { value: false, key: group.group_key, label: group.group_name };
      });
    });
  }

  private _getSelectedGroups() {
    let selected = [];
    this.groups.forEach((group) => {
      const filter = this.newConfigurationForm
        .get('kpi_groups')
        .value.filter((kpi) => kpi.key === group.group_key)
        .shift();
      if (filter) {
        selected.push({ ...group, is_selected: true });
      }
    });
    return selected;
  }

  private _loadDefaultCountry() {
    this._insightFacade.country$.subscribe((country) => {
      this.selectedCountry = country;
    });
  }
}
