export enum CallBackType {
  CLEAR_FILTERS = 'CLEAR_FILTERS',
  OPEN_FORM_BUTTOM_BAR = 'OPEN_FORM_BUTTOM_BAR',
}

export interface EmptyConfig {
  with_empty_response: EmptyData;
  with_filters: EmptyData;
}

export interface EmptyData {
  title: string;
  description: string;
  button_label: string;
  call_back_type: CallBackType;
  call_back?: Function;
}
