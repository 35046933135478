import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';

declare const grecaptcha: any;

declare global {
  interface Window {
    grecaptcha: any;
    reCaptchaLoad: () => void;
  }
}

export interface ReCaptchaConfig {
  theme?: 'dark' | 'light';
  type?: 'audio' | 'image';
  size?: 'compact' | 'normal';
  tabindex?: number;
}

@Component({
  selector: 'mo-captcha',
  template: ``,
  styleUrls: ['./mo-captcha.component.scss'],
})
export class MoCaptchaComponent implements OnInit {
  @Input() key: string;
  @Input() config: ReCaptchaConfig = {};
  @Input() lang: string;

  @Output() captchaResponse = new EventEmitter<string>();
  @Output() captchaExpired = new EventEmitter();

  public widgetId: number;

  private onChange: (value: string) => void;
  private onTouched: (value: string) => void;

  constructor(private element: ElementRef) {}

  ngOnInit() {
    this._registerReCaptchaCallback();
    this._addScript();
  }

  private _render(element: HTMLElement, config): number {
    return grecaptcha.render(element, config);
  }

  private _registerReCaptchaCallback() {
    window.reCaptchaLoad = () => {
      const config = {
        ...this.config,
        sitekey: this.key,
        callback: this._onSuccess.bind(this),
        'expired-callback': this._onExpired.bind(this),
      };
      this.widgetId = this.render(this.element.nativeElement, config);
    };
  }

  private _addScript() {
    let script = document.createElement('script');
    const lang = this.lang ? '&hl=' + this.lang : '';
    script.src = `https://www.google.com/recaptcha/api.js?onload=reCaptchaLoad&render=explicit${lang}`;
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);
  }

  private render(element: HTMLElement, config): number {
    return grecaptcha.render(element, config);
  }

  private _onExpired() {
    this.captchaExpired.emit(null);
    this.onChange(null);
    this.onTouched(null);
  }

  private _onSuccess(token: string) {
    this.captchaResponse.next(token);
    this.onChange(token);
    this.onTouched(token);
  }
}
